.widget--newslisting
{
  --content-layout:             verywide;
  /*--content-squeeze-preference: needwide;*/

  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

  .widget--newslisting__item
, .widget--newslisting hr
{
  grid-column: var(--pagegrid-col-verywide);
}


.widget--newslisting__item
{
  text-decoration: none;
  color: #4F4F4F;
}

  .newslisting__item__image
, .newslisting__item__image-placeholder
{
  aspect-ratio: 40 / 25;
  width: 417px;
  max-width: 30vw;

  margin-right: 45px;
  margin-right: min(45px, 4vw);

  object-fit: cover;

  flex: none;
}

.newslisting__item__image-placeholder
{
  background-color: var(--color-lightgrey);
}

.newslisting__item__meta
{
  display: flex;
  flex-direction: column;
}

.newslisting__item__meta__date
{
  font: 17px var(--rtd-text-fontfamily);
  margin-bottom: 10px;
}

.newslisting__item__meta__date::before
{
  margin-top: -5px; /* compensate for Officina font weird baseline alignment */

  font: 200 18px 'Font Awesome 5 Pro';
  content: var(--fa-calendar-day);

  margin-right: 10px;
}



.newslisting__item__meta__title
{
  color: var(--color-black);
}

.widget--newslisting hr
{
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--color-divider);
}




@media (max-width: 549px)
{
  .widget--newslisting__item
  {
    display: grid;
    grid-column: minmax(45px, 4vw) 1fr;
    grid-row: min-content min-content;
  }
  .newslisting__item__image
  {
    grid-column: 1;
    grid-row: 1;
  }
  .newslisting__item__meta
  {
    grid-column: 2;
    grid-row: 1;
  }
  .newslisting__item__tags
  {
    grid-column: 1 / span 2;
    grid-row: 2;
  }

  .newslisting__item__meta__title
  {
    font: bold 25px var(--rtd-heading-fontfamily);
    margin-bottom: 15px;
  }

  .newslisting__item__meta__description
  {
    font: 17px/24px var(--rtd-text-fontfamily);
  }

  .newslisting__item__tags
  {
    margin-top: 20px;
  }

  .widget--newslisting hr
  {
    width: 100%;
    margin-top:    20px;
    margin-bottom: 20px;
  }
}



@media (min-width: 550px)
{
  .widget--newslisting__item
  {
    display: grid;
    grid-template-columns: min(450px, 36vw) 1fr;
    grid-template-rows: 1fr min-content;
  }
  .newslisting__item__image
  {
    grid-column: 1;
    grid-row:  1 / span 2;
    height:  100%;
  }
  .newslisting__item__meta
  {
    grid-column: 2;
    grid-row: 1;

    padding-top: 23px;
  }
  .newslisting__item__tags
  {
    grid-column: 2;
    grid-row: 2;
  }

  .newslisting__item__meta__title
  {
    font: bold 32px/32px var(--rtd-heading-fontfamily);
    margin-bottom: 15px;
  }

  .newslisting__item__meta__description
  {
    font: 20px/32px var(--rtd-text-fontfamily);
  }

  .newslisting__item__tags
  {
    margin-top: 20px;
  }

  .widget--newslisting hr
  {
    width: 100%;
    margin-top: 36px;
    margin-bottom:  36px;
  }
}
