/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*

.wh-tagedit-selected SHOULD BE .wh-tagedit-tag--selected

*/
.wh-tagedit__addtagbar {
  margin-top: 5px;
  flex: 0 0 100%;
  display: flex;
}

.wh-tagedit-input.wh-tagedit-input {
  flex: 1 1 0 !important;
  border-radius: var(--formcontrols-radius) 0 0 var(--formcontrols-radius);
}

.wh-tagedit__addbutton.wh-tagedit__addbutton.wh-tagedit__addbutton {
  margin-left: 15px;
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
  height: 43px;
  border-radius: 0 var(--formcontrols-radius) var(--formcontrols-radius) 0;
}
.wh-tagedit__addbutton.wh-tagedit__addbutton.wh-tagedit__addbutton:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.wh-tagedit__addbutton.wh-tagedit__addbutton.wh-tagedit__addbutton .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.fourtu-tagedit__items {
  align-items: stretch;
  border: 1px solid #e3e0de;
  background-color: #fcfcfc;
  color: #7a7a7a;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 19px;
  position: absolute;
  z-index: 1000;
}

.fourtu-tagedit__itemvalue {
  cursor: pointer;
  display: block;
  flex: 1;
  padding: 2px 4px;
}
.fourtu-tagedit__itemvalue:hover {
  background-color: var(--color-theme);
  color: #fff;
}

.fourtu-tagedit .wh-form__textinput.wh-form__textinput {
  width: 0;
  flex: 0 1 0px;
  outline: 2px solid #F00;
  position: absolute;
  visibility: hidden;
}

.wh-tagedit-input {
  width: 100px !important;
  border: 1px solid #666;
  border: 1px solid #666666 !important;
}

.wh-tagedit {
  display: flex !important;
  flex-wrap: wrap;
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.wh-tagedit-tag {
  display: flex !important;
  align-items: center;
  height: 29px;
  font: 300 14px var(--rtd-text-fontfamily);
  background-color: #FFFFFF;
  border: 1px solid #8E8E8E;
  border-radius: 16px;
  color: #8E8E8E;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 15px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  cursor: pointer;
  max-width: 100%;
}

.wh-tagedit-tag:hover {
  border-color: var(--color-theme);
}

.wh-tagedit-selected {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
  color: #FFFFFF;
}

.wh-tagedit-tag__deletebutton::after {
  margin-left: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  font-size: 14px;
}

.wh-tagedit-tag__deletebutton:hover::after {
  color: var(--color-theme-text);
  transform: scale(1.2);
}