/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.innovatorcard {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: #FFFFFF;
  box-shadow: var(--itempanel-box-shadow);
  padding-bottom: 15px;
}

.innovatorcard > * {
  pointer-events: none;
}

.innovatorcard a {
  pointer-events: auto;
}

.innovatorcard__anchor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.innovatorcard__contactinfo {
  position: relative;
}

.innovatorcard::before {
  content: "";
  display: block;
  height: 117px;
  background-color: var(--color-almond-dark);
}

.innovatorcard__universitylogo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 40px;
  width: 193px;
  height: 31px;
  object-fit: contain;
}

.innovatorcard__photo {
  margin-top: -33px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  background-size: cover;
  background-color: #F2EFED;
  border-radius: 50%;
}

.innovatorcard__photo::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f007";
  color: var(--color-theme);
  font-size: 28px;
}

.innovatorcard__photo.b-loaded::before {
  display: none;
}

.innovatorcard__contactinfo {
  margin-top: 17px;
  flex: 1 0 auto;
  padding: 0 20px;
  text-align: center;
  font: 15px/21px var(--rtd-text-fontfamily);
  color: var(--rtd-text-color);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.innovatorcard__name {
  color: #3F3F3F;
  font-weight: bold;
}

.innovatorcard__contactinfo a {
  text-decoration: none;
  color: inherit;
}

.innovatorcard__contactinfo a:hover {
  text-decoration: underline;
}

.innovatorcard__name {
  color: #3F3F3F;
}

.innovatorcard__website {
  text-decoration: none;
}

.innovatorcard__view {
  margin-top: auto;
  align-self: center;
  display: flex;
  align-items: center;
  color: var(--color-theme);
  font: 15px var(--rtd-text-fontfamily);
}

.innovatorcard__view::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  font-size: 20px;
  margin-right: 6px;
}