/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*

FUTURE:
- use container query for opt-ins:
    - Expand filter toggle
        - Toggle in Innovations page style (outline orange, hover solid orange)
        - Toggle in 4TU Ethics overview page style (solid orange when closed, black when open)
    - Searchquery search button
        - symbol within the input
        - solid button (label + symbol) merged with the text input

*/
.header-innovations__filterbar {
  --button-font: bold 16px var(--rtd-heading-fontfamily);
}

.header-filterbar__togglefilters {
  text-align: left;
}

.header-filterbar__togglefilters > * {
  display: flex;
}

.header-filterbar__togglefilters__label {
  flex: 1 1 auto;
}

@media (max-width: 767px) {
  .header-filterbar {
    margin-top: 25px;
  }
  .header-filterbar__querycontainer {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .header-filterbar__query {
    flex: 1 0 0px;
    height: 44px;
    border: 0;
    background-color: #FFFFFF;
    font: 15px var(--rtd-heading-fontfamily);
    color: #6F6F6F;
    margin-right: 0; /* Safari fix */
  }
  .header-filterbar__querycontainer {
    position: relative;
  }
  .header-filterbar__query {
    padding-right: 45px; /* make room for the search button - so the text and empty button don't overlap the search icon */
  }
  .header-filterbar__submit {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    margin-left: 0; /* Safari fix */
    position: absolute;
    right: 15px;
    height: 100%;
    font-size: 0;
  }
  .header-filterbar__submit .fa-search {
    font-size: 18px;
    color: var(--color-theme);
  }
  html.innovationspage .header-filterbar__togglefilters {
    border: 0 none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font: var(--button-font);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: var(--formcontrols-height);
    padding: var(--button-text-alignment-tweak) 20px 0 20px;
    border-radius: var(--button-borderradius);
    transition: background-color 0.3s;
    cursor: pointer;
    outline-offset: 2px;
    background-color: transparent;
    border: 1px solid var(--button-color);
    color: var(--button-color);
    --button-color: #FFFFFF;
    --button-contrastcolor: #000000;
  }
  html.innovationspage .header-filterbar__togglefilters:hover {
    background-color: var(--button-color);
    color: #FFFFFF;
    color: var(--button-contrastcolor);
    text-decoration: none;
  }
  html.innovationspage .header-filterbar__togglefilters .wh-form__button--disabled {
    border: 1px solid var(--button-disabled-backgroundcolor);
    color: #666666;
    cursor: default;
  }
  html:not(.innovationspage) .header-filterbar__togglefilters {
    border: 0 none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font: var(--button-font);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: var(--formcontrols-height);
    padding: var(--button-text-alignment-tweak) 20px 0 20px;
    border-radius: var(--button-borderradius);
    transition: background-color 0.3s;
    cursor: pointer;
    outline-offset: 2px;
    background-color: var(--button-color);
    color: #FFFFFF;
    border: 0;
  }
  html:not(.innovationspage) .header-filterbar__togglefilters:hover {
    background-color: var(--button-color-hover);
    color: #FFFFFF;
    text-decoration: none;
  }
  html:not(.innovationspage) .header-filterbar__togglefilters .wh-form__button--disabled {
    background-color: var(--button-disabled-backgroundcolor);
    color: #666666;
    cursor: default;
  }
  .header-filterbar__togglefilters {
    margin-top: 14px;
    width: 100%;
    height: 44px;
    text-transform: none;
  }
  .header-filterbar__togglefilters:hover {
    text-decoration: none;
  }
  .header-filterbar__togglefilters__icon {
    font-size: 18px;
  }
  .header-filterbar__query,
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    height: 43px;
  }
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    height: 44px;
    text-transform: none;
  }
  .header-filterbar__submit:hover,
  .header-filterbar__togglefilters:hover {
    text-decoration: none;
  }
}
@media (min-width: 768px) {
  html.innovationspage .header-filterbar__query.header-filterbar__query {
    /* clears the 'X' from Chrome */
    border-radius: var(--formcontrols-radius) 0 0 var(--formcontrols-radius);
    border-right: 0;
  }
  html.innovationspage .header-filterbar__query.header-filterbar__query::-webkit-search-decoration, html.innovationspage .header-filterbar__query.header-filterbar__query::-webkit-search-cancel-button, html.innovationspage .header-filterbar__query.header-filterbar__query::-webkit-search-results-button, html.innovationspage .header-filterbar__query.header-filterbar__query::-webkit-search-results-decoration {
    display: none;
  }
  html.innovationspage .header-filterbar__submit.header-filterbar__submit {
    border-radius: 0 var(--formcontrols-radius) var(--formcontrols-radius) 0;
  }
  html.innovationspage .header-filterbar__submit {
    border: 0 none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font: var(--button-font);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: var(--formcontrols-height);
    padding: var(--button-text-alignment-tweak) 20px 0 20px;
    border-radius: var(--button-borderradius);
    transition: background-color 0.3s;
    cursor: pointer;
    outline-offset: 2px;
    background-color: var(--button-color);
    color: #FFFFFF;
    border: 0;
  }
  html.innovationspage .header-filterbar__submit:hover {
    background-color: var(--button-color-hover);
    color: #FFFFFF;
    text-decoration: none;
  }
  html.innovationspage .header-filterbar__submit .wh-form__button--disabled {
    background-color: var(--button-disabled-backgroundcolor);
    color: #666666;
    cursor: default;
  }
  html.innovationspage .header-filterbar__submit .fa-search {
    margin-left: 52px;
  }
  html.innovationspage .header-filterbar__togglefilters {
    border: 0 none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font: var(--button-font);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: var(--formcontrols-height);
    padding: var(--button-text-alignment-tweak) 20px 0 20px;
    border-radius: var(--button-borderradius);
    transition: background-color 0.3s;
    cursor: pointer;
    outline-offset: 2px;
    background-color: transparent;
    border: 1px solid var(--button-color);
    color: var(--button-color);
  }
  html.innovationspage .header-filterbar__togglefilters:hover {
    background-color: var(--button-color);
    color: #FFFFFF;
    color: var(--button-contrastcolor);
    text-decoration: none;
  }
  html.innovationspage .header-filterbar__togglefilters .wh-form__button--disabled {
    border: 1px solid var(--button-disabled-backgroundcolor);
    color: #666666;
    cursor: default;
  }
  html:not(.innovationspage) .header-filterbar__querycontainer {
    position: relative;
  }
  html:not(.innovationspage) .header-filterbar__query {
    padding-right: 45px; /* make room for the search button - so the text and empty button don't overlap the search icon */
  }
  html:not(.innovationspage) .header-filterbar__submit {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    margin-left: 0; /* Safari fix */
    position: absolute;
    right: 15px;
    height: 100%;
    font-size: 0;
  }
  html:not(.innovationspage) .header-filterbar__submit .fa-search {
    font-size: 18px;
    color: var(--color-theme);
  }
  html:not(.innovationspage) .header-filterbar__togglefilters {
    border: 0 none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font: var(--button-font);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: var(--formcontrols-height);
    padding: var(--button-text-alignment-tweak) 20px 0 20px;
    border-radius: var(--button-borderradius);
    transition: background-color 0.3s;
    cursor: pointer;
    outline-offset: 2px;
    background-color: var(--button-color);
    color: #FFFFFF;
    border: 0;
  }
  html:not(.innovationspage) .header-filterbar__togglefilters:hover {
    background-color: var(--button-color-hover);
    color: #FFFFFF;
    text-decoration: none;
  }
  html:not(.innovationspage) .header-filterbar__togglefilters .wh-form__button--disabled {
    background-color: var(--button-disabled-backgroundcolor);
    color: #666666;
    cursor: default;
  }
  html:not(.innovationspage).filteredoverview--showfilters .header-filterbar__togglefilters {
    --button-color: #3F3F3F;
  }
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    height: 43px;
    text-transform: none;
  }
  .header-filterbar__submit:hover,
  .header-filterbar__togglefilters:hover {
    text-decoration: none;
  }
}
@media (max-width: 767px) {
  .header-innovations__expandedfilters {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  #membersfilter .header-expandedfilters,
  #blogsoverviewfilter .header-expandedfilters,
  #newsoverviewfilter .header-expandedfilters {
    margin-top: 0;
  }
  .innovationspage--overview .genericfilters__foldout__heading, .innovationspage--overview .projects__filters__status,
  .innovationspage--overview .projects__filters__sortby,
  .innovationspage--overview .genericfilters__panel {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .header-innovations__expandedfilters {
    grid-row: 5;
    grid-column: var(--pagegrid-col-fullwidth);
  }
  .header-innovations__expandedfilters {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  html.innovationspage .header-expandedfilters__content {
    padding-top: 30px;
  }
  .header-innovations__expandedfilters .header-expandedfilters__content {
    grid-column: var(--pagegrid-col-verywide);
  }
  .innovationspage--overview {
    /*
    Projects overview page filters panel
    */
  }
  .innovationspage--overview .projects__filters__content {
    display: flex;
    flex-wrap: wrap;
  }
  .innovationspage--overview .projects__filters__content .projects__filters__sortby select {
    width: 100%;
  }
  .innovationspage--overview .projects__filters__content .projects__filters__status {
    margin-left: 30px;
    flex: 1 0 auto;
    min-width: min-content;
    align-self: center;
  }
  .innovationspage--overview .projects__filters__content .projects__filters__breakline {
    width: 100%;
  }
  .innovationspage--overview .projects__filters__content .genericfilters__panel {
    margin-top: 30px;
  }
  .innovationspage--overview .projects__filters__content .genericfilters__panel + .genericfilters__panel {
    margin-left: 30px;
  }
  html.innovationspage .header-innovations__filterbarbackground {
    box-shadow: 0 2px 13px 4px rgba(0, 0, 0, 0.06);
  }
  .header-filterbar__querycontainer {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .header-filterbar__querycontainer .wh-autocomplete-container {
    width: 100%;
  }
  html.innovationspage .header-innovations__filterbarbackground {
    background-color: #FFFFFF;
  }
  .header-filterbar__query {
    flex: 1 0 0px;
    height: 44px;
    border: 1px solid #9E9E9E;
    font: 15px var(--rtd-text-fontfamily);
    color: #6F6F6F;
    margin-right: 0; /* Safari adds margin */
  }
  .header-filterbar__submit {
    margin-left: 0; /* Safari adds margin */
    padding-top: var(--button-text-alignment-tweak);
    padding-left: 16px;
    padding-right: 16px;
    font: bold 16px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    cursor: pointer;
  }
  .header-filterbar__togglefilters {
    margin-left: 30px;
  }
  .header-filterbar__togglefilters__icon {
    margin-left: 30px;
  }
}
@media (min-width: 768px) and (max-width: 767px) {
  .header-filterbar__query {
    padding-right: 0 !important;
  }
  .header-filterbar__submit .fa-search,
  .header-filterbar__togglefilters__icon {
    margin-left: 15px !important;
  }
}
@media (min-width: 768px) and (max-width: 1099px) {
  .innovationspage--overview .projects__filters__content .genericfilters__foldout__heading {
    flex: 0 0 100% !important;
  }
  .innovationspage--overview .projects__filters__content .projects__filters__status {
    margin-left: 0;
  }
  .innovationspage--overview .projects__filters__content .projects__filters__sortby {
    width: 100%;
  }
  .innovationspage--overview .projects__filters__content .genericfilters__foldout__heading, .innovationspage--overview .projects__filters__content .projects__filters__sortby {
    flex: 0 0 calc((100% - 30px) / 3);
  }
  .innovationspage--overview .projects__filters__content .genericfilters__panel {
    margin-top: 30px;
    flex: 0 0 calc((100% - 30px) / 2);
  }
  .innovationspage--overview .projects__filters__content .genericfilters__panel:nth-child(7) {
    margin-left: 0;
  }
}
@media (min-width: 1100px) {
  .innovationspage--overview .projects__filters__content .genericfilters__foldout__heading, .innovationspage--overview .projects__filters__content .projects__filters__sortby {
    flex: 0 0 calc((100% - 90px) / 4);
  }
  .innovationspage--overview .projects__filters__content .genericfilters__panel {
    margin-top: 30px;
    flex: 0 0 calc((100% - 90px) / 4);
  }
}