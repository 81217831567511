.wh-newsletter__subscribewidget .wh-form__buttongroup,
.wh-newsletter__unsubscribewidget .wh-form__buttongroup {
  border-top: 0;
  padding-top: 0;
  margin-top: 15px;
}

.wh-newsletter__subscribewidget.wh-newsletter__subscribewidget label[for=subscribeform-email],
.wh-newsletter__unsubscribewidget.wh-newsletter__unsubscribewidget label[for=subscribeform-email] {
  width: auto;
  flex: 0 0 auto;
}