/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
Used in SU (2020), 4TU (2020), inGenious, JZOJP, TUE
*/
.defaultgrid__content {
  display: grid;
}
@media (max-width: 639px) {
  .defaultgrid__content > * + * {
    margin-top: 30px;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .defaultgrid__content {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .defaultgrid__content {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}
@media (min-width: 1000px) {
  .defaultgrid__content {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}

.defaultgrid__content--narrow {
  display: grid;
}
@media (max-width: 799px) {
  .defaultgrid__content--narrow > * + * {
    margin-top: 30px;
  }
}
@media (min-width: 800px) and (max-width: 1299px) {
  .defaultgrid__content--narrow {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}
@media (min-width: 1300px) {
  .defaultgrid__content--narrow {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}