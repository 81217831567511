/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*
FIXME:
- add more usage of CSS variables
- in the future switch to using container queries for triggering traits (such as "menubar-translucent")
-

*/
html {
  --header-innovations-shift: 0px;
  --header-innovations-textcontent-padding-h: 56px;
  --header-innovations-textcontent-padding-v: 20px;
  --header-innovations-textcontent-minheight: 160px;
  --header-filterbar-padding-v: 28px;
}

.header-innovations {
  --header-title-color: #3F3F3F;
  --header-expandedfilters-display: grid;
  z-index: 3;
}

.header-innovations.header--hasimage {
  --header-title-color: #FFFFFF;
}

.header-innovations.header--compact {
  --header-innovations-textcontent-padding-v: 20px;
  --header-innovations-textcontent-minheight: 0px;
}

.header-innovations.header--compact.header-innovations--nocoloroverlay.header--withoutimage .header-innovations__filterbar {
  padding-top: 0;
  min-height: 0;
}

@media (max-width: 767px) {
  .header-innovations {
    --header-innovations-textcontent-padding-h: 0px;
  }
}
.innovationspage .page__contentarea.page__contentarea {
  padding-top: 0;
}

.header-filterbar__querycontainer .wh-autocomplete-container {
  position: relative;
}

.innovations-subnav a.wh-wrdauth__logout::after {
  margin-left: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f011";
  font-size: 16px;
}

html.innovationspage .header-menubar-background {
  border-bottom: 1px solid #8E8E8E;
}

.innovations-nav {
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  display: flex;
  height: 52px;
  grid-row: 2;
}

.innovations-nav > a {
  border-radius: 3px 3px 0 0;
  color: #3F3F3F;
  font: 20px/59px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  padding-left: 50px;
  padding-right: 50px;
}

.innovations-nav > a:hover {
  background-color: #F8F8F8;
  color: var(--color-theme);
}

.innovations-nav > a.active {
  background-color: var(--color-theme);
  color: #FFFFFF;
}

.innovations-nav > a.wh-wrdauth__logout {
  margin-left: auto;
}

.innovations-nav > a.loginbutton,
.innovations-nav > a.accountbutton {
  margin-left: auto;
}

.innovations-nav > a.loginbutton:after,
.innovations-nav > a.accountbutton:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f007";
  margin-left: 20px;
}

.innovations-subnav {
  grid-column: var(--pagegrid-col-default);
  display: flex;
  align-items: center;
  height: 52px;
  grid-row: 5;
}

.innovations-subnav > a {
  color: #3F3F3F;
  font: 20px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 10px 0 10px;
}

.innovations-subnav > a {
  margin-left: -10px;
}

.innovations-subnav > a + a {
  margin-left: 40px;
}

.innovations-subnav > a:hover {
  color: var(--color-theme);
}

.innovations-subnav > a.active {
  text-decoration: underline;
}

.header-innovations {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-innovations__background,
.header-innovations__filterbarbackground {
  grid-column: var(--pagegrid-col-verywide);
}

.header-innovations::before {
  content: "";
  display: block;
  grid-row: 2/span 4;
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: #F2EFED;
}

/*
.header-innovations--orange.header--withoutimage
{
  .header-innovations__background
  {
    background: var(--color-orange);
  }
}
*/
.header-innovations--orange.header--hasimage .header-innovations__background,
.header-innovations__background.header-innovations--orange.header--hasimage {
  background: linear-gradient(to right, rgba(225, 103, 11, 0.9), rgba(225, 103, 11, 0.9)), #E1670B var(--headerimage);
  background-size: cover;
}

/*
.header-innovations--blue.header--withoutimage
{
  .header-innovations__background
  {
    background: var(--color-darkblue);
  }
}
*/
.header-innovations--blue.header--hasimage .header-innovations__background,
.header-innovations__background.header-innovations--blue.header--hasimage {
  background: linear-gradient(to right, rgba(21, 49, 78, 0.87), rgba(21, 49, 78, 0.75)), #0067B4 var(--headerimage);
  background-size: cover;
}

.header-innovations--darken.header--withoutimage {
  --header-title-color: #FFFFFF;
}
.header-innovations--darken.header--withoutimage .header-innovations__background {
  background: var(--color-darkgrey);
}

.header-innovations__background.header-innovations--darken.header--withoutimage {
  background: var(--color-darkgrey);
}

.header-innovations--darken.header--hasimage {
  --header-title-color: #FFFFFF;
}
.header-innovations--darken.header--hasimage .header-innovations__background {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0) 100%), var(--headerimage);
  background-size: cover;
}
.header-innovations--darken.header--hasimage .header-innovations__title {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header-innovations__background.header-innovations--darken.header--hasimage {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0) 100%), var(--headerimage);
  background-size: cover;
}

/*
@supports (background-blend-mode: multiply)
{
  .header-innovations__background
  {
    background: linear-gradient(to right, rgba(0,74,130,0.87), rgba(0,74,130,0.75))
              , linear-gradient(to right, #004A82, rgba(14,36,112,0.7))
              , url('[link]');
    background-blend-mode: normal, color;
  }

  .header-innovations__background
  {
    background: linear-gradient(to right, rgba(0,74,130,0.87), rgba(0,74,130, 0.4))
              , linear-gradient(to right, rgba(0,74,130,0.0),  rgba(0,74,130, 0.0))
              , url('[link]');
    background-blend-mode: multiply, color;
  }
*/
.header-innovations__background {
  grid-row: 3;
  background-size: cover;
}

.header-innovations__textcontent {
  grid-row: 3;
  grid-column: var(--pagegrid-col-verywide);
  padding: var(--header-innovations-textcontent-padding-v) var(--header-innovations-textcontent-padding-h);
}

.header-innovations__filterbar {
  grid-row: 4;
}

.header-expandedfilters {
  grid-row: 5;
}

.header-innovations__description {
  color: #FFFFFF;
  font: 13px/18px var(--rtd-text-fontfamily);
}

.header-innovations__description.rtdcontent > * {
  color: #FFFFFF;
  font: inherit;
}

.header-innovations__description.rtdcontent.rtdcontent a {
  color: inherit;
  text-decoration: underline;
  font-weight: normal;
}

.header-filterbar {
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 5/span 2;
}

@media (max-width: 950px) {
  body > .header-innovations__background {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .header-innovations__background {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 3/span 2;
  }
  body > .header-innovations__background {
    display: none !important;
  }
  /*
      body > .header-innovations__background
      {
        grid-row: 2 / span 2;
        // position: sticky;
        // top: calc(-1 * var(--layout-menubar-height)); // allow the header to just stay enough in screen so the past of the background we want to stick is still visible
      }
  */
  /* On mobile extend the orange/blue themecolor background
     to cover the filterbar and expandedfilters.
  */
  html:not(.innovationspage) .header-innovations--orange .header-innovations__background,
  html:not(.innovationspage) .header-innovations--blue .header-innovations__background {
    grid-row: 3/span 3;
  }
  .innovations-nav,
  .innovations-subnav {
    display: none;
  }
  .header-innovations__title {
    color: var(--header-title-color);
    font: bold 24px/28px var(--rtd-heading-fontfamily);
  }
  .header-innovations__description {
    font: 13px/18px var(--rtd-text-fontfamily);
  }
  .header-filterbar__topbar__label {
    display: none;
  }
  .header-innovations__filterbar {
    display: block;
    padding: 0 0 15px 0;
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 768px) {
  .header--image_fullwidth {
    --header-innovations-textcontent-padding-h: 0px;
  }
  /*
    .header-innovations-above
    {
      grid-column: var(--pagegrid-col-fullwidth);
      height: 20px;
      background-color: #F2EFED;
    }
  */
}
@media (min-width: 768px) and (min-width: 950px) {
  .header-innovations.header--compact .header-innovations__title {
    padding-left: 0;
    text-transform: uppercase;
    position: relative;
    top: 7px;
  }
  body > .header-innovations__background {
    grid-row: 2/span 2;
    position: sticky;
    top: calc(-1 * var(--layout-menubar-height));
  }
  .header-innovations.header--image_extend_under_menubar .header-innovations__background {
    display: none;
  }
  /*
  html.pageheader--fixed .header-innovations__background
  {
    opacity: 0;
  }
  */
  .header-innovations.header--image_extend_under_menubar {
    display: contents;
    --header-innovations-shift: var(--layout-menubar-height);
  }
  .header-innovations.header--image_extend_under_menubar .header-innovations__textcontent {
    z-index: 10;
  }
}
@media (min-width: 768px) {
  .header-innovations {
    grid-template-rows: var(--header-innovations-shift) min-content min-content min-content min-content;
  }
  html.innovationspage .header-innovations {
    --header-innovations-shift: 20px;
  }
  html:not(.innovationspage) .header-innovations::before {
    grid-row: 2/span 4;
  }
  .header-innovations--withfilterpanel::before {
    grid-row: 1/span 4;
  }
  html.innovationspage .header-innovations--withfilterpanel::before {
    height: calc(100% - 40px);
  }
  .header-innovations--withsubnav::before {
    grid-row: 1/span 5;
  }
  .header-innovations__textcontent {
    padding: var(--header-innovations-textcontent-padding-v) 0;
  }
  .header-innovations.header--hasimage .header-innovations__textcontent {
    padding: var(--header-innovations-textcontent-padding-v) var(--header-innovations-textcontent-padding-h);
  }
  html.innovationspage {
    --header-innovations-textcontent-padding-h: 0px;
  }
  html.innovationspage .header-innovations__background, html.innovationspage .header-innovations__filterbarbackground {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
  .header--image_fullwidth .header-innovations__background,
  .header-innovations__background.header--image_fullwidth {
    --header-innovations-textcontent-padding-h: 0px;
    grid-column: var(--pagegrid-col-fullwidth) !important;
  }
  .header-innovations__filterbarbackground {
    grid-row: 4;
  }
  .header-innovations__background {
    min-height: var(--header-innovations-textcontent-minheight);
  }
  .innovationspage .header-innovations__background {
    min-height: 210px;
  }
  .header-innovations__textcontent {
    display: flex;
    align-items: center;
    justify-items: space-between;
  }
  .header-innovations__title {
    color: var(--header-title-color);
    font: bold 48px var(--rtd-heading-fontfamily);
    align-self: center;
  }
  .header-innovations__description {
    font: 17px/24px var(--rtd-text-fontfamily);
    align-self: center;
  }
  .header-innovations__title {
    flex: none;
    max-width: 30%;
  }
  .header-innovations__description {
    margin-left: 10%;
    flex: 2 1 0;
  }
  .header-innovations__filterbar {
    display: flex;
    padding: var(--header-filterbar-padding-v) 0;
    min-height: 90px;
    align-items: center;
    grid-column: var(--pagegrid-col-verywide);
  }
  html.innovations .header-innovations__filterbar {
    background-color: #FFFFFF;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .header-filterbar__topbar__label {
    display: none;
  }
}
@media (min-width: 1023px) {
  html.filteredoverview---showfilters .projects__filters {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    opacity: 1;
    pointer-events: auto;
  }
  .projects__results__items,
  .projects__filters__content {
    grid-column: var(--pagegrid-col-verywide);
  }
}