.widget-ctagrid.widget-ctagrid
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  grid-column: var(--pagegrid-col-fullwidth);
  z-index: var(--zindex-overlap-deeplinks);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

/*
KEEP THIS: Use this when our CTA grid implements a background color:

.widget--includespadding .widget-ctagrid > *:first-child
{
  margin-top:      var(--widget-generic-panel-padding-top);
}

.widget--includespadding .widget-ctagrid > *:last-child
{
  margin-bottom:   var(--widget-generic-panel-padding-bottom);
}
*/

.widget-ctagrid__items
{
  grid-column: var(--pagegrid-col-verywide);

  display: grid;
  /*grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );*/
  gap: 30px;
}

@media (min-width: 768px) and (max-width: 999px)
{
  .widget-ctagrid__items
  {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}

@media (min-width: 1000px) and (max-width: 1300px)
{
  .widget-ctagrid__items
  {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

@media (min-width: 1400px)
{
  .widget-ctagrid__items
  {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}



.wh-rtd .widget-ctagrid__items
{
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}
