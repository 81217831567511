/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-highlight {
  grid-column: var(--pagegrid-col-default);
}

.widget-highlight--bg-grey {
  background-color: #F2EFED;
}

.widget-highlight--bg-orange {
  background-color: #F3D3B8;
}

.widget-highlight__content {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: calc(var(--rtd-spacing-paragraph) * 2);
  padding-bottom: calc(var(--rtd-spacing-paragraph) * 2);
}

.widget-highlight .rtdcontent > * {
  color: #4F4F4F;
}