.editionbackbar
{
  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  height: 221px;

  background-color: var(--edition-themecolor);

  text-decoration: none;
}

.editionbackbar__image
{
  grid-column: 1 / 8;
  width: 100%;
  height: 100%;
  min-height: 0; /* don't use the image natural size to keep the aspect ratio */
  object-fit: cover;
}

.editionbackbar__label
{
  grid-column: 9 / 26;
  font: 44px var(--rtd-text-fontfamily);
  color: var(--edition-themecontrastcolor);

  align-self: center;
}

.editionbackbar__label::before
{
  display: inline-block; /* so we don't inherit the underline */
  content: var(--icon-backlink-content);
  font: var(--fontawesome-regular);
  font-size: 32px;
  margin-right: 38px;
}

.editionbackbar:hover .editionbackbar__label
{
  text-decoration: underline;
}
