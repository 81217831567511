/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
@media (max-width: 767px) {
  .page--projectsoverview .projectsoverview__filters__tags {
    display: flex;
    flex-direction: column;
  }
  .page--projectsoverview .projectsoverview__filters__tags > * + * {
    margin-top: calc(var(--grid-spacing) * 0.5);
  }
}
@media (min-width: 768px) {
  .page--projectsoverview .header-innovations__textcontent.header-innovations__textcontent {
    padding-left: 0;
    padding-right: 0;
  }
  .page--projectsoverview .header-innovations__title {
    flex: 0 0 calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
    max-width: none;
    padding-left: 56px;
  }
  .page--projectsoverview .header-innovations__description {
    margin-left: 0;
    padding-right: 56px;
  }
  .page--projectsoverview .header-filterbar__togglefilters {
    display: none;
  }
  .page--projectsoverview .header-filterbar__topbar__label {
    flex: none;
    width: calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
    margin-right: 0;
  }
  .page--projectsoverview .header-expandedfilters {
    margin-top: 0;
  }
  .page--projectsoverview .projectsoverview__filters__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
    margin-left: calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
  }
  .page--projectsoverview .projectsoverview__filters__tags > * {
    flex: 1 1 0px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page--projectsoverview .projectsoverview__filters__tags {
    margin-left: 0;
  }
  .page--projectsoverview .header-innovations__title {
    padding-left: 35px;
  }
  .page--projectsoverview .header-innovations__description {
    padding-right: 35px;
  }
}