/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
@media (max-width: 1024px) {
  .page--ethics-members .deeplinks-wrapper {
    display: none !important;
  }
  #members-sidebar-form {
    display: none;
  }
}
@media (max-width: 767px) {
  #membersfilter .projectsoverview__filters__tags {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 30px;
  }
}
@media (min-width: 750px) and (max-width: 999px) {
  #membersfilter .projectsoverview__filters__tags {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 30px;
  }
}
@media (min-width: 1000px) {
  #membersfilter .projectsoverview__filters__tags {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
  }
}