/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.page--resourcesoverview .deeplinks-wrapper {
  display: none;
}

.resources__filters__content {
  grid-column: var(--pagegrid-col-verywide);
}

.filteredoverview__feedbackandviewbar {
  grid-column: var(--pagegrid-col-verywide);
}

@media (max-width: 639px) {
  .page__contentarea__resources {
    grid-column: var(--pagegrid-col-verywide);
  }
  .resource__info + .resources__results {
    margin-top: 30px;
  }
  .fourturesource__logocontainer {
    display: none;
  }
  .resource__info {
    word-break: break-word;
  }
  .resource__info__readmore__header {
    color: var(--color-theme);
    cursor: pointer;
  }
  .resource__info__readmore__header::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f107";
    margin-right: 10px;
    font-size: 18px;
    vertical-align: middle;
    transform: rotate(0);
    transition: transform 0.1s;
  }
  .resource__info__readmore__body {
    display: none;
  }
  details.resource__info__readmore[open] {
    display: none;
  }
  details.resource__info__readmore[open] + .resource__info__readmore__body {
    display: block;
  }
  details.resource__info__readmore[open] .resource__info__readmore__header::before {
    transform: rotate(-90deg);
  }
  /* .resource__info:not(.resource__info--expand) .resource__info__text
    {
      display: -webkit-box; // ONLY works with -webkit-box
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .resource__info--expand .resource__info__readmore
    {
      display: none;
    }
  */
}
@media (min-width: 640px) {
  .page__contentarea__resources {
    grid-column: var(--pagegrid-col-verywide);
    display: flex;
    align-items: flex-start;
  }
  .resource__info + .resources__results {
    margin-top: 30px;
  }
  .resources__results {
    flex: 4 0 0px;
    margin-left: 5%;
  }
  .resource__info {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    margin-left: 30px;
    order: 2;
    flex: 0 0 300px;
  }
  .resource__info__readmore__header {
    display: none;
  }
  details.resource__info__readmore > * {
    display: block;
  }
}
@media (max-width: 899px) {
  .resources__results {
    margin-left: 0;
  }
  .fourturesource__logocontainer {
    display: none;
  }
}
.resource__info {
  background-color: var(--color-lightgrey);
  padding: 15px;
}
.resource__info details summary {
  list-style-type: none;
}
.resource__info details summary::-webkit-details-marker {
  display: none;
}
.resource__info details summary::marker {
  display: none;
}

.resource__info__intro.resource__info__intro > *,
.resource__info__readmore__body__content.resource__info__readmore__body__content > * {
  color: #333333;
  font: 14px/22px var(--rtd-text-fontfamily);
}

.resource__info__readmore__header {
  font: 14px/22px var(--rtd-text-fontfamily);
}

.fourturesource + .fourturesource {
  margin-top: 30px;
}

.fourturesource {
  display: flex;
}

.fourturesource__logocontainer {
  padding-top: 4px;
  width: 62px;
  margin-right: 30px;
}

.fourturesource__logo {
  flex: none;
  max-width: 62px;
}

.fourturesource__meta {
  flex: 1 1 0px;
}

.fourturesource__tags {
  color: var(--rtd-text-color);
  font: 13px/18px var(--rtd-text-fontfamily);
}

.fourturesource__title {
  color: var(--color-darkblue);
  font: var(--component-summary-title-font);
}

a.fourturesource__title {
  text-decoration: none;
}

a.fourturesource__title:hover {
  text-decoration: underline;
}

.fourturesource__description {
  color: var(--rtd-text-color);
  font: 16px/25px var(--rtd-text-fontfamily);
}