/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
html {
  --formlayout-label-width: 200px;
  --formlayout-label-spacing-h: 30px;
}

.form__uploadfieldselect {
  margin-left: 16px;
}

.wh-form__dateinputgroup input,
.wh-form__timeinputgroup input {
  text-transform: uppercase;
}

.wh-form__buttongroup {
  margin-top: 30px;
  border-top: 1px solid #C5C5C5;
  padding-top: 25px;
}

.wh-form__arrayinput {
  width: 0;
}

.wh-form__fieldgroup + .wh-form__fieldgroup--group {
  margin-top: var(--rtd-spacing-paragraph);
}

/*
Textarea's in Safari don't have a normal baselines.
(sometimes they get it when there's text in the textarea)
Therefore we must align to flex-start instead of the baseline.

This is confirmed to still be an issue in iOS 16.4

- https://bugs.webkit.org/show_bug.cgi?id=142968
- https://bugs.webkit.org/show_bug.cgi?id=32731

```
Robert Hogan 2013-04-02 10:57:05 PDT
https://developer.mozilla.org/en-US/docs/HTML/HTML_Elements/textarea: "In regards to CSS, an <textarea> is a replaced element. The HTML specification doesn't define where the baseline of a <textarea> is. So different browsers set it to different positions. For Gecko, the <textarea> baseline is set on the baseline of the first line of the textarea's first line, on another browser it may be set on the bottom of the <textarea> box."

In reality we seem to be the odd one out - every other browser sets the baseline on the first line of the textarea and we set it on the bottom.
```

Disable baseline alignment for textarea's because
Safari/Webkit cannot handle this correctly.
*/
.wh-form__fieldgroup--textarea .wh-form__label {
  -webkit-align-self: flex-start;
  padding-top: 5px;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form__page h2:first-child,
.myprojects__projects.myprojects__projects h2:first-child {
  margin-top: 0px;
}

.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--textarea,
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--rtd {
  flex-direction: column;
}
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--textarea:not(.wh-form__fieldgroup--hidden),
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--rtd:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 30px;
}
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--textarea > .wh-form__label.wh-form__label,
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--rtd > .wh-form__label.wh-form__label {
  flex: 0 0 100%;
  width: 100%;
  min-width: 0;
  max-width: none;
  font: bold 19px var(--rtd-heading-fontfamily);
  color: #505050;
  margin-bottom: 15px;
}
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--textarea > .wh-form__fields.wh-form__fields,
.innovationspage--myinnovations.innovationspage--myinnovations .wh-form__fieldgroup--rtd > .wh-form__fields.wh-form__fields {
  flex: 0 0 100%;
  width: 100%;
  min-width: 0;
  max-width: none;
}

.wh-form {
  /*
  // Mark: fix om te voorkomen dat lange select options de layout oprekken
  select:not(.wh-rtd__toolbarstyle)
  {
    flex: 1 1 auto;
    width: 0;
  }
  */
  /* date/time */
  /*
  FILL WHOLE AREA

    .wh-form__buttongroup.wh-form__navbuttons > *
    {
      justify-content: center;
      flex: 1 0 auto;
    }
  */
}
.wh-form h3, .wh-form .projectform__texts .wh-form__label {
  font-size: 21px;
  color: #505050;
}
.wh-form h3 {
  margin-bottom: 23px;
}
.wh-form .projectform__texts .wh-form__label {
  margin-top: 5px;
  margin-bottom: 5px;
}
.wh-form .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: var(--formlayout-fieldlines-spacing);
}
.wh-form .wh-form__fieldgroup.wh-form__fieldgroup--nomargin:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 0;
}
.wh-form .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form .wh-form__optionlabel, .wh-form .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form .wh-form__label:empty::after {
  display: none;
}
.wh-form .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 var(--formlayout-label-width);
  max-width: var(--formlayout-label-width);
  min-width: var(--formlayout-label-width);
  padding-right: var(--formlayout-label-spacing-h);
  word-break: break-word;
}
.wh-form .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - var(--formlayout-label-width));
}
.wh-form .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
@media (max-width: 800px) {
  .wh-form .wh-form__fieldgroup {
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-form .wh-form__fieldgroup > .wh-form__label.wh-form__label {
    flex: 0 0 auto;
    padding-bottom: 3px;
  }
  .wh-form .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form .wh-form__fieldgroup > .wh-form__label, .wh-form .wh-form__fieldgroup > .wh-form__fields {
    flex: none;
    max-width: calc(100vw - 40px);
    min-width: 0;
  }
  .wh-form .wh-form__fields {
    width: 100%;
  }
}
.wh-form select + .wh-form__subfield > label,
.wh-form .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form input, .wh-form select:not(.wh-rtd__toolbarstyle),
.wh-form .wh-form__imgedit,
.wh-form .wh-form__dateinputgroup,
.wh-form .wh-form__timeinputgroup {
  max-width: 100%;
  flex: 1;
}
.wh-form input:focus, .wh-form select:not(.wh-rtd__toolbarstyle):focus,
.wh-form .wh-form__imgedit:focus,
.wh-form .wh-form__dateinputgroup:focus,
.wh-form .wh-form__timeinputgroup:focus {
  outline: none;
  box-shadow: 0 0 3px rgb(0, 150, 255);
}
.wh-form .wh-form__imgedit {
  max-width: min(400px, 100%);
}
.wh-form textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}
.wh-form textarea {
  min-height: 140px;
}
.wh-form .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-form .wh-rtd__editor .wh-rtd__html {
  margin: 0;
  padding: 5px;
}
.wh-form .wh-rtd__editor .wh-rtd__body {
  padding: 5px 8px;
}
.wh-form .wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border: 1px solid #AAAAAA;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}
.wh-form .wh-form__fieldgroup--error input, .wh-form .wh-form__fieldgroup--error select,
.wh-form .wh-form__fieldgroup--error textarea,
.wh-form .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  background-color: var(--formcontrols-backgroundcolor-error);
  border-color: var(--formcontrols-border-color-error);
  color: var(--formcontrols-textcolor-error);
}
.wh-form .wh-form__fieldgroup--error {
  background-color: var(--formlayout-fieldgroup-bgcolor-error);
  border-top: 3px solid #FFFFFF;
}
.wh-form .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: var(--formlayout-fieldgroup-error-textcolor);
  font: var(--formlayout-fieldgroup-error-font);
  padding: 8px 0 0;
}
.wh-form .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form .wh-form__dateinputgroup, .wh-form .wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form .wh-form__dateinputgroup input, .wh-form .wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form .wh-form__dateinputgroup__line::after, .wh-form .wh-form__timeinputgroup__line::after {
  top: 12px;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form .wh-form__buttongroup {
  display: flex;
  margin: var(--formlayout-buttongroup-margin);
  /*
      justify-content: space-between;

      .wh-form__button
      {
        min-width: 200px;
      }
  */
}
.wh-form .wh-form__buttongroup .wh-form__button--next, .wh-form .wh-form__buttongroup .wh-form__button--submit {
  margin-left: auto;
}

.wh-form__navbuttons {
  align-items: center;
}