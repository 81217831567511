/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.innovationspage .deeplinks-wrapper {
  display: none;
}

#usereditform .wh-form__fieldgroup {
  flex-direction: column;
  align-items: flex-start;
}
#usereditform .wh-form__fieldgroup > .wh-form__label.wh-form__label {
  flex: 0 0 auto;
  padding-bottom: 3px;
}
#usereditform .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
#usereditform .wh-form__fieldgroup > .wh-form__label, #usereditform .wh-form__fieldgroup > .wh-form__fields {
  flex: none;
  max-width: calc(100vw - 40px);
  min-width: 0;
}
#usereditform .wh-form__fields {
  width: 100%;
}

.defaultgrid {
  grid-column: var(--pagegrid-col-verywide);
}

.centerwrapper,
.innovations-introblock {
  grid-column: var(--pagegrid-col-default);
}

.column5050 {
  display: flex;
}

.column5050__col {
  flex: 0 0 calc(50% - 15px);
}

.column5050__col + .column5050__col {
  margin-left: 30px;
}

.innovations-introblock {
  margin-top: 30px;
  margin-bottom: 40px;
}

.innovations-introblock h1 {
  color: var(--color-theme);
}

.fourtu-dialog__modalbg {
  z-index: 15;
}

.fourtu-popupselect__dialog {
  display: flex;
  flex-direction: column;
}

.myprojects__projects.myprojects__projects h2,
.innovationspage--editproject h2 {
  margin-top: 55px;
  margin-bottom: 35px;
  padding-bottom: 15px;
  border-bottom: 1px solid #C5C5C5;
}

#projecteditform .wh-form__rtd {
  height: 350px;
}

#innovationsresultpage > div {
  display: none;
}

.wh-wrdauth__loginform .wh-form__navbuttons {
  text-align: left; /* set login btn left */
}