/*

Usage:

- This is an 12 column design setup

- main container will set .spc-contentgrid

- grid will have to be reestablished within collapsable content (accordion/FAQ)



It handles:
- footer pushed to bottom of viewport (or page)
- column layout
- ability for deeplinks hovering in contentarea
- topmenu bar scrolling away
- menubar getting sticky
- background for menubar sticky, but scrolling farther to give appearance of menubar shrinking
- menubar disappears when content doesn't fit (flex wrapping into the overflow of the menubar)

*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
:root {
  --pagegrid-columnwidth: 84px;
  --pagegrid-gutter: 26px;
  --pagegrid-gutteroutside-normal-max: 65px;
  --pagegrid-gutteroutside-normal-min: 30px;
  --pagegrid-gutteroutside-mobile: 15px;
  --pagegrid-header-protrude-max: 120px;
  --pagegrid-columns: 12;
  --pagegrid-gutter: 26px;
  --pagegrid-gutteroutside: 30px;
  --pagegrid-columnwidth: 84px;
  --pagegrid-maxwidth: 1424px;
  /*
    --pagegrid-verywide-maxwidth:
             calc(   var(--pagegrid-columnwidth)       * var(--pagegrid-columns)
                 + (( var(--pagegrid-columnwidth) - 1 ) * var(--pagegrid-gutter))
                 )
                                 ;
  */
  --pagegrid-verywide-maxwidth: 1294px;
}
@media (max-width: 640px) {
  :root {
    --pagegrid-gutteroutside: 15px;
    --pagegrid-maxwidth: 1309px;
  }
}

html {
  --page-row-pageheader-textcontent: auto;
}

html.pageheader--filtersheader {
  --page-row-pageheader-textcontent: minmax(0, min-content);
}

body {
  display: grid;
  min-height: 100vh;
  grid-template-rows: minmax(0, min-content) minmax(0, min-content) var(--page-row-pageheader-textcontent) minmax(0, min-content) minmax(0, min-content) 1fr minmax(0, min-content);
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-top-background,
.header-top {
  z-index: 1;
}

.deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
  z-index: 9; /* keep over page content */
}

body > .header-innovations__background {
  z-index: 10;
}

.site-scrollprogressbar {
  z-index: 10;
}

.footer {
  z-index: 10;
}

.header-menubar,
.header-menubar-background,
.header-filterbar__querycontainer .wh-autocomplete-container {
  z-index: 11;
}

.wh-autocomplete-values {
  z-index: 11;
}

.page__contentarea__inset {
  --pagegrid-inset-zindex: 1;
}

.deeplinks-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.deeplinks-wrapper.hide_deeplinks {
  opacity: 0;
  pointer-events: none;
}

.page-header__content {
  position: relative;
  z-index: 1;
}

.footer {
  grid-row: 6;
  grid-column: var(--pagegrid-col-fullwidth);
}

.header-top {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.header-top-background {
  grid-row: 1;
}
html.siteheader--menubar-translucent .header-top-background {
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}
html.pageheader--inflow .header-top-background, html.pagewidth--width-fullwidth .header-top-background {
  grid-column: var(--pagegrid-col-fullwidth);
}

.header-menubar-background {
  grid-row: 2;
}
html.siteheader--menubar-translucent .header-menubar-background {
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}
html.pageheader--inflow .header-menubar-background, html.siteheader--menubar-translucent.header--fullwidth .header-menubar-background {
  grid-column: var(--pagegrid-col-fullwidth);
}

.header-menubar {
  grid-row: 2;
  grid-column: 1;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__background {
  grid-row: 1/span 3;
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: #F2EFED;
}

html.siteheader2021 .page-header__slideshow {
  grid-row: 2/span 2;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

html.pagewidth--width-fullwidth .page-header__slideshow {
  grid-column: 1/-1;
}

html.identity--designunited .page-header__slideshow {
  grid-row: 3;
}

.pageheader-sidebyside {
  grid-row: 3;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.pageheader-carrousel {
  grid-row: 3;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__slidescontent {
  grid-row: 3;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  position: relative;
}

.page-header__content {
  grid-row: 3;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page-header__cta {
  grid-row: 3;
  grid-column: 1;
}

.page-header__meta {
  grid-row: 3;
  grid-column: 1;
}

main {
  display: contents;
}

.header-innovations {
  grid-row: 4;
}

.page__contentarea {
  grid-row: 6;
  grid-column: var(--pagegrid-col-fullwidth);
}

.footer {
  grid-row: 7;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page__contentarea,
.wh-form--webtoolform,
.widget-accordion__item__body__content {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}

.page__body--disablegridlayout {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.page__body--disablegridlayout > .page__contentarea {
  display: block;
  grid-column: var(--pagegrid-col-default);
}

.page-header__title {
  grid-column: var(--pagegrid-col-default);
}

body {
  --pagegrid-col-default-max8col: var(--pagegrid-col-default);
}

.page__contentarea--rtddoc.page__contentarea__projectdetails-content {
  --pagegrid-col-default: 3 / span 23;
  --pagegrid-col-default-max8col: 3 / span 17;
  --pagegrid-col-default-left: var(--pagegrid-col-verywide-left);
  --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
}

.page__contentarea > *,
.page__contentarea--formwebtool > .page-header__title,
.page__contentarea--formwebtool > *,
.widget-accordion__item__body__content > *,
.wh-errorpage .page__contentarea > * {
  grid-column: var(--pagegrid-col-default);
}

@media (max-width: 1023px) {
  /* On mobile makes the default wide use the full viewport */
  .page__contentarea--rtddoc > *,
  .page__contentarea--formwebtool > .page-header__title,
  .page__contentarea--formwebtool > *,
  .widget-accordion__item__body__content > *,
  .wh-errorpage .page__contentarea > * {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
    --pagegrid-col-default-max8col: var(--pagegrid-col-verywide);
    --pagegrid-col-default-left: var(--pagegrid-col-verywide-left);
    --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
  }
}
/* Support grid layout for formwebtool documents

   - Don't use a wrapper, becauce it will override the specificy and therefore overriden the layout of all widgets!
   - However in the future we may need to change the widgets CSS (to have a higher specificy) so we can support other .wh-form usage too

   - In the future we may be able to use :where(.page__contentarea > .wh-form), because the selectors in :where have 0 specificy

*/
@media (max-width: 1023px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-default);
  }
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page,
.wh-form--webtoolform .wh-form__fieldgroup--richtext {
  grid-column: var(--pagegrid-col-fullwidth);
}

.wh-form--webtoolform .wh-form__richtext {
  width: 100%;
}

.wh-form--webtoolform,
.wh-form--webtoolform .wh-form__page--visible,
.wh-form--webtoolform .wh-form__richtext {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.wh-form > a.wh-anchor,
.wh-form__page > a.wh-anchor,
.wh-form__richtext > a.wh-anchor {
  position: relative;
}

@media (max-width: 1024px) {
  .page__contentarea .deeplinks-wrapper > .deeplinks {
    padding-top: var(--whitespace-header-backlink);
  }
}
@media (min-width: 1025px) {
  .page__contentarea .deeplinks-wrapper > .deeplinks {
    /*
    NOTES:
    - don't use margin-top or padding-top on .deeplinks_wrapper, it'll stretch the first row creating a white space
    - by applied padding-top (or margin-top) to the deeplinks (which are taken out of flow) we can create a distance and still allow the first widget in the RTD to stick to the header
    */
    padding-top: var(--whitespace-header-content);
    padding-bottom: 80px;
  }
}
@media (max-width: 1023px) {
  /* there's no design on there to put these in a mobile design
     (when our content goes fullwidth)
  */
  .deeplinks-wrapper .page-sharebuttons,
  .page-readtime {
    display: none;
  }
}
@media (min-width: 1024px) {
  .page__contentarea--rtddoc > *:first-child,
  .page__contentarea--rtddoc > .page-contentstart + * {
    grid-row: 1; /* deeplinks and the first contentarea item must overlap */
  }
}
html.siteheader--menubar-translucent.pagewidth--width-extrawide .page-header__slideshow, html.siteheader--menubar-translucent.pagewidth--width-extrawide .header-menubar-background.header-menubar-background {
  grid-column: var(--pagegrid-col-fullwidth);
  width: 100%;
  max-width: 1544px;
  margin-left: auto;
  margin-right: auto;
}
html.siteheader--menubar-translucent.pagewidth--width-fullwidth .page-header__slideshow, html.siteheader--menubar-translucent.pagewidth--width-fullwidth .header-menubar-background.header-menubar-background {
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-readtime {
  grid-row: 1;
  grid-column: var(--pagegrid-gutter-right);
  font: var(--widget-item-description-font);
}

.page-readtime::before {
  color: var(--color-theme);
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 20px;
  content: var(--fa-clock);
  margin-right: 15px;
}

body > .page__balloon {
  flex: 1 0 auto;
  height: var(--whitespace-footer-content);
}
@media (max-width: 500px) {
  body > .page__balloon {
    height: 30px;
  }
}