/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
.person-details-associatedresearch li::marker
{
  color: var(--color-theme);
  content: var(--fa-chevron-right);
  font: var(--fontawesome-regular);
  // padding-right: 15px;
}
*/
.page--ethics-person .deeplinks-wrapper {
  --whitespace-header-content: 20px;
}

.ftue-member-card {
  background: #F2EFED;
  grid-column: var(--pagegrid-col-fullwidth);
  --whitespace-header-content: 0;
  --ftue-member-card-spacing: 30px;
}

.ftue-member-card a {
  color: var(--rtd-link-color);
  text-underline-offset: 2px;
  outline-offset: var(--rtd-link-outline-offset);
}

.ftue-member-card__photo {
  width: 118px;
  height: 118px;
  border-radius: 50%;
  background: #E0E0E0;
}

.ftue-member-card__position {
  margin-bottom: 20px;
}

.ftue-member-card__title {
  color: #3F3F3F;
  font: var(--rtd-heading-weight) 36px/40px var(--rtd-heading-fontfamily);
  text-wrap: balance;
  text-wrap: pretty;
  margin-bottom: -10px;
  /* In Safari preview since feb 2023 */
  leading-trim: both;
  text-edge: cap alphabetic;
}

.ftue-member-card__meta {
  margin: 0;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-auto-rows: min-content;
  row-gap: 6px;
  font: var(--rtd-text-small-font);
  word-break: break-word;
}

.ftue-member-card__meta__item {
  display: contents;
}

.ftue-member-card__meta dt {
  font-weight: bold;
  white-space: nowrap;
}

.ftue-member-card__meta dd {
  margin-left: 45px;
}

.ftue-member-card__meta__item--links > dt:first-of-type,
.ftue-member-card__meta__item--links > dd:first-of-type {
  margin-top: var(--ftue-member-card-spacing);
}

.ftue-member-card__adresses {
  margin: 0;
  font: var(--rtd-text-small-font);
}

.ftue-member-card__adresses__item dt {
  font-weight: bold;
}

.ftue-member-card__adresses__item dd {
  margin-left: 0;
}

.ftue-member-card__adresses__item + .ftue-member-card__adresses__item {
  margin-top: var(--ftue-member-card-spacing);
}

@media (max-width: 550px) {
  .ftue-member-card {
    grid-row: 1; /* also under the gutter with it's backlink */
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: var(--ftue-member-card-padding-top) min-content min-content;
    --ftue-member-card-padding-top: 75px;
    padding-bottom: 25px;
  }
  .ftue-member-card .page-backlink {
    grid-row: 1;
    margin-top: 13px;
  }
  html.page--ethics-person .deeplinks .page-backlink {
    display: none;
  }
  .ftue-member-card__content {
    grid-column: var(--pagegrid-col-verywide);
  }
  .ftue-member-card__heading {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: var(--ftue-member-card-spacing);
  }
  .ftue-member-card__photo {
    width: 100px;
    height: 100px;
  }
  .ftue-member-card__title {
    font: var(--rtd-heading-weight) 36px/40px var(--rtd-heading-fontfamily);
    font-size: clamp(20px, 6vw, 36px);
    line-height: 120%;
  }
  .ftue-member-card__adresses.ftue-member-card__adresses {
    margin-top: var(--ftue-member-card-spacing);
  }
}
@media (min-width: 551px) and (max-width: 768px) {
  .ftue-member-card {
    grid-row: 1; /* also under the gutter with it's backlink */
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: var(--ftue-member-card-padding-top) min-content;
    --ftue-member-card-padding-top: 75px;
    padding-bottom: 25px;
  }
  .ftue-member-card .page-backlink {
    grid-row: 1;
    margin-top: 13px;
  }
  html.page--ethics-person .deeplinks .page-backlink {
    display: none;
  }
  .ftue-member-card__content {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 2;
    display: grid;
    grid-template-columns: 100%;
  }
  .ftue-member-card__heading {
    grid-row: 2;
    grid-column: 1;
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: var(--ftue-member-card-spacing);
  }
  .ftue-member-card__meta {
    grid-row: 3;
    grid-column: 1;
  }
  .ftue-member-card__adresses {
    grid-row: 4;
    grid-column: 2;
    justify-self: end;
  }
}
@media (min-width: 769px) and (max-width: 1023px) {
  .ftue-member-card {
    grid-row: 1; /* also under the gutter with it's backlink */
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: var(--ftue-member-card-padding-top) min-content min-content;
    --ftue-member-card-padding-top: 60px;
    padding-bottom: 30px;
  }
  .ftue-member-card .page-backlink {
    margin-top: 13px;
  }
  html.page--ethics-person .deeplinks .page-backlink {
    display: none;
  }
  .ftue-member-card__content {
    display: contents;
  }
  .ftue-member-card__photo {
    grid-row: 2;
    grid-column: var(--pagegrid-gutter-left);
    justify-self: end;
    align-self: center;
  }
  .ftue-member-card__heading {
    grid-row: 2;
    grid-column: var(--pagegrid-col-default);
    display: flex;
    align-items: center;
    column-gap: 30px;
    margin-bottom: 30px;
  }
  .ftue-member-card__meta {
    grid-row: 3;
    grid-column: var(--pagegrid-col-default-left);
  }
  .ftue-member-card__adresses {
    grid-row: 3;
    grid-column: var(--pagegrid-col-default-right);
  }
}
/* When content is centered we can place the
   photo at the left outside the centered content */
@media (min-width: 1024px) {
  .ftue-member-card {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: var(--ftue-member-card-padding-top) min-content min-content;
    --ftue-member-card-padding-top: 80px;
    padding-bottom: 30px;
  }
  .ftue-member-card .page-backlink {
    margin-top: 13px;
  }
  html.page--ethics-person .deeplinks .page-backlink {
    display: none;
  }
  .ftue-member-card__content {
    display: contents;
  }
  .ftue-member-card__photo {
    grid-row: 2;
    grid-column: var(--pagegrid-gutter-left);
    justify-self: end;
    align-self: center;
  }
  .ftue-member-card__heading {
    display: contents;
  }
  .ftue-member-card__heading__textual {
    grid-row: 2;
    grid-column: var(--pagegrid-col-default);
    align-self: center;
  }
  .ftue-member-card__meta {
    margin-top: 15px;
    grid-row: 3;
    grid-column: var(--pagegrid-col-default-left);
  }
  .ftue-member-card__adresses {
    margin-top: 15px;
    grid-row: 3;
    grid-column: var(--pagegrid-col-default-right);
  }
}