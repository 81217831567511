/*

iOS style switch
also see: https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/;

Style using CSS variables:
--component-checkbox-switch-borderwidth:   3px;
--component-checkbox-switch-track-width:  55px;
--component-checkbox-switch-track-height: 24px;
--component-checkbox-switch-track-color:  #A4A4A4;
--component-checkbox-switch-track-color-disabled: #A4A4A4;
--component-checkbox-switch-track-color-active: var(--color-theme);
--component-checkbox-switch-thumb-size:   18px;
--component-checkbox-switch-thumb-color:  #FFFFFF;

Example usage:

<input id="mytoggle" type="checkbox" class="spc-toggle" /><label for="mytoggle">[! placeholder for custom styling !]</label><label for="mytoggle">Toggle active</label>

2025-03-18
- changed display: inline-block; to inline-flex so we can use align-self: center; on the

*/

input[type="checkbox"].spc-toggle + label
{
  vertical-align: middle;

  user-select: none; /* prevent double click starting a selection in Chrome and Firefox */
}

input[type="checkbox"].spc-toggle
{
  /*
  Make this not be an replaced element, so instead of a system component we'll
  be able to use an normal element with ::before/::after pseudo elements.
  */
  -webkit-appearance: none; /* Safari */
  -moz-appearance: none; /* Firefox <80 */
  appearance: none; /* Chrome 84 (jul 2020), FF 80 (aug 2020) */

  flex: none; /* never let a flex container squueze */

  display: inline-flex;
  vertical-align: middle;

  position: relative;
  width:  var(--component-checkbox-switch-track-width);
  min-width: var(--component-checkbox-switch-track-width);
  height: var(--component-checkbox-switch-track-height);
  border-radius: calc(var(--component-checkbox-switch-track-height) * 0.5);

  background-color: var(--component-checkbox-switch-track-color);
  border: var(--component-checkbox-switch-borderwidth) solid var(--component-checkbox-switch-track-color);

  top: 0 !important;

  cursor: pointer;

  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

input[type="checkbox"].spc-toggle::before
{
  content: "";

  position: absolute;
  top: 50%;
  margin-top: calc(-0.5 * var(--component-checkbox-switch-thumb-size));
  height: var(--component-checkbox-switch-thumb-size);

  left: 1px;
  width: var(--component-checkbox-switch-thumb-size);

  background-color: var(--component-checkbox-switch-thumb-color);

  border-radius: 50%;

  transition: left 0.2s, background-color 0.2s, border-color 0.2s;
}

/* OFF */
input[type="checkbox"].spc-toggle
{
}
input[type="checkbox"].spc-toggle.spc-toggle:hover
{
  border-color: var(--component-checkbox-switch-track-color);
  /*//background-color: #a9abad;*/
}

/* ON */
input[type="checkbox"].spc-toggle.spc-toggle:checked
{
  background-color: var( --component-checkbox-switch-track-color-active);
  border-color: var( --component-checkbox-switch-track-color-active);
}
input[type="checkbox"].spc-toggle:checked:hover
{
  /*//background-color: #287c31;*/
}

/* Disabled */
input[type="checkbox"].spc-toggle[disabled]
{
  background-color: #dcddde;
}
input[type="checkbox"].spc-toggle[disabled]::before
{
  display: none;
}


input[type="checkbox"].spc-toggle:checked::before
{
  left: calc(100% - var(--component-checkbox-switch-thumb-size) - 1px);
}
