/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.wh-form--uploading .canvasupload {
  cursor: default;
}
.wh-form--uploading .canvasupload .canvasupload__busy {
  display: flex;
}
.wh-form--uploading .canvasupload .canvasupload__note {
  display: none;
}

.canvasupload {
  width: 100%;
  position: relative;
  font: 16px var(--rtd-text-fontfamily);
  font-style: italic;
  cursor: pointer;
  display: flex;
}
.canvasupload__note {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  line-height: 20px;
  justify-content: center;
  align-items: center;
}
.canvasupload__note .icon {
  display: none;
}
.canvasupload__note .text {
  padding: 10px 20px;
  text-align: center;
  font-style: italic;
}
.canvasupload.canedit .canvasupload__note {
  display: none;
}
.canvasupload__busy {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 60px;
}
.canvasupload.canvasupload--disabled {
  cursor: default;
}
.canvasupload.canvasupload--disabled .canvasupload__note {
  display: none;
}
.canvasupload__imgwrapper {
  flex: 1 1 100%;
  position: relative;
  max-width: 200px;
}
.canvasupload__imgcontainer {
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #f3f3f1;
  /* Circle mask */
}
.canvasupload__imgcontainer::after {
  pointer-events: none;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at center, transparent 70%, rgba(255, 255, 255, 0.8) 0%);
  background-size: 100% 100%;
  background-position: 50% 50%;
}
.canvasupload__img {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0% 0%;
}
.canvasupload.canedit .canvasupload__img {
  cursor: move;
}
.canvasupload__tools {
  position: relative;
  padding-left: 20px;
}
.canvasupload__dragnote {
  z-index: -1;
  visibility: hidden;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 15px;
}
.canvasupload__dragnote .icon {
  display: none;
  /*
  flex: none;
  width: 25px;
  */
}
.canvasupload__scale {
  width: 100%;
  transition: opacity 0.2s;
  z-index: -1;
  visibility: hidden;
}
.canvasupload__scale .text {
  margin-top: 10px;
}
.canvasupload.canedit .canvasupload__dragnote, .canvasupload.canedit .canvasupload__scale {
  visibility: visible;
  z-index: 1;
}
.canvasupload__input {
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;
}
.canvasupload__value {
  height: 100%;
  width: 100%;
}
.canvasupload__clear {
  margin-top: 10px;
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
  width: 100%;
  font-size: 15px;
}
.canvasupload__clear:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.canvasupload__clear .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}
.canvasupload__clear::after {
  margin-left: auto;
  padding-left: 18px;
  font: var(--formcontrol-icon-delete-font);
  content: var(--formcontrol-icon-delete-icon);
}