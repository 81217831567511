/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-persons {
  --content-squeeze-preference: preferwide;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-persons--wide {
  --content-layout: verywide;
}

.widget-persons a {
  color: var(--rtd-link-color);
  text-decoration: none;
}

.widget-persons a:hover {
  color: var(--rtd-link-color-hover);
  text-decoration: underline;
}

.widget-persons.widget--background-grey::before {
  content: "";
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  grid-row: 1;
  background-color: var(--color-lightgrey);
}

html.pagewidth--width-fullwidth .widget-persons.widget--background-grey,
.widget-persons--verywide.widget--background-grey {
  background-color: var(--color-lightgrey);
}

.widget-persons__items {
  grid-row: 1;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-persons.widget--background .widget-persons__items {
  padding-top: var(--widget-generic-panel-padding-top);
  padding-bottom: var(--widget-generic-panel-padding-bottom);
}

.widget-persons__item {
  /* Never allow content to go outside the item area.
     The most usual culprit is URLs (for example to a LinkedIn page)
     which people paste into the description field.
  */
  word-break: break-word;
}

.widget-persons__title {
  grid-column: var(--pagegrid-col-default);
}

.widget-persons__section.widget-persons__section.widget-persons__section {
  width: 100%;
  grid-column: 1/-1;
}

.widget-persons__section {
  font: var(--rtd-h2-font);
  color: var(--rtd-heading-color);
  margin-top: 15px;
  margin-bottom: 15px;
}

.widget-persons__section:first-child {
  margin-top: 0;
}

.widget-person__item__link {
  display: block;
  position: relative;
}

.widget-person__item__link__cta {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 44px;
  height: 44px;
  background: linear-gradient(180deg, #D35C00 0%, #EE6B06 100%);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-person__item__link__cta::after {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  font-size: 18px;
}

.widget-person__item__link .widget-person__item__link__cta:hover {
  background-color: #FFFFFF;
  color: var(--color-theme);
}

.widget-person__photo {
  /*
  NOTE:
  - images are scaled serverside to a square
  - use placeholder width/height on <img> so modern browsers can calc height based on aspect ratio
  */
  display: block;
  width: 100%;
}

.widget-person__photoplaceholder {
  background-color: #E5E5E5;
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-person__photoplaceholder::before {
  content: var(--fa-user);
  color: #CCCCCC;
  font: var(--fontawesome-light);
  font-size: 70px;
}

.person__fullname {
  margin-top: 8px;
  color: var(--color-theme);
  font: bold 19px/23px var(--rtd-heading-fontfamily);
}

.person__meta__describe {
  margin-top: 3px;
  font: 14px/18px var(--rtd-text-fontfamily);
  color: #3F3F3F;
}

.person__role {
  margin-top: 4px;
  color: #3F3F3F;
}

.person__meta__contact {
  margin-top: 3px;
  font-size: 12px;
}

/*
.person__meta__contact .far
{
  flex: none;
  font-size: 13px;
  width: 19px;
}
*/
.person__phone::before {
  display: inline-block;
  content: "T";
  width: 17px;
}

.person__email::before {
  display: inline-block;
  content: "E";
  width: 17px;
}

.person__meta__row {
  display: flex;
  align-items: center;
  font-weight: normal;
}

.person__meta__row a {
  font-weight: normal;
}

.person__email.person__email,
.person__phone.person__phone,
.person__profile.person__profile {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.person__meta__row--profile .far {
  color: var(--color-theme);
  width: 16px;
}

.person__profile {
  padding-top: 3px;
  font-family: var(--rtd-heading-fontfamily);
}

.widget-persons__items {
  grid-column: var(--pagegrid-col-default);
}

.widget-persons--wide .widget-persons__items.widget-persons__items {
  grid-column: var(--pagegrid-col-verywide);
}

.widget-richcontent .widget-persons__items {
  grid-column: var(--pagegrid-col-verywide);
}

.widget-persons__items {
  display: grid;
  gap: var(--grid-spacing);
}

@media (max-width: 459px) {
  .widget-persons__items {
    grid-template-columns: 100%;
  }
}
@media (min-width: 460px) and (max-width: 799px) {
  .widget-persons__items {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (min-width: 800px) {
  .widget-persons__items {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (min-width: 1024px) {
  .widget-persons--4col .widget-persons__items {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}