.projectdublock
{
  --pagetile-padding-h: 20px;
  --pagetile-image-padding-top: 56.1%;

  display: flex;
  flex-direction: column;

  position: relative;
  text-decoration: none;

  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
}

html.use-edition-themecolor .projectdublock
{
  --pagetile-heading-color-hover:   var(--edition-themecolor);
  --component-tag-color:            var(--edition-tagcolor);
  --component-tag-hover-background: var(--edition-tagcolor);
  --component-tag-border-color:     var(--edition-themecolor);
}

.projectdublock > *
{
  pointer-events: none;
  position: relative;
}

  .projectdublock a
, .projectdublock__anchor
, .projectdublock [data-ftulink]
{
  pointer-events: auto;
}

.projectdublock__anchor
{
  position: absolute;
  left:   0;
  right:  0;
  top:    0;
  bottom: 0;
  /*z-index: -1;*/
}

.projectdublock.notmatching
{
  display: none;
}

/* override ".rtdcontent a:hover" */
a.projectdublock.projectdublock
{
  text-decoration: none;
}

.projectdublock--islink:hover
{
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.2);
}

.projectdublock--islink:hover .projectdublock__image
{
  transform: scale(1.1);
}

.projectdublock__top
{
  flex: none;

  position: relative;
  overflow: hidden;
  /*padding-top: $image_paddingtop; // MS Edge cannot handle % padding, it messes up height calculation of the flex container!*/
}

/* Alternative way to stretch to aspect ratio (so it works in MS Edge) */
.projectdublock__top::before
{
  display: block;
  content: "";
  padding-top: var(--pagetile-image-padding-top);
}

.projectdublock__image
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: var(--pagetile-image-padding-top);
  background-size: cover;

  /*transition: transform 0.15s ease-in-out;*/
  transition: transform 0.15s ease-out;
}

.projectdublock__image--empty
{
  background-color: var(--color-lightgrey);
}




/* FIXME: use html language for language specific label */
.projectdublock--story .projectdublock__top::after
{
  content: "Column";
  position: absolute;
  left:  15px;
  top:  15px;
  background-color: var(--color-theme);
  color: #FFFFFF;

  padding: 0 14px;
  height: 39px;

  display: flex;
  align-items: center;
  padding-top: 5px; /* compensate for Officina font */
}

html.use-edition-themecolor .projectdublock__top::after
{
  background-color: var(--edition-themecolor);
  color: var(--edition-themecontrastcolor);
}

.projectdublock--story .projectdublock__meta
{
  background-color: var(--color-black);

  --pagetile-heading-color:         var(--color-white);
  --pagetile-heading-color-hover:   var(--color-white);

  --rtd-text-color:                 var(--color-white);
  --component-tag-color:            var(--color-white);
  --component-tag-hover-background: var(--color-white);
  --component-tag-border-color:     var(--color-white);

}



.projectdublock--banner .projectdublock__meta
{
  background-color: var(--edition-themecolor);

  --button-color:                 var(--edition-themecontrastcolor);
  --button-color-hover:           var(--edition-themecontrastcolor);

  --pagetile-heading-color:       var(--edition-themecontrastcolor);
  --pagetile-heading-color-hover: var(--edition-themecontrastcolor);

  color: var(--edition-themecontrastcolor);
  --rtd-text-color:    var(--edition-themecontrastcolor);
  /*--rtd-heading-color: var(--edition-themecontrastcolor);*/
}

.projectdublock--banner .projectdublock__meta .spc-textbutton
{
  font-weight: bold;
}




.projectdublock__meta
{
  flex: 1 0 auto; /* fit content but stretch if an item next to us is higher */

  padding: 25px;

  font-family: var(--rtd-text-fontfamily);
  font-size: 14px;
  line-height: 20px;
  color: var(--rtd-text-color);

  display: flex;
  flex-direction: column;
}

.projectdublock__title
{
  color: var(--rtd-text-color);
  font: 700 24px/34px var(--rtd-heading-fontfamily);
  margin-bottom: 12px;
}

.projectdublock:hover .projectdublock__title
{
  color: var(--pagetile-heading-color-hover);
}

.projectdublock__description
{
  color: var(--rtd-text-color);
  font: 18px/27px var(--rtd-text-fontfamily);
  margin-bottom: 15px;
}

.projectdublock__filler
{
  flex: 1 0 0px;
}

.projectdublock__meta > *:last-child
{
  margin-bottom: 0;
}

.projectdublock__tags
{
  margin-top: 20px; /* min whitespace */
  align-items: end;
}
