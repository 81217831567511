.projectsviewer {
  --content-layout: verywide;
  --content-squeeze-preference: needwide;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.projectsviewer.widget--background-grey {
  background: var(--color-bggrey);
  padding-top: var(--widget-generic-bigpanel-padding-top);
  padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
}

.projectsviewer.widget--background-grey .page__footer {
  background: var(--color-bggrey);
}

.projectsviewer.widget--background-grey + .page__balloon {
  height: 0; /* have the projectsviewer connect to the page__foter (with crumbpath) or site footer */
}

.projectsviewer__header {
  grid-column: var(--pagegrid-col-verywide);
}

.innovationspage .widget-header__title {
  color: var(--color-theme);
}

/************************************************************************
*
*  Grid mode
*/
@media (min-width: 1024px) {
  .projectsviewer__buttonbar {
    display: none;
  }
  .projectsviewer__itemswrapper {
    grid-column: var(--pagegrid-col-wide);
  }
  .projectsviewer__items {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: var(--grid-spacing);
  }
}
@media (min-width: 1024px) {
  .widget--stories .projectsviewer__itemswrapper,
  .projectdetails-du__relatedprojects .projectsviewer__itemswrapper,
  .page--blogitem .projectsviewer__itemswrapper {
    grid-column: var(--pagegrid-col-verywide);
  }
}
.projectsviewer {
  grid-column: 1/-1;
}

.projectdetails__related__header h2 {
  color: #000000;
  font: var(--rtd-h1-font);
}

.projectdetails__related__header a::before {
  font: var(--icon-link-font);
  content: var(--icon-link-content);
  color: #8E8E8E;
  margin-right: 20px;
}

.projectdetails__related__header a {
  color: #000000;
  font: bold 21px var(--rtd-heading-font);
  text-transform: none;
}

.projectdetails__related__header a:hover {
  text-decoration: underline;
}

.projectdetails__related__header a::after {
  display: none;
}

/************************************************************************
*
*  Carrousel mode mixins
*/
/************************************************************************
*
*  Carrousel mode
*/
@media (max-width: 1023px) {
  .projectsviewer__itemswrapper {
    grid-column: var(--pagegrid-col-fullwidth);
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    scroll-behavior: smooth;
    padding-top: 5px;
    padding-bottom: 8px;
  }
  .projectsviewer__itemswrapper::-webkit-scrollbar {
    width: 0 !important;
  }
  .projectsviewer__itemswrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .projectsviewer__items {
    display: flex;
  }
  .projectsviewer__items::after {
    content: "";
    flex: 0 0 100%;
  }
  .projectsviewer__items > * {
    scroll-snap-margin-left: var(--pagegrid-gutteroutside);
    scroll-margin-left: var(--pagegrid-gutteroutside);
  }
  .projectsviewer__items > * + * {
    margin-left: 30px;
  }
}
@media (max-width: 600px) {
  .projectsviewer__items > * {
    scroll-snap-align: start;
    flex: 0 0 100%;
  }
}
@media (max-width: 599px) {
  .projectsviewer__items > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .widget-pagehighlights__items > *:nth-child(1n+1) {
    margin-left: 38px;
    scroll-snap-align: start;
  }
}
@media (min-width: 600px) and (max-width: 799px) {
  .projectsviewer__items > * {
    flex: 0 0 auto;
    width: calc((100% - 30px) / 2);
  }
  .widget-pagehighlights__items > *:nth-child(2n+1) {
    margin-left: 38px;
    scroll-snap-align: start;
  }
}
@media (min-width: 800px) and (max-width: 1023px) {
  .projectsviewer__items > * {
    flex: 0 0 auto;
    width: calc((100% - 60px) / 3);
  }
  .widget-pagehighlights__items > *:nth-child(3n+1) {
    margin-left: 38px;
    scroll-snap-align: start;
  }
}
/************************************************************************
*
*  Buttonbar (only used in carrousel mode)
*/
.projectsviewer__buttonbar {
  display: none;
}

.carrouselcontent--multiplepages .projectsviewer__buttonbar {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
}

.projectsviewer__buttonbar__previousbutton,
.projectsviewer__buttonbar__nextbutton {
  flex: none;
  padding: 4px 6px;
  cursor: pointer;
  color: var(--rtd-text-color);
}

.projectsviewer__buttonbar__previousbutton:hover,
.projectsviewer__buttonbar__nextbutton:hover {
  color: var(--color-theme);
}

.projectsviewer__buttonbar__previousbutton[disabled],
.projectsviewer__buttonbar__nextbutton[disabled] {
  color: #DDDDDD;
}

.projectsviewer__buttonbar__previousbutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f104";
  font-size: 20px;
}

.projectsviewer__buttonbar__bullets {
  flex: 1 0 0px;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.projectsviewer__buttonbar__nextbutton::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f105";
  font-size: 20px;
}

/************************************************************************
*
*  Jumpbuttons
*/
.carrouselbullets {
  display: flex;
  -webkit-tap-highlight-color: transparent;
}

.carrouselbullets > * {
  padding: 5px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.carrouselbullets > *::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #AAAAAA;
  transform: scale(0.4);
}

.carrouselbullets > *.carrouselbullet--selected::before {
  background-color: var(--color-theme);
  transform: scale(1);
}

@media (max-width: 639px) {
  .carrouselbullets > * {
    padding: 4px;
  }
  .carrouselbullets > *::before {
    width: 16px;
    height: 16px;
  }
}