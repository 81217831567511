/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.forgotpasswordbutton {
  margin-left: 30px;
  color: var(--rtd-text-color);
}

/* My projects page */
.myprojects__startproject .startprojectbutton {
  margin-top: 30px;
}

.myprojects__projects.myprojects__projects.myprojects__projects h2 {
  margin-top: 65px;
  margin-bottom: 30px;
}