.sharecomponent__buttonbar {
  display: flex;
  align-items: flex-start;
  column-gap: var(--component-sharepage-button-spacing);
  row-gap: 18px;
}

.sharecomponent__buttonbar .wh-share {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  flex: none;
  width: 37px;
  height: 37px;
  width: var(--component-sharepage-button-size, 37px);
  height: var(--component-sharepage-button-size, 37px);
  background-color: #FFFFFF;
  background: var(--component-sharepage-button-background, #FFFFFF);
  color: var(--component-sharepage-button-color);
  border: var(--component-sharepage-button-border);
  border-radius: var(--component-sharepage-button-radius, 8px);
  cursor: pointer;
}

/*
.sharecomponent__buttonbar > .wh-share + .wh-share
{
  margin-left: var(--component-sharepage-button-spacing);
}
*/
.sharecomponent__buttonbar .wh-share:hover {
  background-color: var(--component-sharepage-button-color);
  background-image: none;
  color: var(--component-sharepage-button-background);
}

.sharecomponent__buttonbar .wh-share[data-network=bluesky] svg,
.footer__socialitem svg {
  width: 23px;
  height: 23px;
  fill: #FFFFFF;
}

.sharecomponent__buttonbar .wh-share[data-network=bluesky]:hover svg,
.footer__socialitem:hover svg {
  fill: #000000;
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-twitter);
}

.sharecomponent__buttonbar .wh-share[data-network=facebook]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-facebook-f);
}

.sharecomponent__buttonbar .wh-share[data-network=linkedin]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-linkedin-in);
}

.sharecomponent__buttonbar .wh-share[data-network=whatsapp]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-whatsapp);
}

.sharecomponent__buttonbar .wh-share.siteaction--emailpagelink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-envelope);
}

.sharecomponent__buttonbar .wh-share[data-network=linktoclipboard]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-link);
}

/*
LinkedIn 20
Facebook 19
X 18px
link 16px
*/
.sharecomponent__buttonbar .wh-share.wh-share::before {
  font-size: var(--component-sharepage-button-symbolsize, 20px);
}

@media (min-width: 480px) {
  .sharecomponent__buttonbar .wh-share[data-network=whatsapp] {
    display: none;
  }
}
.sharecomponent__buttonbar .wh-share[data-network=print]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-print);
  font-size: 19px;
  font-size: var(--component-sharepage-button-symbolsize, 19px);
}