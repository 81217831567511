.wh-form--uploading .canvasupload {
  cursor: default;
}
.wh-form--uploading .canvasupload .canvasupload__busy {
  display: flex;
}
.wh-form--uploading .canvasupload .canvasupload__note {
  display: none;
}

.canvasupload {
  display: block;
  width: 50%;
  position: relative;
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}
.canvasupload__note {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  line-height: 20px;
  justify-content: center;
  align-items: center;
}
.canvasupload__note .icon {
  font-size: 36px;
  margin-bottom: 10px;
}
.canvasupload__note .text {
  padding: 10px 20px;
  text-align: center;
  font-style: italic;
}
.canvasupload.canedit .canvasupload__note {
  display: none;
}
.canvasupload__busy {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 60px;
}
.canvasupload.canvasupload--disabled {
  cursor: default;
}
.canvasupload.canvasupload--disabled .canvasupload__note {
  display: none;
}
.canvasupload__imgcontainer {
  overflow: hidden;
  position: relative;
  max-width: 100%;
  background-color: #f3f3f1;
  /* Circle mask
  &::after
  {
    pointer-events: none;
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: radial-gradient(circle at center, transparent 60%, rgba(255,255,255,0.6) 0%);
    background-size: 100% 100%;
    background-position: 50% 50%;
  }
  */
}
.canvasupload__img {
  position: absolute;
  left: 0;
  top: 0;
  transform-origin: 0% 0%;
}
.canvasupload.canedit .canvasupload__img {
  cursor: move;
}
.canvasupload__tools {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  padding-left: 20px;
}
.canvasupload__dragnote {
  z-index: -1;
  visibility: hidden;
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 30px;
}
.canvasupload__dragnote .icon {
  flex: none;
  width: 25px;
}
.canvasupload__scale {
  width: 100%;
  transition: opacity 0.2s;
  z-index: -1;
  visibility: hidden;
}
.canvasupload__scale .text {
  margin-top: 10px;
}
.canvasupload.canedit .canvasupload__dragnote, .canvasupload.canedit .canvasupload__scale {
  visibility: visible;
  z-index: 1;
}
.canvasupload__input {
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;
}
.canvasupload__value {
  height: 100%;
  width: 100%;
}
.canvasupload__clear {
  top: 0;
  right: 0;
  width: 100%;
  height: 30px;
  display: block;
  color: #fff;
  background-color: #283477;
  text-align: center;
  margin-top: 20px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.8;
  transition: opacity 0.5s;
  cursor: pointer;
}
.canvasupload__clear:hover {
  opacity: 1;
}