/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.page--projectsoverview .page__contentarea.page__contentarea {
  margin-top: 0;
  padding-top: 0;
}
.page--projectsoverview .filteredoverview__feedbackandviewbar {
  margin-top: 20px;
  margin-bottom: 15px;
}
.page--projectsoverview .deeplinks-wrapper {
  display: none;
}

.page--projectsoverview--grid .page__contentarea > .projectsoverview__rtdcontent {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
.page--projectsoverview--grid .page__contentarea > .projectsoverview__rtdcontent > * {
  grid-column: var(--pagegrid-col-verywide);
}

.page--projectsoverview--list .page__contentarea > .projectsoverview__rtdcontent {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
.page--projectsoverview--list .page__contentarea > .projectsoverview__rtdcontent > * {
  grid-column: var(--pagegrid-col-default);
}

.page__contentarea > .rtdcontent + .projectsoverview__items {
  margin-top: 15px;
}

.projectsoverview__items--listview {
  grid-column: var(--pagegrid-col-default);
}
.projectsoverview__items--listview .projectblock {
  display: flex;
  align-items: center;
  padding: 0 22px;
  height: 60px;
  background-color: #F2EFED;
  font: bold 22px/26px var(--rtd-heading-fontfamily);
  color: #3F3F3F;
  text-decoration: none;
}
.projectsoverview__items--listview .projectblock.notmatching {
  display: none;
}
.projectsoverview__items--listview .projectblock:hover {
  background-color: var(--color-theme);
  color: #FFFFFF;
  text-decoration: none;
}
.projectsoverview__items--listview .projectblock::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 17px;
  margin-right: 17px;
}
.projectsoverview__items--listview .projectblock + .projectblock {
  margin-top: 14px;
}

.projectsoverview__items--gridview {
  grid-column: var(--pagegrid-col-verywide);
  margin-left: -4px;
  margin-right: -4px;
  padding: 4px;
}

@media (max-width: 767px) {
  .page--projectsoverview .page__contentarea {
    margin-top: 0;
  }
  .page--projectsoverview .filteredoverview__feedbackandviewbar {
    margin-top: 14px;
    margin-bottom: 10px;
  }
}
.projectblock-image--empty {
  background-color: #CCCCCC;
}

.projectsoverview__items--gridview {
  gap: var(--grid-spacing);
}
@media (max-width: 767px) {
  .projectsoverview__items--gridview {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .projectsoverview__items--gridview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (min-width: 1000px) {
  .projectsoverview__items--gridview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
.projectsoverview__items--gridview .projectblock {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.projectsoverview__items--gridview .projectblock.notmatching {
  display: none;
}
.projectsoverview__items--gridview a.projectblock.projectblock {
  text-decoration: none;
}
.projectsoverview__items--gridview .projectblock:hover {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.projectsoverview__items--gridview .projectblock:hover .projectblock-image {
  transform: scale(1.1);
}
.projectsoverview__items--gridview .projectblock-top {
  flex: none;
  position: relative;
  overflow: hidden;
}
.projectsoverview__items--gridview.projectsoverview__items--withimages .projectblock-top::before {
  display: block;
  content: "";
  padding-top: 56.1%;
}
.projectsoverview__items--gridview .projectblock-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 56.1%;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}
.projectsoverview__items--gridview .projectblock-title {
  color: var(--color-theme);
}
.projectsoverview__items--gridview .projectblock:hover .projectblock-title {
  color: var(--color-theme-hover);
}
.projectsoverview__items--gridview .widget-ctablocks__description {
  padding: 15px;
  font-family: var(--rtd-text-fontfamily);
  font-size: 14px;
  line-height: 20px;
  color: var(--rtd-text-color);
}
.projectsoverview__items--gridview.projectsoverview__items--withimages .projectblock-title {
  position: absolute;
  width: 100%;
  bottom: 0px;
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
}
.projectsoverview__items--gridview .projectblock-description {
  display: flex;
  align-self: baseline;
  justify-content: space-between;
  width: 100%;
  flex: 1 0 auto;
  padding: 17px 20px 14px 20px;
  color: var(--rtd-text-color);
  font: 14px/20px var(--rtd-text-fontfamily);
}
.projectsoverview__items--gridview .projectblock-description::after {
  margin-top: 2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 17px;
  color: #3F3F3F;
}
.projectsoverview__items--gridview .projectblock-description > * {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media (max-width: 320px) {
  .projectblock-title {
    font: bold 18px/20px var(--rtd-heading-fontfamily);
    margin-top: 3px;
    padding: 7px 30px 7px 20px;
  }
}
@media (max-width: 767px) {
  .projectblock-title {
    font: bold 19px var(--rtd-heading-fontfamily);
    margin-top: 3px;
  }
}
@media (max-width: 767px) {
  .projectblock-title {
    margin-top: 3px;
    padding: 7px 30px 7px 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .projectblock-title {
    font: bold 20px/24px var(--rtd-heading-fontfamily);
    margin-top: 3px;
    padding: 11px 30px 7px 20px;
  }
}
@media (min-width: 1000px) {
  .projectblock-title {
    font: bold 24px/29px var(--rtd-heading-fontfamily);
    margin-top: 3px;
    padding: 19px 30px 12px 20px;
  }
}
.projectsoverview__items--listview .projectblock-title {
  padding-left: 0;
}

.projectsoverview__items--noimages .projectblock-top {
  background-color: #F2EFED;
}

.projectsoverview__items--noimages .projectblock-title {
  color: #3F3F3F;
}