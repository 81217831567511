/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
#slidemenu-container {
  pointer-events: none;
}

html:not(.sidebar-enabled) #slidemenu-container {
  z-index: -1; /* prevent accessibility checkers from thinking elements are overlapped by this container */
  transition: z-index 0s linear 1s; /* when closing we apply the z-index: -1; after the transition should be done */
}

html.sidebar-enabled #slidemenu {
  overflow: auto;
  pointer-events: auto;
  transform: translateX(0%) !important;
}

@media (min-width: 640px) {
  #slidemenu-container {
    grid-column: var(--pagegrid-col-fullwidth);
    grid-row: 2/span 6;
    z-index: 101;
    position: relative;
    overflow: hidden;
  }
  #slidemenu {
    position: absolute;
    height: 100%;
    width: 80%;
    max-width: 310px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
}
@media (max-width: 639px) {
  #slidemenu-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 101;
  }
  #slidemenu {
    position: fixed;
    width: 100%;
    height: 100vh;
  }
}
#slidemenu {
  /* Don't take space when inactive
     If we use display: none; we cannot use transitions.
     (or we need more js code and might get a flicker on mobile devices)
  height: 0;
  overflow: hidden;
  */
  pointer-events: none;
  transition: transform 0.4s;
  right: 0;
  transform: translateX(calc(100% + 5px));
  -webkit-backface-visibility: hidden;
}

#slidemenu:focus {
  outline: none;
}

html.sidebar-enabled .header-top-background,
html.sidebar-enabled .header-top {
  z-index: 101;
}

/*

ADDME:
Change desktop version
- not based on row anymore - but sticky + top: (set by JS) + bottom: 0; overflow-y: auto;
- html overflow: hidden; so page doesn't scroll

*/
@media (min-width: 640px) {
  #slidemenu {
    padding-top: 23px;
  }
  /*
  .sidebar__header
  {
    display: flex;
    //justify-content: space-between;
  }

  .sidebar__header__identity.sidebar__header__identity
  {
    display: none;
  }
  */
  .sidebar__header__identity {
    display: none !important;
  }
  .sidebar-action-close {
    position: absolute !important;
    top: 0;
    right: 10px;
  }
}
@media (max-width: 639px) {
  .sidebar__header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F2EFED;
    min-height: 53px;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
  html.sidebar-enabled body > * {
    visibility: hidden;
  }
  #slidemenu-container {
    margin-top: 1px;
    visibility: visible;
  }
}
a.sidebar__header__identity {
  display: flex;
  text-decoration: none;
  margin-bottom: -3px;
}

.sidebar__identity__organizationtitle,
.sidebar__identity__sitetitle {
  font: bold 20px/24px var(--rtd-heading-fontfamily);
}

.sidebar__identity__organizationtitle {
  color: #E1670B;
}

.sidebar__identity__sitetitle {
  color: #8E8E8E;
  max-width: 100%;
  white-space: break-spaces;
}

.sidebar__menu + * {
  margin-top: 50px;
}

.sidebar__languages + * {
  margin-top: 40px;
}

#slidemenu {
  background-color: #FFFFFF;
  color: #363636;
  padding-bottom: 23px;
}

#slidemenu-close {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  margin-left: 30px;
  flex: none;
  color: #3F3F3F;
  font: 15px var(--rtd-heading-fontfamily);
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

#slidemenu-close::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  font-size: 20px;
}

/*
#slidemenu-container hr
{
  margin-top:    $spacing;
  margin-bottom: $spacing;

  margin-left:   $sidemainmenu-padding-left + $sidebar-content-indent;

  border: 0;
  border-top: 1px solid #D7D7D7;
}

#slidemenu-container .sidebar__header + hr
{
  margin-top: 0;
}
*/
/*
.sidebar__relatedwebsites
{
  margin-top: $spacing-large;
  margin-left:  $sidemainmenu-padding-left;
  margin-right: $sidemainmenu-padding-right;

  // make each link be on a new line + shrink-wrapping
  // (another way would be to have them have display: table; or added <br /> in the template)
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__relatedwebsites a
{
  display: flex;
  align-items: center;

  position: relative;

  color: $sidebar-menu-level1-textcolor;
  font: $sidebar-menu-level1-font;
  @if ($sidebar-menu-level1-uppercase) { text-transform:  uppercase; }
  text-decoration: none;

  padding: $sidebar-menu-level1-padding;
}

.sidebar__relatedwebsites a::before
{
  position: absolute;
  left: 0;

  @include fontawesome-solid;
  content: $fa-external-link-alt;
  font-size: 13px;
}

.sidebar__relatedwebsites a.iscurrent::before
{
  content: $fa-chevron-right;
}

.sidebar__relatedwebsites a:hover
{
  text-decoration: underline;
}
*/
.sidebar__secondarylinks {
  margin-left: 53px;
  margin-right: 23px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__secondarylinks a {
  color: var(--rtd-text-color);
  font: 16px var(--rtd-heading-fontfamily);
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sidebar__secondarylinks a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks a + a {
  margin-top: 5px;
}

.sidebar__languages {
  margin-left: 53px;
  margin-right: 23px;
  display: flex;
  align-items: center;
  color: var(--rtd-text-color);
  font: 16px var(--rtd-heading-fontfamily);
}

.sidebar__languages a,
.sidebar__languages span {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.sidebar__languages a:first-child,
.sidebar__languages span:first-child {
  margin-left: -8px;
}

.sidebar__languages a {
  color: #363636;
}

.sidebar__languages span.selected {
  text-decoration: none;
}

.sidebar__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 23px;
  margin-right: 23px;
}

.sidebar__menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button.sidemainmenu__item__link {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  width: 100%;
  text-align: left;
}

/*
By default we hide level 3 and 4, but using the toggles they can be expanded.
DON'T use display: none; because that way the items won't influence the width of
the pulldown until opened causing the pulldown to get wider (and reposition if it's centered).
*/
.sidebar__menu--level2,
.sidebar__menu--level3,
.sidebar__menu--level4 {
  height: 0;
  overflow: hidden;
  visibility: hidden; /* prevent tabnavigation within hidden content */
}

.sidemainmenu__item--expand > .sidebar__menu--level2,
.sidemainmenu__item--expand > .sidebar__menu--level3,
.sidemainmenu__item--expand > .sidebar__menu--level4 {
  height: auto;
  overflow: visible;
  visibility: visible;
}

/*
.sidemainmenu-level1__item.sidemainmenu__item--expand
{
  background-color: $sidebar-menu-level1-expandedbgcolor;
}
.sidemainmenu-level2__item.sidemainmenu__item--expand
{
  background-color: $sidebar-menu-level2-expandedbgcolor;
}
*/
.sidebar__menu--level3 {
  padding-top: 5px;
}

.sidemainmenu-level1__itemlink,
.sidemainmenu-level2__itemlink,
.sidemainmenu-level3__itemlink {
  display: block;
  position: relative;
  cursor: pointer;
}

.sidemainmenu-level1__itemlink:hover,
.sidemainmenu-level2__itemlink:hover,
.sidemainmenu-level3__itemlink:hover {
  text-decoration: underline;
}

.sidemainmenu__item__toggle {
  position: absolute;
  left: 0;
  cursor: pointer;
  max-width: fit-content;
  padding: 7px 0px 7px 7px;
  margin-left: -7px;
  margin-top: -7px;
}

.sidemainmenu__item__toggle:hover::before {
  color: var(--color-theme);
}

.sidemainmenu__item__toggle::before {
  content: "";
  width: 25px;
  flex: none;
  font-size: 16px;
  color: #363636;
}

/*
.sidebar__menu--level1 .sidemainmenu__item__toggle { outline: 1px solid #FF0000; }
.sidebar__menu--level2 .sidemainmenu__item__toggle { outline: 1px solid #00FF00; }
*/
.sidebar__menu--level1 .sidemainmenu__item__toggle::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  padding-left: 1px;
}
.sidebar__menu--level1 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  content: "\f068";
  padding-left: 1px;
}

.sidebar__menu--level2 .sidemainmenu__item__toggle::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  padding-left: 1px;
}
.sidebar__menu--level2 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  content: "\f068";
  padding-left: 1px;
}

.sidebar__menu--level3 .sidemainmenu__item__toggle::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  padding-left: 1px;
}
.sidebar__menu--level3 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  content: "\f068";
  padding-left: 1px;
}

.sidemainmenu-level1__itemlink {
  color: #363636;
  font: 16px var(--rtd-text-fontfamily);
  text-decoration: none;
  padding: 7px 0px 7px 30px;
}

.sidemainmenu-level2__itemlink {
  color: #363636;
  font: 15px var(--rtd-text-fontfamily);
  text-decoration: none;
  padding: 7px 10px 7px 50px;
}

.sidemainmenu-level3__itemlink {
  color: #363636;
  font: 15px var(--rtd-text-fontfamily);
  text-decoration: none;
  padding: 7px 10px 7px 70px;
}

.sidemainmenu-level4__itemlink {
  color: #363636;
  font: 15px var(--rtd-text-fontfamily);
  text-decoration: none;
  padding: 7px 10px 7px 90px;
}

/*
If body has overflow: hidden; the sticky won't work on children anymore

html.sidebar-enabled body
{
  // height: 100%;
  // width: 100%;
  overflow: hidden;
}
*/
html body::after {
  /*
    animation: hidepagecontent 1s;
    animation-direction: reverse;
  */
}

html.sidebar-enabled body::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.25s, backdrop-filter 0.25s, -webkit-backdrop-filter 0.25s;
  /*
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  */
  z-index: 100;
  /*
    animation: hidepagecontent 1s;
    animation-fill-mode: forwards;
  */
}

@keyframes hidepagecontent {
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(3px);
  }
}