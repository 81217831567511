/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
$gridcol-left: null;
$gridcol-right: null;
$gridcol-left-left: null;
$gridcol-left-right: null;

@if ($pagegrid-columns == 12)
{
*/
/*
}
@else if ($pagegrid-columns == 14)
{
  $gridcol-left: 3 / span 18;
  $gridcol-right: 21 / span 9;

  $gridcol-left-left: 3 / span 9;
  $gridcol-left-right: 13 / span 8;
}
*/
.widget-neo {
  --content-layout: verywide;
  --content-squeeze-preference: needwide;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  z-index: var(--zindex-overlap-deeplinks);
}

.widget-neo::before {
  content: "";
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  grid-row: 1;
  background-color: var(--color-lightgrey);
}

.widget-neo a {
  color: var(--rtd-link-color);
  text-decoration: none;
}

.widget-neo a:hover {
  color: var(--rtd-link-color-hover);
  text-decoration: underline;
}

html.pagewidth--width-fullwidth .widget-neo {
  background-color: var(--color-lightgrey);
}

.widget-neo__content {
  grid-column: var(--pagegrid-col-fullwidth);
  grid-row: 1;
  padding-top: var(--widget-generic-panel-padding-top);
  padding-bottom: var(--widget-generic-panel-padding-bottom);
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-neo .widget-neo__event:hover,
.widget-neo .widget-neo__item:hover {
  text-decoration: none;
}

.widget-neo__newsitems {
  display: contents;
}

.widget-neo__item__image {
  width: 100%;
  background-size: cover;
}

.widget-neo__item__date {
  font: 15px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  color: #767676;
}

.widget-neo__item__title,
.widget-neo__event__title {
  font: bold 24px/32px var(--rtd-heading-fontfamily);
  color: var(--color-theme);
}

.widget-neo__item__description {
  font: 16px/24px var(--rtd-text-fontfamily);
  color: var(--rtd-text-color);
}

.widget-neo__newstitle,
.widget-neo__eventstitle {
  color: #000000;
}

.widget-neo__item,
.widget-neo__event {
  display: block;
}

a.widget-neo__item {
  text-decoration: none;
}

a.widget-neo__item:hover .widget-neo__item__title {
  text-decoration: underline;
}

.widget-neo__morelink.widget-neo__morelink {
  color: #767676;
  text-decoration: underline;
}

.widget-neo__event {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #C7C7C7;
}

.widget-neo__event:first-child {
  padding-top: 0;
  border-top: 0;
}

.widget-neo__event__date {
  font: 15px var(--rtd-heading-fontfamily);
  color: #767676;
}

@media (max-width: 799px) {
  .widget-neo__newstitle,
  .widget-neo__eventstitle {
    grid-column: var(--pagegrid-col-fullwidth);
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
    font: bold 19px/24px var(--rtd-heading-fontfamily);
    border-bottom: 1px solid #C5C5C5;
    padding-bottom: 9px;
    margin-bottom: 15px;
    color: #000000;
  }
  .widget-neo__item,
  .widget-neo__newsfooter,
  .widget-neo__events,
  .widget-neo__eventsfooter {
    grid-column: var(--pagegrid-col-verywide);
    margin-left: var(--pagegrid-gutteroutside);
    margin-right: var(--pagegrid-gutteroutside);
  }
  .widget-neo__item__date {
    font: 14px var(--rtd-heading-fontfamily);
  }
  .widget-neo__newsfooter,
  .widget-neo__eventsfooter {
    text-align: right;
  }
  .widget-neo__morelink.widget-neo__morelink {
    font: 16px/22px var(--rtd-heading-fontfamily);
  }
  .widget-neo__item0 .widget-neo__item__image {
    width: 100%;
    padding-top: 36%;
  }
  .widget-neo__item0 .widget-neo__item__meta {
    margin-top: 10px;
  }
  .widget-neo__item0 .widget-neo__item__title {
    font: bold 19px/24px var(--rtd-heading-fontfamily);
  }
  .widget-neo__item1 {
    margin-top: 20px;
  }
  .widget-neo__item2,
  .widget-neo__item3 {
    margin-top: 10px;
  }
  .widget-neo__item1,
  .widget-neo__item2,
  .widget-neo__item3 {
    display: flex;
  }
  .widget-neo__item1 .widget-neo__item__image,
  .widget-neo__item2 .widget-neo__item__image,
  .widget-neo__item3 .widget-neo__item__image {
    flex: 0 0 15vw;
    height: 15vw;
    margin-right: 15px;
    background-size: cover;
  }
  .widget-neo__item1 .widget-neo__item__title,
  .widget-neo__item2 .widget-neo__item__title,
  .widget-neo__item3 .widget-neo__item__title {
    font: bold 17px/28px var(--rtd-heading-fontfamily);
  }
  .widget-neo__item1 .widget-neo__item__description,
  .widget-neo__item2 .widget-neo__item__description,
  .widget-neo__item3 .widget-neo__item__description {
    display: none;
  }
  .widget-neo__eventsfooter,
  .widget-neo__newsfooter {
    margin-top: 15px;
    border-top: 1px solid #C5C5C5;
    padding-top: 10px;
  }
  .widget-neo__event {
    display: flex;
  }
  .widget-neo__event__image {
    flex: 0 0 15vw;
    height: 15vw;
    margin-right: 15px;
    background-size: cover;
  }
}
@media (min-width: 800px) {
  .widget-neo__newstitle,
  .widget-neo__eventstitle {
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 30px;
    font: bold 28px var(--rtd-heading-fontfamily);
  }
  .widget-neo__morelink.widget-neo__morelink {
    font: 17px var(--rtd-heading-fontfamily);
  }
}
@media (min-width: 800px) {
  .widget-neo__content {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content min-content min-content min-content min-content;
  }
  .widget-neo__newstitle {
    grid-column: 3/span 16;
  }
  .widget-neo__newstitle,
  .widget-neo__eventstitle {
    grid-row: 1;
  }
  .widget-neo__item0 {
    /* this definition is used by browsers which do not understand
       the display: contents; at the end
    */
    display: block;
    grid-row: 2/span 3;
    grid-column: 3/span 7;
    display: contents;
  }
  .widget-neo__item0 .widget-neo__item__image {
    width: 100%;
    padding: 23%;
    grid-column: 3/span 7;
    grid-row: 2/span 2;
  }
  .widget-neo__item0 .widget-neo__item__meta {
    grid-column: 3/span 7;
    grid-row: 4;
    margin-top: 10px;
  }
  .widget-neo__item0 .widget-neo__item__description {
    /*
    This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
    Firefox issues:
    - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
    - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
    - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
    */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  .widget-neo__item1,
  .widget-neo__item2,
  .widget-neo__item3 {
    grid-column: 11/span 8;
    display: flex;
  }
  .widget-neo__item1 .widget-neo__item__image,
  .widget-neo__item2 .widget-neo__item__image,
  .widget-neo__item3 .widget-neo__item__image {
    flex: 0 0 95px;
    margin-right: 30px;
    height: 95px;
    padding-top: 0;
    background-size: cover;
  }
  .widget-neo__item1 .widget-neo__item__title,
  .widget-neo__item2 .widget-neo__item__title,
  .widget-neo__item3 .widget-neo__item__title {
    font: bold 18px/24px var(--rtd-heading-fontfamily);
  }
  .widget-neo__item1 .widget-neo__item__description,
  .widget-neo__item2 .widget-neo__item__description,
  .widget-neo__item3 .widget-neo__item__description {
    display: none;
  }
  .widget-neo__event__title {
    font: bold 18px/24px var(--rtd-heading-fontfamily);
  }
  .widget-neo__item2,
  .widget-neo__item3 {
    margin-top: 20px;
  }
  .widget-neo__item1 {
    grid-row: 2;
  }
  .widget-neo__item2 {
    grid-row: 3;
  }
  .widget-neo__item3 {
    grid-row: 4;
  }
  .widget-neo__newsfooter {
    grid-column: 3/span 16;
  }
  .widget-neo--norightcolumn .widget-neo__newstitle {
    grid-column: var(--pagegrid-col-verywide);
  }
  .widget-neo--norightcolumn .widget-neo__item0, .widget-neo--norightcolumn .widget-neo__item0 .widget-neo__item__image,
  .widget-neo--norightcolumn .widget-neo__item0 .widget-neo__item__meta {
    grid-column: 3/span 13;
  }
  .widget-neo--norightcolumn .widget-neo__newsitems {
    grid-column: 17/span 9;
    grid-row: 2/span 3;
    display: flex;
    flex-direction: column;
  }
  .widget-neo--norightcolumn .widget-neo__item1 .widget-neo__item__image, .widget-neo--norightcolumn .widget-neo__item2 .widget-neo__item__image,
  .widget-neo--norightcolumn .widget-neo__item3 .widget-neo__item__image {
    flex: 0 0 160px;
    height: 100px;
  }
  .widget-neo--norightcolumn .widget-neo__newsfooter {
    border-top: 0;
    margin-top: auto;
  }
  .widget-neo__eventstitle,
  .widget-neo__events,
  .widget-neo__eventsfooter {
    margin-left: 30px;
  }
  .widget-neo__eventstitle {
    grid-column: 19/span 7;
  }
  .widget-neo__events {
    grid-row: 2/span 3;
    grid-column: 19/span 7;
  }
  .widget-neo__event {
    display: flex;
  }
  .widget-neo__event__image {
    flex: 0 0 95px;
    margin-right: 30px;
    height: 95px;
    padding-top: 0;
    background-size: cover;
  }
  .widget-neo__eventsfooter {
    grid-column: 19/span 7;
    grid-row: 5;
  }
  .widget-neo__eventsfooter,
  .widget-neo__newsfooter {
    margin-top: 15px;
    border-top: 1px solid #C5C5C5;
    padding-top: 15px;
  }
}