/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
html {
  --menubar-pulldown-spacing: 15px;
}

ul.spc-menubar {
  display: flex;
}

.spc-menubar,
.spc-menubar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.spc-menubar > li {
  position: relative;
  display: flex;
  align-items: center;
}

ul.spc-menubar > li + li {
  margin-left: 0;
}

.spc-menubar a {
  /*
  // Keep all items single-line, use ellipsis if needed
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}

ul.spc-menubar > li > a {
  color: #FFFFFF;
  text-decoration: none;
  white-space: nowrap;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  letter-spacing: 0.25px;
  /*
    // Make the whole height an clickable area + allow us to have the
    // bottom border (for hover) on the bottom of the menu bar
    height: 100%;
    display: flex;
    flex-direction: column; // the :after must be below the visible title
    justify-content: center;
  */
}

ul.spc-menubar > li + li {
  margin-left: 20px;
}

.header-menubar__content a {
  border-bottom: 1px solid transparent;
}

.spc-menubar__pulldown {
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 5;
  display: none;
  padding-top: var(--menubar-pulldown-spacing);
  transform: translate(0, 100%);
}

/* content within the pulldown */
.spc-menubar__level2 {
  max-height: calc(100vh - var(--siteheader-height-approximation));
  overflow-y: auto;
  overscroll-behavior: contain;
}

/* show pulldown when hovering over a menubar item (or it's subchildren) */
ul.spc-menubar > li:hover > .spc-menubar__pulldown {
  display: block;
}

.spc-menubar__pulldown > ul {
  position: relative;
  padding: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 6px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  width: max-content;
}

.spc-menubar__pulldown {
  /*
      &::before
      {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #000;
        border-width: 13px;
        margin-left: -13px;
      }
  */
}
.spc-menubar__pulldown::after, .spc-menubar__pulldown::before {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  top: -9px;
  pointer-events: none;
}
.spc-menubar__pulldown::after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 12px;
  margin-left: -12px;
}

/*
ul.spc-menubar > li > a::after
{
  display: block;
  content: attr(data-title) ".";
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;

  letter-spacing: 0px;
}

// FIXME: media hover:hover ?
ul.spc-menubar > li > a:hover
{
  font-weight: bold;
  letter-spacing: 0px;
  // border-bottom: 1px solid $color-theme-cta;
}
*/
ul.spc-menubar__level2 > li > a {
  color: #3F3F3F;
  text-decoration: none;
  font: 16px/20px var(--rtd-text-fontfamily);
  display: block;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

ul.spc-menubar__level2 > li > a:hover,
ul.spc-menubar__level3 > li > a:hover {
  text-decoration: none;
  background-color: var(--color-theme);
  color: #FFFFFF;
}

ul.spc-menubar__level2 > li + li {
  margin-top: 0px;
}

.spc-menubar--hassubitems > a::before {
  position: absolute;
  left: 10px;
  margin-top: 3px;
  font-size: 15px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f067";
}

ul.spc-menubar__level3 > li > a {
  color: #3F3F3F;
  text-decoration: none;
  font: 14px/20px var(--rtd-text-fontfamily);
  display: block;
  padding-left: 50px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}