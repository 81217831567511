/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*

Home header - 1440 x 540

Header:
- siteheader--menubar-translucent
  - Single image
  - Slideshow
- pageheader--inflow
- page--withfilterpanel


*/
.page-header__slideshow {
  position: relative;
}

.page-header__slideshow .carrousel__cell {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
}

.page-header__slideshow {
  display: grid;
}
.page-header__slideshow::before {
  display: block;
  grid-column: 1;
  grid-row: 1;
  content: "";
  /*
      height:     $pageheader-preferredvwheight;
      max-height: calc(35vmin - 100px);
      min-height: $pageheader-minheight; // mobile header height
  */
  min-height: var(--pageheader-default-minheight);
  height: var(--pageheader-default-height-fallback);
  height: var(--pageheader-default-height);
}
html.pageheader--large .page-header__slideshow::before {
  /*
      height:     37.5vw;
      max-height: calc(540px - 100px);
      min-height: 300px;
  */
  min-height: var(--pageheader-large-minheight);
  height: var(--pageheader-large-height-fallback);
  height: var(--pageheader-large-height);
}
html.pageheader--flatten .page-header__slideshow::before {
  display: none;
}

.page-header__slidescontent {
  grid-column: var(--pagegrid-col-verywide);
  padding-top: 25px;
  padding-bottom: 35px;
  display: grid;
  align-items: end;
  --rtd-text-color: var(--color-white);
  --rtd-text-font: 18px/29px var(--rtd-text-fontfamily);
  --component-tag-color: #FFFFFF;
  --component-tag-hover-background: #FFFFFF;
  --component-tag-border-color: #FFFFFF;
}
@media (max-width: 767px) {
  .page-header__slidescontent {
    padding-top: 75px;
  }
}

.page-header__slidetag {
  --color-theme: #FFFFFF; /* also make the tag text and border white */
  margin-bottom: 15px;
}

.page-header__slidecontent {
  grid-column: 1;
  grid-row: 1;
  color: #FFFFFF;
}

.page-header__slidecontent__inner > * {
  max-width: 855px;
  max-width: max(500px, 50%);
}

.page-header__slidecontent__inner > .page-header__slidetitle {
  max-width: 855px;
}

.page-header__slidecontent .wh-form__button {
  margin-top: 30px;
}

.page-header__slide,
.page-header__slidecontent {
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
}

.page-header__slide.activeslide,
.page-header__slidecontent.activeslide {
  opacity: 1;
  pointer-events: auto;
}

.page-header__slideshow::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(180deg, rgba(84, 83, 80, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.page-header__slideshow__navigation {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 3;
  align-self: end;
  justify-self: end;
  position: relative;
  z-index: 2;
  padding-bottom: 35px;
  display: flex;
}

.page-header__slideshow__prevbutton,
.page-header__slideshow__nextbutton {
  color: #FFFFFF;
  cursor: pointer;
  border: 2px solid #FFF;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header__slideshow__nextbutton {
  margin-left: 18px;
}

.page-header__slideshow__prevbutton:hover,
.page-header__slideshow__nextbutton:hover {
  background-color: #FFFFFF;
  color: #000000;
}

.page-header__slideshow__prevbutton::before {
  /*
  @include fontawesome-light;
  content: $fa-arrow-circle-left;
  font-size: 36px;
  */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f060";
  font-size: 20px;
}

.page-header__slideshow__nextbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f061";
  font-size: 20px;
}

.page-header__content h1.page-header__title,
.page-header__slidescontent h1.page-header__slidetitle {
  color: #FFFFFF;
}

.page-header__slidetitle {
  font: bold 44px/50px var(--rtd-heading-fontfamily);
}

.page-header__slidescontent .widget-cta__button {
  font: 18px var(--rtd-heading-fontfamily);
  padding-top: 3px;
}

.pageheader__date {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font: bold 17px var(--rtd-heading-fontfamily);
  margin-top: 8px;
}

.pageheader__date::before {
  margin-top: -5px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 15px;
  content: var(--fa-clock);
  margin-right: 6px;
}

html.page--withdeadline .pageheader__date::before {
  content: var(--fa-stopwatch);
}

@media (max-width: 767px) {
  .page-header__slidescontent {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .page-header__slidetitle {
    font: bold 24px/28px var(--rtd-heading-fontfamily);
  }
  .page-header__slidescontent .widget-cta__button {
    font: 18px var(--rtd-heading-fontfamily);
    padding-top: 3px;
    height: 41px;
  }
  .page-header__slideshow__navigation {
    padding-top: 25px;
    align-self: start;
  }
  .page-header__slideshow__prevbutton,
  .page-header__slideshow__nextbutton {
    width: 24px;
    height: 24px;
  }
  .page-header__slideshow__prevbutton::before,
  .page-header__slideshow__nextbutton::before {
    font-size: 14px;
  }
  .page-header__slideshow__nextbutton {
    margin-left: 12px;
  }
}
.page-header__content {
  color: #FFFFFF;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.page-header__content .header-background-fullwidth {
  background-color: var(--color-lightgrey);
}
@media (max-width: 1023px) {
  .page-header__content .page-header__meta {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .page-header__content .page-header__meta {
    grid-column: var(--pagegrid-col-default);
  }
}
.page-header__content.page--withfilterpanel .page-header__meta {
  grid-column: var(--pagegrid-col-verywide);
}
.page-header__content h1.page-header__title,
.page-header__content .page-header__slidetitle {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.page-header__content .page-header__content {
  color: #FFFFFF;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  align-items: end;
}
.page-header__content .page-header__meta {
  padding: 25px 0;
}
.page-header__content.pageheader--flatten .page-header__meta {
  padding-top: 35px;
  padding-bottom: 15px;
}

.page__body .page-header__content {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.page__body .pageheader__date {
  font-size: 14px;
  margin-bottom: 4px;
}
.page__body h1.page-header__title {
  color: var(--pagetitle-inflow-color);
}
.page__body .page-header__subtitle {
  color: #8E8E8E;
  font: var(--rtd-h1-subtitle-font);
}
.page__body .page-header__subtitle + * {
  margin-top: 35px;
}
.page__body .page-header__title + .page-header__subtitle {
  margin-top: 15px;
  margin-bottom: 20px;
}

html.pageheader--cee .page-header__slidescontent, html.pageheader--cee .page-header__meta {
  padding-bottom: 75px;
}
html.pageheader--cee.pageheader--flatten .page-header__meta {
  padding-top: 35px;
  padding-bottom: 35px;
}
html.pageheader--cee .page-header__slidetitle {
  font: bold 48px/54px var(--rtd-heading-fontfamily);
}
@media (max-width: 767px) {
  html.pageheader--cee .page-header__slidescontent {
    padding-top: 25px;
    padding-right: 70px;
    padding-bottom: 20px;
  }
  html.pageheader--cee .page-header__slidetitle {
    font: bold 26px/30px var(--rtd-heading-fontfamily);
  }
}

/*
.page-header__title
{
  font: bold 50px/62px var(--rtd-heading-fontfamily);
  font-size: unquote("max(22px, min(6.5vw, 50px))"); // Chrome 79, Safari 11.1+, iOS 11.3+
  font-size: unquote("clamp(22px, 6.5vw, 50px)");    // Chrome 79, Safari 13.1+, iOS 13.5+ - https://caniuse.com/#feat=css-math-functions
}
*/
.page-header__date {
  font: 14px var(--rtd-text-fontfamily);
  color: #767676;
  text-transform: uppercase;
}