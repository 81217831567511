@media (min-width: $minwidth-centercontent)
{
  .page--blogoverview .searchresults
  {
    --component-summary-columns: var(--pagegrid-col-verywide);
  }
}

.page--blogoverview .widget--includespadding + .searchresults
{
  margin-top: 50px;
}

.page--blogoverview .searchresults
{
  grid-column: var(--pagegrid-col-default);
}

.page--blogoverview .searchresult__imagecol
{
  flex: none;
  width: auto;
}

.page--blogoverview .searchresult__image
{
  width: 195px;
  height:  138px;
  padding-top: 0;
}



.page--blogitem .page__body
{
  --pagegrid-col-default:         var(--pagegrid-col-verynarrow);
  --pagegrid-col-default-max8col: var(--pagegrid-col-verynarrow);
}
