.blogitem-metadata-panel {
  color: #3F3F3F;
  font-size: 16px;
}
.blogitem-metadata-panel h2 {
  font: var(--metadata-sidepanel-h2-font);
}
.blogitem-metadata-panel h2:first-child {
  margin-top: 0;
}

.metadata-panel__item + .metadata-panel__item {
  margin-top: 25px;
}

.metadata-panel__item--tags h2 {
  margin-bottom: 4px;
}

.metadata-panel__item--authorlinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.metadata-panel__item--authorlinks li {
  padding: 0;
  margin: 0;
}

.blogitem-metadata-panel {
  --component-taglist-gap-h: 4px;
  --component-taglist-gap-v: 5px;
  --component-tag-height: 28px;
  --component-tag-radius: 16px;
  --component-tag-padding: 2px 14px 3px 14px;
  --component-tag-font: 14px var(--rtd-text-fontfamily);
}

.spc-tag[data-tagcolor=themecolor] {
  --component-tag-background: #E0670BE0;
  --component-tag-border: none;
  --component-tag-text-color: #FFFFFF;
}

.spc-tag[data-tagcolor=neutral] {
  --component-tag-background: #3F3F3FE0;
  --component-tag-border: 1px solid #949494;
  --component-tag-text-color: #FFFFFF;
}

.spc-tag[data-tagcolor=additional] {
  --component-tag-background: #FFFFFF;
  --component-tag-border: 1px solid #3F3F3F;
  --component-tag-text-color: #3F3F3F;
}