html
{
  /************************************************************
   *
   *  Form controls (checkbox / iOS switch style
   */

  --component-checkbox-switch-borderwidth:   3px;
  --component-checkbox-switch-track-width:  61px;
  --component-checkbox-switch-track-height: 30px;
  --component-checkbox-switch-track-color:  #A4A4A4;
  --component-checkbox-switch-track-color-disabled: #A4A4A4;
  --component-checkbox-switch-track-color-active: var(--color-orange);
  --component-checkbox-switch-thumb-size:   24px;
  --component-checkbox-switch-thumb-color:  #FFFFFF;
}
