/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.page__filterbar .wh-autocomplete-container {
  z-index: 1;
}

.page__filterbar {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.resources__filters__query {
  width: 100%;
}

@media (max-width: 800px) {
  .resources__filters__content {
    display: flex;
    flex-wrap: wrap;
  }
  .resources__filters__content > * {
    width: 100%;
  }
  .resources__filters__content > * + * {
    margin-top: 15px;
  }
  .resources__filters__querycontainer {
    flex: 0 0 auto;
    width: calc(100% - 150px);
  }
  .resources__filters__submit {
    flex: 0 0 auto;
    width: 150px;
  }
  .resources__filters__query.resources__filters__query {
    border-right: 0 !important;
    border-radius: var(--formcontrols-radius) 0 0 var(--formcontrols-radius);
  }
  .resources__filters__submit .fa-search {
    margin-left: 15px;
  }
}
@media (min-width: 801px) and (max-width: 899px) {
  .resources__filters__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 18px;
    grid-auto-flow: column row;
  }
  .resources__filters__label {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .resources__filters__querycontainer {
    grid-column: 1/4;
    grid-row: 2;
    margin-right: -32px;
  }
  .resources__filters__submit {
    grid-column: 4;
    grid-row: 2;
  }
  .pagefilters__tagselection0 {
    grid-column: 1/span 2;
    grid-row: 3;
  }
  .pagefilters__tagselection1 {
    grid-column: 3/span 2;
    grid-row: 3;
  }
  .pagefilters__tagselection2 {
    grid-column: 1/span 2;
    grid-row: 4;
  }
  .pagefilters__tagselection3 {
    grid-column: 3/span 2;
    grid-row: 4;
  }
}
@media (min-width: 900px) and (max-width: 1180px) {
  .resources__filters__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 18px;
    grid-auto-flow: column row;
  }
  .resources__filters__label {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .resources__filters__querycontainer {
    grid-column: 1/4;
    grid-row: 2;
    margin-right: -32px;
  }
  .resources__filters__submit {
    grid-column: 4;
    grid-row: 2;
  }
}
@media (min-width: 900px) {
  .pagefilters__tagselection0,
  .pagefilters__tagselection1,
  .pagefilters__tagselection2,
  .pagefilters__tagselection3 {
    grid-row: 3;
  }
}
@media (min-width: 1181px) {
  .resources__filters__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 18px;
    grid-auto-flow: column row;
  }
  .resources__filters__querycontainer {
    grid-column: 2/span 2;
    margin-right: -32px;
  }
  .resources__filters__submit {
    grid-column: 4;
  }
}
.resources__filters__label.resources__filters__label {
  align-self: center;
  color: var(--color-theme);
  font: 600 25px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  padding-top: 3px;
}

/*
.resources__filters__querycontainer
{
  position: relative;
  flex: 1 0 auto;

  display: flex;
  flex-wrap: wrap; // so our autosuggest is wrapped to be exactly under our input, aligned at the left and the correct width!
}
*/
.resources__filters__query {
  flex: 1 0 0px;
  height: 44px;
  border: 0;
  background-color: #FFFFFF;
  font: 15px var(--rtd-heading-fontfamily);
  color: #6F6F6F;
}

.resources__filters__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  background-color: var(--color-theme);
  color: #FFFFFF;
  transition: background-color 0.25;
  display: flex;
  align-items: center;
  height: var(--formcontrols-height);
  font: bold 18px var(--rtd-heading-fontfamily);
  padding-top: 3px;
  padding-left: 16px;
  padding-right: 13px;
  cursor: pointer;
}

.resources__filters__submit:hover {
  background-color: var(--color-orange-hover);
}

.resources__filters__submit .fa-search {
  margin-left: auto;
  transform: scaleX(-1);
}

.resources__filters label {
  cursor: pointer;
}

.filters__option {
  flex: none;
  display: flex;
}

.resources__filters__breakline {
  width: 100% !important;
  margin: 0;
}