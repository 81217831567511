.widget--schedule.widget--schedule
{
  --content-layout:             verywide;
  --content-squeeze-preference: preferwide;

  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget--schedule .widget-header__title
{
  font: bold 30px/36px var(--rtd-heading-fontfamily); /* FIXME */
}

.schedule__item
{
  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.schedule__item__heading
{
  grid-column: var(--pagegrid-col-verywide);
  min-height: 73px;

  font: 20px/32px var(--rtd-text-fontfamily);
  border-bottom: 1px solid #AAAAAA;

  cursor: pointer;
}

.schedule__item__heading__content
{
  height: 100%;
}

.schedule__item__heading:hover
{
  background-color: #FCFCFC;
}

.schedule__item__heading__content::after
{
  font:    var(--accordion-header-symbol-font);

  color:   var(--accordion-header-symbol-color);
  content: var(--accordion-header-symbol-content);

  text-align: right;

  margin-left: 20px;
  margin-right: 15px;
}

/*
.schedule__item__heading:hover .schedule__item__heading__content::after
{
  font-weight: bold;
  transform: translate(1px, -1px);
}
*/

.schedule__item__heading[aria-selected] .schedule__item__heading__content::after
{
  color:   var(--accordion-header-symbol-open-color);
  content: var(--accordion-header-symbol-open-content);
}



.schedule__item__content
{
  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.schedule__item:last-child .schedule__item__content
{
  padding-bottom: 0;
}

.schedule__item__content[hidden]
{
  display: none;
}



.schedule__item__title
{
  padding-top: 4px; /* compensate for weird font alignment */
  font-weight: bold;
}

  .schedule__item__meta__subtitle
, .schedule__item__meta__location
{
  font: bold 20px/32px var(--rtd-text-fontfamily);
}

.schedule__item__meta__description
{
  font: 18px/27px var(--rtd-text-fontfamily);
}



/* mobile & tablet */
@media (max-width: 900px)
{
  .schedule__item__heading__content
  {
    position: relative;
    padding-right: 50px; /* make room for the open/close symbol */
  }

  .schedule__item__heading__content::after
  {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%,-50%);
  }

  .schedule__item__content
  {
    padding-top: 0;
    padding-bottom: 30px;
  }

  .schedule__item__image
  {
    grid-column: var(--pagegrid-col-verywide);

    width: 100%;
    object-fit: cover;
    aspect-ratio: 415 / 277;

    margin-bottom: 15px;
  }

  .schedule__item__imageplaceholder
  {
    display: none;
  }

  .schedule__item__meta
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .schedule__item__meta > *
  {
    grid-column: var(--pagegrid-col-verywide);
  }
}



/* Desktop */
@media (min-width: 901px)
{
  .schedule__item__heading
  {
    min-height: 73px;
    grid-column: var(--pagegrid-col-fullwidth);
  }

  .schedule__item__heading__content
  {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    align-items: center;
  }

  .schedule__item__time
  {
    grid-column: 3 / 6;
  }

  .schedule__item__title
  {
    grid-column: 11 / 25;
    padding-left: var(--pagegrid-col-verywide-leftsmall-right-padding)
  }

  .schedule__item__heading__content::after
  {
    grid-column: 25;
  }

  .schedule__item__content
  {
    padding-top: 48px;
    padding-bottom: 30px;
  }

    .schedule__item__image
  , .schedule__item__imageplaceholder
  {
    grid-column: var(--pagegrid-col-verywide-leftsmall);

    width: 100%;
    object-fit: cover;
    aspect-ratio: 415 / 277;
  }

  .schedule__item__meta
  {
    grid-column: var(--pagegrid-col-verywide);
  }

  .widget--schedule-withimages .schedule__item__meta
  {
    grid-column: var(--pagegrid-col-verywide-leftsmall-right);
    padding-left: var(--pagegrid-col-verywide-leftsmall-right-padding); /* some extra whitespace */
  }
}
