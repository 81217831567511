.widget--featuredpages
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);

  background-color: var(--theme-background);
}

.widget--featuredpages.widget--includespadding
{
  padding-top:    var(--widget-generic-bigpanel-padding-top);
  padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
}

.widget--featuredpages .widget-header
{
  grid-row: 1;
}

.widget--featuredpages__slides
{
  grid-row: 2;
  grid-column: var(--pagegrid-col-verywide);

  display: grid;
}

.widget--featuredpages__tabs
{
  grid-row: 3;
  grid-column: var(--pagegrid-col-verywide);

  margin-top:  48px;
}



.widget--featuredpages__slide
{
  grid-column: 1;
  grid-row: 1;

  background-color: #F8F8F8;
}



/* because we cannot use CSS subgrid's yet,
   whe'll give the __slide a display: contents; making the
   image and overlay direct children of the grid.
*/
.widget--featuredpages__slides
{
  overflow: hidden;
}


.widget--featuredpages__slide
{
  display: contents;
}
.widget--featuredpages__slide
{
  grid-column: 1;
  grid-row: 1;

  /*display: grid;*/
  display: contents;
}

/* Only show thr first item in the RTD preview of the widget */
.wh-rtd .widget--featuredpages__slide:nth-child(1n + 2)
{
  display: none;
}




.widget--featuredpages__slide__overlay .ftu-tagsblock__item
{
  align-self: start;
  border-color: #FFFFFF;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.widget--featuredpages__slide__title
{
  color: var(--color-white);
  font: 42px/59px var(--rtd-heading-fontfamily);
}

.widget--featuredpages__slide__description
{
  --rtd-text-color: #BBBBBB;
  --rtd-link-color: #BBBBBB;
  --rtd-text-font: 18px/27px var(--rtd-heading-fontfamily);

  /* In case we fall back to the page's overview "description" (which isn't richtext) */
  color: var(--rtd-text-color);
  font:  var(--rtd-text-font);
}

.widget--featuredpages__slide__description.widget--featuredpages__slide__description a
{
  text-decoration: underline;
}



@media (max-width: 800px)
{
  .widget--featuredpages__slides
  {
    grid-column: var(--pagegrid-col-fullwidth);
  }

  .widget--featuredpages__slide__image
  {
    opacity: 0;
    transition: opacity 0.10s;
    transition-delay: 0.75s;
  }
  .widget--featuredpages__slide__overlay
  {
    opacity: 0;
    transition: opacity 0.10s;
    transition-delay: 0.75s;
  }

  .widget--featuredpages__slide.activeslide .widget--featuredpages__slide__image
  {
    z-index: 2;
    opacity: 1;
    transition: opacity 0.75s;
  }

  .widget--featuredpages__slide.activeslide .widget--featuredpages__slide__overlay
  {
    z-index: 2;
    opacity: 1;
    transition: opacity 0.75s;
  }

  .widget--featuredpages__slide__image
  {
    grid-column: 1;
    grid-row: 1;
    width: 100%;

    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  .widget--featuredpages__slide__overlay
  {
    grid-column: 1;
    grid-row: 2;

    padding: 30px var(--pagegrid-gutteroutside) 30px var(--pagegrid-gutteroutside);

    background-color: var(--color-black);
/*
    display: flex;
    flex-direction: column;
    justify-content: end;
*/  }
}



/* Desktop (the design) */
@media (min-width: 801px)
{
  .widget--featuredpages__slide__image
  {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    min-height: 100%; /* if the container is stretch due to it's textual content, this forces the images to grow with it */

    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  .widget--featuredpages__slide__overlaygradient
  {
    background: linear-gradient(270deg, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.7) 100%);
    z-index: 2;
  }

  .widget--featuredpages__slide__overlay
  {
    z-index: 3;
  }
  .widget--featuredpages__slide__overlay.activeslide
  {
    z-index: 4;
  }




    .widget--featuredpages__slide__overlaygradient
  , .widget--featuredpages__slide__overlay
  {
    grid-column: 1;
    grid-row: 1;
    /*z-index: 1;*/

    width: 420px;
    max-width: 33%;
    min-width: 320px;
    padding: 30px 30px 34px 57px;

    padding-top:    30px;
    padding-bottom: 40px;
    padding-right:  clamp(15px,4%,30px);
    padding-left:   clamp(15px,4%,57px);

    display: flex;
    flex-direction: column;
    justify-content: end;
  }



  /***************************************************************************
   * Fancy crossfade for desktop
   */

  .widget--featuredpages--slideshow .widget--featuredpages__slide__image
  {
    opacity: 0;
    animation: 3s fadeSlideOutWithBlur; /* the animation forces the opacity to stay at 1 for 11 seconds */
  }
  .widget--featuredpages--slideshow .widget--featuredpages__slide__overlay
  {
    opacity: 0;
    animation: 0.9s fadeSlideOut; /* the animation forces the opacity to stay at 1 for 11 seconds */
    animation-delay: 0;
  }

  .widget--featuredpages__slide.activeslide .widget--featuredpages__slide__image
  {
    animation: 2.750s fadeSlideIn forwards;
    animation-delay: 250ms;
    z-index: 2;
  }
  .widget--featuredpages__slide.activeslide .widget--featuredpages__slide__overlay
  {
    animation: 1.50s fadeSlideIn forwards;
    animation-delay: 350ms;
    z-index: 4;
  }
  /***************************************************************************/
}




.widget--featuredpages__tabs
{
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
}

.widget--featuredpages__tab
{
  flex: 1 0 0;
  min-width: 250px;
}



.widget--featuredpages__tab__progress::before
{
  display:  block;
  background-color: var(--color-theme);
  content: "";
  width:  0;
  height: 3px;
}

.widget--featuredpages__tab.active .widget--featuredpages__tab__progress::before
{
  animation: featuredpage_tabbar linear 6s;
  /*outline: 1px solid var(--color-theme);*/
}

@keyframes featuredpage_tabbar
{
  from { width:   0%; color: #F00; }
  to   { width: 100%; color: #000; }
}



.widget--featuredpages__tab__progress
{
  height: 3px;
  background-color: #C7C4C3;
}

.widget--featuredpages__tab__progress + *
{
  margin-top: 30px;
}


.widget--featuredpages__tab.active .widget--featuredpages__tab__progress
{
  background-color: #969291;
}


.widget--featuredpages__tab__title
{
  color: var(--color-black);
  font: bold 18px var(--rtd-heading-fontfamily);
}

.widget--featuredpages__tab__description
{
  font: 16px var(--rtd-heading-fontfamily);
}

.widget--featuredpages__tab__title + .widget--featuredpages__tab__description
{
  margin-top: 5px;
}




@keyframes fadeSlideOut
{
  from { opacity: 1; }
  to   { opacity: 0; }
}


@keyframes fadeSlideOutWithBlur
{
  from { opacity: 1; filter: blur(0); transform: scale(1.0); }
  to   { opacity: 1; filter: blur(10px); transform: scale(1.2); }
}



@keyframes fadeSlideIn
{
  from { opacity: 0; }
  to   { opacity: 1; }
}
