html {
  --memberlist-photo-size: 68px;
}

html.page--ethics-members .deeplinks-wrapper {
  grid-row: 2;
}

.widget-memberlist {
  grid-column: var(--pagegrid-col-default);
}

.memberlist {
  display: grid;
  grid-template-columns: min-content minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) min-content;
  grid-auto-rows: min-content;
  column-gap: 17px;
}

.filteredoverview--noresults .memberlist {
  display: none;
}

.memberlist .member-wrapper:first-child .member {
  border-top: 1px solid #8E8E8E;
}

.memberlist__header,
.member {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  align-items: center;
}

.memberlist__header {
  color: #757575;
  font: 13px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  padding-bottom: 8px;
}

.memberlist__header__name {
  grid-column: 1/3;
}

.memberlist ul {
  display: contents;
  padding: 0;
  margin: 0;
}

.member-wrapper {
  display: contents;
}

.member-wrapper.notmatching,
.member-wrapper.notcurrentpage {
  display: none;
}

a.member {
  color: inherit;
  text-decoration: none;
}

.member {
  padding-left: 17px;
  padding-right: 24px;
  font: var(--rtd-text-compact-font);
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #8E8E8E;
}

a.member:hover {
  background-color: #F0F0F0;
}

.member__photo {
  background: #979797;
  width: var(--memberlist-photo-size);
  height: var(--memberlist-photo-size);
  border-radius: 50%;
}

div.member__photo {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.member__photo::before {
  content: var(--fa-user);
  color: #FFFFFF;
  font: var(--fontawesome-light);
  font-size: 36px;
}

.member__name {
  font: var(--rtd-heading-compact-font);
}

.member .icon {
  color: var(--color-orange);
  transform: translate(0, 0);
  transition: transform 0.2s;
}

a.member:hover .icon {
  transform: translate(7px, 0);
}