/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
.editionpreview
{
  padding: 20px;
}
*/
.edition-event-preview {
  margin-top: 30px;
  margin-bottom: 30px;
  grid-column: var(--pagegrid-col-default);
  --whitespace-header-content: 0;
}

.edition-event-preview > *:not(.widget--background):last-child {
  --widget-generic-panel-padding-bottom: 0;
}

html.use-edition-themecolor .pageheader-sidebyside {
  border-bottom-color: var(--edition-themecolor);
}

.edition-event.selected ~ .page__balloon,
.edition-event.selected ~ .page__balloon + .page__footer {
  background-color: #FAFBFF;
}

.edition-event--withcolofon.selected ~ .page__balloon {
  --whitespace-footer-content: 0;
}

.edition-event--withcolofon.selected ~ .page__balloon + .page__footer {
  background-color: var(--color-theme);
}

html.use-edition-themecolor .edition-event__intro__when {
  --color-theme: var(--edition-themecolor);
}

html.use-edition-themecolor .edition-event--withcolofon.selected ~ .page__balloon + .page__footer {
  background-color: var(--edition-themecolor);
  --component-navpath-color: var(--edition-themecontrastcolor);
}

.edition-event {
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
  display: none;
  background: #FAFBFF;
  padding-bottom: 95px;
  --rtd-h1-font: bold 30px/32px var(--rtd-heading-fontfamily);
}

.edition-event + .edition-event__colofon {
  margin-top: 0;
}

.edition-event.selected {
  display: grid;
}

.edition-event > * {
  grid-column: var(--pagegrid-col-verywide);
}

.edition-event__intro {
  --content-layout: verywide;
  --content-squeeze-preference: needwide;
  margin-bottom: 40px; /* hack.. */
}

@media (min-width: 1001px) {
  .edition-event__schedule,
  .edition-event__relatedprojects {
    grid-column: var(--pagegrid-col-verywide);
  }
  .edition-event__intro {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .edition-event__intro__meta {
    grid-column: var(--pagegrid-col-verywide-leftsmall);
  }
  .edition-event__intro__textcolumn {
    grid-column: var(--pagegrid-col-verywide-leftsmall-right);
    padding-left: var(--pagegrid-col-verywide-leftsmall-right-padding);
  }
  .edition-event__intro__textcolumn {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
  }
  .edition-event__intro .edition-event__intro__subcolumn {
    flex: 1 1 350px;
  }
}
@media (max-width: 1000px) {
  .edition-event__intro,
  .edition-event__schedule,
  .edition-event__relatedprojects {
    grid-column: var(--pagegrid-col-verywide);
  }
  .edition-event__intro {
    grid-column: var(--pagegrid-col-verywide);
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
  }
  .edition-event__intro .edition-event__intro__meta {
    flex: 1 1 0;
    min-width: max-content;
    margin-right: 30px;
  }
  .edition-event__intro .edition-event__intro__textcolumn {
    flex: 2 1 600px;
    max-width: 66.66%; /* 2/3th */
    min-width: min(100%, 500px);
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
  }
  .edition-event__intro .edition-event__intro__subcolumn {
    flex: 1 1 350px;
  }
}
.edition-event__intro__when {
  color: var(--color-theme);
  font: var(--rtd-text-font);
}

.edition-event__intro__title {
  color: var(--rtd-heading-color);
  font: var(--widget-cta-heading-font);
  font-weight: normal;
}

@media (min-width: 600px) {
  .edition-event__intro .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .edition-event__intro .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
}