#researchoverviewfilter {
  /*
    @media (min-width: 900px) and (max-width: 899px)
    {
      .projectsoverview__filters__tags
      {
        display: flex;
        column-gap: 60px;
        row-gap: 30px;

        flex-wrap: wrap;
      }
    }
  */
}
@media (max-width: 899px) {
  #researchoverviewfilter .projectsoverview__filters__tags {
    display: flex;
    column-gap: 60px;
    row-gap: 30px;
    flex-wrap: wrap;
  }
  #researchoverviewfilter .filterpanel[data-categoryid=research-topic] .filterpanel__options {
    column-count: 2;
    column-gap: 60px;
  }
}
@media (min-width: 900px) {
  #researchoverviewfilter .projectsoverview__filters__tags {
    display: flex;
    column-gap: 60px;
    row-gap: 30px;
    flex-wrap: wrap;
  }
  #researchoverviewfilter .filterpanel {
    flex: 1 1 0;
  }
  #researchoverviewfilter .filterpanel[data-categoryid=research-topic] {
    flex: 100%;
  }
  #researchoverviewfilter .filterpanel[data-categoryid=research-topic] .filterpanel__options {
    column-count: 3;
    column-gap: 60px;
  }
}