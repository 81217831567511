/*@import "../../shared/config.scss";*/


.widget-highlight
{
  grid-column: var(--pagegrid-col-default);

  /*
  color: var(--widget-highlight-textcolor);
  background-color: var(--widget-highlight-background);

  padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h) var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  border: 2px solid var(--widget-highlight-border-color);
  border-radius: var(--widget-border-radius);
  */

  background: var(--widget-highlight-background);
  --rtd-text-color: #4F4F4F;
}

.site--4tu .widget-highlight
{
  --widget-highlight-pad-h: 25px;
  --widget-highlight-pad-v: calc(var(--rtd-spacing-paragraph) * 2);
}

.widget-highlight--bg-grey
{
/*  background-color: #F2EFED;*/
  --widget-highlight-background: #F2EFED;
}

.widget-highlight--bg-orange
{
/*  background-color: #F3D3B8;*/
  --widget-highlight-background: #F3D3B8;
}




.widget-highlight__content
{
  /*
  // padding-left:   var(--pagegrid-gutteroutside);
  // padding-right:  var(--pagegrid-gutteroutside);
  */
  padding-left:   var(--widget-highlight-pad-h);
  padding-right:  var(--widget-highlight-pad-h);
  padding-top:    var(--widget-highlight-pad-v);
  padding-bottom: var(--widget-highlight-pad-v);
}

/*
.widget-highlight .rtdcontent > *
{
  color: #4F4F4F;
}
*/
