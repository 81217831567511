/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
html {
  --tagpanel-heading-color: var(--color-theme);
  --tagpanel-heading-font: bold 20px/24px var(--rtd-heading-fontfamily);
  --tagpanel-heading-margin: 10px;
  --tagpanel-option-pad-top: 2px;
  --tagpanel-option-pad-bottom: 2px;
  --tagpanel-option-spacing: 1px;
  --tagpanel-option-font: 15px/23px var(--rtd-text-fontfamily);
  --tagpanel-checkradio-spacing: 10px;
}

/* The new 4TU Ethics pages have some subtle style changes
   NOTE: In the future use container queries to opt-in to a compact-checkradio trait.
 */
#membersfilter,
#blogsoverviewfilter,
#newsoverviewfilter,
#researchoverviewfilter,
#eventsoverviewfilter {
  font-size: 15px;
  /* OPT-IN to smaller checkboxes
     FIXME: in the future place this in a container query
  */
  --formcontrol-checkradio-size: 18px;
  --formcontrol-check-font: 400 12px/24px var(--fontawesome);
  --formcontrol-check-shift: -1px;
  --tagpanel-option-spacing: 2px;
}
@media (min-width: 768px) {
  #membersfilter .header-filterbar__querycontainer,
  #blogsoverviewfilter .header-filterbar__querycontainer,
  #newsoverviewfilter .header-filterbar__querycontainer,
  #researchoverviewfilter .header-filterbar__querycontainer,
  #eventsoverviewfilter .header-filterbar__querycontainer {
    max-width: 527px;
  }
}
#membersfilter .header-innovations__filterbar,
#blogsoverviewfilter .header-innovations__filterbar,
#newsoverviewfilter .header-innovations__filterbar,
#researchoverviewfilter .header-innovations__filterbar,
#eventsoverviewfilter .header-innovations__filterbar {
  justify-content: space-between;
}
#membersfilter .header-expandedfilters__content .expandedfilters__item h3,
#blogsoverviewfilter .header-expandedfilters__content .expandedfilters__item h3,
#newsoverviewfilter .header-expandedfilters__content .expandedfilters__item h3,
#researchoverviewfilter .header-expandedfilters__content .expandedfilters__item h3,
#eventsoverviewfilter .header-expandedfilters__content .expandedfilters__item h3 {
  margin-bottom: 15px;
}
#membersfilter .header-expandedfilters__content .filterpanel__title,
#blogsoverviewfilter .header-expandedfilters__content .filterpanel__title,
#newsoverviewfilter .header-expandedfilters__content .filterpanel__title,
#researchoverviewfilter .header-expandedfilters__content .filterpanel__title,
#eventsoverviewfilter .header-expandedfilters__content .filterpanel__title {
  margin-bottom: 4px;
  font: var(--rtd-heading-weight) 19px var(--rtd-heading-fontfamily);
}
#membersfilter .header-expandedfilters__content .filterpanel--pulldown select,
#blogsoverviewfilter .header-expandedfilters__content .filterpanel--pulldown select,
#newsoverviewfilter .header-expandedfilters__content .filterpanel--pulldown select,
#researchoverviewfilter .header-expandedfilters__content .filterpanel--pulldown select,
#eventsoverviewfilter .header-expandedfilters__content .filterpanel--pulldown select {
  width: 100%;
}