/**
Deeplinks support
first written for TUE webdesign
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
@media (min-width: 1024px) {
  .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    grid-column: var(--pagegrid-gutter-left);
    /*background-color: #0F0 !important;*/
    /* take deeplinks out of flow */
    /*
    disabled for now.. we don't use deeplinks but do place the sharebar here..
    however when scrolling the sharebar overlaps stuff.
    When we reenable this we need a solution for that problem.
    */
    position: -webkit-sticky;
    position: sticky;
    top: 125px; /* $menubar-height + 30px; */
    /* Prevent content in the same row (such as a large widget/paragraph/list being in it)
       stretch our wrapper. Otherwise sticky won't correctly. */
    align-self: start;
    /*
    We must prevent inside content from stretching this wrapper
    (and therefore the grid row, causing a gap below content next to the gutter sidebar)

    option 1) give .deeplinks a position: absolute; (iOS 10.3)
    option 2) use grid-row: 2 / 10000; (iOS 10.3)
    option 3) contain: size; (iOS 15.4+, iPhone 6s, iPad Air 2, iPad Mini 4, iPad gen 5)
    */
    grid-row: 2/1000;
  }
  .deeplinks {
    /* take out of flow so content isn't pushed down.
       (or in case of our grid our row won't be stretched)
    */
  }
}
@media (max-width: 1023px) {
  .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    grid-row: 1;
    grid-column: var(--pagegrid-col-verywide);
  }
  .deeplinks__item {
    display: none;
  }
}
.page-backlink {
  pointer-events: auto;
}

.deeplinks {
  /* take out of flow so content isn't pushed down.
     (or in case of our grid our row won't be stretched)
  */
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
  width: 100%;
}

.deeplinks a,
.deeplinks input,
.deeplinks label {
  pointer-events: auto;
}

.page-backlink + .deeplinks__item {
  margin-top: 30px;
}

a.deeplinks__item {
  /*
  NOTES:
  - Use display: table;
    display: table; would shrinkwrap, however display: table; doesn't
    use overflow: hidden; because that would need to be applied to table-cell

  - Shrinkwrap using min-content
    - Container - width: 100%;
    - Items     - display: block; width: min-content; max-width: 100%;
    Doesn't work in IE (no min-content support, except in grid)

  - (NOT tested) use grid?

  - Flex (when using align-items: flex-start) items will be shrinkwrapped.
    - width: 100%; to not allow the flex to just grow to fit flex children
    - align-items: flex-start; to shrinkwrap to the left

  display: table;

  display: block;
  width: min-content;
  */
  flex: none;
  max-width: 203px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.27);
  padding: 2px 4px;
  background-color: #FFFFFF;
  color: var(--rtd-text-color);
  font: 12px/15px var(--rtd-text-fontfamily);
  text-decoration: none;
  padding: 7px 10px;
  pointer-events: auto;
}

.deeplinks__item__label {
  word-break: break-word;
  /*
  -- single line ellipsis
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  */
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

a.deeplinks__item:active,
a.deeplinks__item:hover {
  color: var(--color-theme);
}

.deeplinks__item + .deeplinks__item {
  margin-top: 9px;
}