.widget-richcontent--2col {
  grid-column: var(--pagegrid-col-fullwidth);
}

.page__contentarea > .widget-richcontent--3col {
  grid-column: var(--pagegrid-col-default);
}

.page__contentarea > .widget-richcontent--3col.widget-richcontent--wide {
  --content-layout: verywide;
  grid-column: var(--pagegrid-col-verywide);
}

.widget-richcontent--3col {
  --content-squeeze-preference: needwide;
}

@media (max-width: 599px) {
  .widget-richcontent__content {
    display: block;
    padding-left: var(--pagegrid-gutteroutside-mobile);
    padding-right: var(--pagegrid-gutteroutside-mobile);
  }
  .widget-richcontent__column + .widget-richcontent__column {
    margin-top: var(--rtd-spacing-paragraph);
  }
  .widget-richcontent__column:first-child > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column:last-child > *:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 600px) {
  .widget-richcontent .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent--2col {
    --content-layout: var(--content-layout-default);
  }
  .widget-richcontent--2col .widget-richcontent__content {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-richcontent--2col .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-verywide-left);
  }
  .widget-richcontent--2col .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-verywide-right);
  }
}
@media (min-width: 1000px) {
  .widget-richcontent--2col .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-default-left);
  }
  .widget-richcontent--2col .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-default-right);
  }
  .widget-richcontent--equalheight .widget-richcontent__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.widget-richcontent--2col .widget-richcontent .widget-richcontent__column.widget-richcontent__column:nth-child(1) {
  grid-column: var(--pagegrid-col-fullwidth);
}
.widget-richcontent--2col .widget-richcontent .widget-richcontent__column.widget-richcontent__column:nth-child(2) {
  grid-column: var(--pagegrid-col-fullwidth);
}