/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.blogitem-bodycontent {
  display: contents;
}

.blogitem-bodycontent.blogitem-bodycontent > * {
  grid-column: var(--pagegrid-col-default);
}

.blogitem-bodycontent.blogitem-bodycontent > .widget-richcontent--2col .widget-richcontent__content {
  display: flex;
  column-gap: 30px;
}
.blogitem-bodycontent.blogitem-bodycontent > .widget-richcontent--2col .widget-richcontent__content .widget-richcontent__column {
  flex: 1 1 0px;
}

.blogitem-metadata-panel:empty {
  display: none;
}

/*
Tablet & desktop layout - content & metadata-sidepanel side-by-side
*/
@media (min-width: 768px) {
  .blogitem-bodycontent.blogitem-bodycontent {
    --pagegrid-col-default: 7 / span 13;
    --pagegrid-col-default-left: 7 / span 7;
    --pagegrid-col-default-right: 13 / span 7;
    --pagegrid-col-default-max8col: 7 / span 13;
  }
  .blogitem-bodycontent .widget-accordion__item__body__content {
    --pagegrid-template-columns: $pagegrid-inside;
    --pagegrid-col-default: 1 / -1;
    --pagegrid-col-default-max8col: 1 / -1;
  }
  .blogitem-bodycontent {
    display: contents;
  }
  html.page--withmetadata .page__contentarea {
    position: relative;
  }
  .blogitem-metadata-panel {
    grid-row: 1/span 100;
    grid-column: 21/span 5;
    padding-left: 25px;
  }
  .blogitem-metadata-panel::before {
    content: "";
    border-left: 1px solid #C5C5C5;
    position: absolute;
    margin-left: -26px;
    top: 0;
    bottom: 100px;
  }
}
@media (min-width: 768px) and (max-width: 1100px) {
  .blogitem-bodycontent.blogitem-bodycontent {
    --pagegrid-col-default: 3 / span 17;
  }
  .blogitem-metadata-panel {
    grid-column: 21/span 5;
  }
}
.blogitem-metadata-panel span.blogitem__author {
  color: inherit;
  text-decoration: none;
}
.blogitem-metadata-panel a {
  color: var(--rtd-link-color);
  text-decoration: underline;
  text-underline-offset: 2px;
  outline-offset: var(--rtd-link-outline-offset);
}