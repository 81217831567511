.widget--eventsgrid
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  grid-column: var(--pagegrid-col-fullwidth);
}


@media (max-width: 599px)
{
  .widget--eventsgrid
  {
    grid-column: var(--pagegrid-col-verywide);
  }

  .widget--eventsgrid__item + .widget--eventsgrid__item
  {
    margin-top: 30px
  }

  .eventsgrid__item__image
  {
    aspect-ratio: 10 / 8;
  }

  /* only show 2 items max on mobile */
  .widget--eventsgrid__item:nth-child(1n+3)
  {
    display: none;
  }
}


@media (min-width: 600px)
{
  .widget--eventsgrid
  {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .widget--eventsgrid__items
  {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
}


@media (min-width: 600px) and (max-width: 767px)
{
  .widget--eventsgrid__items
  {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);

    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content min-content;
    column-gap: 30px;
    row-gap: 30px;
  }

  .eventsgrid__item__image
  {
    aspect-ratio: 1 / 1;
  }

  /* only show 4 items max */
  .widget--eventsgrid__item:nth-child(1n+5)
  {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1099px)
{
  .widget--eventsgrid__items
  {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);

    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    grid-template-rows: min-content min-content min-content;
    column-gap: 30px;
    row-gap: 30px;
  }

  .widget--eventsgrid__item:nth-child(1)
  {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  .widget--eventsgrid__item:nth-child(2)
  {
    grid-column: 2;
    grid-row: 1;
  }

  .widget--eventsgrid__item:nth-child(3)
  {
    grid-column: 2;
    grid-row: 2;
  }

    .widget--eventsgrid__item:nth-child(2) .eventsgrid__item__image
  , .widget--eventsgrid__item:nth-child(3) .eventsgrid__item__image
  {
    aspect-ratio: 1 / 1;
  }

    .widget--eventsgrid__item:nth-child(4)
  , .widget--eventsgrid__item:nth-child(5)
  {
    display: none;
  }
}


@media (max-width: 1100px)
{
  .eventsgrid__item__meta__date
  {
    display: none;
  }
}

.wh-rtd-embeddedobject__preview .eventsgrid__item__meta__date
{
  display: none !important;
}




/* designed */
@media (min-width: 1100px)
{
  .widget--eventsgrid__items
  {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);

    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 30px;
    row-gap: 30px;

    aspect-ratio: 4 / 2;
  }

  .widget--eventsgrid__item:nth-child(1)
  {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  .widget--eventsgrid__item:nth-child(2)
  {
    grid-column: 2;
    grid-row: 1;
  }
}




.eventsgrid__item__image-placeholder
{
  background-color: var(--color-lightgrey);
}



.widget--eventsgrid__item
{
  text-decoration: none;
  display: grid;
}

  .eventsgrid__item__image
, .eventsgrid__item__image-placeholder
{
  grid-column: 1;
  grid-row: 1;

  width:  100%;
  height: 100%;

  object-fit: cover;

  flex: none;
}

.eventsgrid__item__bottom
{
  grid-column: 1;
  grid-row: 1;
  align-self: end;

  display: flex;
}

.eventsgrid__item__meta
{
  flex: 1 0 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 11px 18px 8px 18px;

  word-break: break-word; /* text must allways fix */

  background-color: rgba(0,0,0,0.4);
  backdrop-filter: blur(0.5px) contrast(60%);
}

.eventsgrid__item__dayblock
{
  width: 84px;
  min-height: 84px;

  flex: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #FFFFFF;

  /*
  Nice effect to have the image partly visible at the left and bottom
  to have more contrast with the background (outside the item):

  background-color: rgba(255,255,255,0.95);
  background-clip:  content-box;
  border-left: 2px solid rgba(255,255,255,0.5);
  border-bottom: 2px solid rgba(255,255,255,0.5);
  */
}

    .eventsgrid__item__meta__day
    {
      color: #161616;
      font: bold 28px var(--rtd-heading-fontfamily);
    }

    .eventsgrid__item__meta__month
    {
      color: var(--text-grey-small);
      font: bold 12px var(--rtd-heading-fontfamily);
      text-transform: uppercase;
    }

.eventsgrid__item__meta__date
{
  /*color: #4F4F4F;*/
  color: var(--color-white);
  font: 17px var(--rtd-text-fontfamily);
  text-transform: lowercase;
  margin-bottom: 2px;
}

.eventsgrid__item__meta__title
{
  color: var(--color-white);
  font: bold 18px/22px var(--rtd-heading-fontfamily);
}

/* The first item will always be black */
.widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta
{
  background-color: #000000;
}



@media (min-width: 768px)
{
  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta
  {
    padding: 13px 36px 11px 36px;
  }

  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__dayblock
  {
    width: 129px;
    min-height: 129px;
  }

  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta__day
  {
    font: bold 35px var(--rtd-heading-fontfamily);
  }

  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta__month
  {
    font: bold 15px var(--rtd-heading-fontfamily);
  }

  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta__date
  {
    margin-bottom: 10px;
  }

  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta__date
  {
    /*color: var(--text-grey-small);*/
    mix-blend-mode: exclusion;
    color: #FFFFFF;
  }

  .widget--eventsgrid__item:nth-child(1) .eventsgrid__item__meta__title
  {
    font: bold 32px/36px var(--rtd-heading-fontfamily);
  }
}




/*
.widget--eventsgrid__item--bottomlight .eventsgrid__item__meta
{
  / * lower contrast also automatically makes the light background darker * /
  backdrop-filter: blur(2px) contrast(60%);
  text-shadow: 1px 1px 3px rgba(0,0,0,0.4);
}

.widget--eventsgrid__item--bottomgrey .eventsgrid__item__meta__date
{
  color:  var(--color-white);
}

.widget--eventsgrid__item--bottomlight .eventsgrid__item__meta__date
{
  color:  var(--color-white);
}

.widget--eventsgrid__item--bottomdark .eventsgrid__item__meta
{
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(2px) contrast(40%);
}

.widget--eventsgrid__item--bottomdark .eventsgrid__item__meta .eventsgrid__item__meta__title
{
  text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}
*/



/*
.widget--eventsgrid__item--bottomlight .eventsgrid__item__meta__date
{
  color: #4F4F4F;
}

  .widget--eventsgrid__item--bottomgrey .eventsgrid__item__meta__date
, .widget--eventsgrid__item--bottomdark .eventsgrid__item__meta__date
{
  color:  var(--color-white);
}

  .widget--eventsgrid__item--bottomlight .eventsgrid__item__meta__date
, .widget--eventsgrid__item--bottomlight .eventsgrid__item__meta__title
{
  color: #000000;
}
*/
