/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
NOTES:

IE and Edge-legacy require a polyfill for summary+details to work
(https://caniuse.com/details)

The <summary> element cannot be display: flex; in Safari.
Also see:
- https://bugs.webkit.org/show_bug.cgi?id=190065
- https://github.com/philipwalton/flexbugs#flexbug-9

About accordion's and useability:
https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html

*/
.page__contentarea--rtddoc > .widget-accordion,
.wh-form__richtext > .widget-accordion {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.page__contentarea--rtddoc > .widget-accordion .widget-accordion__title, .page__contentarea--rtddoc > .widget-accordion .widget-accordion__item__header,
.wh-form__richtext > .widget-accordion .widget-accordion__title,
.wh-form__richtext > .widget-accordion .widget-accordion__item__header {
  grid-column: var(--pagegrid-col-default);
}
.page__contentarea--rtddoc > .widget-accordion .widget-accordion__item,
.wh-form__richtext > .widget-accordion .widget-accordion__item {
  grid-column: var(--pagegrid-col-fullwidth);
}
.page__contentarea--rtddoc > .widget-accordion .widget-accordion__item__headerwrapper,
.wh-form__richtext > .widget-accordion .widget-accordion__item__headerwrapper {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-accordion__item:last-child .widget-accordion__item__body__content::after {
  margin-top: 22.5px;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--accordion-item-line-color);
  grid-column: var(--pagegrid-col-default);
}

.widget-accordion__item:last-child .widget-accordion__item__body__content {
  padding-bottom: 0;
}

.widget-richcontent__column .widget-accordion__item__body__content > * {
  grid-column: var(--pagegrid-col-fullwidth);
}

.widget-accordion input {
  display: none;
}

.widget-accordion__description {
  margin-bottom: 15px;
}

.widget-accordion details summary {
  list-style-type: none;
}
.widget-accordion details summary::-webkit-details-marker {
  display: none;
}
.widget-accordion details summary::marker {
  display: none;
}

.widget-accordion summary:focus {
  outline: 0;
}

html[data-whatinput=keyboard] .widget-accordion summary:focus .widget-accordion__item__header {
  outline: 1px solid var(--color-theme);
  outline: -webkit-focus-ring-color auto 1px;
}

.widget-accordion__item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--accordion-header-color);
  font: var(--accordion-header-font);
  border-top: 1px solid var(--accordion-item-line-color);
  min-height: 49px;
  padding: 9px 0 3px 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.widget-accordion__item__header::after {
  color: var(--accordion-header-symbol-color);
  font: var(--accordion-header-symbol-font);
  content: var(--accordion-header-symbol-content);
  margin-left: 20px;
  margin-right: 20px;
}

.widget-accordion__item__header:hover {
  background-color: #FCFCFC;
}

.widget-accordion__item__header:hover::after {
  font-weight: bold;
  transform: translate(1px, -1px);
}

.widget-accordion__item[open] .widget-accordion__item__header::after {
  content: var(--accordion-header-symbol-open-content);
}

.widget-accordion__item__body__content {
  padding-top: 5px;
  padding-bottom: 22.5px;
}