/*
NOTE:

Flex solution:
https://googlechrome.github.io/samples/css-flexbox-abspos/
- In modern browser we could also make the __preview flex
- Absolute positioned flex children will align/justify as if they are the only content within the flex container.
  (this way we could center the button)
*/
.wh-video__innerframe__preview {
  display: grid;
}

.wh-video__innerframe__preview picture,
.wh-video__playbutton {
  grid-column: 1;
  grid-row: 1;
}

.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  /*
  We cannot use grid alignment due to IE not (seeming to be) able to center align within our cell
  align-self: center;
  justify-self: center;
  */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 121px;
  height: 121px;
  /*
  &::after
  {
    opacity: 0;
    content: url("playbutton-red.svg");
  }
  */
}
.wh-video__playbutton::before, .wh-video__playbutton::after {
  position: absolute;
  width: 74px;
  height: 74px;
}
.wh-video__playbutton::before {
  opacity: 0.8;
  transform: scale(1);
  content: url("playbutton.svg");
}

.wh-video__playbutton:hover::before {
  opacity: 0.95;
  transform: scale(1.1);
}

/*
.wh-video__playbutton:hover::before
{
  opacity: 0;
  // background-color: $color-theme; //$color-theme-cta-hover;
}
/*
.wh-video__playbutton:hover::after
{
  opacity: 1;
  //filter: drop-shadow(0 0 3px rgba(255, 255, 255, 1));
  //box-shadow: 0 0 4px rgba(255,255,255,1);
}
.wh-video__innerframe__preview:hover .wh-video__playbutton
{
  transform: scale(1.1);
}
*/