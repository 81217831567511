/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.innovatorcard.notmatching {
  display: none;
}

.header-filterbar__topbar__university {
  margin-right: 20px;
}

@media (max-width: 767px) {
  .header-filterbar__topbar__university {
    border-bottom: 15px;
  }
  .header-filterbar__topbar__university select {
    width: 100%;
  }
}
.innovationspage--innovator .header-filterbar__topbar {
  align-items: baseline;
}
.innovationspage--innovator .header-filterbar__topbar__personname {
  font: bold 36px/34px var(--rtd-heading-fontfamily);
  padding-top: 13px;
  color: var(--color-theme);
}
.innovationspage--innovator .header-filterbar__topbar a {
  margin-left: auto;
  font: 18px var(--rtd-heading-fontfamily);
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-theme);
}
.innovationspage--innovator .header-filterbar__topbar a::after {
  margin-left: 12px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  font-size: 22px;
  vertical-align: middle;
}

/*****************************************************************************
*
*   Related projects
*/
.userdetails__grid {
  margin-top: 45px;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.userdetails__grid__header {
  grid-column: var(--pagegrid-col-verywide);
  margin-bottom: 35px;
  display: flex;
  align-items: end;
}
.userdetails__grid__header h2 {
  color: #3F3F3F;
  font-size: 18px;
  text-transform: uppercase;
}

.userdetails__grid__content {
  grid-column: var(--pagegrid-col-verywide);
}

.innovator__description {
  margin-top: 30px;
  grid-column: var(--pagegrid-col-verywide);
}

.innovationspage--innovator .innovatorcard::before {
  padding-top: 56.287%;
  height: auto;
}
.innovationspage--innovator .innovatorcard__photo {
  width: 106px;
  height: 106px;
  margin-top: -53px;
}
.innovationspage--innovator .projecttile__image {
  padding-top: 56.287%;
  height: auto;
}