@import "../../shared/config.scss";

/*

Views
- Mobile         -> stacked, image above
- Tablet/desktop -> Image floating top right

*/

.widget-cta-video
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  --rtd-link-color:    var(--theme-textcolor);
  --rtd-heading-color: var(--theme-textcolor);
  --rtd-text-color:    var(--theme-textcolor);

/*  --widget-title-font: var(--rtd-h1-subtitle-font);*/
  --widget-title-font: var(--widget-cta-video-title-font);


  z-index: var(--zindex-overlap-deeplinks);

  background: var(--theme-background);
}


.widget__subtitle
{
  color: var(--rtd-text-color);
}


.widget-cta-video__image
{
  background-size: cover;
}

.widget-cta-video__subtitle + .widget-cta-video__title
{
  margin-top: 4px;
}

  .widget-cta-video__text > * + .widget-cta-video__description
, .widget-cta-video__text > * + .widget-cta-video__button
{
  margin-top: 25px;
}


.widget-cta-video--withbackground
{
  .rtdcontent > p.normal > a
  {
    text-decoration: underline;
  }

  .widget-cta-video__text
  {
  }
}


.widget-cta-video__text
{
  display: flex;
  flex-direction: column;
  align-items: start;
}
.widget-cta-video__text::before
{
  content: "";
  flex: 1 0 0px;
}
.widget-cta-video__text::after
{
  content: "";
  flex: 2 0 0px;
}


.widget-cta-video .widget-cta__button
{
  margin-top: 15px;
}



.widget-cta-video__imagewrapper
{
  aspect-ratio: 16 / 9;
  max-width: 100%;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;

  position: relative; /* because the playbutton may be absolutely positioned */
}

.widget-cta-video__imagewrapper > *
{
  grid-column: 1;
  grid-row: 1;
}

.widget-cta-video__imagewrapper iframe
{
  display: block;
}

.widget-cta-video .wh-video__playbutton
{
/*  position: relative;*/
  background: none;
  border: 0;
}

.widget-cta-video .wh-video__playbutton::before
{
  position: static;
}



/* Mobile */
/*@media (max-width: 699px)*/
@media (max-width: 999px)
{
  .widget-cta-video
  {
    grid-column: var(--pagegrid-col-fullwidth);
  }

  .widget-cta-video__text
  {
    padding-top:    var(--widget-generic-panel-padding-top);
    padding-bottom: var(--widget-generic-panel-padding-bottom);
    padding-left:   var(--pagegrid-gutteroutside);
    padding-right:  var(--pagegrid-gutteroutside);
  }

  .widget-cta-video.colortheme--grey
  {
    padding-top:    var(--widget-generic-bigpanel-padding-top);
    padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
  }
}


/* Tablet / desktop */
/*@media (min-width: 700px)*/
@media (min-width: 1000px)
{
  .widget-cta-video
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  /* If we want to use flex-wrap: wrap;
     we could use a container query to detect the video
     has wrapped so we can add whitespace below (in case our widget has a background color).
   */
  .widget-cta-video__content
  {
    grid-column: var(--pagegrid-col-verywide);

    display: flex;
/*    flex-wrap: wrap;*/
    column-gap: 30px;
  }

  .widget-cta-video__text
  {
    flex: 1 0 40%;
  }

  .widget-cta-video__imagewrapper
  {
/*    display: grid;*/
    position: relative;

    flex: 3 0 40%;
    min-width: min(450px, 100vw);
  }


  .widget-cta-video__image
  {
    width: 100%;
    height: 100%;
  }

  .widget-cta-video__text
  {
    padding-top:    var(--widget-generic-panel-padding-top);
    padding-bottom: var(--widget-generic-panel-padding-bottom);
  }


.widget-cta-video--wide
{
/*  outline: 2px solid #000;*/
}

  .widget-cta-video--wide .widget-cta-video__content
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .widget-cta-video--wide .widget-cta-video__imagewrapper
  {
    min-height: 100%;
  }


  .widget-cta-video--focusleft .widget-cta-video__imagewrapper
  {
    order: -1;
  }
/*
  .widget-cta-video--wide.widget-cta-video--focusleft .widget-cta-video__text
  {
    grid-column: var(--pagegrid-col-verywide-right-extraspacing);
    grid-column: 14 / 26;
    grid-row: 1;
  }
  .widget-cta-video--wide.widget-cta-video--focusleft .widget-cta-video__imagewrapper
  {
    grid-column: viewport_left / 14;
    grid-row: 1;
  }

  .widget-cta-video--wide.widget-cta-video--focusright .widget-cta-video__text
  {
    / * text at the left * /
    grid-column: var(--pagegrid-col-verywide-left-extraspacing);
    grid-row: 1;
  }
  .widget-cta-video--wide.widget-cta-video--focusright .widget-cta-video__imagewrapper
  {
/ *    grid-column: var(--pagegrid-col-verywide-focusright);* /
    grid-column: 14 / viewport_right;
    grid-row: 1;
  }
*/



  /* with a grey background we place the whitespace around both
     the image and text.
  */
  .widget-cta-video.colortheme--grey
  {
    padding-top:    var(--widget-generic-bigpanel-padding-top);
    padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
  }
  .widget-cta-video.colortheme--grey .widget-cta-video__text
  {
    padding-top: 0;
    padding-bottom: 0;
  }
}
