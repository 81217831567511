.spc-multiselect {
  /* make positioning container so auto-positioning
     can also make the popover appear above the pulldown/trigger */
  position: relative;
  display: flex;
  flex-direction: column;
}

.multiselect__hidden {
  display: none;
}

.multiselect__emptytext--hidden {
  display: none;
}

.multiselect__valuedisplay.multiselect__valuedisplay.multiselect__valuedisplay {
  position: relative;
  left: 0;
  display: block;
  width: 100%;
  max-width: none;
  height: var(--formcontrols-height);
  flex: none;
  text-align: left;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 0 var(--textcontrols_padright) 0 var(--textcontrols_padleft);
  white-space: nowrap;
  border-color: #999999 !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiselect__valuedisplay__title {
  display: inline-block;
  flex: 1 0 0px;
  background-color: #FFFFFF;
  display: inline-block;
  line-height: 20px;
}

.multiselect__panel__trigger {
  user-select: none;
  cursor: pointer;
}

.multiselect__panel__trigger::after {
  display: none;
}

.multiselect__panel__container:focus-within {
  opacity: 1;
  pointer-events: auto;
}

/*
.multiselect__panel__search
{
  display: flex;
  align-items: center;

  margin: 4px;

  position: relative;
}
.multiselect__panel__search input
{
  // background-color: #F3F3F1;
  background-color: #F0F0F0;
  border-radius: 4px;
  border: 0;
  //border-top: 1px solid #D0D0D0;
  //border-left: 1px solid #D0D0D0;
  width: 100%;

  // font: 16px "Open Sans";

  &:focus
  {
    background-color: #E7E7E7;
    outline: 0;
  }

  &::placeholder
  {
    color: #C5C5C5;
    opacity: 1;
  }

  &:focus::placeholder
  {
    color: #999999;
    opacity: 1;
  }
}

.multiselect__panel__search .far
{
  position: absolute;
  right: 15px;
  font-size: 15px;
}
*/
.multiselect__panel__items {
  padding: 15px 15px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.multiselect__panel__item {
  display: flex;
}

.multiselect__panel__item > label {
  flex: 1 0 0;
}

.multiselect__panel__items .radiolist__option > label {
  flex: 1 0 0;
  display: flex;
}

.multiselect__panel__items .radiolist__option > label .badge {
  margin-left: auto;
}

.multiselect__panel__buttonbar {
  justify-content: flex-end;
  border-top: 1px solid #DDDDDD;
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  padding-right: 19px;
  height: 60px;
}

.multiselect__panel__cancel {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--formcontrols-height);
  color: #888888;
  font-weight: 600;
}

.multiselect__panel__clear {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  text-decoration: underline;
  color: var(--rtd-text-color);
  outline-offset: 3px;
  cursor: pointer;
}

.multiselect__panel {
  display: flex;
  flex-direction: column;
  overflow: hidden; /* cut corners so backgrounds don't stuck out */
}

.multiselect__panel__buttonbar {
  flex: none;
}

/*
@media (max-width: 640px)
{
  @include multiselect-fullscreen;
}

@media (min-width: 640px)
{
  @include multiselect-inline;
}
*/
.multiselect__panel__container {
  position: relative;
}

.multiselect__panel {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.multiselect__panel {
  width: 100%;
  min-width: 300px;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.63);
  border-radius: 4px;
}