/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.innovationspage--editproject {
  --formlayout-label-width: 150px;
}
.innovationspage--editproject h3 {
  text-transform: uppercase;
  margin-top: 40px;
}
.innovationspage--editproject .column5050 {
  flex-wrap: none;
}
.innovationspage--editproject .column5050__col {
  flex: 1 1 50%;
}
.innovationspage--editproject .column5050__col h3:first-child {
  margin-top: 0;
}
@media (max-width: 767px) {
  .innovationspage--editproject .column5050 {
    display: block;
  }
  .innovationspage--editproject .column5050__col + .column5050__col {
    margin-left: 0;
    margin-top: 30px;
  }
}

.projecteditform__themes .wh-form__optiondata {
  flex-wrap: nowrap;
}

.projecteditform__themes .wh-form__optionlabel {
  width: 100%;
  max-width: 280px;
}

.projecteditform__themes .infobutton-wrapper.infobutton-wrapper {
  margin-left: 0;
}

.wh-form__optiondata {
  position: relative;
}

.projectform__files .wh-form__fieldgroup,
.projectform__texts .wh-form__fieldgroup {
  flex-direction: column;
  align-items: flex-start;
}
.projectform__files .wh-form__fieldgroup > .wh-form__label.wh-form__label,
.projectform__texts .wh-form__fieldgroup > .wh-form__label.wh-form__label {
  flex: 0 0 auto;
  padding-bottom: 3px;
}
.projectform__files .wh-form__fieldgroup .wh-form__subfield,
.projectform__texts .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
.projectform__files .wh-form__fieldgroup > .wh-form__label, .projectform__files .wh-form__fieldgroup > .wh-form__fields,
.projectform__texts .wh-form__fieldgroup > .wh-form__label,
.projectform__texts .wh-form__fieldgroup > .wh-form__fields {
  flex: none;
  max-width: calc(100vw - 40px);
  min-width: 0;
}
.projectform__files .wh-form__fields,
.projectform__texts .wh-form__fields {
  width: 100%;
}

.wh-form.wh-form .wh-form__fieldgroup > .wh-form__fields {
  max-width: none;
}

.projectform__field__info {
  font-size: italic;
  font-size: 17px;
  margin-bottom: 20px;
}

.projecteditform__submitinstructions {
  background-color: rgba(238, 107, 6, 0.1);
  font: 17px var(--rtd-text-fontfamily);
  color: var(--rtd-text-color);
  padding: 15px;
  border-radius: 3px;
  border: 1px solid var(--color-theme);
  margin-bottom: 30px;
}

.wh-form__navbuttons-wrapper {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  position: sticky;
  bottom: 0;
  transition: background-color 0.25s, box-shadow 0.25s;
}

.wh-form__navbuttons-wrapper .wh-form__navbuttons {
  padding-top: 25px;
  padding-bottom: 20px;
}

.wh-form__navbuttons-wrapper--sticky {
  background-color: #F2EFED;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.22);
}
.wh-form__navbuttons-wrapper--sticky .wh-form__navbuttons {
  border-top: 1px solid transparent;
}

.wh-form__navbuttons-wrapper--nonsticky {
  background-color: #FFFFFF;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0);
}
.wh-form__navbuttons-wrapper--nonsticky .wh-form__navbuttons {
  padding-top: 25px;
  border-top: 1px solid #C5C5C5;
}

.projectform .wh-form__buttongroup.wh-form__navbuttons {
  grid-column: var(--pagegrid-col-default);
  margin-top: 0;
}
.projectform .wh-form__buttongroup.wh-form__navbuttons .wh-form__button--preview {
  margin-left: auto;
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  background-color: #FFFFFF;
}
.projectform .wh-form__buttongroup.wh-form__navbuttons .wh-form__button--preview:hover {
  background-color: var(--button-color);
  color: #FFFFFF;
  color: var(--button-contrastcolor);
  text-decoration: none;
}
.projectform .wh-form__buttongroup.wh-form__navbuttons .wh-form__button--preview .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}
.projectform .wh-form__buttongroup.wh-form__navbuttons .wh-form__button--submit {
  margin-left: auto;
}
.projectform .wh-form__buttongroup.wh-form__navbuttons .wh-form__button--preview + .wh-form__button--submit {
  margin-left: 28px;
}

[data-wh-form-name=introduction] {
  --rtdplaceholdertext: "Introduce the project";
}

[data-wh-form-name=objective] {
  --rtdplaceholdertext: "Describe the objective(s) and the expected outcome(s) of the project.";
}

[data-wh-form-name=results] {
  --rtdplaceholdertext: "Describe the results and learnings of this project. If applicable, also describe the dissemination and impact of the project.";
}

[data-wh-form-name=recommendations] {
  --rtdplaceholdertext: "Describe the recommendations that result from this project, e.g. tips, tools, strategies, etc. that others could benefit from.";
}

[data-wh-form-name=outcomes] {
  --rtdplaceholdertext: "Describe the practical outcomes that result from this project e.g. toolbox, report, website, publication, presentation, etc. for others to use.";
}

.projectform__texts {
  margin-bottom: 60px;
}