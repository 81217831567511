dialog.filteredoverview__teaser {
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
}

.filteredoverview__teaser {
  display: none;
  position: fixed;
  margin-top: auto;
  z-index: 99999;
  bottom: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
  pointer-events: none;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.filteredoverview__teaser.filteredoverview__teaser--aboveresults {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

.site-dialog .filteredoverview__teaser {
  margin: var(--pagegrid-gutteroutside-mobile);
  width: stretch;
  width: -webkit-fill-available;
}

.filteredoverview__teaser__button,
.filtersdialog__applybutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  min-height: var(--filteredoverview-teaserbar-height);
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border: var(--filteredoverview-teaserbar-border);
  border-radius: var(--filteredoverview-teaserbar-borderradius);
  background: var(--filteredoverview-teaserbar-background);
  box-shadow: 3px 2px 4px 6px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}

/*
.filteredoverview__teaser--aboveresults
{
  opacity: 1;
  pointer-events: auto;
}
*/
.filteredoverview__teaser__button,
.filtersdialog__applybutton {
  font: var(--filteredoverview-teaserbar-font);
  color: var(--filteredoverview-teaserbar-color);
  transition: background-color 0.25s;
  text-transform: uppercase;
  /*
    &:focus
    {
      box-shadow: 0 0 0 2px #000;
      outline: 0;

      // Let Webkit use it's default focus indicator
      @supports (outline: -webkit-focus-ring-color auto 5px)
      {
        box-shadow: none;
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
    &:active:focus
    {
      box-shadow: none;
      outline: none;
    }
  */
}
.filteredoverview__teaser__button:hover,
.filtersdialog__applybutton:hover {
  background: var(--filteredoverview-teaserbar-hover-background);
  color: var(--filteredoverview-teaserbar-hover-color);
}

/* ACOI
.filteredoverview__teaser__button::after
{
  content: ">";
  margin-left: 15px;

  font: var(--fontawesome-regular);
  // content: var(--fa-angle-right);
  // content: var(--fa-arrow-right);
  content: var(--fa-long-arrow-right);
  font-size: 20px;

  margin-top: 2px;
}
*/
@media (min-width: 376px) {
  .filteredoverview__teaser__button,
  .filtersdialog__applybutton {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 375px) {
  .filteredoverview__teaser__button,
  .filtersdialog__applybutton {
    padding-left: 18px;
    padding-right: 18px;
  }
}