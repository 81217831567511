/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.page--search {
  --pagegrid-col-default: var(--pagegrid-col-verywide);
}
.page--search .page__contentarea__meta {
  grid-column: var(--pagegrid-col-verywide);
}

.consilio-match {
  font-weight: bold;
  color: #444444;
}

.searchpage__searchcontrol {
  position: relative;
  grid-column: var(--pagegrid-col-default);
  margin-top: 25px;
  margin-bottom: 60px;
}

.searchpage__searchcontrol .searchcontrol__input {
  width: 100%;
  font-size: 16px; /* just a little bigger, so iOS won't zoom in to the input */
  padding-right: 40px;
}

.searchpage__searchcontrol .searchcontrol__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchpage__searchcontrol .searchcontrol__submit::before {
  font: var(--fontawesome-solid);
  content: var(--fa-search);
  font-size: 16px;
}

.searchpage__searchcontrol .searchcontrol__submit:hover::before {
  color: var(--color-red);
}

.searchresult.searchresult {
  display: block;
  text-decoration: none;
  color: var(--rtd-text-color);
}

a.searchresult:hover {
  background-color: var(--color-lightgrey);
}

.searchresult__content {
  padding-top: var(--component-summary-padding-v);
  padding-bottom: var(--component-summary-padding-v);
}

.searchresult__image,
.searchresult__imageplaceholder {
  width: 100%;
  padding-top: 75%;
}

.searchresult__image {
  background-size: cover;
}

.searchresult__imageplaceholder {
  background-color: #F0F0F0;
}

.searchresult:hover .searchresult__imageplaceholder {
  background-color: #E0E0E0;
}

.searchresult__metacol {
  flex: 1 1 0;
  min-width: 0;
}

.searchresult__date {
  color: #767676;
  font: 15px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.searchresult__title {
  color: var(--component-summary-title-color);
  font: var(--component-summary-title-font);
}

.searchresult__url {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  padding-top: 3px;
  color: #333333;
  font: var(--component-summary-url-font);
}

.searchresult__description {
  font: var(--component-summary-description-font);
}

html.page--search .searchresult__description {
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media (min-width: 600px) {
  .neotabs,
  .searchresults {
    grid-column: var(--component-summary-columns);
  }
  .searchresults {
    margin-left: calc(var(--pagegrid-gutteroutside) * -1);
    margin-right: calc(var(--pagegrid-gutteroutside) * -1);
  }
  .searchresult {
    text-decoration: none;
  }
  .searchresult__content {
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
  }
  .searchresult__imagecol {
    flex: none;
    width: 160px;
    margin-right: 30px;
  }
  html.page--publications .searchresult__imagecol {
    width: 180px;
  }
  .searchresult__image {
    width: 100%;
    padding-top: 75%;
  }
  html.page--publications .searchresult__image {
    padding-top: 56.35%;
  }
  .searchresult + .searchresult {
    margin-top: 4px;
  }
}
@media (max-width: 599px) {
  .searchresult__imagecol {
    display: none;
  }
  .searchresults {
    grid-column: var(--pagegrid-col-fullwidth);
  }
  .searchresult {
    display: block;
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .searchresult__url {
    display: none;
  }
}