/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.filteredoverview__teaser--old {
  /*
  .filteredoverview__teaser--aboveresults
  {
    opacity: 1;
    pointer-events: auto;
  }
  */
}
.filteredoverview__teaser--old .filteredoverview__teaser {
  display: none;
  position: fixed;
  /*
    position: -webkit-sticky;
    position: sticky;
  */
  margin-top: auto;
  z-index: 99999;
  bottom: 0;
  width: 100%;
  background-color: var(--color-theme);
  box-shadow: 3px 2px 4px 6px rgba(0, 0, 0, 0.07);
  opacity: 0;
  transition: opacity 0.5s;
  cursor: pointer;
  pointer-events: none;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.filteredoverview__teaser--old a.filteredoverview__teaser {
  text-decoration: none;
}
.filteredoverview__teaser--old .filteredoverview__teaser__content {
  position: relative;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-default);
  height: var(--filteredoverview-teaserbar-height);
  display: flex;
  align-items: center;
}
.filteredoverview__teaser--old html.filteredoverview--showfilters .filteredoverview__teaser {
  display: block;
  opacity: 1;
  pointer-events: auto;
}
.filteredoverview__teaser--old .filteredoverview__teaser__label {
  font: 600 18px/30px var(--rtd-text-fontfamily);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  text-transform: uppercase;
  transition: background-color 0.25s;
}
.filteredoverview__teaser--old .filteredoverview__teaser__label:hover {
  background-color: var(--color-theme-text-hover);
}
.filteredoverview__teaser--old .filteredoverview__teaser__label:focus {
  box-shadow: 0 0 0 2px #000;
  outline: 0;
}
@supports (outline: -webkit-focus-ring-color auto 5px) {
  .filteredoverview__teaser--old .filteredoverview__teaser__label:focus {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }
}
.filteredoverview__teaser--old .filteredoverview__teaser__label:active:focus {
  box-shadow: none;
  outline: none;
}
.filteredoverview__teaser--old .filteredoverview__teaser__label::after {
  content: ">";
  margin-left: 15px;
  font: var(--fontawesome-regular);
  content: var(--fa-angle-right);
  font-size: 23px;
  margin-top: 2px;
}
.filteredoverview__teaser--old .filteredoverview__teaser__close {
  position: absolute;
  right: 15px;
  font: 15px var(--rtd-text-fontfamily);
  color: #FFFFFF;
  padding: 6px 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.filteredoverview__teaser--old .filteredoverview__teaser__close:hover {
  background-color: var(--color-theme-hover);
}
.filteredoverview__teaser--old .filteredoverview__teaser__close::after {
  margin-left: 15px;
  font-size: 20px;
  font: var(--fontawesome-regular);
  content: var(--fa-times);
}
@media (max-width: 640px) {
  .filteredoverview__teaser--old .filteredoverview__teaser__close {
    display: none;
  }
  .filteredoverview__teaser--old .filteredoverview__teaser__label {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 375px) {
  .filteredoverview__teaser--old .filteredoverview__teaser__label {
    padding-left: 30px;
    padding-right: 30px;
  }
}