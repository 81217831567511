.spc-tiles--tile {
  /*
    .spc-tile__when
    {
      font-size: 12px;
    }
  */
  /*
    .widget-ctablocks__description
    {
      padding: 15px;
      font-family: var(--rtd-text-fontfamily);
      font-size: 14px;
      line-height: 20px;
      color: var(--rtd-text-color);
    }
  */
  /*
    .spc-tile__description::after
    {
      margin-top: 2px;

      font: var(--fontawesome-solid);
      content: var(--fa-chevron-right);
      // @include fontawesome-solid;
      // content: $fa-chevron-right;
      font-size: 17px;
      color: #3F3F3F;
    }
  */
}
.spc-tiles--tile .spc-tile {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content min-content 1fr;
  z-index: 0;
  position: relative;
  text-decoration: none;
  background-color: var(--component-spctile-background);
  border-radius: var(--component-spctile-radius);
  border: var(--component-spctile-border);
  box-shadow: var(--component-spctile-box-shadow);
}
.spc-tiles--tile .spc-tile:focus {
  outline: 3px solid var(--color-darkblue);
  outline-offset: 2px; /* have some offset because the outline might not have a good contrast with all blocks */
}
.spc-tiles--tile .spc-tile.notmatching,
.spc-tiles--tile .spc-tile.notcurrentpage {
  display: none !important;
}
.spc-tiles--tile .spc-tile__title {
  font: var(--component-spctile-title-font);
  margin-top: 3px;
}
.spc-tiles--tile a.spc-tile.spc-tile {
  text-decoration: none;
  color: #FFFFFF;
}
.spc-tiles--tile .spc-tile:hover {
  background: var(--component-spctile-hover-background);
}
.spc-tiles--tile .spc-tile:hover .spc-tile__image {
  transform: scale(1.05);
}
.spc-tiles--tile .spc-tile__top {
  grid-column: 1;
  grid-row: 1/span 2;
  flex: none;
  position: relative;
  overflow: hidden;
  aspect-ratio: var(--gridimage-aspectratio);
  border-radius: var(--component-spctile-radius) var(--component-spctile-radius) 0 0;
}
.spc-tiles--tile .spc-tile__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: var(--gridimage-aspectratio);
  object-fit: cover;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}
.spc-tiles--tile .spc-tile__secondarytags {
  grid-column: 1;
  grid-row: 3;
  padding: var(--component-spctile-padding-v) var(--component-spctile-padding-h) 0 var(--component-spctile-padding-h);
}
.spc-tiles--tile .spc-tile__meta {
  grid-column: 1;
  grid-row: 4;
  padding: var(--component-spctile-padding-v) var(--component-spctile-padding-h);
}
.spc-tiles--tile .spc-tile__title {
  color: var(--component-spctile-title-color);
}
.spc-tiles--tile .spc-tile:hover .spc-tile__title {
  color: var(--component-spctile-hover-title-color);
}
.spc-tiles--tile .spc-tile__description {
  display: flex;
  align-self: baseline;
  justify-content: space-between;
  width: 100%;
  flex: 1 0 auto;
  padding: 17px 20px 14px var(--block-pad-h);
  color: var(--component-spctile-text-color);
  font: var(--component-spctile-text-font);
  --rtd-text-color: var(--component-spctile-text-color);
  --rtd-text-font: var(--component-spctile-text-font);
}
.spc-tiles--tile .spc-tile__description > * {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.spc-tiles--tile .spc-tile__when {
  color: var(--component-spctile-text-color);
  font: var(--component-spctile-text-font);
}