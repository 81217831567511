
.widget-header
{
  grid-column: var(--pagegrid-col-verywide);
  margin-bottom: var(--widget-generic-largetitle-margin);
}

@media (min-width: 768px)
{
  .widget-header
  {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .widget-header__overviewlink
  {
    flex: none;
    margin-left: 30px;
  }
}

.widget-header__title
{
  /*align-self: center;*/
  font: var(--rtd-h1-font);
  color: var(--color-black);
}

.widget-header__overviewlink
{
  /*grid-column: var(--pagegrid-col-verywide-right);*/
  /*justify-self: end;*/
  /*align-self: center;*/

  font: 22px var(--rtd-heading-fontfamily);

  color: var(--color-black);
  text-decoration: none;
}

a.widget-header__overviewlink:hover
{
  color: var(--color-theme);
  text-decoration: underline;
}

.widget-header__overviewlink::before
{
  display: inline-block; /* so underline won't be applied when the anchor whe're in gets hovered */
  font: var(--icon-link-font);
  content: var(--icon-link-content);

  color: var(--text-grey-large);
  font-size: 15px;

  margin-right: 30px;

  transform:  translateX(0); /* signal intent to animate */
  transition: transform 0.2s ease-out;
}

a.widget-header__overviewlink:hover::before
{
  transform:  translateX(15px);
  transition: transform 0.2s ease-in;
  /*transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1.5);*/
}
