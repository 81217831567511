#eventsoverviewfilter .projectsoverview__filters__tags {
  display: flex;
  row-gap: 30px;
}
#eventsoverviewfilter .filterpanel[data-categoryid=when] {
  display: flex;
  flex-direction: column;
}
#eventsoverviewfilter .filterpanel[data-categoryid=when] .filterpanel__subfields {
  display: grid;
  grid-template-columns: min-content min-content;
}
#eventsoverviewfilter .filterpanel[data-categoryid=when] .filterpanel__subfield {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  align-items: center;
}
#eventsoverviewfilter .filterpanel[data-categoryid=when] .filterpanel__subfield label {
  padding-right: 15px;
}
#eventsoverviewfilter .filterpanel[data-categoryid=when] .filterpanel__subfield + .filterpanel__subfield {
  margin-top: 11px;
}
@media (min-width: 300px) and (max-width: 549px) {
  #eventsoverviewfilter .projectsoverview__filters__tags {
    flex-direction: column;
  }
}
@media (min-width: 550px) {
  #eventsoverviewfilter .projectsoverview__filters__tags {
    flex-wrap: wrap;
  }
  #eventsoverviewfilter .projectsoverview__filters__tags .filterpanel {
    flex: 0 1 auto;
  }
  #eventsoverviewfilter .projectsoverview__filters__tags .spacer {
    flex: 1 0 25px;
    max-width: 100px;
  }
}