/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
html.page--publications .searchresults {
  margin-top: 30px;
  padding-top: 17px;
  padding-bottom: 17px;
}
html.page--publications .searchresults:first-child {
  margin-top: 0;
}
html.page--publications .searchresult:hover .searchresult__content {
  background-color: #E5E5E5;
}
html.page--publications .searchresult__header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  font: 18px/26px var(--rtd-heading-fontfamily);
}
html.page--publications .searchresult__title {
  margin-right: 10px;
}
html.page--publications .searchresult__filemeta {
  display: flex;
  color: var(--rtd-text-color);
  font: 14px/28px var(--rtd-text-fontfamily);
}
html.page--publications .searchresult__filemeta > * + *::before {
  margin-left: 5px;
  margin-right: 5px;
  content: "|";
}
html.page--publications .searchresult__date {
  color: #3F3F3F;
  font: 13px var(--rtd-text-fontfamily);
  margin-bottom: 4px;
}
html.page--publications .searchresult__description {
  color: var(--rtd-text-color);
  font: 15px/24px var(--rtd-text-fontfamily);
  text-decoration-style: italic;
}

.pagination_navbar {
  display: flex;
  margin-top: 22px;
  border-top: 1px solid #C5C5C5;
  padding-top: 25px;
}

a.pagination_navbar__previous,
a.pagination_navbar__next {
  color: var(--rtd-link-color);
  text-decoration: none;
}

a.pagination_navbar__previous:hover,
a.pagination_navbar__next:hover {
  color: var(--rtd-link-color-hover);
  text-decoration: underline;
}

.pagination_navbar__prevpage {
  flex: none;
}

.pagination_navbar__items {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_navbar__items > a {
  display: block;
  width: 30px;
  height: 30px;
  color: #3F3F3F;
  padding-right: 1px;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: 16px var(--rtd-text-fontfamily);
  text-decoration: none;
}

.pagination_navbar__items > a.selected,
.pagination_navbar__items > a:hover {
  text-decoration: none !important;
  background-color: var(--color-theme);
  border-radius: 50%;
  color: #FFFFFF;
}

.pagination_navbar__items > * + * {
  margin-left: 11px;
}

.pagination_navbar__previous,
.pagination_navbar__next {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.pagination_navbar__previous {
  margin-right: 35px;
}

.pagination_navbar__previous__label {
  margin-left: 16px;
}

.pagination_navbar__next {
  margin-left: 35px;
}

.pagination_navbar__next__label {
  margin-right: 16px;
}

@media (max-width: 640px) {
  .pagination_navbar {
    justify-content: space-between;
  }
  .pagination_navbar__previous__label,
  .pagination_navbar__next__label {
    display: none;
  }
  .pagination_navbar__previous {
    margin-right: 15px;
  }
  .pagination_navbar__next {
    margin-left: 15px;
  }
}