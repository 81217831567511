.widget-ctablocks {
  --content-layout: verywide;
  --content-squeeze-preference: needwide;
  grid-column: var(--pagegrid-col-fullwidth);
  z-index: var(--zindex-overlap-deeplinks);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-ctablocks > *:first-child {
  margin-top: var(--widget-generic-panel-padding-top);
}

.widget-ctablocks > *:last-child {
  margin-bottom: var(--widget-generic-panel-padding-bottom);
}

.widget-ctablocks--bg-grey {
  --theme-name: "lightgrey";
}

.widget-ctablocks--bg-grey::before {
  content: "";
  display: block;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  grid-row: 1/2;
  background-color: var(--color-lightgrey);
}

html.pagewidth--width-fullwidth .widget-ctablocks--bg-grey {
  background-color: var(--color-lightgrey);
}

.widget-ctablocks__title {
  grid-column: var(--pagegrid-col-verywide);
  text-align: center;
  margin-bottom: 15px;
}

.widget-ctablocks__items {
  grid-column: var(--pagegrid-col-verywide);
  margin-left: -4px;
  margin-right: -4px;
  padding: 4px;
}

.widget-ctablocks__items__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.widget-ctablocks__item {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  background-color: #FFFFFF;
}
.widget-ctablocks__item .widget-ctablocks--descriptioninthemecolor {
  background: var(--theme-background);
}

.widget-ctablocks__item-top {
  flex: none;
  position: relative;
  overflow: hidden;
}

.widget-ctablocks__item-top::before {
  display: block;
  content: "";
  padding-top: var(--widget-ctablocks-paddingtop);
}

.widget-ctablocks__item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: var(--widget-ctablocks-paddingtop);
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}

.widget-ctablocks__item {
  border: 1px solid #DCDCDC;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
}

a.widget-ctablocks__item.widget-ctablocks__item {
  text-decoration: none;
}

.widget-ctablocks__item:hover {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.widget-ctablocks__item:hover .widget-ctablocks__item-image {
  transform: scale(1.1);
}

.widget-ctablocks__description {
  padding: 15px;
  font-family: var(--rtd-text-fontfamily);
  font-size: 14px;
  line-height: 20px;
  color: var(--rtd-text-color);
}

.widget-ctablocks__item-title {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  text-align: center;
  backdrop-filter: blur(0.8px);
  background: var(--theme-background-transparent);
}

.widget-ctablocks__item-title {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.widget-ctablocks__item-description {
  flex: 1 0 auto;
  padding: 17px 20px 14px 20px;
  color: var(--rtd-text-color);
  font: 14px/20px var(--rtd-text-fontfamily);
  text-align: center;
}
.widget-ctablocks__item-description .widget-ctablocks--descriptioninthemecolor {
  color: var(--theme-textcolor);
}

.widget-ctablocks__item-description > * {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box; /* ONLY works with -webkit-box */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media (max-width: 480px) {
  .widget-ctablocks__item-title {
    font: bold 19px var(--rtd-heading-fontfamily);
  }
  .widget-cta .wh-form__button--forward::after {
    margin-left: 15px; /* instead of 70px; */
  }
}
@media (max-width: 767px) {
  .widget-ctablocks__item-title {
    font: bold 19px var(--rtd-heading-fontfamily);
    padding: 9px 30px 6px 30px;
  }
}
@media (max-width: 320px) {
  .widget-ctablocks__item-title {
    font: bold 18px/20px var(--rtd-heading-fontfamily);
    padding: 7px 30px 7px 30px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .widget-ctablocks__item-title {
    font: bold 20px/24px var(--rtd-heading-fontfamily);
    padding: 11px 30px 7px 30px;
  }
}
@media (min-width: 1000px) {
  .widget-ctablocks__item-title {
    font: bold 24px/29px var(--rtd-heading-fontfamily);
    padding: 19px 30px 12px 30px;
  }
}