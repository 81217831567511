.page--editionsoverview
{
  --pagegrid-col-verywide-leftsmall:     3 / 10; /* 4 columns */
  --editionsoverview-year-spacing:       clamp(40px, 7vw,  70px);
  --editionsoverview-year-title-spacing: clamp( 0px, 1vw,   7px);
}

.editionsoverview__year__title
{
  margin-top: 0;
  margin-bottom: var(--editionsoverview-year-title-spacing);
  /*font: var(--rtd-h1-font);*/
  font: var(--widget-cta-heading-font);

  color: var(--rtd-heading-color);
}

.editionsoverview__year
{
  grid-column: var(--pagegrid-col-verywide);
}

.editionsoverview__year + .editionsoverview__year
{
  margin-top: var(--editionsoverview-year-spacing);
}

.page--editionsoverview hr
{
  width: 100%;
  border: 0;
  border-bottom: 1px solid var(--color-divider);
}



.editionsoverview__year + .editionsoverview__item__image
{
  margin-top: 55px;
}

.editionsoverview__item
{
  text-decoration: none;
  color: var(--rtd-text-color);
}

/* 417 x 285 */
  .editionsoverview__item__image
, .editionsoverview__item__imageplaceholder
{
  display: block;
  object-fit: cover;
  aspect-ratio: 42 / 28;

  border-right: 7px solid var(--edition-themecolor, #888888);
}

.editionsoverview__item__imageplaceholder
{
  background-color: var(--color-lightgrey);
}

.editionsoverview__item__meta .filler
{
  flex: 1 1 0;
}

.editionsoverview__item__when
{
  font: var(--rtd-text-small-font);
  margin-bottom: 15px;
  text-transform: lowercase;
}

.editionsoverview__item__when::before
{
  /*margin-top: -5px; // compensate for Officina font weird baseline alignment*/

  font: 200 19px 'Font Awesome 5 Pro';
  content: var(--fa-calendar-day);

  margin-right: 15px;
}


.editionsoverview__item__title
{
  color: var(--rtd-heading-color);
  font: bold 32px/32px var(--rtd-heading-fontfamily);
}

.editionsoverview__item__title + .editionsoverview__item__description
{
  margin-top: 15px;
}

.editionsoverview__item__description
{
  font: var(--rtd-text-font);

  margin-bottom: -10px; /* compensate for Officina font have so much whitespace below the baseline */
}

.editionsoverview__item__tags
{
  margin-top: 20px;
}



@media (max-width: 600px)
{
    .editionsoverview__item__image
  , .editionsoverview__item__imageplaceholder
  {
    grid-column: var(--pagegrid-col-verywide);
    width: 100%;
  }

    .editionsoverview__item__image + .editionsoverview__item__meta
  , .editionsoverview__item__imageplaceholder + .editionsoverview__item__meta
  {
    margin-top: 15px;
  }

  .page--editionsoverview hr
  {
    width: 100%;
    margin-top:    30px;
    margin-bottom: 30px;
  }
}


@media (min-width: 601px) and (max-width: 900px)
{
  .page--editionsoverview hr
  {
    width: 100%;
    margin-top:    20px;
    margin-bottom: 20px;
  }

  .widget--schedule
  {
    grid-column: var(--pagegrid-col-verywide);
  }

  .editionsoverview__item__heading__content
  {
    padding-top: 7px;
    padding-bottom: 5px;
  }

  .editionsoverview__item__content
  {
    padding-top: 27px;
  }

    .editionsoverview__item__image
  , .editionsoverview__item__imageplaceholder
  {
    float: left;
    width: 417px;
    max-width: 50%;
    margin-right:  30px;
    margin-bottom: 15px;
  }
}


@media (min-width: 901px)
{
  .page--editionsoverview hr
  {
    width: 100%;
    margin-top:    34px;
    margin-bottom: 34px;
  }

  .widget--schedule
  {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .editionsoverview__item
  {
    /*
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    */
    display: flex;
  }

  .editionsoverview__item__heading__content
  {
    display: flex;
    align-items: center;
  }

  .editionsoverview__item__content
  {
    padding-top: 48px;

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .editionsoverview__item__heading
  {
    height: 73px;

    width: var(--pagegrid-verywide-maxwidth);
    max-width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
  }

    .editionsoverview__item__image
  , .editionsoverview__item__imageplaceholder
  {
    /*
    grid-column: var(--pagegrid-col-verywide-leftsmall);
    width: 100%;
    object-fit: cover;
    aspect-ratio: 415 / 277;
    */
    flex: none;
    align-self: start;

    width: 32%;
    object-fit: cover;
    aspect-ratio: 415 / 277;
  }

  .editionsoverview__item__meta
  {
    /*
    grid-column: 11 / 26;
    padding-left: 30px; * some extra whitespace *
    */

    flex: 1 1 0;
    margin-left: 45px;

    padding-top: 25px;
  }
}

