/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
--content-squeeze-preference: needwide;
--content-squeeze-preference: preferwide;
--content-squeeze-preference: auto;
--content-squeeze-preference: notwide;


Pass 1
- read the calculated width of the metadata bar
- set the metadata bar width to the --content-squeeze variable
- set classname .squeeze-test
- widgets can either indicate their preference:
  --content-squeeze-preference: needwide;
  This element/widget doesn't want it... it would be broken or ugly if squeezed.
  It will only be sqeeuzed if there's no other way.
  (you start the page with such a widget)

  --content-squeeze-preference: preferwide;
  This widget would be better off with more space.

  --content-squeeze-preference: auto;
  --content-squeeze-preference: notwide; // widget doesn't use the space anyway


/ all WIDE widget MUST set --content-squeeze: 0px;

Pass 2
- Iterate content to determine:
    - which content must stay squeezed
    - if and where to clip/collapse the metadata bar

- Two layout options can happen:
    - the metadata hits a wide widget
      DO: clip metabar above the wide widget, set class on widgets above it to stay squeezed

    - no content is hit by the metabar
      DO: apply squeesh class to all elements which didn't start below the metabar

- Pseudo code
  - Check if the element overlaps the metabar
    (meaning it's wide because it refuses to shrink)

    let overlap_h = item_x_end > metabar_x_start;
    let overlap_v = item_y_start < metabar_y_end;

        TODO:
        - Clip the metadata bar X pixels above this widget
        - Remove all .squeeze classes starting from the widget
  - if still in loop -> push element into list of elements to squeeze

- did the first wide widget come before the metadata bar ended?
  .
*/
.page__contentarea__projectdetails-content,
.page__contentarea__inset {
  margin-top: var(--whitespace-header-content);
}

.page__contentarea__projectdetails-content {
  grid-column: var(--pagegrid-col-fullwidth);
  grid-row: 1;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__contentarea__projectdetails-content.squeeze_test > *,
.page__contentarea__projectdetails-content.apply_selective_squeeze > *.squeeze {
  max-width: calc(100% - var(--content-squeeze));
}

.page__contentarea__projectdetails-content *:first-child {
  margin-top: 0;
}

@media (max-width: 767px) {
  .page__contentarea__inset__toggle {
    display: none;
  }
  .page__contentarea__inset__contentwrapper {
    max-height: none !important;
  }
}
@media (min-width: 768px) {
  .page__contentarea__inset {
    padding-left: 20px;
    padding-bottom: 20px;
    box-shadow: -10px 10px 25px #FFF;
    align-self: start;
    justify-self: end;
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 1;
    width: 300px;
    max-width: 30vw; /* about the min required width to fix the share buttons */
    position: relative; /* for positioning of the toggle button */
    z-index: var(--pagegrid-inset-zindex);
    background-color: #FFFFFF;
  }
  .page__contentarea__inset__contentwrapper {
    overflow: hidden;
    padding: 3px; /* some whiteroom at all sides to prevent outline around focussed elements from being cut off */
    margin-right: -3px;
  }
  .page__contentarea__inset--open > .page__contentarea__inset__contentwrapper {
    max-height: none !important;
  }
  .page__contentarea__inset__toggle {
    display: none;
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .page__contentarea__inset.insetcontainer--clipped .page__contentarea__inset__toggle {
    display: flex;
  }
  .page__contentarea__inset--open > .page__contentarea__inset__contentwrapper {
    padding-bottom: 50px;
  }
  .page__contentarea__inset--open .page__contentarea__inset__toggle {
    bottom: -17px; /* half over the inset, half over the page content */
  }
  .page__contentarea__inset--open::before {
    content: "";
    position: absolute;
    left: 0;
    right: -50px;
    top: 0;
    bottom: 0;
    box-shadow: -12px 3px 15px 6px white, -10px 42px 20px 5px rgba(255, 255, 255, 0.95);
    z-index: -1;
    pointer-events: none;
  }
  /* Fadeout content
     - to indicate it's clipped
     - and to prevent visual clashing with the "More" button
     - to increate the hitarea of the toggle button
  */
  .page__contentarea__inset__toggle::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 140px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 90%, #FFFFFF 100%);
  }
  .page__contentarea__inset--open > .page__contentarea__inset__toggle::before {
    display: none;
  }
  .page__contentarea__inset__toggle::after {
    content: "";
    display: block;
    width: 100%;
    border: 1px solid #979797;
    opacity: 0.56;
    position: absolute;
    left: 0;
    right: 0;
  }
  .page__contentarea__inset__toggle__button {
    position: relative;
    z-index: 1;
    padding: 0 18px;
    height: 30px;
    border: 1px solid #C5C5C5;
    border-radius: 14px;
    font: 14px/17px var(--rtd-heading-fontfamily);
    background-color: #FFFFFF;
    padding-top: 4px;
  }
  .page__contentarea__inset__toggle__button {
    display: grid;
    align-items: center;
  }
  .page__contentarea__inset__toggle__button > * {
    grid-row: 1;
    grid-column: 1;
  }
  .page-inset__togglebutton__more::after {
    content: var(--fa-caret-down);
    font: var(--fontawesome-solid);
    color: var(--color-theme);
    margin-left: 10px;
  }
  .page-inset__togglebutton__less::after {
    content: var(--fa-caret-up);
    font: var(--fontawesome-solid);
    color: var(--color-theme);
    margin-left: 10px;
  }
  .page-inset__togglebutton__less {
    visibility: hidden;
  }
  .page__contentarea__inset--open .page-inset__togglebutton__more {
    visibility: hidden;
  }
  .page__contentarea__inset--open .page-inset__togglebutton__less {
    visibility: visible;
  }
  .page__contentarea__inset__toggle:hover .page__contentarea__inset__toggle__button {
    background-color: var(--color-black);
    color: var(--color-white);
  }
  .page__contentarea__inset__toggle:hover .page__contentarea__inset__toggle__button > *::after {
    color: var(--color-white);
  }
}