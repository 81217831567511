/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget--neplists {
  --content-layout: verywide;
  --content-squeeze-preference: needwide;
  grid-column: var(--pagegrid-col-verywide);
}

.widget--neplists a {
  color: var(--rtd-link-color);
  text-decoration: none;
}

.widget--neplists a:hover {
  color: var(--rtd-link-color-hover);
  text-decoration: underline;
}

.widget--neplists__title {
  color: #000000;
}

.widget--neplists__item {
  display: flex;
  border-bottom: 1px solid #CCCCCC;
  padding-top: 5px;
  padding-bottom: 5px;
}

.widget--neplists__item__image {
  flex: none;
  width: 30%;
  margin-right: 15px;
  background-size: cover;
  align-self: center;
}

.widget--neplists__item__image::before {
  display: block;
  content: "";
  padding-top: 65%;
}

.widget--neplists__item__meta {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.widget--neplists__item__date {
  font: 13px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  color: #767676;
}

.widget--neplists__item__title {
  font: bold 15px/20px var(--rtd-heading-fontfamily);
  color: var(--color-theme);
}

.widget--neplist__overviewlink {
  margin-top: 20px;
  justify-self: end;
}

@media (max-width: 767px) {
  .widget--neplists__title--events,
  .widget--neplists__title--publications {
    margin-top: 30px;
  }
  .widget--neplists__item__image {
    flex: none;
    width: 25%;
  }
  .widget--neplist__overviewlink {
    display: table;
    margin-left: auto;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .widget--neplists__item__image {
    display: none;
  }
}
@media (min-width: 768px) {
  .widget--neplists__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: min-content min-content min-content min-content min-content min-content;
    grid-auto-flow: column;
    grid-column-gap: 30px;
  }
  .widget--neplists__title--news,
  .widget--neplists__item--newsitem,
  .widget--neplist__overviewlink--news {
    grid-column: 1;
  }
  .widget--neplists__title--events,
  .widget--neplists__item--event,
  .widget--neplist__overviewlink--events {
    grid-column: 2;
  }
  .widget--neplists__title--publications,
  .widget--neplists__item--publication,
  .widget--neplist__overviewlink--publications {
    grid-column: 3;
  }
  .widget--neplist__overviewlink {
    grid-row: -1;
  }
}
@media (max-width: 799px) {
  .widget--neplists__title {
    font: bold 19px/24px var(--rtd-heading-fontfamily);
    border-bottom: 1px solid #C5C5C5;
    padding-bottom: 9px;
    margin-bottom: 15px;
  }
  .widget--neplists__item__date {
    font: 14px var(--rtd-heading-fontfamily);
  }
  .widget--neplist__overviewlink {
    font: 16px/22px var(--rtd-heading-fontfamily);
  }
}
@media (min-width: 800px) {
  .widget--neplists__title {
    border-bottom: 1px solid #C5C5C5;
    margin-bottom: 20px;
    font: bold 28px var(--rtd-heading-fontfamily);
  }
  .widget--neplist__overviewlink {
    font: 17px var(--rtd-heading-fontfamily);
  }
}