.navpath {
  display: flex;
  align-items: center;
  margin-left: -7px;
  color: var(--component-navpath-color);
  font: var(--component-navpath-font);
}

.navpath__item {
  padding-left: 7px;
  padding-right: 7px;
}

a.navpath__item {
  color: inherit;
  text-decoration: none;
  font-weight: normal;
}

a.navpath__item:hover {
  text-decoration: underline;
}

.navpath__seperator {
  flex: none;
}
.navpath__seperator::before {
  font: var(--component-navpath-seperator-font);
  content: var(--component-navpath-seperator-content);
}