/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
@media (min-width: 1024px) {
  .page--eventsoverview .searchresults,
  .page--newsoverview .searchresults {
    --component-summary-columns: var(--pagegrid-col-verywide);
  }
}
.neotabs {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 19px;
  border-bottom: 1px solid #C5C5C5;
  font: 19px/24px var(--rtd-heading-fontfamily);
  margin-top: 30px;
}

.neotabs:first-child {
  margin-top: 0;
}

.neotabs + .searchresults {
  margin-top: 7px;
}

.neotabs a,
.neotabs span {
  position: relative;
  font-weight: normal;
  color: #3F3F3F;
  padding: 5px 12px;
  text-decoration: none;
}

.neotabs * + * {
  margin-left: 11px;
}

.neotabs a::after,
.neotabs span::after {
  content: "";
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 6px;
  border-bottom: 1px solid transparent;
}

.neotabs span::after {
  border-color: #3F3F3F;
}

.neotabs a:hover::after {
  border-color: #CCCCCC;
}

.neotabs *:first-child {
  margin-left: -12px;
}