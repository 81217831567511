.edition-event__colofon {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: var(--color-theme);
  color: var(--color-white);
  --rtd-text-color: var(--color-white);
  --rtd-heading-color: var(--color-white);
  padding-top: var(--widget-generic-bigpanel-padding-top);
  padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
  padding-bottom: max(var(--widget-generic-bigpanel-padding-bottom), var(--whitespace-footer-content));
}

.edition-event__colofon__content {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  flex-wrap: wrap;
  row-gap: 60px;
}

html.use-edition-themecolor .edition-event__colofon {
  background-color: var(--edition-themecolor);
  color: var(--edition-themecontrastcolor);
  --rtd-text-color: var(--edition-themecontrastcolor);
  --rtd-heading-color: var(--edition-themecontrastcolor);
}

.edition-event__colofon__title {
  width: 100%;
  font: var(--widget-cta-heading-font);
}

.edition-event__colofon__staff {
  flex: 1 1 500px;
}

.edition-event__colofon__editorial {
  flex: 1 1 500px;
}

.edition-event__colofon__staff {
  font: var(--rtd-text-small-font);
  columns: 2;
}

.edition-event__colofon__staff h3 {
  font: inherit;
  font-weight: bold;
}

.edition-event__colofon__staff__group {
  padding-bottom: 28px; /* we cannot use margins on Safari, it'll add the to both the current column and at the top of the next */
  break-inside: avoid;
}

.edition-event__colofon__staff ul {
  list-style: none;
  margin: 0;
  padding: 0;
}