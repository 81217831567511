.divider--white
{
  border-bottom: 1px solid #FFFFFF;
  grid-column: var(--pagegrid-col-fullwidth);
}

.divider--grey
{
  border-bottom: 1px solid var(--color-lightgrey);
  grid-column: var(--pagegrid-col-fullwidth);

  margin-top:    60px;
  margin-bottom: 60px;
}
