/* NOTE:
   - When using url() the Webhare CSS will rewrite the relative path (to an /.publisher/sd/4tu/site/fonts/... path)
   - When not using url() Webhare will leave the URL alone (treating it as an absolute url??)
   - Webhare won't rewrite the CSS from the imported CSS file, so it will be able to override the Tollium backend styling
*/
@import url("../../web/fonts/fontawesome/css/all.css");
@import url("../../web/fonts/officina/officina.css");
@import url("../../web/fonts/lato/lato.css");


/*
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,500,600&display=swap");

DU menubar #FAF6F4
DU connect widget grey #FAF6F4
DU footer bg #D0C7BD
*/

html
{
  --color-divider:         #EAEAEA; /* 979797 20% on #FFFFFF */
  --color-divider-on-grey: #E2DFDF; /* 979797 20% on #FAF6F4 */


  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */

  --formlayout-fieldgroup-error-textcolor: var(--color-error);

  --color-lightgrey:          #F2EFED; /* background color */

  --color-bggrey:  #FAF6F4; /* CTA / DU menubar / DU connect widget */

  --color-black-grey:  #3F3F3F;



  --color-almond:             #F2EFED; /* background color for some widget */
  --color-almond-dark:        #D0C7BD; /* background color for footer (more contrast for small texts) & innovator cards */

  --headerlogo-colortint: #F2971A; /* logo (orange peach) */
  --headerlogo-grey:      #8E8E8E;


  /* logo colors when used on almond-dark background color */
  --footerlogo-colortint: var(--color-orange);
  --footerlogo-grey:      #8E8E8E;
  --footer-text-color:    #3F3F3F;
  --footer-heading-color: #393939;


  /*--color-darkblue-bgh:           #15314E;*/
  --color-darkgrey:           #3F3F3F;

  --color-darkblue:           #003B8E;
  --color-darkblue-hover:     #767BAD;
  --color-orange:             #E1670B;
  --color-orange-hover:       #F49120;
  --color-orange-hover-dark:  #CC4400; /* FIXME: made up */
  --color-orange-transparent: rgba(225,103,11,0.85);
  --color-red:                #D82F37;
  --color-red-hover:          #EE5050; /* not designed */
  --color-black:              #000000;
  --color-white:              #FFFFFF;

  /* transparent colors, usually for an overlay */
  --color-almond-transparent:    #F2EFEDCC;
  --color-darkblue-transparent:  #003B8ECC;
  --color-orange-transparent:    #E1670BCC;
  --color-red-transparent:       #D82F37CC;
  --color-black-transparent:     #000000CC;


  --color-theme:       var(--color-orange);
  --color-theme-hover: var(--color-orange-hover);
  --color-theme-hover-dark: var(--color-orange-hover-dark);
  --color-theme-transparent: rgba(225,103,11,0.3);

  --color-theme-text:  var(--color-orange);
  --color-theme-text-hover: var(--color-orange-hover);


/* #E1670B; // links, button, CTA widget bg, CTA banners widget bars (bold-orange)*/

  /* NOTE: text colors may be overridden based on the background.
           (a grey may get darker so it has enough contrast on a grey background)
  */
  --text-grey-large:    #ACACAC;
  --text-grey-small:    #8E8E8E; /* 20px Quote person name */



  --color-error-control-border:     #cd202c;
  --color-error-control-background: #FFEAEA;

  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767677; /* WCAG 4.53:1 contrastratio */
  --color-input-placeholder: var(--color-grey-text-small);
  --color-input-disabled-placeholder: var(--color-grey-text-small);


  --grid-spacing: 30px;



  --pagetitle-inflow-color: var(--color-theme);

  --fontawesome:         'Font Awesome 5 Pro';
  --fontawesome-light:   300 14px 'Font Awesome 5 Pro';
  --fontawesome-regular: 400 14px 'Font Awesome 5 Pro';
  --fontawesome-solid:   900 14px 'Font Awesome 5 Pro';
  --fontawesome-brands:  14px 'Font Awesome 5 Brands';



  /************************************************************
   *
   *  Form controls / generic settings
   */

  --formcontrols-themecolor: var(--color-orange);


  /************************************************************
   *
   *  Form layout
   */

  --formlayout-fieldlines-spacing: 20px;
  --formlayout-buttongroup-margin: 20px 0 0 0;


  /************************************************************
   *
   *  Form controls (textual fields)
   */

  /* Normal height for single-line controls */
  --formcontrols-height:             43px;
  --formcontrols-radius:              3px;

  --textcontrols_focus_bordercolor: #0067B4;
  --formcontrol-checkradio-label-color:    #000;

  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus */
  --textcontrols-font:                 16px var(--rtd-text-fontfamily);

  --textcontrols_placeholdercolor:     #999999;
  --textcontrols_textcolor:            #282828;

  --textcontrols_disabled_bgcolor:     rgba(255,255,255,0.6);
  --textcontrols_disabled_bordercolor: #C5C5C5;
  --textcontrols_disabled_placeholder: inherit;
  --textcontrols_disabled_textcolor:   #888888; /*#282828;*/

  --textcontrols_padleft:         15px;
  --textcontrols_padright:        15px;
  --textcontrols_padleft-mobile:  10px;
  --textcontrols_padright-mobile: 10px;


  --controls_textcolor_error:        #EE0000; /* lightest red which passes accessibility tests (4.53:1 contrast on white) */
  --controls_outline_color:          #979797;
  --controls_outline-color_disabled: #BFBFBF;
  --controls_outline-color_error:    #CD202C;
  --controls_outline-color_focus:    #222222;
  --controls_backgroundcolor_error:  #FFEAEA;

  --controls-icon-color: var(--color-theme);


    /************************************************************
     *
     *  Form controls (default icons)
     */

    --formcontrol-icon-delete-font: 400 18px 'Font Awesome 5 Pro';
    --formcontrol-icon-delete-icon: var(--fa-trash-alt);


    /************************************************************
     *
     *  Form controls (checkbox and radio buttons)
     */

      /* Shared checkbox & radiobutton settings */
      --formcontrol-checkradio-size:                24px;
      --formcontrol-checkradio-bordercolor:         #979797;
      --formcontrol-checkradio-borderwidth:         1px;
      --formcontrol-checkradio-borderradius:        2px;

      /* Hover */
      /* Color for subtly showing the checkmark/bullet symbol upon hover */
      --formcontrol-checkradio-hover-color:         #F0F1F2;
      --formcontrol-checkradio-hover-bgcolor:       #FFFFFF;
      --formcontrol-checkradio-hover-bordercolor:   var(--formcontrols-themecolor);

      /* Checked radio button (white background) */
      --formcontrol-checkradio-checked-color:       #FFFFFF;
      --formcontrol-checkradio-checked-bgcolor:     var(--formcontrols-themecolor);
      --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
      --formcontrol-radio-checked-color:            var(--formcontrols-themecolor);


      /************************************************************
       *
       *  Form controls (checkbox)
       */

      --formcontrol-check-font: 400 17px/24px var(--fontawesome);
      --formcontrol-check-content: '\f00c'; /* checkmark in FA5 */
      /*
      --formcontrol-check-content: "\e931"; UT-icon-UT_icon_50_check-16px
      --formcontrol-check-content: "✓"; // checkmark
      --formcontrol-check-content: "✗"; // ballot X
      --formcontrol-check-content: "✕"; // multiplication
      */

      --formcontrol-check-shift:                    3px;

      /* Disabled checkbox */
      --formcontrol-check-disabled-bgcolor:             #F2F2F2;
      --formcontrol-check-disabled-bordercolor:         #BBBBBB;
      --formcontrol-check-disabled-iconcolor:           transparent;

      --formcontrol-check-disabled-checked-bgcolor:     #F2F2F2;
      --formcontrol-check-disabled-checked-bordercolor: #BBBBBB;
      --formcontrol-check-disabled-checked-iconcolor:   #BBBBBB;


      /************************************************************
       *
       *  Form controls (radio)
       */

      --formcontrol-radio-shift:                        0px;
      --formcontrol-radio-innersize:                    14px;

      --formcontrol-radio-disabled-bgcolor:             #F2F2F2;
      --formcontrol-radio-disabled-bordercolor:         #BBBBBB;
      --formcontrol-radio-disabled-iconcolor:           transparent;

      --formcontrol-radio-disabled-checked-bordercolor: #BBBBBB;
      --formcontrol-radio-disabled-checked-bgcolor:     #BBBBBB;
      --formcontrol-radio-disabled-checked-iconcolor:   #BBBBBB;

      /* Focus */
      --formcontrol-checkradio-focus-bordercolor:   #FFFFFF; /* create contrast to the box shadow */
      --formcontrol-checkradio-focus-boxshadow:     0 0 2px 3px rgb(44, 108, 214);



  /************************************************************
   *
   *  Page components / Autosuggest
   */

  --component-autosuggest-radius: var(--formcontrols-radius);
  --component-autosuggest-padding: 13px 0 9px 0;
  --component-autosuggest-background: #FFFFFF;

  --component-autosuggest-item-pad-h: var(--textcontrols_padleft); /* align options with the text input */
  --component-autosuggest-item-pad-v: 4px;
  --component-autosuggest-item-font:  inherit;
  --component-autosuggest-item-color: #000000;


  /************************************************************
   *
   *  Page components / Buttons
   */

  --button-borderradius:      2px;
  --button-color:             var(--color-orange);
  --button-color-hover:       var(--color-orange-hover);
  --button-contrastcolor:     #FFFFFF;
  --button-fontfamily:        var(--rtd-text-fontfamily);
  --button-font:              17px var(--button-fontfamily);
  /*--button-height:            50px;*/
  --button-disabled-backgroundcolor: #AAAAAA;
  --button-text-alignment-tweak:  2px; /* because of Officina font */


  /************************************************************
   *
   *  Filteredoverview / Teaser bar
   */

   --filteredoverview-teaserbar-height: 64px;



  /************************************************************
   *
   *  Page components / Infopopup
   */

  --infopopup-arrowsize: 8px;
  --infopopup-bordercolor: #666666;
  --infopopup-backgroundcolor: #FFFFFF;


  /************************************************************
   *
   *  Page components / Consent bar (cookiebar)
   */

  --consentbar-background:   #F1EFED;
  --consentbar-padding:      40px 0 40px 0;

  --consentbar-heading-color: var(--color-theme);
  --consentbar-heading-font: bold 36px/28px Officina, Arial, Sans-Serif;

  --consentbar-text-color:   #666666;
  --consentbar-text-font:         17px/24px Helvetica;

  --consentbar-padding:      43px 0 46px 0;
  --consentbar-preferenceslink-font: 18px/24px Officina, Arial, Sans-Serif;

  --consentsettings-option-background: transparent;
  --consentsettings-option-border:     2px solid var(--color-theme);

  --consentsettings-option-color: #000000;
  --consentsettings-option-font:  bold 25px/28px Officina, Arial, Sans-Serif;



  /************************************************************
   *
   *  Page components / Navigation path (crumpath)
   */

  --component-navpath-color:    var(--color-grey-text-small);
  --component-navpath-font:     var(--rtd-text-compact-font);
  --component-navpath-seperator-font:    inherit;
  --component-navpath-seperator-content: "/";



  /************************************************************
   *
   *  Very site specific page component settings
   */



  --itempanel-box-shadow: 1px 1px 9px 2px rgba(0,0,0,0.22);

  --pagetile-heading-color:       var(--rtd-text-color);
  --pagetile-heading-color-hover: var(--color-theme);

  /* FIXME: maybe replace with aspect-ratio ? */
  --widget-ctablocks-paddingtop: 56.1%;

/*
Titles
- Features project item title - 24px/32px
- Zie ook item title          - 24px/34px
- Stories item title          - 24px/32px



  related projects description   18px/27px
  zie-ook-item-description 17px/32px
  stories widget 1e item description:  16px/24px;
*/
}


/* For these we want buttons to be larger */
  .page-header__slidescontent
, .widget-cta__button /* in header and CTA widget */
{
  --formcontrols-height:  50px;
}


html
{

  /************************************************************
   *
   *  Page components / Share bar
   */

  /* Square outline */
  /*
  --component-sharepage-button-background: #FFFFFF;
  --component-sharepage-button-color:      var(--color-theme);
  --component-sharepage-button-border:     1px solid var(--color-theme);
  --component-sharepage-button-size:       37px;
  --component-sharepage-button-symbolsize: 20px;
  --component-sharepage-button-radius:     5px;
  --component-sharepage-button-spacing:    14px;
  */

  --component-sharepage-button-background: var(--color-theme);
  --component-sharepage-button-color:      #FFFFFF;
  --component-sharepage-button-border:     1px solid var(--color-theme);
  --component-sharepage-button-size:       44px;
  --component-sharepage-button-symbolsize: 20px;
  --component-sharepage-button-radius:     50%;
  --component-sharepage-button-spacing:    16px;


}

html .page__contentarea
{
  /************************************************************
   *
   *  Page components / Share bar
   */

  /* Square outline */
  /*
  --component-sharepage-button-background: #FFFFFF;
  --component-sharepage-button-color:      var(--color-theme);
  --component-sharepage-button-border:     1px solid var(--color-theme);
  --component-sharepage-button-size:       37px;
  --component-sharepage-button-symbolsize: 20px;
  --component-sharepage-button-radius:     5px;
  --component-sharepage-button-spacing:    14px;
  */

  --component-sharepage-button-background: var(--color-theme);
  --component-sharepage-button-color:      #FFFFFF;
  --component-sharepage-button-border:     1px solid var(--color-theme);
  --component-sharepage-button-size:       36px;
  --component-sharepage-button-symbolsize: 16px;
  --component-sharepage-button-radius:     50%;
  --component-sharepage-button-spacing:    11px;
}





html
{
  --whitespace-footer-content: 100px;

  --rtd-spacing-paragraph:    15px;
  --rtd-spacing-intro-after:  35px;

  --rtd-link-color:         var(--color-theme);
  --rtd-link-color-hover:   var(--color-theme-hover);
  --rtd-link-outline-offset: 3px;

  --rtd-list-marker-color:  var(--color-theme);

  --rtd-text-color:         #3F3F3F;
  --rtd-text-fontfamily:    Lato, Arial, Sans-Serif;
  --rtd-text-font:          17px/26px var(--rtd-text-fontfamily);
  --rtd-text-small-font:    15px/22px var(--rtd-text-fontfamily);
  --rtd-text-compact-font:  14px/21px var(--rtd-text-fontfamily); /* navpath & member list */

  --rtd-heading-color:      var(--color-black);
  --rtd-heading-fontfamily: Officina, Arial, Sans-Serif;
  --rtd-heading-weight:     600;
  --rtd-heading-compact-font: var(--rtd-heading-weight) 16px/24px var(--rtd-heading-fontfamily);

/*  --rtd-h1-font:            bold 48px/50px var(--rtd-heading-fontfamily);*/
  --rtd-h1-font:            bold clamp(35px, 3.5vw, 48px)/110% var(--rtd-heading-fontfamily);

  --rtd-h1-subtitle-font:        32px/38px var(--rtd-heading-fontfamily); /* .page-header__subtitle for DU projects */
  --rtd-h2-font:            bold 25px/28px var(--rtd-heading-fontfamily);
  --rtd-h3-font:            bold 20px/26px var(--rtd-heading-fontfamily);

  --widget-title-font:  var(--rtd-h2-font);
  --widget-cta-video-title-font: bold clamp(35px, 3.5vw, 40px)/110% var(--rtd-heading-fontfamily); /* .page-header__subtitle for DU projects */

  --metadata-sidepanel-h2-font: bold 19px/24px var(--rtd-heading-fontfamily);

  --rtd-intro-font: bold 22px/28px var(--rtd-heading-fontfamily);

  /*
  Item heading font is used by: ;
  - Featured project item title
  - Downloads & Links thumb mode ("Zie ook") widget item title
  - Stories (widget) item title
  - Stories summary title
  - Carrousel header item title
  */
  --widget-item-heading-font:           24px/32px var(--rtd-heading-fontfamily);
  --widget-item-description-font:       var(--rtd-text-font); /*17px/26px var(--rtd-text-fontfamily);*/

  /* used for CTA and Edition title on edition page */
  --widget-cta-heading-font:            bold 44px/50px var(--rtd-heading-fontfamily); /* FIXME:  using design united value */;


  --widget-quote-withimage-font:        26px/34px var(--rtd-text-fontfamily);
  --widget-quote-withoutimage-font:     32px/40px var(--rtd-text-fontfamily);


  --component-summary-title-color:      var(--rtd-heading-color);
  --component-summary-title-font:       var(--widget-item-heading-font);
  --component-summary-description-font: var(--widget-item-description-font);
  --component-summary-padding-v:        22px;

  /*, .page--newsoverview .searchresults*/
  /*, .page--eventsoverview .searchresults*/
  --component-summary-columns:          var(--pagegrid-col-verywide);


  /* Quote widget */
  --rtd-quote-color:        var(--rtd-text-color);
  --rtd-quote-photosize:    157px;
  --rtd-quote-margin:       30px;
}

html.identity--designunited
{
  --whitespace-footer-content: 200px;

  --widget-cta-heading-font: bold 44px/50px var(--rtd-heading-fontfamily);

  --rtd-text-fontfamily:    Officina, Arial, Sans-Serif;

  --rtd-text-font:          20px/32px var(--rtd-text-fontfamily);
  --rtd-text-small-font:    17px/25px var(--rtd-text-fontfamily);

  /*--rtd-h1-font:            bold 62px/68px var(--rtd-heading-fontfamily);*/
  --rtd-h1-font:            bold clamp(32px,6vw,62px)/107% var(--rtd-heading-fontfamily); /* also .page-header__title */
}

html.page--search
{
  --component-summary-title-color:      var(--color-theme);
  --component-summary-title-font:       bold 22px/25px var(--rtd-heading-fontfamily);
  --component-summary-url-font:              13px      var(--rtd-text-fontfamily);
  /*--component-summary-description-font:      15px/22px var(--rtd-text-fontfamily);*/
  --component-summary-padding-v:        15px;
}


/*/////////////////////////////////////////////////////////////////////////////////////////////
//
// Tags
*/

html
{
  --component-taglist-gap-h: 9px;
  --component-taglist-gap-v: 7px;

  --component-tag-color:        var(--color-theme);
  --component-tag-hover-background: var(--color-theme);
  --component-tag-border-color: var(--color-theme);
  --component-tag-height:       32px;
  --component-tag-padding:      7px 12px 3px 12px;
  --component-tag-radius:       4px;
}

.ftue-member-card
{
  --component-tag-color:        var(--color-black-grey);
  --component-tag-hover-background: var(--color-black-grey);
  --component-tag-border-color: var(--color-black-grey);
  --component-tag-height:       29px;
  --component-tag-padding:      3px 14px 3px 14px;
  --component-tag-radius:       16px;
}
@media (max-width: 550px)
{
  .ftue-member-card
  {
    --component-tag-height:       27px;
    --component-tag-padding:      2px 10px 2px 10px;
    --component-tag-radius:       15px;
  }
}

.comp-togglebuttonbar
{
  --component-tag-background:   #FFFFFF;
  --component-tag-text-color:   var(--color-black-grey);
  --component-tag-border:       1px solid var(--color-black-grey);
  --component-tag-height:       29px;
  --component-tag-padding:      3px 14px 3px 14px;
  --component-tag-radius:       16px;

  --component-tag-font: 14px/17px var(--rtd-text-fontfamily);
  --component-tag-color: var(--color-black-grey);
  --component-tag-hover-background: var(--color-black-grey);
}

/* Widget settings */


/*/////////////////////////////////////////////////////////////////////////////////////////////
//
// Whitespace
*/


html
{
  /** Default whitespace between header and content
      In some cases (heading image followed by content with fullwidth color background)
      this margin will be removed.
  */
  --whitespace-header-content:  40px;
  --whitespace-header-backlink: 30px;
  --whitespace-footer-content: 80px;
  --grid_spacing:              30px;
  /*--rtdcontent_vspace: 15px;*/
  /*--rtdcontent_vspace_heading: 30px;*/


  --widget-panel-divider-color:  #DDDDDD; /* 979797 33% */


  --widget-generic-largetitle-margin: 50px; /* whitespace below the H1-styled title in widgets */

  /* White at the top and bottom of panels with an background color */
  --widget-generic-panel-padding-top:        50px;
  --widget-generic-panel-padding-bottom:     50px;
  --widget-generic-bigpanel-padding-top:     50px;
  --widget-generic-bigpanel-padding-bottom:  70px;

  --rtd-margin-greywithpadding-connected:   -20px; /* FIXME: needs to depend on whether the small or large padding size was used */
}

html.identity--designunited
{
  --whitespace-header-content: 80px;

  --pagetitle-inflow-color: #000000;

  /* White at the top and bottom of panels with an background color */
  --widget-generic-panel-padding-top:       100px;
  --widget-generic-panel-padding-bottom:    100px;
  --widget-generic-bigpanel-padding-top:    110px;
  --widget-generic-bigpanel-padding-bottom: 110px;

  --rtd-margin-greywithpadding-connected:   -45px; /* FIXME: needs to depend on whether the small or large padding size was used */
}

.widget-cta-video
{
  --widget-generic-panel-padding-top: 40px;
  --widget-generic-panel-padding-bottom: 40px;
}





html.identity--designunited.page--withbacklink
{
  --whitespace-header-content: 50px;
}




@media (max-width: 550px) /* $minwidth-desktop-fontsizes - 1px)*/
{
  html.identity--4tu
  {
    --rtd-h1-font:          bold 27px/30px var(--rtd-heading-fontfamily);
    --rtd-h1-subtitle-font:     25px/30px var(--rtd-heading-fontfamily); /* .page-header__subtitle for DU projects */
  }

  html.identity--designunited
  {
    --rtd-h1-font:          bold 32px/35px var(--rtd-heading-fontfamily);
    --rtd-h1-subtitle-font:      24px/30px var(--rtd-heading-fontfamily); /* .page-header__subtitle for DU projects */

    --widget-generic-largetitle-margin:        20px;

    --widget-generic-panel-padding-top:        40px;
    --widget-generic-panel-padding-bottom:     40px;
    --widget-generic-bigpanel-padding-top:     75px;
    --widget-generic-bigpanel-padding-bottom:  75px;

    --rtd-margin-greywithpadding-connected:   -35px; /* FIXME: needs to depend on whether the small or large padding size was used */
  }

    html.identity--4tu
  , html.identity--designunited
  {
    --whitespace-header-content: 40px;
    --whitespace-footer-content: 40px;

    --rtd-text-font:        16px/24px var(--rtd-text-fontfamily);
    --rtd-h2-font:          bold 19px/24px var(--rtd-heading-fontfamily);
    --rtd-h3-font:          bold 17px/24px var(--rtd-heading-fontfamily);
    --rtd-intro-font:       bold 17px/24px var(--rtd-heading-fontfamily);

    --widget-item-heading-font:          18px/24px var(--rtd-heading-fontfamily);
    --widget-item-description-font:      16px/24px var(--rtd-text-fontfamily);

    --widget-quote-withimage-font:        24px/30px var(--rtd-text-fontfamily);
    --widget-quote-withoutimage-font:     28px/35px var(--rtd-text-fontfamily);


    --widget-generic-largetitle-margin:        20px;

    --widget-generic-panel-padding-top:        40px;
    --widget-generic-panel-padding-bottom:     40px;
    --widget-generic-bigpanel-padding-top:     50px;
    --widget-generic-bigpanel-padding-bottom:  50px;

    --rtd-margin-greywithpadding-connected:   -50px; /* FIXME: needs to depend on whether the small or large padding size was used */
  }
}

body
{
  --icon-link-font:    400 14px 'Font Awesome 5 Pro';
  --icon-link-content: var(--fa-arrow-right);
  --icon-backlink-content:  var(--fa-arrow-left);

  --icon-look-down: var(--fa-arrow-down);

  --accordion-header-color:               var(--rtd-heading-color);
  --accordion-header-font:                var(--rtd-h3-font);
  --accordion-header-symbol-font:         200 16px 'Font Awesome 5 Pro';
  --accordion-header-symbol-color:        var(--color-black);
  --accordion-header-symbol-content:      var(--fa-plus);
  --accordion-header-symbol-open-color:   var(--color-black);
  --accordion-header-symbol-open-content: var(--fa-minus);
  --accordion-item-line-color:            #C5C5C5;
}

.colortheme--grey
{
  --theme-name: "lightgrey";
  --theme-ispale: no;

  --theme-background: var(--color-bggrey);

  --color-divider: var(--color-divider-on-grey);
  /*--theme-textcolor:  var(--rtd-text-color);*/
}

.colortheme--grey
{
/*
  --color-bggrey:      #FAF6F4; / * CTA / DU menubar / DU connect widget * /
  --color-black-grey:  #3F3F3F;

  --text-grey-large:    #ACACAC;
  --text-grey-small:    #8E8E8E; / * 20px Quote person name * /
*/

  /*--text-grey-large:    #ACACAC;*/
  --text-grey-small:    #3F3F3F;

}



.colortheme--darkblue
{
  --theme-name: "darkblue";
  --theme-ispale: no;

  --theme-background: var(--color-darkblue);
  --theme-background-transparent: var(--color-darkblue-transparent);
  --theme-textcolor:  #FFFFFF;

  --button-color: #FFFFFF;
  --button-contrastcolor: var(--color-darkblue);
}

.colortheme--orange
{
  --theme-name: "orange";
  --theme-ispale: no;

  --theme-background: var(--color-orange);
  --theme-background-transparent: var(--color-orange-transparent);
  --theme-textcolor:  #FFFFFF;

  --button-color: #FFFFFF;
  --button-contrastcolor: var(--color-orange);
}

.colortheme--red
{
  --theme-name: "red";
  --theme-ispale: no;

  --theme-background: var(--color-red);
  --theme-background-transparent: var(--color-red-transparent);
  --theme-textcolor:  #FFFFFF;

  --button-color: #FFFFFF;
  --button-contrastcolor: var(--color-red);
}

.colortheme--black
{
  --theme-name: "black";
  --theme-ispale: no;

  --theme-background: var(--color-black);
  --theme-background-transparent: var(--color-black-transparent);
  --theme-textcolor:  #FFFFFF;

  --button-color: #FFFFFF;
  --button-contrastcolor: var(--color-black);
}





html
{
  /*font-family: Officina, Arial, Sans-Serif;*/
  font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-family: var(--rtd-text-fontfamily);
  font-weight: normal;

  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  color: #4F4F4F;
  color: var(--rtd-text-color);
}

html.identity--designunited .widget-cta__title
{
  font: var(--widget-cta-heading-font);
}


/*
Notes on apple-data-detectors:
- Must use !important to override properties
- Can also create a anchor <a> around phone numbers: <a href="tel:" />
- The links are automatically added in the Safari app, but a webview within another app may not show them
*/
a[x-apple-data-detectors]
{
  color: #E1670B !important;
}

body
{
  background-color: #FFFFFF; /* required for WCAG compliance */
}

h1
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h1-font);
  margin: 0;
}

  h2
, .widget__title
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h2-font);
  margin: 0;
}

.widget__title
{
  font: var(--widget-title-font);
}

/* special option for Design United to have H2's with H1 styling */
h2.heading2large
{
  font-size: 48px;
  line-height: 50px;
}

  h3
, .wh-form__grouptitle
, .widget-accordion__item__header
, .projectform__texts .wh-form__label
{
  color: var(--rtd-heading-color);
  font: var(--rtd-h3-font);
  margin: 0;
}

  html
, .wh-rtd-embeddedobject /* otherwise Webhare will override our html font-size */
{
  font: var(--rtd-text-font);
}

  p.normal
, ol.ordered
, ul.unordered
{
  font: var(--rtd-text-font);
  color: #4F4F4F;
  color: var(--rtd-text-color);
}

p.intro
{
  font: var(--rtd-intro-font);
  color: #3F3F3F;
}

p.description
{
  font: bold 15px/26px var(--rtd-heading-fontfamily);
  color: #3F3F3F;
}

  .wh-form__fieldgroup--rtd .wh-rtd__body > p.normal
, .wh-form__fieldgroup--rtd .wh-rtd__body > ol.ordered
, .wh-form__fieldgroup--rtd .wh-rtd__body > ul.unordered
{
  color: #282828;
}

/* Placeholder text for .wh-rtd */
.wh-form__rtd--empty .wh-rtd__body::before
{
  /*color: $textcontrols_placeholdercolor;*/
  color: #999999;
}




.widget-ctablocks__title
{
  text-align: center;
}

/* Widgets with their own color background get a larger widget__title */
  .widget-cta__title
, .widget-ctablocks__title
, .widget-news__title
{
  font-size: 30px;
  line-height: 38px;
  text-transform: none;
}
.widget__subtitle
{
  font: 15px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

  .widget-accordion__title
, .widget-downloadsandlinks .widget__title
{
  margin-bottom: 15px;
}






  /*.rtdcontent a   <-- this is too generic and interferes with styling of widgets */
  p.intro a
, p.description a
, p.normal a
, ul.unordered a
, ol.ordered a
, .wh-form__richtext a
{
  color: var(--rtd-link-color);
/*  font-weight: bold;*/
/*  text-decoration: none;*/
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}



/*.rtdcontent a:hover*/
  p.intro a:hover
, p.description a:hover
, p.normal a:hover
, ul.unordered a:hover
, ol.ordered a:hover
{
  color: #F49120;
  color: var(--rtd-link-color-hover);
  text-decoration: underline;
}



sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}



.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}



/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}

ul.unordered
{
  padding-left: 18px;
}

ol.ordered
{
  padding-left: 16px;
}

ul li::marker
{
  color: var(--rtd-list-marker-color);
  font-size: 14px;
}

ol li::marker
{
  color: var(--rtd-list-marker-color);
  font-size:   14px;
  font-weight: bold;
}


/* NOTE: Safari 17 still doesn't support use of font and content in a ::marker
         Also the list items will be placed in the padding of the ul.
         So using flex and a li::before works much better. */
ul.person-details-associatedresearch
{
  list-style: none;
  margin: 0;
  padding: 0;

  line-height: 135%; /* instead of 160% for ul */
  margin-top: 10px !important; /* instead of 20px */
}
.person-details-associatedresearch li
{
  padding: 0;
  margin: 0;

  display: flex;
  align-items: baseline;

  padding-top: 3px;
  padding-bottom: 3px;
}

.person-details-associatedresearch li::before
{
  flex: none;
  color: var(--color-theme);
  content: var(--fa-chevron-right);
  font: var(--fontawesome-regular);

  width: 18px;
}



/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}



  .wh-video
, p.intro
, p.description
, p.normal
, ul.unordered
, ol.ordered
, .page__contentarea__meta + .wh-form--webtoolform
{
  margin-top:    15px;
  margin-top:    var(--rtd-spacing-paragraph);
  margin-bottom: 0;
}

p.intro + *
{
  margin-top: 35px;
  margin-top: var(--rtd-spacing-intro-after);
}
p.intro + p.intro
{
  margin-top: 15px;
  margin-top: var(--rtd-spacing-paragraph);
}

.widget
{
  margin-top: 18px;
}

.widget + *
{
  margin-top: 18px;
}

  h2.heading2
, .widget--withtitle
{
  margin-top: 38px;
}

.widget--opaque
{
  margin-top: 45px;
}

.widget-quote--withoutimage
{
  margin-top:    45px;
  margin-bottom: 45px;
}

h3.heading3
{
  margin-top: 30px;
}

@media (max-width: 550px) /* $minwidth-desktop-fontsizes - 1px)*/
{
  .widget
  {
    margin-top: 15px;
  }

  .widget + *
  {
    margin-top: 15px;
  }

    h2.heading2
  , .widget--withtitle
  {
    margin-top: 35px;
  }

  h3.heading3
  {
    margin-top: 25px;
  }
}



  h2.heading2 + .wh-video
, h2.heading2 + p.intro
, h2.heading2 + p.description
, h2.heading2 + p.normal
, h2.heading2 + ul.unordered
, h2.heading2 + ol.ordered
{
  margin-top: 20px;
}



/* A widget which has distinct edges
   (either lines or it has it's own box/full background)
   requires some more margin so content below it doesn't
   feel cramped.
*/
  .widget--opaque.widget--opaque + *:not(.widget--divider)
, .widget--opaque.widget--opaque + .divider--inactive + *:not(.widget--includespadding) /* with inactive divider inbetween */
{
  margin-top: var(--widget-generic-panel-padding-bottom);
}

  .widget--includespadding + .widget--includespadding
, .widget--includespadding + .divider--inactive + .widget--includespadding
{
  margin-top: 0;
}




  .divider--white + *
, .divider--grey + *
{
  margin-top: 0;
}




/* A widget which already adds padding around
   it's (textual) content.
*/
.widget--includespadding.widget--includespadding + .widget--includespadding
{
  margin-top: 0;
}

/* Connect grey backgrounds together
   (and deduplicate the grey padding)
*/
.widget--includespadding.colortheme--grey + .widget--includespadding.colortheme--grey
{
  margin-top: var(--rtd-margin-greywithpadding-connected);
  --widget-generic-panel-padding-top: 0px;
  --widget-generic-bigpanel-padding-top: 0px;
}



/*
widget-colorblock + widget-colorblock -> single white line
white + white -> single grey line
*/
.widget--includespadding + .widget--divider
{
  margin-top: 0;
}




.page-contentstart
{
  display: none;
}




.page-readtime
{
  margin-top: var(--whitespace-header-content);
}



/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}

  .wh-rtd > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}




.neotabs
{
  /*--whitespace-header-content: 0px;*/
}

.filteredoverview__resultsanchor
{
  grid-column: 1 / -1; /* otherwise the margin-top won't work if .page-contentstart + * matches on this element */
}

.deeplinks-wrapper
{
  margin-top: 0 !important; /* it's .deeplink child must use the margin otherwise the grid-row will be stretched by this element */
}

.edition-eventtabs
{
  --whitespace-header-content: 0;
}


/* The first RTD item in contentarea gets the margin-top to distance it from the header.
   Applying of the whitespace to the deeplinks is done in grid-layout.scss because it
   is dependant on media queries which use SASS variables.
*/
  .page__contentarea--rtddoc      > *:first-child
, .page__contentarea--rtddoc      > .page-contentstart + *
, .page__contentarea--formwebtool > .page-contentstart + *
, .page__contentarea--iscustom    > .page-contentstart + *
, .page-contentstart + .blogitem-bodycontent > :first-child
, .page-contentstart + .blogitem-bodycontent + .blogitem-metadata-panel > :first-child
{
  margin-top: var(--whitespace-header-content);
}

.blogitem-metadata-panel.blogitem-metadata-panel::before
{
  top: var(--whitespace-header-content);
}



/* However... cancel the margin (connect the element to the header)
   if the first item has it's own (background-color) panel with padding */
  .page__contentarea--rtddoc > .widget--includespadding:first-child
, .page__contentarea--rtddoc > .page-contentstart + .widget--includespadding
{
  margin-top: 0;
  border-top: 1px solid rgba(0,0,0,0.2);
}

/* Content such as tabs (for edition page) which MUST always connect to the header */
/*
.page__contentarea--rtddoc > .page-contentstart + .connecttoheader
{
  --whitespace-header-content: 0;
}
*/

/* If the header is disabled AND the "Connect" widget is used
   as first item in the contentarea, make it have the
   height of a side-by-side header.
*/
  .page__contentarea--rtddoc > .widget-richcontent-with-links:first-child
, .page__contentarea--rtddoc > .page-contentstart + .widget-richcontent-with-links
{
  min-height: var(--pageheader-sidebyside-minheight);
}






/* Make the last contentarea item stick to the bottom
   if it has it's own (grey/color) panel with padding.
*/
.page__contentarea > .widget--includespadding:last-child
{
  margin-bottom: 0;
}

  .page__contentarea .page__balloon
/*, .widget--includespadding.edition-event__colofon + .page__balloon*/
, .edition-event + .page__balloon
{
  /* override
     - .widget + *
     - .widget--opaque.widget--opaque + *:not(.widget--divider)
  */
  margin-top: 0 !important; /* override selectors such as ".widget + *" setting a margin */
  height: var(--whitespace-footer-content);

  grid-column: var(--pagegrid-col-fullwidth);
}



/* Make the fillter element and page footer (with breadcrums navigation)
   take on the same color as the last widget. */
  .widget--includespadding.colortheme--grey + .page__balloon
, .widget--includespadding.colortheme--grey + .page__balloon + .page__footer
{
  background-color: var(--color-bggrey);
}


/* stick to either the crumble path, newsletter subscribe bar (DU) or footerlinks panel */
.widget--sticktofooter + .page__balloon
{
  --whitespace-footer-content: 0;
}
.widget--sticktofooter + .page__balloon + .page__footer .page__footer__content.navpath
{
  padding-top: 12px;
}


/* These widgets (in specified conditions) use a bigger padding,
   which means the footer should use a small margin to get the same distance
   to the last content.

  .widget-cta.colortheme--grey + .page__balloon
, .widget--featuredpages.widget--includespadding + .page__balloon
, .widget-richcontent-with-links.widget--includespadding + .page__balloon
, .projectsviewer.widget--includespadding.colortheme--grey + .page__balloon
*/
  .widget--includespadding.colortheme--grey + .page__balloon
{
  --whitespace-footer-content: 85px;
}

/* .widget--background-grey
FIXME: widget should use a class indicating whether they use the small or large whitespace */
  .widget-ctablocks.widget--includespadding.colortheme--grey + .page__balloon
, .widget-neo.widget--includespadding.colortheme--grey + .page__balloon
, .widget-persons.widget--includespadding.colortheme--grey + .page__balloon
, .widget-richcontent-with-links.widget--includespadding.colortheme--grey + .page__balloon
{
  --whitespace-footer-content: 150px;
}




html
{
  /* Social networks */
  --fa-twitch: "\f1e8";
  --fa-twitter: "\f099";
  --fa-twitter-square: "\f081";
  --fa-facebook: "\f09a";
  --fa-facebook-f: "\f39e";
  --fa-facebook-messenger: "\f39f";
  --fa-facebook-square: "\f082";
  --fa-linkedin: "\f08c";
  --fa-linkedin-in: "\f0e1";
  --fa-instagram: "\f16d";
  --fa-whatsapp: "\f232";
  --fa-youtube:   "\f167";

  --fa-exclamation-triangle: "\f071";

  --fa-play: "\f04b";
  /*--fa-play-circle: "f144";*/


  --fa-search: "\f002";

  --fa-minus: "\f068";
  --fa-plus:  "\f067";

  --fa-external-link: "\f08e";
  --fa-external-link-alt: "\f35d";


  --fa-arrow-left:    "\f060";
  --fa-arrow-right:   "\f061";
  --fa-arrow-down:    "\f063";

  --fa-arrow-from-left: "\f343";
  --fa-arrow-to-right: "\f340";

  /* Angle */
  --fa-angle-left:    "\f104";
  --fa-angle-right:   "\f105";
  --fa-angle-up:      "\f106";
  --fa-angle-down:    "\f107";

  /* Time related */
  --fa-stopwatch:     "\f2f2";
  --fa-calendar:      "\f133";
  --fa-calendar-alt:  "\f073";
  --fa-calendar-day:  "\f783";

  --fa-check:         "\f00c";

  --fa-clock:         "\f017";

  --fa-arrow-circle-down: "\f0ab";

  /* Chevron */
  --fa-chevron-left:  "\f053";
  --fa-chevron-right: "\f054";
  --fa-chevron-up:    "\f077";
  --fa-chevron-down:  "\f078";

  --fa-chevron-double-left: "\f323";
  --fa-chevron-double-right: "\f324";

  --fa-cross:         "\f654";

  /* Circle */
  --fa-arrow-circle-left:  "\f0a8";
  --fa-arrow-circle-right: "\f0a9";
  --fa-info-circle: "\f05a";
  --fa-plus-circle: "\f055";
  --fa-minus-circle: "\f056";
  --fa-times-circle: "\f057";

  --fa-arrow-alt-from-left: "\f347";

  --fa-times:         "\f00d";

  --fa-user:  "\f007";

  --fa-power-off: "\f011";

  --fa-trash-alt: "\f2ed";

  --fa-pencil: "\f040";

  --fa-browser: "\f37e";

  /* --fa-project-diagram: "\f542"; */
  --fa-flask: "\f0c3";

  --fa-envelope: "\f0e0";
  --fa-print: "\f02f";

  --fa-long-arrow-right: "\f178";

  --fa-caret-down: "\f0d7";
  --fa-caret-up:   "\f0d8";

  --fa-link: "\f0c1";
}
