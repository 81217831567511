/*
Also used in actaverba, JuisteZorg
spc-forms-textual-input 2021 version
/shared/forms/textual-input.scss

2024: Added wh-form-imgedit and wh-form-upload styling
*/

input[type='search']
{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
}

input::placeholder /* Chrome 57, Edge 12, Firefox 51, Opera 44, Safari 10.1, iOS 10.3 */
{
  opacity: 1;
  color: var(--textcontrols_placeholdercolor);
}

  :disabled input::placeholder
, [data-wh-form-disabled] input::placeholder
{
  opacity: 1;
  color: var(--textcontrols_disabled_placeholder);
}



  input[type='text']
, input[type='url']
, input[type='email']
, input[type='password']
, input[type='number']
, input[type='date']
, input[type='time']
, input[type='search']
, .wh-form__dateinputgroup
, .wh-form__timeinputgroup
, textarea
, select:not(.wh-rtd__toolbarstyle)
, .multiselect__panel__trigger /* SPC */
{
  padding-left:  var(--textcontrols_padleft);
  padding-right: var(--textcontrols_padright);

  font: var(--textcontrols-font);
  height: var(--formcontrols-height);
}



wh-form-imgedit
{
  --wh-form-imgedit-edge-distance: 8px;
}

wh-form-imgedit::part(deletebutton)::before
{
  outline: 1px solid #F00;
}



wh-form-upload
{
  flex: 1 1 auto;
  height: var(--formcontrols-height);
}

wh-form-upload::part(filename)
{
  padding-left:  var(--textcontrols_padleft);
  padding-right: var(--textcontrols_padright);
  font: var(--textcontrols-font);

  background: #F8F8F8;
  border: 1px solid #999999;
  color: #777777;
}

wh-form-upload::part(filename):focus-within
{
}


wh-form-upload::part(button)
{
  color: var(--button-color);
  height: 100%;
  box-sizing: border-box;
}

.wh-form__arrayrow wh-form-upload::part(deletebutton)
{
  display: none;
}

wh-form-upload::part(selectbutton)
{
/*  outline: 1px solid #F00;*/
}

wh-form-upload::part(deletebutton)
{
/*  outline: 1px solid #00A;*/
}

/*
wh-form-upload::part(deletebutton)::before
{
  outline: 1px solid #000;
  font: var(--fontawesome-regular);
  content: var(--fa-trash);
  color: inherit;
}

wh-form-upload::part(deletebutton):hover
{
  background: var(--color-theme);
  color: #FFFFFF !important;
}
*/



textarea
{
  flex: 1 0 0px;
  padding-right: var(--textcontrols_padleft);
}



/* These components only get the focus styling
   (they handle the borders and disabled styling themselves)
*/
  .wh-form__rtd:focus-within
, .wh-form__dateinputgroup:focus-within
, .wh-form__timeinputgroup:focus-within
{
  /*@include textfield-focusstyling;*/
  /*@mixin textfield-focusstyling*/
  border-color: var(--textcontrols_focus_bordercolor);
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40,52,119,0.15) !important;
}

  .wh-form__dateinputgroup__line.wh-form__dateinputgroup__line input:focus
, .wh-form__timeinputgroup__line.wh-form__timeinputgroup__line input:focus
{
  border: 0;
  outline: 0;
  box-shadow: none;
  background-color: #F0F0F0;
}
