/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*

iOS style switch
also see: https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/;

Available SASS variables:

$checkbox_switch_borderwidth:   3px;
$checkbox_switch_track_width:  45px;
$checkbox_switch_track_height: 24px;
$checkbox_switch_track_color:  #A4A4A4;
$checkbox_switch_track_color-active: $color-blue;
$checkbox_switch_thumb_size:   18px;
$checkbox_switch_thumb_color:  #FFFFFF;


Example usage:

<input id="mytoggle" type="checkbox" class="spc-toggle" /><label for="mytoggle">[! placeholder for custom styling !]</label><label for="mytoggle">Toggle active</label>

*/
input[type=checkbox].spc-toggle {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

input[type=checkbox].spc-toggle + label + label {
  vertical-align: middle;
  user-select: none;
}

input[type=checkbox].spc-toggle + label {
  flex: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 55px;
  min-width: 55px;
  height: 24px;
  border-radius: 12px;
  background-color: #A4A4A4;
  border: 3px solid #A4A4A4;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

html[data-whatinput=keyboard] input[type=checkbox].spc-toggle:focus + label {
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}

input[type=checkbox].spc-toggle + label::before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -9px;
  height: 18px;
  left: 1px;
  width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transition: left 0.2s, background-color 0.2s, border-color 0.2s; /* iOS 6 */
  transition: left 0.2s, background-color 0.2s, border-color 0.2s;
}

/* OFF */
input[type=checkbox].spc-toggle.spc-toggle + label:hover {
  border-color: #A4A4A4;
}

/* ON */
input[type=checkbox].spc-toggle.spc-toggle:checked + label {
  background-color: var(--color-orange);
  border-color: var(--color-orange);
}

/* Disabled */
input[type=checkbox].spc-toggle[disabled] + label {
  background-color: #dcddde;
}

input[type=checkbox].spc-toggle[disabled] + label::before {
  display: none;
}

input[type=checkbox].spc-toggle:checked + label::before {
  left: calc(100% - 18px - 1px);
}