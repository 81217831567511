/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.fourtu-texteditarray__add {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
}
.fourtu-texteditarray__add:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.fourtu-texteditarray__add .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}
.fourtu-texteditarray__add::before {
  content: "Add website";
}
.fourtu-texteditarray__add::after {
  margin-left: 35px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f055";
  font-size: 18px;
  vertical-align: middle;
}

.fourtu-texteditarray__inputholder {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.fourtu-texteditarray__row {
  align-items: center;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.fourtu-texteditarray__rowdelete {
  cursor: pointer;
  flex: none;
  font-size: 20px;
  width: 23px;
  height: 23px;
  padding-left: 5px;
}
.fourtu-texteditarray__rowdelete::after {
  font: var(--formcontrol-icon-delete-font);
  content: var(--formcontrol-icon-delete-icon);
}

.fourtu-texteditarray {
  /*
    &[data-row-count = "0"]
    {
      .wh-form__fields
      {
        display: none;
      }
    }
  */
}
.fourtu-texteditarray .wh-form__fieldline > .wh-form__textinput {
  display: none;
}