/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.footer {
  position: relative;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.footer__panel {
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: var(--color-almond-dark);
  --rtd-link-color: currentColor; /* inherit the color (use we use inherit the CSS variable (themecolor) will be inherited) */
  --rtd-link-color-hover: currentColor;
}

.footer__identity {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
}

.footer__organizationtitle {
  color: var(--footerlogo-colortint);
}

.footer__sitetitle {
  color: #787878;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer input[name=footercolumn] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

.footer__column__heading {
  display: block;
  margin-bottom: 7px;
}

.footer__column1 li + li {
  margin-top: 5px;
}

.footer__column1 a,
.footer__column__heading {
  font: bold 15px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  color: var(--footer-heading-color);
}

.footer__column2 .rtdcontent a {
  font-weight: normal;
  text-decoration: none;
}

.footer__column2 .rtdcontent > *,
.footer__column3 .rtdcontent > * {
  color: var(--footer-text-color);
  font-size: 14px;
  line-height: 30px;
}

.footer__column1 ul,
.footer__column1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__bottombar {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.footer__bottombar__menu {
  flex: none;
  margin-left: 30px;
}

.footer__bottombar__copyright {
  margin-right: 25px;
}

.footer__bottombar__copyright a {
  text-decoration: underline;
}

.fbcpart {
  display: inline-block;
}

.footer__bottombar__copyright a:hover {
  color: var(--color-theme);
}

.footer__bottombar__menu {
  display: flex;
  margin-left: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__bottombar__menu li + li {
  margin-left: 20px;
}

.footer__socialitems {
  display: flex;
}

a.footer__socialitem {
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  color: #FFFFFF;
  background-color: var(--color-theme);
  border-radius: 50%;
}
a.footer__socialitem .fa-facebook-f {
  font-size: 20px;
}
a.footer__socialitem .fa-instagram {
  font-size: 20px;
}
a.footer__socialitem .fa-linkedin-in {
  font-size: 20px;
}
a.footer__socialitem .fa-twitter {
  font-size: 20px;
}
a.footer__socialitem .fa-youtube {
  font-size: 18px;
}

a.footer__socialitem:focus,
a.footer__socialitem:hover {
  color: var(--color-theme);
  background-color: #FFFFFF;
}

.footer__socialitem + .footer__socialitem {
  margin-left: 11px;
}

.footer__socialitems + h3 {
  margin-top: 40px;
}

.footer__newslettersignup {
  position: relative;
  display: flex;
}

.footer__newslettersignup input {
  border: 0;
  background-color: rgba(255, 255, 255, 0.54);
  width: 100%;
  height: 38px;
  padding-left: 11px;
  padding-right: 48px;
  font: 14px var(--rtd-text-fontfamily);
}
.footer__newslettersignup input::placeholder {
  opacity: 1;
  color: rgba(57, 57, 57, 0.45);
}

.footer__newslettersignup__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 48px;
  margin-left: -48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer__newslettersignup__submit__icon {
  color: var(--color-theme);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__divider {
  border: 0;
  border-top: 1px solid #8E8E8E;
}

.footer__bottombar__logo {
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  margin-left: auto;
}
@media (max-width: 600px) {
  .footer__bottombar__logo {
    display: none;
  }
}

.footer__newslettersignup__success {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity 0.25s;
}

html.subscribedtonewsletter .footer__newslettersignup {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
html.subscribedtonewsletter .footer__newslettersignup__success {
  visibility: visible;
  height: auto;
  opacity: 1;
  padding-top: 15px;
}

a.footer__partners__item {
  color: var(--footer-heading-color);
}

@media (max-width: 599px) {
  .footer__partners__items {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 17px;
    row-gap: 17px;
  }
  .footer__partners__items > :nth-child(even) {
    align-self: center;
    justify-self: end;
  }
}
@media (min-width: 600px) {
  .footer__partners {
    grid-column: var(--pagegrid-col-verywide);
  }
  .footer__partners__item + .footer__partners__item {
    margin-left: 35px;
  }
}
@media (max-width: 799px) {
  .footer__panel {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .footer__panel > * {
    padding-left: var(--pagegrid-gutteroutside);
    padding-right: var(--pagegrid-gutteroutside);
  }
  .footer__organizationtitle,
  .footer__sitetitle {
    font: bold 28px/34px var(--rtd-heading-fontfamily);
  }
  .footer__mainmenu,
  .footer__bottombar__menu {
    display: none;
  }
  .footer__column__heading {
    display: block;
    padding-top: 5px;
    padding-bottom: 3px;
    margin-bottom: 0;
  }
  .footer__column2,
  .footer__column3,
  .footer__socialitems__group,
  .footer__newsletter__group {
    margin-top: 20px;
  }
  .footer__newsletter__group {
    margin-bottom: 12px;
  }
  .footer__mainmenu a,
  .footer__column__heading {
    font: bold 15px var(--rtd-heading-fontfamily);
  }
  .footer__mainmenu a {
    padding: 1px 0;
  }
  .footer__divider {
    margin-left: var(--pagegrid-gutteroutside);
    margin-right: var(--pagegrid-gutteroutside);
    margin-top: 24px;
    margin-bottom: 18px;
  }
  .footer__bottombar {
    height: 41px;
  }
  label[for=footer__column3__expand] {
    cursor: pointer;
    display: flex;
  }
  input + label[for=footer__column3__expand]::after {
    margin-left: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f055";
    font-size: 16px;
  }
  input:checked + label[for=footer__column3__expand]::after {
    content: "\f056";
  }
  .footer__column3 .footer__column__content {
    display: none;
  }
  .footer__column3 input:checked ~ .footer__column__content {
    display: block;
  }
}
@media (max-width: 500px) {
  .footer__divider {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 800px) {
  .footer input[name=footercolumn] {
    display: none;
  }
  .footer__panel {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    padding-top: 35px;
    padding-bottom: 30px;
  }
  .footer__organizationtitle,
  .footer__sitetitle {
    font: bold 36px/38px var(--rtd-heading-fontfamily);
  }
  .footer__mainmenu a,
  .footer__column__heading {
    font: bold 16px var(--rtd-heading-fontfamily);
  }
  .footer__column1 a {
    padding: 1px 0;
  }
  .footer__divider {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 4;
  }
  .footer__explore {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 5;
  }
  .footer__partners {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 6;
  }
  .footer__divider {
    margin-top: 30px;
    margin-bottom: 27px;
    width: 100%;
  }
}
@media (min-width: 800px) and (max-width: 1023px) {
  .footer__identity {
    margin-bottom: 25px;
  }
  .footer__identity {
    grid-row: 1;
  }
  .footer__mainmenu {
    display: none;
  }
  .footer__column2 {
    grid-column: var(--pagegrid-col-verywide-left);
    grid-row: 2;
  }
  .footer__column3 {
    grid-column: var(--pagegrid-col-verywide-left);
    grid-row: 3;
  }
  .footer__column4 {
    display: contents;
  }
  .footer__newsletter__group,
  .footer__socialitems__group {
    grid-column: var(--pagegrid-col-verywide-right);
  }
  .footer__socialitems__group {
    grid-row: 2;
  }
  .footer__column3,
  .footer__newsletter__group {
    margin-top: 30px;
  }
  .footer__newsletter__group {
    grid-row: 3;
  }
  .footer__socialitems {
    margin-bottom: 30px;
  }
  .footer__bottombar {
    height: 45px;
  }
}
@media (min-width: 950px) {
  .footer__explore {
    display: none;
  }
}
@media (min-width: 1024px) {
  .footer__identity {
    margin-bottom: 25px;
  }
  .footer__column1 {
    grid-column: 3/span 5;
  }
  .footer__column2 {
    grid-column: 9/span 5;
  }
  .footer__column3 {
    grid-column: 15/span 5;
  }
  .footer__column4 {
    grid-column: 21/span 5;
    display: flex;
    flex-direction: column;
  }
  .footer__socialitems {
    margin-bottom: 30px;
  }
  .footer__column__heading--newsletter {
    margin-top: auto;
  }
  .footer__explore {
    display: none;
  }
  .footer__partners {
    text-align: center;
  }
  .footer__bottombar {
    height: 45px;
  }
}
details.footer__explore {
  margin-top: 20px;
  margin-bottom: 20px;
}
details.footer__explore summary {
  list-style-type: none;
}
details.footer__explore summary::-webkit-details-marker {
  display: none;
}
details.footer__explore summary::marker {
  display: none;
}

details.footer__explore summary {
  font: 16px var(--rtd-heading-fontfamily);
  color: #3F3F3F;
  cursor: pointer;
}

details.footer__explore summary:hover {
  color: var(--color-theme);
}

.footer__explore__name {
  text-decoration: underline;
}

.footer__explorepanel {
  padding-bottom: 10px;
}

.footer__explore__toggle__opentext {
  display: none;
}

.footer__explore[open] .footer__explore__toggle__opentext {
  display: flex;
}

.footer__explore[open] .footer__explore__toggle__closedtext {
  display: none;
}

.footer__toggle__opentext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__explorepanel__close {
  font: 16px var(--rtd-heading-fontfamily);
  padding: 5px;
  margin-left: -5px;
  margin-top: -5px;
}

.footer__explorepanel__close::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00d";
  font-size: 18px;
  margin-top: -1px;
  width: 32px;
}

.footer__explorepanel__category {
  margin-left: 32px;
  margin-top: 20px;
}

.footer__explorepanel__header {
  color: #E1670B;
  font: bold 15px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  margin-bottom: 5px;
}

.footer__explorepanel__items a {
  display: flex;
  align-items: baseline;
  max-width: max-content;
  font: 14px var(--rtd-heading-fontfamily);
  padding: 3px 0;
}

.footer__explorepanel__items a::before {
  margin-top: 1px;
  margin-right: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f178";
  font-size: 14px;
}

.footer__explorepanel__items a:hover {
  color: var(--color-theme);
}