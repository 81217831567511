.acoifilterpage-filters__filters .filterpanel + .filterpanel {
  margin-top: var(--filterpage-filtergroup-spacing);
}

/* Visual grouping of one or more .filterpanel's */
.filtergroup {
  background: var(--contentpanels-background);
  border-radius: var(--contentpanels-radius);
  box-shadow: var(--common-itemblocks-box-shadow);
  padding: var(--filterpage-filtergroup-padding);
  padding-left: 0;
  padding-right: 0;
}

.filterpanel__title {
  margin: var(--filterpage-filtergroup-padding);
  margin-top: 0;
  margin-bottom: 8px;
  font: var(--tagpanel-heading-font);
}

.filterpanel__options {
  padding: var(--filterpage-filtergroup-padding);
  padding-top: 0;
  padding-bottom: 0;
}

.optionlist__item {
  break-inside: avoid;
}

.tagcategory {
  --textcontrols-font: var(--tagpanel-option-font);
}

.filterpanel--pulldown select,
.acoifilterpage-filterdialog select,
.spc-multiselect,
.multiselect__summary {
  width: -webkit-fill-available;
}

.acoifilterpage-filters__filters .spc-multiselect, .acoifilterpage-filters__filters .multiselect__summary,
.acoifilterpage-filters__filters select,
.acoifilterpage-filterdialog .spc-multiselect,
.acoifilterpage-filterdialog .multiselect__summary,
.acoifilterpage-filterdialog select {
  margin: var(--filterpage-filtergroup-padding);
  margin-top: 0;
  margin-bottom: 0;
}

.multiselect__summary {
  margin-top: 10px;
}

.multiselect__summary:empty {
  display: none;
}

.tagcategory__title {
  color: var(--tagpanel-heading-color);
  font: var(--tagpanel-heading-font);
  margin-bottom: var(--tagpanel-heading-margin);
  padding-bottom: 0px;
}

.site-acoi .tagcategory__title {
  text-transform: uppercase;
}

.checkboxlist__option,
.radiolist__option {
  display: flex;
  align-items: baseline;
}

.checkboxlist__option,
.radiolist__option {
  color: var(--rtd-text-color);
  font: var(--tagpanel-option-font);
}

.checkboxlist__option + .checkboxlist__option,
.radiolist__option + .radiolist__option {
  margin-top: var(--tagpanel-option-spacing);
}

.filterbar__option + .filterbar__option,
.multiselect__panel__item + .multiselect__panel__item {
  margin-top: 15px;
}

/* FIXME: remove ? */
.filterbar__option {
  display: flex;
}

.badge,
.multiselect__summaryitem__badge {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #E6E6E6;
  padding: 0 10px;
  height: 20px;
  border-radius: 10px;
  text-align: right;
  font: 12px var(--rtd-text-fontfamily);
  color: #333333;
}

input:checked ~ .badge {
  background-color: var(--color-theme);
  color: #FFFFFF;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option {
  display: flex;
  align-items: baseline;
}

.radiolist__option label,
.checkboxlist__option label {
  cursor: pointer;
}

.checkboxlist__option > input + label,
.radiolist__option > input + label {
  padding-left: var(--tagpanel-checkradio-spacing);
  padding-top: var(--tagpanel-option-pad-top);
  padding-bottom: var(--tagpanel-option-pad-bottom);
}

/*
button[type="reset"]
{
  padding: 0;
  margin: 0;
  border: 0;
  background: none;

  cursor: pointer;

  text-decoration: underline;

  // margin-top: 15px;

  padding: 3px 0px;

  font: 15px var(--rtd-text-fontfamily);
  color: var(--rtd-link-color);
}
*/
a.downloadresultsbutton {
  flex: none;
  display: flex;
  color: var(--rtd-link-color);
  text-decoration: underline;
}

a.downloadresultsbutton::after {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background: var(--color-purple-cta-background);
  color: #FFFFFF;
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-to-bottom);
}

a.downloadresultsbutton:hover::after {
  background: var(--color-purple-cta-background-hover);
}

.filterpanel--showmore {
  --filterpage-filtergroup-spacing: 28px;
  text-align: center;
}

.filterpanel__showmorebutton[hidden] {
  display: none;
}

.filterpanel__showmorebutton {
  --button-height: var(--formcontrols-height) !important;
  --button-border: 1px solid var(--color-purple1) !important;
  --button-font: 15px var(--rtd-text-font) !important;
  font-size: 15px;
  height: 40px;
  text-align: center;
  margin: var(--filterpage-filtergroup-padding);
  margin-top: 0;
  margin-bottom: 0;
  padding-left: var(--button-padding-side-withicon);
}

.filterpanel__showmorebutton::before {
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-circle-down);
  font-size: 18px;
  margin-right: 10px;
}

.filterpanel__showmorebutton:hover {
  text-decoration: none;
}

.filterpanel__showmorebutton:hover span {
  text-decoration: underline;
}

.multiselect__panel__clear {
  font-size: 16px;
}

.multiselect__panel__ok {
  margin-left: 25px;
  --button-height: 30px;
  --button-padding-v: 2px;
  --button-font: 16px var(--rtd-text-font) !important;
  font-size: 16px;
}