.infobutton:focus + .infobutton-panel,
.infobutton + .infobutton-panel:hover {
  opacity: 1;
}

.infobutton-wrapper {
  position: relative;
}

.checkboxlist__option .infobutton-wrapper {
  margin-left: auto;
  padding-left: 15px;
}

.infobutton {
  cursor: pointer;
  color: var(--color-theme);
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  /*
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
  */
  padding: 5px;
  margin: -5px;
  font-size: 18px;
}

.infobutton-wrapper {
  flex: none;
  margin-left: auto;
}

.infobutton-panel {
  z-index: 11;
  position: absolute;
  bottom: 20px;
  right: -118px;
  color: #000000;
  font: 15px "Open Sans";
  padding-bottom: var(--infopopup-arrowsize);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
  cursor: pointer;
}

.infobutton-panel__content {
  position: relative;
  width: max-content;
  min-width: 200px;
  max-width: 340px;
  background-color: #FFFFFF;
  outline: 1px solid var(--infopopup-bordercolor);
  border-radius: 3px;
  padding: 15px;
  text-transform: none;
}

.infobutton-panel__content.infobutton-panel__content > * {
  font: 15px/20px var(--rtd-text-fontfamily);
}

.infobutton-panel__content:after,
.infobutton-panel__content:before {
  top: 100%;
  right: 120px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.infobutton-panel__content:after {
  border-color: transparent;
  border-top-color: var(--infopopup-backgroundcolor);
  border-width: var(--infopopup-arrowsize);
  margin-left: calc(0 - var(--infopopup-arrowsize));
}

.infobutton-panel__content:before {
  border-color: transparent;
  border-top-color: var(--infopopup-bordercolor);
  border-width: calc(var(--infopopup-arrowsize) + 1px);
  margin-left: calc(0 - var(--infopopup-arrowsize) - 1px);
}