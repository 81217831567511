@charset "UTF-8";
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.edition-eventtabs input {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

@media (min-width: 768px) {
  /* the outside container creates the fullwidth line */
  .edition-eventtabs {
    --whitespace-header-content: 36px;
    --color-eventborders: #AAAAAA;
    grid-column: var(--pagegrid-col-fullwidth);
    background-color: #FFFFFF;
    border-bottom: 1px solid var(--color-eventborders); /* #00F47F; */
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .edition-eventtabs__items {
    grid-column: var(--pagegrid-col-verywide);
    height: 92px;
    display: flex;
  }
  .edition-eventtab {
    display: inline-flex;
    flex-direction: column;
    justify-items: center;
    width: 269px; /* preferred width */
    padding-top: 17px;
    padding-bottom: 27px; /* 30px - 3px */
    padding-left: 15px;
    padding-right: 15px;
    color: #AAAAAA;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    user-select: none;
  }
  .edition-eventtab ~ .edition-eventtab {
    border-left: 1px solid var(--color-eventborders);
  }
  .edition-eventtab__abovetitle {
    font: 15px/17px var(--rtd-text-fontfamily);
    margin-bottom: 10px;
  }
  /* ensure we always have content in the line so it doesn't collapse */
  .edition-eventtab__abovetitle::before {
    display: inline;
    content: "​"; /* zero width space */
  }
  .edition-eventtab__title {
    font: 20px/22px var(--rtd-text-fontfamily);
  }
  .edition-eventtab:hover {
    background-color: #FAFAFA;
    color: #3F3F3F;
  }
  input:checked + .edition-eventtab {
    color: #3F3F3F;
    border-bottom: 3px solid var(--color-theme);
  }
  html.use-edition-themecolor input:checked + .edition-eventtab {
    --color-theme: var(--edition-themecolor);
  }
}
@media (max-width: 769px) {
  html.page--edition .page__body {
    background: #FAFBFF;
  }
  .edition-eventtabs {
    margin-top: 0 !important;
    grid-column: 1/-1;
    position: -webkit-sticky;
    position: sticky;
    top: var(--siteheader-height-approximation);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 25%, rgba(255, 255, 255, 0) 100%);
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: var(--zindex-overlap-contentarea);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    /*
        width: calc(100% - 60px);
        margin-left: auto;
        margin-right: auto;
    */
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* without this snappoints don't seem to work correctly on iOS */
  }
  .edition-eventtabs::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  /*
    .edition-eventtabs::before
    {
      content: "";
      position: absolute;
      top: 0;
      bottom: -100px;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    }
  */
  .edition-eventtabs__items {
    grid-column: 3/27;
    z-index: 5;
    display: flex;
    column-gap: 30px;
  }
  .edition-eventtab {
    background-color: #EEEEEE;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 14px 20px 6px 20px;
    flex: none;
    cursor: pointer;
  }
  .edition-eventtab + .edition-eventtab {
    margin-left: 30px;
  }
  .edition-eventtab__abovetitle {
    font: 15px/17px var(--rtd-text-fontfamily);
    margin-bottom: 10px;
  }
  /* ensure we always have content in the line so it doesn't collapse */
  .edition-eventtab__abovetitle::before {
    display: inline;
    content: "​"; /* zero width space */
  }
  .edition-eventtab__title {
    font: 20px/22px var(--rtd-text-fontfamily);
  }
  .edition-eventtab:hover {
    background-color: #DDDDDD;
    color: #3F3F3F;
  }
  input:checked + .edition-eventtab {
    color: #3F3F3F;
    border-bottom: 3px solid var(--color-theme);
  }
}