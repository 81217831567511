/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
html {
  --filteredoverview-feedbackbar-color: #767676;
  --filteredoverview-feedbackbar-font: 18px/22px var(--rtd-heading-fontfamily);
}
@media (max-width: 767px) {
  html {
    --filteredoverview-feedbackbar-font: 16px/19px var(--rtd-heading-fontfamily);
  }
}

.filteredoverview__feedbackandviewbar {
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-default);
  margin-bottom: 20px;
  /* Prevent "X results" header from wiggling (changing baseline position or amount of whitespace above/below)
     by making sure it's line-height/min-height is the same as when there's one row of filter tags. */
  min-height: 44px; /* 29 + 7.5px + 7.5px */
}
.filteredoverview__feedbackandviewbar h2.filteredoverview__feedback {
  line-height: 44px;
}

.filteredoverview__feedback {
  text-transform: uppercase;
  color: #3F3F3F;
  margin-right: 15px;
}

.filteredoverview__feedbackandviewbar__content {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.filteredoverview__filtertags {
  display: none;
}

.filteredoverview--filtersactive .filteredoverview__filtertags {
  display: block;
}

.filtertags__title {
  flex: none;
}

.filtertags__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -7.5px;
  margin-bottom: -7.5px;
  margin-left: -15px;
}

.filtertags__item {
  display: inline-flex;
  align-items: center;
  min-height: 29px;
  padding-top: 4px;
  padding-bottom: 4px;
  font: 300 14px var(--rtd-text-fontfamily);
  background-color: #FFFFFF;
  border: 1px solid #8E8E8E;
  border-radius: 16px;
  color: #8E8E8E;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 15px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
}

.filtertags__item:hover {
  color: var(--color-theme);
  border-color: var(--color-theme);
}

.filteredoverview__feedbackandviewbar .filtertags__item::after {
  margin-left: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00d";
  font-size: 14px;
  color: var(--color-theme);
}

.filtertags__item__remove {
  align-self: center;
  padding: 0 8px 0 8px;
  font-size: 9px;
}