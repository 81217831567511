.projectheader {
  --header-innovations-project-image-col: 3 / span 13;
  --header-innovations-project-text-col: 17 / span 9;
  --header-innovations-project-image-stickout: 70px;
  --header-innovations-project-transition-duration: 0.25s;
}

html.innovationspage--project .projectheader {
  --header-innovations-project-image-col: 3 / span 11;
  --header-innovations-project-text-col: 15 / span 11;
  --header-innovations-project-image-stickout: 30px;
}

html.page--blogsitem .projectheader {
  --header-innovations-project-image-col: 3 / span 11;
  --header-innovations-project-text-col: 15 / span 11;
}

.projectheader {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-fullwidth);
}

body > .projectheader,
.page__body > .projectheader {
  grid-row: 3;
}

.projectheader-image-caption {
  visibility: hidden;
  opacity: 0;
  transition: opacity var(--header-innovations-project-transition-duration);
  color: #666666;
  font: 14px/20px var(--rtd-text-fontfamily);
}

.projectheader-image-caption.activeslide {
  visibility: visible;
  opacity: 1;
}

.projectheader__backlink {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 19px;
  align-self: center;
}

.projectheader__backlink__link {
  display: flex;
  align-items: center;
  font: 15px var(--rtd-heading-fontfamily);
  text-decoration: none;
  text-transform: uppercase;
}

.projectheader__backlink__link .linkicon::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f104";
  font-size: 23px;
  margin-right: 10px;
  text-decoration: none;
  vertical-align: top;
}

.projectheader__backlink__link .linktext {
  padding-top: 4px;
}

.projectheader__backlink__link:hover .linktext {
  text-decoration: underline;
}

.projectheader__slideshow {
  background-color: #444444;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}

.projectheader__slideshow::before {
  content: "";
  padding-top: 56.24%;
  grid-row: 1;
  grid-column: 1;
}

.projectheader__slideshow > * {
  grid-row: 1;
  grid-column: 1;
  background-size: cover;
}

.projectheader__slideshow--slideshow > * {
  opacity: 0;
  transition: opacity var(--header-innovations-project-transition-duration);
}

.projectheader__slideshow > *.activeslide {
  opacity: 1;
}

.projectheader__meta {
  display: flex;
  flex-direction: column;
}

.projectheader__meta__universities {
  display: flex;
  margin-bottom: 30px;
}

.projectheader__meta__universities > img {
  max-width: 350px;
  max-height: 40px;
  flex: 0 1 auto;
  min-width: 0;
}

.projectheader__meta__universities > img + img {
  margin-left: 15px;
}

.projectheader__title {
  color: var(--color-theme);
  margin-top: auto;
}

.projectheader__subtitle {
  color: var(--color-theme);
  font: 25px var(--rtd-heading-fontfamily);
  margin-top: 3px;
  font: clamp(17px, 1.6vw, 25px)/115% var(--rtd-heading-fontfamily);
}

.projectheader__slideshow-jumpbuttons {
  display: flex;
}

.projectheader__slideshow-jumpbutton {
  background-color: #8E8E8E;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(0.5);
}

.projectheader__slideshow-jumpbutton.active {
  background-color: var(--color-theme);
  cursor: default;
  transform: scale(1);
}

.projectheader__slideshow-jumpbutton + .projectheader__slideshow-jumpbutton {
  margin-left: 5px;
}

html.page--blogsitem .projectheader .pageheader__date,
html.page--newsitem-ethics .projectheader .pageheader__date {
  order: -1;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 8px;
}
html.page--blogsitem .projectheader .pageheader__date::before,
html.page--newsitem-ethics .projectheader .pageheader__date::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

@media (max-width: 799px) {
  .projectheader {
    background-color: #F2EFED;
    grid-template-rows: minmax(35px, min-content) min-content min-content min-content;
  }
  .projectheader__slideshow {
    grid-column: 1/-1;
    grid-row: 2;
  }
  a.projectheader__backlink__link {
    color: var(--color-theme);
  }
  .projectheader-image-caption {
    grid-row: 3;
    grid-column: var(--pagegrid-col-verywide);
    margin-top: 3px;
  }
  .projectheader__meta {
    display: flex;
    grid-column: var(--pagegrid-col-verywide);
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .projectheader__title {
    font: bold 26px/28px var(--rtd-heading-fontfamily);
  }
  .projectheader__meta__universities {
    display: none;
  }
  .projectheader__slideshow-jumpbuttons {
    grid-row: 2;
    grid-column: var(--pagegrid-col-verywide);
    align-self: end;
    margin-bottom: 20px;
    z-index: 2;
  }
  .projectheader__slideshow-jumpbutton {
    background-color: #FFFFFF;
    opacity: 0.5;
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
  .projectheader__slideshow-jumpbutton.active {
    background-color: #FFFFFF;
    opacity: 1;
  }
  .projectheader__publicationdate {
    grid-column: var(--pagegrid-col-verywide);
    padding-bottom: 15px;
  }
}
@media (min-width: 800px) {
  .projectheader {
    grid-template-rows: minmax(35px, min-content) min-content var(--header-innovations-project-image-stickout) min-content;
  }
  .projectheader::before {
    content: "";
    grid-row: 1/span 2;
    grid-column: var(--pagegrid-col-fullwidth);
    background-color: #F2EFED;
  }
  .projectheader__slideshow {
    grid-row: 2/span 2;
    grid-column: var(--header-innovations-project-image-col);
    background-color: #444444;
  }
  .pageheader--inset .projectheader__slideshow-jumpbuttons {
    margin-left: 15px;
  }
  .pageheader--inset .projectheader__meta {
    padding-top: 45px;
  }
  .pageheader--inset .projectheader__title {
    margin-top: 0;
  }
  a.projectheader__backlink__link {
    color: var(--color-theme);
  }
  .projectheader__meta {
    grid-row: 2;
    grid-column: var(--header-innovations-project-text-col);
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
  .projectheader__slideshow-jumpbuttons {
    grid-row: 4;
    grid-column: var(--header-innovations-project-image-col);
    padding-top: 15px;
  }
  .projectheader--withimagecredits .projectheader__slideshow-jumpbuttons {
    grid-row: 3;
    align-self: end;
    padding-top: 0;
    margin-bottom: 15px;
    width: max-content;
    /*
    padding: 8px;
    border-radius: 4px;

    pointer-events: auto;
    */
  }
  .projectheader--withimagecredits .projectheader__slideshow-jumpbuttons > * {
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
  .projectheader-image-caption {
    grid-row: 4;
    grid-column: var(--header-innovations-project-image-col);
    padding-top: 12px;
  }
  .projectheader__publicationdate {
    grid-row: 3;
    grid-column: 15/-2;
    height: 30px;
    display: flex;
    align-items: flex-end;
    color: #666666;
    font: 15px/100% var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    margin-top: 4px;
  }
}