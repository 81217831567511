.newsoverview {
  --gridimage-aspectratio: 3 / 2;
  grid-column: var(--pagegrid-col-verywide);
}

.newsoverview__items {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-rows: min-content;
  column-gap: 27px;
  row-gap: 31px;
}
@media (max-width: 1250px) {
  .newsoverview__items {
    column-gap: 23px;
    row-gap: 26px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (max-width: 950px) {
  .newsoverview__items {
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (max-width: 550px) {
  .newsoverview__items {
    row-gap: 25px;
    grid-template-columns: minmax(0, 1fr);
  }
}