.footer__newsletterbar {
  grid-column: var(--pagegrid-col-fullwidth);
  padding-top: 80px;
  padding-bottom: 55px;
  background-color: var(--theme-background);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  --rtd-text-color: #FFFFFF;
  --rtd-text-font: 16px/24px var(--rtd-text-fontfamily);
  color: var(--color-white);
}

.footer__newsletterbar.colortheme--black {
  --rtd-text-color: #BBBBBB;
}

.footer__newsletterbar__text p.heading {
  color: var(--color-white);
  font: var(--rtd-h2-font);
}

.footer__newsletterbar__signupform {
  align-self: start;
  position: relative;
  display: flex;
}

.footer__newsletterbar__signupform input {
  background-color: #FFFFFF;
  border: 0;
  width: 100%;
  height: 66px;
  padding-left: 25px;
  padding-right: 65px;
  font: 16px var(--rtd-text-fontfamily);
}
.footer__newsletterbar__signupform input::placeholder {
  opacity: 1;
  color: #3F3F3F;
}

.footer__newsletterbar__signupform__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 65px;
  padding-right: 25px;
  margin-left: -65px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer__newsletterbar__signupform__submit::before {
  font: var(--icon-link-font);
  content: var(--icon-link-content);
  color: var(--text-grey-large);
  font-size: 18px;
}

.footer__newsletterbar__signup-success {
  opacity: 0;
  visibility: hidden; /* so screenreaders know this is invisible */
  height: 0;
  transition: opacity 0.25s;
}

html.subscribedtonewsletter .footer__newsletterbar__signupform {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
html.subscribedtonewsletter .footer__newsletterbar__signup-success {
  visibility: visible;
  height: auto;
  opacity: 1;
  padding-top: 15px;
}

/* Mobile */
@media (max-width: 700px) {
  .footer__newsletterbar__text,
  .footer__newsletterbar__signup {
    grid-column: var(--pagegrid-col-verywide);
  }
  .footer__newsletterbar__signup {
    margin-top: 15px;
  }
}
/* Tablet */
@media (min-width: 701px) {
  .footer__newsletterbar__text {
    grid-column: var(--pagegrid-col-verywide-left);
  }
  .footer__newsletterbar__signup {
    grid-column: var(--pagegrid-col-verywide-right);
  }
}
/* Desktop */
@media (min-width: 1000px) {
  .footer__newsletterbar__text {
    grid-column: 3/8;
  }
  .footer__newsletterbar__signup {
    grid-column: 11/26;
  }
}