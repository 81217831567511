.widget--mediacollage
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;
  grid-column: var(--pagegrid-col-verywide);
}

/* When in the normal pagegrid adhire to the grid (using the very wide layout).
   Otherwise just use the full width our container gives us.
*/
.page__contentarea > .widget--mediacollage
{
  grid-column: 1 / -1;
/*
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
*/
}

/*
.page__contentarea > .widget--mediacollage > .mediacollage__content
{
  grid-column: var(--pagegrid-col-verywide);
}
*/

.mediacollage__content
{
  gap: 25px;
}

.mediacollage__item
{
  position: relative;
}

.mediacollage__item .wh-video
{
  margin-top: 0;
  height: 100%;
}

.mediacollage__item .wh-video__innerframe
{
  height: 100%;
}

.mediacollage__item img
{
  width: 100%;
  /*min-height: 100%; at least stretch to given height, but grow (and stretch other cells) if needed */
  height: 100%; /* min-height doesn't work here in Safari */
  aspect-ratio: 3 / 2;
}

.mediacollage--firstlarge .mediacollage__content
{
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  grid-template-rows: min-content min-content;
}

.mediacollage--firstlarge .mediacollage__item:nth-child(1)
{
  grid-column: 1;
  grid-row: 1 / 3;
}

.mediacollage--firstlarge .mediacollage__item:nth-child(2)
{
  grid-column: 2;
  grid-row: 1;
}

.mediacollage--firstlarge .mediacollage__item:nth-child(3)
{
  grid-column: 2;
  grid-row: 2;
}

.mediacollage--firstlarge .mediacollage__item:first-child img
{
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
}

.mediacollage--lastlarge .mediacollage__content
{
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  grid-template-rows: min-content min-content;
}

.mediacollage--lastlarge .mediacollage__item:nth-child(1)
{
  grid-column: 1;
  grid-row: 1;
}

.mediacollage--lastlarge .mediacollage__item:nth-child(2)
{
  grid-column: 1;
  grid-row: 2;
}

.mediacollage--lastlarge .mediacollage__item:nth-child(3)
{
  grid-column: 2;
  grid-row: 1 / 3;
}

.mediacollage--lastlarge .mediacollage__item:nth-child(3) img
{
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
}
