/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget--contentsummary {
  margin-top: 30px;
}

.widget--contentsummary a {
  text-decoration: none;
}

.widget--contentsummary a:hover {
  text-decoration: underline;
}

.widget--contentsummary h2 {
  margin-top: 30px;
  margin-bottom: 0;
}

.widget--contentsummary ul {
  margin: 0;
}