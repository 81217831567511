/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
@media (max-width: 767px) {
  .programmesoverview__filters__tags {
    display: flex;
    flex-direction: column;
  }
  .programmesoverview__filters__tags > * + * {
    margin-top: calc(var(--grid-spacing) * 0.5);
  }
}
@media (min-width: 768px) {
  .page--programmesoverview .header-innovations__textcontent.header-innovations__textcontent {
    padding-left: 0;
    padding-right: 0;
  }
  .page--programmesoverview .header-innovations__title {
    flex: 0 0 calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
    max-width: none;
    padding-left: 56px;
  }
  .page--programmesoverview .header-innovations__description {
    margin-left: 0;
    padding-right: 56px;
  }
  .page--programmesoverview .header-filterbar__togglefilters {
    display: none;
  }
  .page--programmesoverview .header-filterbar__topbar__label {
    flex: none;
    width: calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
    margin-right: 0;
  }
  .page--programmesoverview .header-expandedfilters {
    margin-top: 0;
  }
  .page--programmesoverview .programmesoverview__filters__tags {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-column-gap: var(--grid-spacing);
    column-gap: var(--grid-spacing);
    row-gap: 15px;
  }
  .page--programmesoverview .filterpanel__title {
    display: none;
  }
  .page--programmesoverview .filterpanel--radiolist .filterpanel__options {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .page--programmesoverview .filterpanel--radiolist > .filterpanel__options > * + * {
    margin-left: var(--grid-spacing);
  }
  .page--programmesoverview .filterpanel--radiolist .filterpanel__options label {
    position: relative;
    top: 2px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page--programmesoverview .programmesoverview__filters__tags {
    margin-left: 0;
  }
  .page--programmesoverview .header-innovations__title {
    padding-left: 35px;
  }
  .page--programmesoverview .header-innovations__description {
    padding-right: 35px;
  }
}