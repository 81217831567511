/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.explorepanel {
  /*
  background-color: rgba(255,255,255,0.8);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  */
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  z-index: 11;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: transform 0.25s ease-in, opacity 0.25s, visibility 0s linear 0.3s;
}

html.siteheader--showexplorepanel .explorepanel {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.25s ease-out, opacity 0.25s, visibility 0s linear 0s;
}

.explorepanel__topbar {
  grid-column: var(--pagegrid-col-verywide);
  height: 100px;
  border-bottom: 1px solid #C9C9C9;
  display: flex;
  align-items: center;
  /*
  border-bottom: 1px solid rgba(201, 201, 201, 0);
  transition: border-color 0.25s linear 0.15s;
  */
}

.explorepanel__columns {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  padding-top: 30px;
  padding-bottom: 70px;
}

.explorepanel__close {
  font: 16px var(--rtd-heading-fontfamily);
  color: #3F3F3F;
  cursor: pointer;
}

.explorepanel__close:hover {
  color: #000000;
}

.explorepanel__close:hover::before {
  transform: scale(1.2);
}

.explorepanel__close::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00d";
  font-size: 16px;
  margin-right: 12px;
}

.explorepanel__address.rtdcontent > p.heading {
  font: bold 16px/20px var(--rtd-heading-fontfamily);
}

.explorepanel__address.rtdcontent > p.normal {
  font: 14px/20px var(--rtd-text-fontfamily);
}

.explorepanel__address.rtdcontent > p.normal a {
  color: inherit;
  font: inherit;
  text-decoration: none;
}

.explorepanel__address.rtdcontent > p.normal a[href="https://4tu.nl/"],
.explorepanel__address.rtdcontent > p.normal a[href="https://4tu.nl/en/"],
.explorepanel__address.rtdcontent > p.normal a[href="https://www.4tu.nl/"],
.explorepanel__address.rtdcontent > p.normal a[href="https://www.4tu.nl/en/"] {
  text-decoration: underline;
}

.explorepanel__address.rtdcontent > p.normal a:hover {
  color: #000000;
  text-decoration: underline;
}

.explorepanel__columns > * {
  flex: 1 0 0px;
}

.explorepanel__columns > .explorepanel__column--manyitems {
  flex: 2 0 0px;
}

.explorepanel__columns > * + * {
  margin-left: 60px;
}

.explorepanel__header {
  font: bold 18px var(--rtd-heading-fontfamily);
  color: #E1670B;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.explorepanel__items--manyitems {
  columns: 2;
  column-gap: 60px;
}

.explorepanel__column a {
  color: #3F3F3F;
  display: flex;
  align-items: baseline;
  font: 16px var(--rtd-heading-fontfamily);
  text-decoration: none;
  padding: 5px 0;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.explorepanel__column a:hover span {
  text-decoration: underline;
}

.explorepanel__column a:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  margin-right: 17px;
}

.explorepanel__address > p.heading a {
  color: inherit;
  font: inherit;
  text-decoration: underline;
}