/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.page--projectsoverview-du .page__body {
  background-color: var(--color-lightgrey);
}

.page--projectsoverview-du .page__filterbar {
  background: none;
}
.page--projectsoverview-du .header-filterbar__topbar__label {
  color: var(--color-black-grey);
}
.page--projectsoverview-du .page__headerfilters {
  border-bottom: 1px solid #D9D5D4; /* to get a higher contrast to the grey color used on this page */
}

@media (max-width: 767px) {
  .page--projectsoverview-du .projectsoverview__filters__tags {
    display: flex;
    flex-direction: column;
  }
  .page--projectsoverview-du .projectsoverview__filters__tags > * + * {
    margin-top: calc(var(--grid-spacing) * 0.5);
  }
}
@media (min-width: 768px) {
  .page--projectsoverview-du .header-innovations__textcontent.header-innovations__textcontent {
    padding-left: 0;
    padding-right: 0;
  }
  .page--projectsoverview-du .header-innovations__title {
    flex: 0 0 calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
    max-width: none;
    padding-left: 56px;
  }
  .page--projectsoverview-du .header-innovations__description {
    margin-left: 0;
    padding-right: 56px;
  }
  .page--projectsoverview-du .header-filterbar__topbar__label {
    flex: none;
    width: calc((100% - var(--grid-spacing) * 2) / 3 + 30px);
    margin-right: 0;
  }
  .page--projectsoverview-du .header-expandedfilters {
    margin-top: 0;
  }
  .page--projectsoverview-du .projectsoverview__filters__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
  }
  .page--projectsoverview-du .projectsoverview__filters__tags > * {
    flex: 1 1 0px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page--projectsoverview-du .projectsoverview__filters__tags {
    margin-left: 0;
  }
  .page--projectsoverview-du .header-innovations__title {
    padding-left: 35px;
  }
  .page--projectsoverview-du .header-innovations__description {
    padding-right: 35px;
  }
}