#newsoverviewfilter .projectsoverview__filters__tags {
  display: flex;
  column-gap: 45px;
  row-gap: 30px;
}
#newsoverviewfilter .spacer {
  display: none;
}
#newsoverviewfilter select[name=sort] {
  min-width: 150px;
}
@media (min-width: 300px) and (max-width: 549px) {
  #newsoverviewfilter .projectsoverview__filters__tags {
    flex-direction: column;
  }
  #newsoverviewfilter .filterpanel[data-categoryid=location] .filterpanel__options {
    column-count: 2;
  }
  #newsoverviewfilter [data-categoryid=sort] {
    width: 100%;
  }
}
@media (min-width: 550px) {
  #newsoverviewfilter .projectsoverview__filters__tags {
    flex-wrap: wrap;
  }
  #newsoverviewfilter .projectsoverview__filters__tags .filterpanel {
    flex: 0 1 auto;
  }
}
@media (min-width: 750px) {
  #newsoverviewfilter .projectsoverview__filters__tags .spacer {
    display: block;
    flex: 1 0 0;
    max-width: 100px;
  }
}