.widget-quote
{
  grid-column: var(--pagegrid-col-default-max8col);

  background: var(--widget-quote-background);
}


.widget-quote--hasimage
{
/*  display: flex;*/
  display: grid;
  grid-template-rows: min-content min-content;
  grid-template-columns: min-content minmax(0, 1fr);
  align-items: center;
}

.widget-quote--hasimage .widget-quote__image
{
  width:  var(--rtd-quote-photo-width);
  height: var(--rtd-quote-photo-height);

  border-radius: 50%;

/*  flex: none;*/
  grid-column: 1;
  grid-row: 1 / span 2;

  margin-right: var(--rtd-quote-margin);
}

/*
Image ending in a arrow shape at the right

.site--vo .widget-quote__image
{
  border-radius: 0;
  --arrowstabs-arrow-width: 15px;
  clip-path: polygon(0% 0%, calc(100% - var(--arrowstabs-arrow-width)) 0%, 100% 50%, calc(100% - var(--arrowstabs-arrow-width)) 100%, 0% 100%, 0% 0%);
}
*/





.widget-quote--hasimage .widget-quote__text
{
/*  flex: 1 0 0px;*/
  grid-column: 2;
  grid-row: 1;

  padding-top: var(--widget-quote-padding-top);
  padding-bottom: var(--widget-quote-padding-bottom);
}

.widget-quote--hasimage .widget-quote__quote
{
  grid-column: 2;
  grid-row: 2;

  color: var(--rtd-quote-color);
  font: var(--widget-quote-withimage-font);
  font-style: italic;
}

.widget-quote--hasimage .widget-quote__quote + .widget-quote__person
{
  margin-top: 23px;
}

.widget-quote--hasimage .widget-quote__person__name
{
  color: var(--widget-quote-name-color);
  font: var(--widget-quote-withimage-name-font);
}

.widget-quote--hasimage .widget-quote__person__function
{
  color: var(--widget-quote-withimage-function-color);
  font: var(--widget-quote-withimage-function-font);
}





.site--vo .widget-quote__image
{
  align-self: start;

  border-radius: 0;
  clip-path: polygon(0% 0%, calc(100% - var(--site-slant-width)) 0%, 100% 100%, 0% 100%, 0% 0%);
}

/* 750px is used in settings/rtd.css */
@media (max-width: 750px)
{
  .site--vo .widget-quote
  {
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    grid-template-rows: min-content min-content;

    --site-slant-width: 5px;
  }

  .site--vo .widget-quote__image
  {
    grid-row: 2;
    grid-column: 1;
    border-radius: 0;
  }

  .site--vo .widget-quote__text
  {
    display: contents;
  }

  .site--vo .widget-quote__quote
  {
    grid-row: 1;
    grid-column: 1 / span 2;
    padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  }


  .site--vo .widget-quote__person
  {
    grid-row: 2;
    grid-column: 2;
    align-self: center;
    margin: 0 !important; /* cancel margin meant for normal display */
  }
}

@media (min-width: 751px)
{
  .site--vo .widget-quote
  {
    --site-slant-width: 14px;
  }

  .site--vo .widget-quote__image
  {
    align-self: start;
    position: relative;
    top: -18px;
    left: -18px;
  }

  /* 4TU.Schools version has a background so it needs padding */
  .site--vo .widget-quote__text
  {
    padding-right: 30px;
  }

  .site--vo .widget-quote__quote
  {
    max-width: 530px;
  }
}




.widget-quote--withoutimage
{
  border-left: 3px solid var(--color-theme);
}

.widget-quote--withoutimage .widget-quote__text
{
  padding-left:   30px;
  padding-right:  30px;
  padding-top: var(--widget-quote-padding-top);
  padding-bottom: var(--widget-quote-padding-bottom);
}

.widget-quote--withoutimage .widget-quote__quote.widget-quote__quote
{
  color: var(--rtd-quote-color);
  font: var(--widget-quote-withoutimage-font);
  font-style: italic;

  text-wrap: pretty;
/*  text-wrap: balance;*/
}

.widget-quote--withoutimage .widget-quote__person__name
{
  margin-top: 20px;
  color: var(--widget-quote-name-color);
  font: var(--widget-quote-withoutimage-name-font);
}

.widget-quote--withoutimage .widget-quote__person__name::before
{
  content: "— ";
}

.widget-quote--withoutimage .widget-quote__person__function
{
  color: var(--widget-quote-function-color);
  font: var(--widget-quote-withoutimage-function-font);
  margin-top: 5px;
}
