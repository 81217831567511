.page--projectdetails-du .project-contact__contactinfo__email {
  color: var(--color-black);
  font-weight: normal;
}

.project__universities {
  list-style: none;
  margin: 0;
  padding: 0;
}

.project__universities li {
  margin: 0;
  padding: 0;
}

.project__universities li + li {
  margin-top: 23px;
}

.project__universities img {
  display: block;
  max-width: min(100%, 200px);
  height: 58px;
  object-fit: contain;
  transition: -webkit-filter 0.25s;
  transition: filter 0.25s;
  cursor: pointer;
}

.project__universities {
  margin-top: 20px;
}