.fourtu-dialog {
  background: white;
  padding: 20px;
  min-width: 450px;
  max-width: 600px;
  max-height: calc(100vh - 60px);
}
.fourtu-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fourtu-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.fourtu-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.fourtu-dialog .wh-form__fieldgroup {
  flex-direction: column;
}
.fourtu-dialog .wh-form__fields {
  width: 100%;
}
.fourtu-dialog input {
  width: 100%;
}
.fourtu-dialog .wh-form__label {
  overflow: visible;
  width: 100%;
  max-width: 100%;
  padding-right: 0;
  padding-bottom: 5px;
}
.fourtu-dialog .wh-form__buttongroup {
  text-align: right;
}
.fourtu-dialog .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}