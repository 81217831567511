.widget-downloadsandlinks ul
{
  list-style: none;
  margin: 0;
  padding: 0;
}


.widget-dl-listicons .widgetdl__item
{
  display: flex;
  align-items: baseline;

  border-top: 1px solid #C5C5C5;
  padding: 11px;

  position: relative;
}

.widget-dl-listicons .widgetdl__item.widgetdl__item:hover
{
  background-color: #F5F5F5;
}

  .widget-dl-listicons .widgetdl__item__icon
, .widget-dl-listicons .widgetdl__item__meta
{
  pointer-events: none;
}

.widget-dl-listicons .widgetdl__item__anchor
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
}

.widget-dl-listicons .widgetdl__item:last-child
{
  border-bottom: 1px solid #C5C5C5;
}

  .widget-dl-listicons .widgetdl__item__icon
, .widget-dl-listicons .widgetdl__item__title
, .widget-dl-listicons .widgetdl__item__filesize
{
  color: #000000;
}

.widget-dl-listicons .widgetdl__item__icon
{
  flex: none;
  font-size: 18px;

  text-align: center;
  width: 20px;
  margin-right: 10px;

  color: var(--color-theme);
}

.widget-dl-listicons .widgetdl__item__icon::before
{
  vertical-align: middle;
}

.widget-dl-listicons .widgetdl__item__icon.fa-arrow-right
{
  font-size: 13px;
}

.widget-dl-listicons .widgetdl__item__icon.fa-external-link-alt
{
  font-size: 16px;
}

.widget-dl-listicons .widgetdl__item__meta
{
  font-size: 16px;
  line-height: 24px;
}

.widget-dl-listicons .widgetdl__item__title
{
  font-weight: bold;
}

.widget-dl-listicons .widgetdl__item__filesize
{
  margin-left: 10px;
  white-space: nowrap;
}

.widget-dl-listicons .widgetdl__item__description
{
  margin-top: 5px;
  color: var(--rtd-text-color);
}






.widget-dl-listthumbs .widgetdl__item
{
  display: flex;
  background-color: var(--color-bggrey);
  position: relative; /* for the abs positioned anchor within */
}

.widget-dl-listthumbs .widgetdl__item + .widgetdl__item
{
  margin-top: var(--rtd-spacing-paragraph); /* FIXME: make new CSS var for this? */
}

.widget-dl-listthumbs .widgetdl__item.widgetdl__item:hover
{
}

  .widget-dl-listthumbs .widgetdl__item__thumb
, .widget-dl-listthumbs .widgetdl__item__meta
{
  pointer-events: none;
  object-fit: cover;
}

.widget-dl-listthumbs .widgetdl__item__anchor
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
}





  .widget-dl-listthumbs .widgetdl__item__title
, .widget-dl-listthumbs .widgetdl__item__filesize
{
  color: var(--color-black-grey);
}

  .widget-dl-listthumbs .widgetdl__item__thumb
, .widget-dl-listthumbs .widgetdl__item__thumb-placeholder
{
  flex: none;

  width:  149px;
  min-height: 100px;
  /*height: 100%;*/
}

@media (max-width: 500px)
{
    .widget-dl-listthumbs .widgetdl__item__thumb
  , .widget-dl-listthumbs .widgetdl__item__thumb-placeholder
  {
    display: none;
  }
}

.widget-dl-listthumbs .widgetdl__item__thumb-placeholder
{
  background-color: var(--color-lightgrey);

  /* if there's a filetype icon (in case of a download */
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-dl-listthumbs .widgetdl__item__thumb-placeholder .widgetdl__item__icon
{
  font-size: 30px;
}

.widget-dl-listthumbs .widgetdl__item__meta
{
  padding: 15px 60px 15px 30px;

  /*display: flex;*/
  /*align-items: center;*/
  align-self: center;

  flex: 1 0 0px; /* all remaining space */

  position: relative;
}

.widget-dl-listthumbs .widgetdl__item__title
{
  font: var(--widget-item-heading-font);
}

.widget-dl-listthumbs .widgetdl__item__filesize
{
  margin-left: 10px;
  white-space: nowrap;
}

.widget-dl-listthumbs .widgetdl__item__description
{
  margin-top: 5px;

  --rtd-text-font:  17px/32px var(--rtd-text-fontfamily); /*var(--widget-item-description-font);*/
  --rtd-text-color: #8D8A89;
}


.widget-dl-listthumbs .widgetdl__item .widgetdl__item__meta::after
{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0,-50%);

  display: inline-block; /* so underline won't be applied when the anchor whe're in gets hovered */
  font: var(--icon-link-font);
  content: var(--icon-link-content);

  color: var(--text-grey-large);
  font-size: 15px;

  margin-right: 30px;

  transition: transform 0.2s ease-out;
}

.widget-dl-listthumbs .widgetdl__item__anchor:hover ~ .widgetdl__item__meta .widgetdl__item__title
{
  text-decoration: underline;
}

/*a.widget-dl-listthumbs .widgetdl__item:hover::after*/
.widget-dl-listthumbs .widgetdl__item__anchor:hover ~ .widgetdl__item__meta::after
{
  transform: translate(15px,-50%);
  transition: transform 0.2s ease-in;
  /*transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1.5);*/
}
