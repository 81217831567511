/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*
.header-menubar
{
    .header-menubar__search
  , .header-menubar__showsidemainmenu
  {
    color: #3F3F3F;
  }
}
*/
/*
@container menubar-translucent (width > 0)
{

}
*/
html.siteheader2021 {
  /***********************************************************************
  **
  **  Header layout
  */
  /***********************************************************************
  **
  **  Search panel
  */
  /***********************************************************************
  **
  **  Effect on menubar when searchpanel is active
  */
  /***********************************************************************
  **
  **  Header layout
  */
  /* last button */
}
html.siteheader2021 .header-top {
  z-index: 1;
}
html.siteheader2021 .header-top, html.siteheader2021 .header-menubar {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
html.siteheader2021 .header-top__content, html.siteheader2021 .header-menubar__content {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  align-items: center;
}
html.siteheader2021 .header-menubar-background, html.siteheader2021 .header-menubar {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
}
html.siteheader2021 .header-top__content, html.siteheader2021 .explorepanel__topbar {
  padding-top: 18px;
  padding-bottom: 10px;
}
html.siteheader2021 a.header-top__identity {
  margin-right: 30px;
  text-decoration: none;
  display: flex;
}
html.siteheader2021 .header-top__slogan {
  display: flex;
  align-items: center;
  white-space: pre;
  margin-bottom: 12px;
  color: #8E8E8E;
  font: 14px var(--rtd-heading-fontfamily);
}
html.siteheader2021 .header-top__organizationtitle {
  color: var(--headerlogo-colortint);
}
html.siteheader2021 .header-top__sitetitle {
  color: var(--headerlogo-grey);
  max-width: 100%;
  white-space: break-spaces;
}
html.siteheader2021 .header-top__sitetitle > * {
  display: inline-block;
  white-space: nowrap;
}
html.siteheader2021 .header-top__organizationtitle, html.siteheader2021 .header-top__sitetitle {
  font: bold 45px var(--rtd-heading-fontfamily);
  font-size: max(18px, min(5.2vw, 45px));
  font-size: clamp(18px, 5.2vw, 45px);
  line-height: 100%;
}
html.siteheader2021 .header-top__organizations {
  display: flex;
  align-items: baseline;
}
html.siteheader2021 .header-menubar-background {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  transition: -webkit-backdrop-filter 0.25s, backdrop-filter 0.25s;
}
html.siteheader2021 .header-top__toggleexplorepanel {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
}
html.siteheader2021 .header-top__organizations {
  margin-left: auto;
  align-self: start;
  margin-top: 20px;
  position: relative;
  z-index: 100;
}
html.siteheader2021 .header-top__organizations img {
  display: block;
}
html.siteheader2021 .header-menubar__languages {
  margin-right: 36px;
}
html.siteheader2021 .header-menubar__languages span {
  text-decoration: underline;
  padding: 2px 10px;
}
html.siteheader2021 .header-menubar__languages a {
  text-decoration: none;
  color: inherit;
  padding: 2px 10px;
}
html.siteheader2021 .header-menubar__languages a:hover {
  text-decoration: underline;
}
html.siteheader2021 .header-menubar__searchwrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2px;
}
html.siteheader2021 .header-menubar__search-input-and-suggestions-wrapper {
  position: absolute;
  right: -10px;
  width: 350px;
  max-width: calc(100vw - 55px);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}
html.siteheader2021 .header-menubar__searchinput, html.siteheader2021 .header-menubar__searchwrapper .wh-autocomplete-container {
  width: 100%;
}
html.siteheader2021 .header-menubar__searchwrapper:focus-within .header-menubar__search-input-and-suggestions-wrapper {
  opacity: 1;
  pointer-events: auto;
}
html.siteheader2021 .header-menubar__searchwrapper:focus-within .header-menubar__search {
  color: #000000;
}
html.siteheader2021 .header-menubar__searchinput::-webkit-search-cancel-button {
  display: none;
}
html.siteheader2021 .header-menubar__search {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: relative;
  margin-left: auto;
  cursor: pointer;
}
html.siteheader2021 .header-menubar-background {
  -webkit-backdrop-filter: blur(0);
  transition: -webkit-backdrop-filter 0.2s;
  backdrop-filter: blur(0);
  transition: backdrop-filter 0.2s;
}
html.siteheader2021 .header-top__slogan, html.siteheader2021 .header-menubar__menubar,
html.siteheader2021 .header-menubar__showsidemainmenu {
  opacity: 1;
  transition: opacity 0.2s;
}
html.siteheader2021 .headersearchpanelactive .header-menubar-background {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
html.siteheader2021 .headersearchpanelactive .header-top__slogan, html.siteheader2021 .headersearchpanelactive .header-menubar__menubar,
html.siteheader2021 .headersearchpanelactive .header-menubar__showsidemainmenu {
  opacity: 0.5;
}
html.siteheader2021 .header-menubar__search, html.siteheader2021 .header-menubar__showsidemainmenu {
  flex: none;
}
html.siteheader2021 .header-menubar__menubar {
  flex: 1 1 auto;
  min-width: 0;
}
html.siteheader2021 .header-menubar__spacer {
  flex: 1 0 20px;
}
html.siteheader2021 .header-menubar--hidemenuitems .header-menubar__menubar {
  visibility: hidden;
  pointer-events: none;
  position: relative;
  overflow: hidden;
}
@media (min-width: 950px) {
  html.siteheader2021 {
    --layout-menubar-height: 100px;
    --layout-menubar-shrink: 3px; /* amount to let the menubar background move out of the viewport */
  }
  html.siteheader2021 .header-menubar {
    padding-top: 5px;
    min-height: var(--layout-menubar-height);
    color: #FFFFFF;
  }
  html.siteheader2021 a.header-menubar__identity,
  html.siteheader2021 a.header-menubar__identity + .header-menubar__spacer {
    display: none;
  }
  html.siteheader2021 .header-menubar {
    top: 0px;
  }
  html.siteheader2021 .header-menubar-background {
    top: calc(-1 * var(--layout-menubar-shrink));
  }
  html.siteheader2021 .header-menubar__searchinput.header-menubar__searchinput {
    height: 41px;
  }
  html.siteheader2021 .site-scrollprogressbar {
    position: sticky;
    top: 97px;
  }
  html.siteheader2021.identity--designunited .header-menubar__showsidemainmenu {
    display: none;
  }
}
@media (max-width: 949px) {
  html.siteheader2021 {
    --siteheader-height-approximation: 39px;
    --layout-menubar-height: 53px;
    --layout-menubar-shrink: 6px; /* amount to let the menubar background move out of the viewport */
  }
  html.siteheader2021 .header-top, html.siteheader2021 .explorepanel {
    display: none;
  }
  html.siteheader2021 .header-menubar {
    min-height: var(--layout-menubar-height);
    top: calc(-1 * var(--layout-menubar-shrink));
  }
  html.siteheader2021 a.header-menubar__identity {
    padding-top: 7px;
    display: flex;
    text-decoration: none;
    font: bold 28px var(--rtd-heading-fontfamily);
    font-size: max(20px, min(3.5vw, 28px));
    font-size: clamp(20px, 3.5vw, 28px);
  }
  html.siteheader2021 .header-menubar__organizationtitle {
    color: var(--headerlogo-colortint);
  }
  html.siteheader2021 .header-menubar__sitetitle {
    color: #FFFFFF;
  }
  html.siteheader2021 .header-menubar__menubar, html.siteheader2021 .header-menubar__languages {
    display: none;
  }
  html.siteheader2021 .header-menubar--sitetitle-multiline {
    padding-top: 9px;
    padding-bottom: 5px;
  }
  html.siteheader2021 .header-menubar-background {
    top: -14px;
  }
  html.siteheader2021 .header-menubar__searchinput.header-menubar__searchinput {
    height: 39px;
  }
  html.siteheader2021 .site-scrollprogressbar {
    position: sticky;
    top: 39px;
  }
}
@media (min-width: 581px) and (max-width: 1200px) {
  html.siteheader2021 .header-top__organizations {
    display: none;
  }
}
html.siteheader2021 .header-top__language {
  margin-left: auto;
  display: flex;
}
html.siteheader2021 .header-top__language a {
  display: block;
  align-self: center;
  padding: 3px 5px;
  text-decoration: none;
  color: inherit;
}
html.siteheader2021 .header-top__language a:last-child {
  margin-right: -5px;
}
html.siteheader2021 .header-menubar__content {
  display: flex;
  align-items: center;
  font-family: var(--rtd-heading-fontfamily);
  font-size: 20px;
  line-height: 24px;
}
html.siteheader2021 li.header-menubar__menuitem-account > a::before,
html.siteheader2021 .sidemainmenu-level1__item.header-menubar__menuitem-account > a::before,
html.siteheader2021 .sidemainmenu-level1__item.header-menubar__menuitem-account > button::after {
  content: var(--fa-user);
  font: var(--fontawesome-regular);
  font-size: 18px;
  vertical-align: middle;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}
html.siteheader2021 .sidemainmenu-level1__item.header-menubar__menuitem-account > button::after {
  margin-left: 7px;
  font-size: 15px;
}
html.siteheader2021 .spc-menubar li.iconbutton > a {
  font-size: 0;
  width: 32px;
  border-bottom: none !important;
}
html.siteheader2021 .spc-menubar li.iconbutton > a:hover {
  text-decoration: none;
  border-bottom: none !important;
}
html.siteheader2021 .spc-menubar .iconbutton .spc-menubar__pulldown {
  transform: translate(-16px, 100%);
}
html.siteheader2021 .header-menubar {
  --menubar-iconbuttons-spacing: 6px;
}
html.siteheader2021 .header-menubar__showsidemainmenu, html.siteheader2021 .header-menubar__search,
html.siteheader2021 .header-menubar__profilebutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  flex: none;
  cursor: pointer;
  min-width: 32px;
  padding: 5px 9px 7px 9px;
  height: 36px;
  margin-left: var(--menubar-iconbuttons-spacing);
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
html.siteheader2021 .header-menubar__search {
  margin-left: 0;
}
html.siteheader2021 .header-menubar__searchwrapper {
  margin-left: var(--menubar-iconbuttons-spacing); /* whitespace 23px minus the increased hitarea */
}
html.siteheader2021 .header-menubar__search {
  color: inherit;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  text-align: center;
}
html.siteheader2021 .header-menubar__search .far {
  vertical-align: middle;
}
html.siteheader2021 .spc-menubar li.iconbutton > a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
}
html.siteheader2021 .spc-menubar .iconbutton > li > a:hover,
html.siteheader2021 .header-menubar__search:hover,
html.siteheader2021 .header-menubar__showsidemainmenu:hover {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}
html.siteheader2021 .header-menubar__showsidemainmenu {
  margin-right: -7px;
}
html.siteheader2021 .header-menubar__showsidemainmenu .fas {
  font-size: 18px;
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) {
  /*
  By setting the color for content, these will also inherit this:
    - .header-menubar__search
    - .header-menubar__showsidemainmenu
  */
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-top-background {
  background-color: #FFFFFF;
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar-background {
  background-color: rgba(0, 0, 0, 0.35);
  transition: background-color 0.25s;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__organizationtitle, html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__sitetitle,
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__menubar > ul > li > a,
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__search,
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__showsidemainmenu {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
  transition: text-shadow 0.25s;
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__content {
  color: #FFFFFF;
}
@media (max-width: 949px) {
  html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) .header-menubar__content {
    color: #3F3F3F;
  }
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) a.header-menubar__selected,
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) ul.spc-menubar > li > a:hover {
  border-bottom: 1px dotted #FFFFFF;
}
html.siteheader2021.siteheader--menubar-translucent:not(.pageheader--fixed) html.identity--designunited a.header-menubar__selected {
  border-bottom-color: var(--color-theme);
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow.identity--4tu .header-top-background, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset.identity--4tu .header-top-background, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed.identity--4tu .header-top-background {
  background-color: #FFFFFF;
  border-bottom: 1px solid #E0E0E0;
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow.identity--4tu .header-menubar-background, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset.identity--4tu .header-menubar-background, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed.identity--4tu .header-menubar-background {
  background-color: rgba(242, 239, 237, 0.9);
  border-bottom: 1px solid #D1D1D1;
  -webkit-backdrop-filter: blur(3px); /* iOS 9+ and still in 17.5 */
  backdrop-filter: blur(3px);
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow.identity--designunited .header-top-background, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset.identity--designunited .header-top-background, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed.identity--designunited .header-top-background {
  background-color: #FFFFFF;
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow.identity--designunited .header-menubar-background, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset.identity--designunited .header-menubar-background, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed.identity--designunited .header-menubar-background {
  background-color: #FAF6F4;
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow .header-menubar__sitetitle, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset .header-menubar__sitetitle, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed .header-menubar__sitetitle {
  color: #8E8E8E;
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow .header-menubar__content, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset .header-menubar__content, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed .header-menubar__content {
  color: var(--rtd-text-color);
  font-family: var(--rtd-heading-fontfamily);
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow a.header-menubar__selected,
html.siteheader2021:not(.page--ethics-members).pageheader--inflow ul.spc-menubar > li > a:hover, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset a.header-menubar__selected,
html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset ul.spc-menubar > li > a:hover, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed a.header-menubar__selected,
html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed ul.spc-menubar > li > a:hover {
  border-bottom: 1px dotted var(--rtd-text-color);
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow.identity--designunited a.header-menubar__selected, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset.identity--designunited a.header-menubar__selected, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed.identity--designunited a.header-menubar__selected {
  border-bottom-color: var(--color-theme);
  border-bottom-style: solid;
}
html.siteheader2021:not(.page--ethics-members).pageheader--inflow ul.spc-menubar > li > a, html.siteheader2021:not(.page--ethics-members).pageheader--inset.pageheader--inset.pageheader--inset ul.spc-menubar > li > a, html.siteheader2021:not(.page--ethics-members).siteheader--menubar-translucent.pageheader--fixed ul.spc-menubar > li > a {
  color: #3F3F3F;
}

@media (min-width: 950px) {
  html:has(.header-innovations.header--image_extend_under_menubar) {
    /*
    By setting the color for content, these will also inherit this:
      - .header-menubar__search
      - .header-menubar__showsidemainmenu
    */
  }
  html:has(.header-innovations.header--image_extend_under_menubar) .header-top-background {
    background-color: #FFFFFF;
  }
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar-background {
    background-color: rgba(0, 0, 0, 0.35);
    transition: background-color 0.25s;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__organizationtitle, html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__sitetitle,
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__menubar > ul > li > a,
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__search,
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__showsidemainmenu {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0);
    transition: text-shadow 0.25s;
  }
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__content {
    color: #FFFFFF;
  }
}
@media (min-width: 950px) and (max-width: 949px) {
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__content {
    color: #3F3F3F;
  }
}
@media (min-width: 950px) {
  html:has(.header-innovations.header--image_extend_under_menubar) a.header-menubar__selected,
  html:has(.header-innovations.header--image_extend_under_menubar) ul.spc-menubar > li > a:hover {
    border-bottom: 1px dotted #FFFFFF;
  }
  html:has(.header-innovations.header--image_extend_under_menubar) html.identity--designunited a.header-menubar__selected {
    border-bottom-color: var(--color-theme);
  }
}
@media (max-width: 949px) {
  html:has(.header-innovations.header--image_extend_under_menubar).identity--4tu .header-top-background {
    background-color: #FFFFFF;
    border-bottom: 1px solid #E0E0E0;
  }
  html:has(.header-innovations.header--image_extend_under_menubar).identity--4tu .header-menubar-background {
    background-color: rgba(242, 239, 237, 0.9);
    border-bottom: 1px solid #D1D1D1;
    -webkit-backdrop-filter: blur(3px); /* iOS 9+ and still in 17.5 */
    backdrop-filter: blur(3px);
  }
  html:has(.header-innovations.header--image_extend_under_menubar).identity--designunited .header-top-background {
    background-color: #FFFFFF;
  }
  html:has(.header-innovations.header--image_extend_under_menubar).identity--designunited .header-menubar-background {
    background-color: #FAF6F4;
  }
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__sitetitle {
    color: #8E8E8E;
  }
  html:has(.header-innovations.header--image_extend_under_menubar) .header-menubar__content {
    color: var(--rtd-text-color);
    font-family: var(--rtd-heading-fontfamily);
  }
  html:has(.header-innovations.header--image_extend_under_menubar) a.header-menubar__selected,
  html:has(.header-innovations.header--image_extend_under_menubar) ul.spc-menubar > li > a:hover {
    border-bottom: 1px dotted var(--rtd-text-color);
  }
  html:has(.header-innovations.header--image_extend_under_menubar).identity--designunited a.header-menubar__selected {
    border-bottom-color: var(--color-theme);
    border-bottom-style: solid;
  }
  html:has(.header-innovations.header--image_extend_under_menubar) ul.spc-menubar > li > a {
    color: #3F3F3F;
  }
}