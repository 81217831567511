/*
// $contentfullgrid: true;
// $font-heading-offset: 3px; // compensate for not not aligning correctly
*/


/*
NOTES:

IE and Edge-legacy require a polyfill for summary+details to work
(https://caniuse.com/details)

The <summary> element could not be display: flex; or grid; in the past with Safari.
Also see:
- https://bugs.webkit.org/show_bug.cgi?id=190065
- https://github.com/philipwalton/flexbugs#flexbug-9

About accordion's and useability:
https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html

*/


.widget-accordion__title
{
  margin-bottom: var(--rtd-spacing-h3-after);
}

.widget-accordion__description
{
  margin-bottom: 15px;
}

/*
//@if ($contentfullgrid)
//{
    // We are specific in which situation we go fullwidth
    // (in a page with inset such as Projects-DU-details we might not want to go fullwidth.
    // That page uses it's on contentarea container.
*/
      .page__contentarea--rtddoc > .widget-accordion
    , .wh-form__richtext > .widget-accordion
    {
      grid-column: var(--pagegrid-col-fullwidth);

      display: grid;
      grid-template-columns: var(--pagegrid-template-columns);

        .widget-accordion__title
      , .widget-accordion__item__header
      {
        grid-column: var(--pagegrid-col-default);
      }

      /* NOTE: <details> cannot be grid */
      .widget-accordion__item
      {
        grid-column: var(--pagegrid-col-fullwidth);
      }

      .widget-accordion__item__headerwrapper /* because <summary> cannot be a grid */
      {
        display: grid;
        grid-template-columns: var(--pagegrid-template-columns);
      }
    }

    .widget-accordion__item:last-child .widget-accordion__item__body__content::after
    {
      margin-top: var(--accordion-body-padding-bottom);

      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background-color: var(--accordion-item-line-color);

      grid-column: var(--pagegrid-col-default);
    }

    .widget-accordion__item:last-child .widget-accordion__item__body__content
    {
      padding-bottom: 0;
    }

    /* Although officially you cannot create an FAQ widget within a two column widget,
       it is possible for it to exist. (for example if you copy & paste an FAQ widget to the
       richtext content of the FAQ) */
    .widget-richcontent__column .widget-accordion__item__body__content > *
    {
      grid-column: var(--pagegrid-col-fullwidth);
    }
/*
//}
*/



/* Hide default arrow browsers show on <summary> */
.widget-accordion details summary { list-style-type: none; } /* Firefox */
.widget-accordion details summary::-webkit-details-marker { display: none; } /* old Chrome */
.widget-accordion details summary::marker { display: none; } /* Chrome 84+ (oct 2020), FF68 (jul 2019), SF11.1 (mar 2018) */

.widget-accordion summary:focus
{
  outline: 0;
}

.widget-accordion summary:focus-visible .widget-accordion__item__header /* Safari 14.1 friendly.. (still no :focus-visible there) */
{
  outline: 1px solid var(--color-theme);
  outline: -webkit-focus-ring-color auto 1px;
}

.widget-accordion__item__header
{
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--accordion-header-color);
  font: var(--accordion-header-font);

  border-top: 1px solid var(--accordion-item-line-color);

  min-height: var(--accordion-header-height);
/*  padding: #{6px + $font-heading-offset} 0 #{6px - $font-heading-offset} 0;*/
  padding: 9px 0 9px 0;
/*  font: 600 17px/20px $font-heading;*/

  -moz-user-select: none; /* FF<69 (before 3 sep 2019) */
  -webkit-user-select: none; /* Chrome <54, all SF & iOS */
  user-select: none;

  cursor: pointer;
}

.widget-accordion details:not([open]):last-child .widget-accordion__item__header
{
  border-bottom: 1px solid var(--accordion-item-line-color);
}


.widget-accordion__item__header::after
{
  color: var(--accordion-header-symbol-color);
  font: var(--accordion-header-symbol-font);
  content: var(--accordion-header-symbol-content);

  margin-left: 20px;
  margin-right: 20px;
}

.widget-accordion__item__header:hover
{
  background: var(--accordion-header-hover-background);
}
.widget-accordion__item__header:hover::after
{
  font-weight: bold;
  transform: translate(1px, -1px);
}

.widget-accordion__item[open] .widget-accordion__item__header::after
{
  content: var(--accordion-header-symbol-open-content);
}

.widget-accordion__item__body__content
{
  padding-top: 5px;
  padding-bottom: var(--accordion-body-padding-bottom);
}
