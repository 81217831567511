
/*
navigationbutton original 36px, now 44px
*/

.pageheader-carrousel
{
  position: relative; /* position navigation (prev/next) buttons within */



  display: grid; /* to get the teaser at the correct position */
  grid-template-columns: var(--pagegrid-template-columns);
}

.pageheader-carrousel > *
{
  grid-row: 1;
}

.pageheader-carrousel__viewport
{
  grid-column: var(--pagegrid-col-fullwidth);
}




.pageheader-carrousel__buttons
{
  display: flex;
  position: relative;
}

.pageheader-carrousel__navigationbar
{
  display: flex;
  justify-content: end;
}






.pageheader-carrousel__teaser
{
  background-color: var(--color-theme);
  color: #FFFFFF;
  font: 16px var(--rtd-heading-fontfamily);
  text-decoration: none;

  /* size / shape */
  display: inline-block;
  line-height: 44px;
  height: 44px;
  padding: 0 28px 0 22px;
  border-radius: 22px;
  white-space: nowrap;


  cursor: pointer;

  transform: scale(1.0);
  transition: transform 0.2s ease-out;


  /* https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

/*@media (max-width: 768px)*/
/*
@media (max-width: 1300px)
{
  .pageheader-carrousel__buttons
  {
    / * position * /
    grid-column: 11 / 26;
    align-self: end; / * bottom * /
    justify-self: end; / * right * /
    margin-bottom: 38px;
    z-index: 1;

    flex-direction: column;
  }

  .pageheader-carrousel__teaser
  {
    grid-column: var(--pagegrid-col-verywide);
  }

  .pageheader-carrousel__navigationbar
  {
    margin-top: 30px;
  }

  .pageheader-carrousel__nextbutton
  {
    margin-left: 18px;
  }
}
*/


/* mobile */
@media (max-width: 640px)
{
    .pageheader-carrousel__item__meta.pageheader-carrousel__item__meta
  , .pageheader-carrousel__content.pageheader-carrousel__content::after
  {
    grid-row:  1;
    left: 100vw;
    transform: translateX(-100%);
  }

  .pageheader-carrousel__buttons
  {
    /* position */
    grid-column: var(--pagegrid-col-verywide);
    align-self: end; /* bottom */
    z-index: 1;

    margin-bottom: 38px;


    display: grid;
    grid-template-columns: 1fr min-content 1fr;
  }

  /* teaser is centered */
  .pageheader-carrousel__teaser
  {
    grid-column: 2;
  }

  .pageheader-carrousel__navigationbar
  {
    grid-column: 3;
    justify-self: end;
  }

  .pageheader-carrousel__nextbutton
  {
    margin-left: 18px;
  }
}



@media (min-width: 641px) and (max-width: 1300px)
{
  .pageheader-carrousel__buttons
  {
    /* position */
    grid-column: 11 / 26;
    align-self: end; /* bottom */
    justify-self: end; /* right */
    margin-bottom: 38px;
    z-index: 1;

    flex-direction: column;
  }

  .pageheader-carrousel__teaser
  {
    grid-column: var(--pagegrid-col-verywide);
    /*justify-self: end;*/
  }

  .pageheader-carrousel__navigationbar
  {
    margin-top: 30px;
  }

  .pageheader-carrousel__nextbutton
  {
    margin-left: 18px;
  }
}



@media (min-width: 1301px)
{
  .pageheader-carrousel__buttons
  {
    /* position */
    grid-column: 11 / 26;
    align-self: end;
    /*justify-self: start;*/
    margin-bottom: 38px;
    z-index: 1;
  }

  .pageheader-carrousel__navigationbar
  {
    margin-left: auto;
  }
  .pageheader-carrousel__nextbutton
  {
    margin-left: 18px;
  }
}



@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}



.pageheader-carrousel__teaser::before
{
  content: var(--icon-look-down);
  font: 200 14px 'Font Awesome 5 Pro';
  margin-right: 10px;
}

.pageheader-carrousel__teaser:hover
{
  background-color:  var(--color-theme-hover-dark);
  transform: scale(1.1);
  /*text-shadow: 1px 1px 2px rgba(0,0,0,1);*/
}




.pageheader-carrousel__content
{
  overflow: hidden; /* The same element must do the clipping AND scrolling, otherwise the sticky __meta panel trick doesn't work */

  display: grid;
  grid-template-columns: repeat( calc( var(--itemcount) - 1 ), 80vw) 100vw;
  /*grid-template-rows: minmax(0, 1fr) min-content;*/
  grid-column-gap: 22px;
  column-gap: 22px;

  min-height: 400px;
  min-height: var(--pageheader-carrousel-minheight);
}

.pageheader-carrousel__item
{
  display: contents;
}

/*
html.page--projectsoverview-du .filteredoverview__teaser
{
  grid-row: 3;
  grid-column: var(--pagegrid-col-default);

  position: sticky;
  top: max(400px, calc(80vw * 0.5));
}
*/

.pageheader-carrousel__item__image
{
  grid-row: 1 / span 2;

  width: 100%;

  height: 80vw; /* fallback */

  height: min( calc(70vw)
             , calc(90vh - var(--siteheader-height-approximation))
             , var(--pageheader-carrousel-maxheight)
             );

  min-height: 100%; /* we may get stretched if a __meta container is larger */

  object-fit: cover;
}

.pageheader-carrousel__item:nth-child(1) .pageheader-carrousel__item__image
{
  grid-column: 1;
}



  .pageheader-carrousel__content::after
, .pageheader-carrousel__item__meta
{
  grid-column: 1;
  grid-row: 2;
  position: sticky;
  left: 0;

  max-width: 43%;
  min-width: 300px;
  min-width: min(300px, 70vw);
}

.pageheader-carrousel__item__meta
{
  /*opacity: 0;*/
  /*transition: opacity 0 1s;*/
}

.pageheader-carrousel__item--inview .pageheader-carrousel__item__meta
{
  z-index: 5;

  /*transition: opacity 0.2s;*/
  /*opacity: 1;*/
}



/* white background for all panels */
.pageheader-carrousel__content::after
{
  content: "";
  background-color: #FFFFFF;
}



/* place all text panels at the bottom left */
.pageheader-carrousel__item__meta
{
  padding: 45px 55px;
  padding: clamp(12px,3vw,45px) clamp(15px,3vw,45px);
}

.pageheader-carrousel__item__title
{
  color: var(--color-black);
  font: 44px/50px var(--rtd-heading-fontfamily);
  font-size: clamp(20px,3.5vw,44px);
  line-height: 120%;
}

.pageheader-carrousel__item__description
{
  --rtd-text-color: #8E8E8E;
  --rtd-text-font: clamp(18px,2.2vw,24px)/150% var(--rtd-heading-fontfamily);;
}

* + .pageheader-carrousel__item__description
{
  margin-top: 22px;
}

* + .pageheader-carrousel__item__link
{
  margin-top: 25px;
}

@media (max-width: 700px)
{
  .pageheader-carrousel__item__description
  {
    display: none;
  }
}

a.pageheader-carrousel__item__link
{
  display: inline-block;
  color: var(--color-theme);
  font: 18px/24px Officina, Arial, Sans-Serif; /* like  --consentbar-preferenceslink-font */
  text-decoration: none;
}

a.pageheader-carrousel__item__link:hover
{
  text-decoration: underline;
}

.pageheader-carrousel__item__link::before
{
  display: inline-block; /* allows us to override the underline on hover */

  font: var(--icon-link-font);
  content: var(--icon-link-content);

  margin-right: 18px;
}



/************************************************************************
*
*  Carrousel mode
*/

/*.projectsviewer__itemswrapper*/
.pageheader-carrousel
{
  grid-column: var(--pagegrid-col-fullwidth);
}

.pageheader-carrousel__content
{
  /*@include horizontalcarrousel;*/
  overflow-x: scroll;
  /*Carrousel style scrolling for iOS11, SF11+, Chr69+, Android Browser 67, Firefox 68 (jul 2019)*/
  scroll-snap-type: x mandatory; /* proximity */
  -webkit-overflow-scrolling: touch; /* without this snappoints don't seem to work correctly on iOS */


  /*@include hide-scrollbars;*/
  /*https://stackoverflow.com/questions/3296644/hiding-the-scroll-bar-on-an-html-page*/
  scrollbar-width: none;          /* Firefox 64+ */


  scroll-behavior: smooth;
}

/* Chrome, Safari, newer versions of Opera */
.pageheader-carrousel__content::-webkit-scrollbar { width: 0 !important; height: 0 !important; }

.pageheader-carrousel__item
{
  /* Safari < 14.5  */
  scroll-snap-margin-left: 0; /*var(--pagegrid-gutteroutside);*/
  scroll-margin-left: 0; /*var(--pagegrid-gutteroutside);*/
}

.pageheader-carrousel__item__image
{
  scroll-snap-align: start;

  /* Safari < 14.5  */
  scroll-snap-margin-left: 0; /*var(--pagegrid-gutteroutside);*/
  scroll-margin-left: 0; /*var(--pagegrid-gutteroutside);*/
}




  .pageheader-carrousel__previousbutton
, .pageheader-carrousel__nextbutton
{
  color: #FFFFFF;
  background-color: rgba(0,0,0,0.1);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);

  transition: opacity 0.2s
            , border-color 0.2s;


  cursor: pointer;
  border: 2px solid #FFF;

  width: 44px;
  height: 44px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

  .pageheader-carrousel__previousbutton:not(:disabled):hover
, .pageheader-carrousel__nextbutton:not(:disabled):hover
{
  background-color: #FFFFFF;
  border-color: rgba(0,0,0,0.2);

  color: #000000;
}

.pageheader-carrousel__previousbutton::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-left);
  font-size: 20px;
}

.pageheader-carrousel__nextbutton::before
{
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  font-size: 20px;
}


  .pageheader-carrousel__previousbutton:disabled
, .pageheader-carrousel__nextbutton:disabled
{
  opacity: 0.3;
  border-color: transparent;
}
