/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.projecttile {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: #FFFFFF;
  box-shadow: var(--itempanel-box-shadow);
  padding-bottom: 12px;
}

.projecttile.notmatching {
  display: none;
}

.projecttile__top {
  position: relative;
  overflow: hidden;
}

.projecttile__top::after {
  display: block;
  width: 100%;
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) 100%);
  position: absolute;
  bottom: 0;
  padding-top: 66px;
}

.projecttile .projecttile__top::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  background-color: var(--color-theme);
  opacity: 0;
  transition: opacity 0.25s;
}

.projecttile:hover .projecttile__top::before {
  opacity: 0.8;
}

.projecttile--edit:hover .projecttile__top::before {
  opacity: 0.73;
}

.projecttile--edit:hover .projecttile__state > .projecttile__tag--islive {
  outline: 1px solid rgba(0, 0, 0, 0.5);
}

.projecttile__image {
  content: "";
  display: block;
  padding-top: 65%;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  transform: scale(1);
  transition: transform 0.25s;
}

.projecttile__image--placeholder {
  background-color: #F2EFED;
}

.projecttile:hover .projecttile__image {
  transform: scale(1.15);
}

.projecttile__title {
  position: absolute;
  z-index: 1;
  left: 16px;
  right: 16px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  bottom: 12px;
  color: #FFFFFF;
  font: 19px/22px var(--rtd-heading-fontfamily);
}

.projecttile__state,
.projecttile__toptags {
  position: absolute;
  left: 16px;
  top: 17px;
}

.projecttile__state .projecttile__tag {
  background-color: #FFFFFF;
}

.projecttile__state .projecttile__tag--islive {
  background-color: var(--color-theme);
  color: #FFFFFF;
}

.projecttile__top::before {
  z-index: 1;
}

.projecttile__state {
  z-index: 1;
}

.projecttile__toptags {
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 2;
}

.projecttile__toptags {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.projecttile__toptags .projecttile__tag {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF;
}

.projecttile:hover .projecttile__toptags {
  opacity: 1;
}

.projecttile__description {
  margin-top: 14px;
  margin-bottom: 14px;
  padding: 0 16px;
}

.projecttile__tagslist {
  margin-top: auto;
  margin-bottom: 14px;
  padding: 0 16px;
}

.projecttile__description {
  flex: 0 0 auto;
  color: var(--rtd-text-color);
  font: 0.7368421053 var(--rtd-text-fontfamily);
  word-break: break-word;
  /*
    This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68

  //   This work on:
  //   - Chrome 14+  (sep 2011)
  //   - Edge 17+    (apr 2018)
  //   - Safari 5+   (mar 2012)
  //   - iOS 5+      (mar 2012)
  //   - Firefox 68+ (jul 2019)

     Also see
     - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
     - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
     - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
    */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}

.projecttile__tag {
  display: inline-flex;
  align-items: center;
  height: 22px;
  border: 1px solid var(--color-theme);
  color: var(--color-theme);
  white-space: nowrap;
  font: 12px var(--rtd-text-fontfamily);
  padding: 0 13px;
  border-radius: 11px;
}

.projecttile__tag.projecttile__highlighttag {
  height: 22px;
  border: 1px solid #FFFFFF;
  border-radius: 16px;
  background-color: var(--color-theme);
  color: #FFFFFF;
  font: bold 12px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.projecttile__tagslist .projecttile__tag + .projecttile__tag {
  margin-top: 9px;
}

.project__themes {
  display: block;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.project__description {
  font-size: 14px;
}

.project__tags {
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 10px 10px 5px;
}
.project__tags > span {
  display: inline-block;
  height: 20px;
  margin-left: 5px;
  margin-bottom: 5px;
  background-color: #333;
  border-radius: 10px;
  padding: 3px 10px 0 10px;
}

.projecttile__editbuttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.projecttile__editbuttons__edit {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  height: 37px;
  padding: 0 24px 0 11px;
  font: 15px var(--rtd-text-fontfamily);
}
.projecttile__editbuttons__edit:hover {
  background-color: var(--button-color);
  color: #FFFFFF;
  color: var(--button-contrastcolor);
  text-decoration: none;
}
.projecttile__editbuttons__edit .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.projecttile__editbuttons__edit::before {
  margin-right: 8px;
  content: "\f040";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 15px;
}

.projecttile__editbuttons__delete {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--rtd-text-color);
  font: 15px var(--rtd-text-fontfamily);
  text-decoration: none;
  white-space: nowrap;
}

.projecttile__editbuttons__delete::after {
  margin-left: 8px;
  font: var(--formcontrol-icon-delete-font);
  content: var(--formcontrol-icon-delete-icon);
  font-size: 15px;
}

.projecttile--newproject {
  padding-bottom: 0;
  /*
    .projecttile__top::before // orange hover overlay
    {
      display: none;
    }
  */
}
.projecttile--newproject .projecttile__top::after {
  display: none;
}
.projecttile--newproject .projecttile__title {
  color: var(--color-theme);
  font: bold 19px var(--rtd-heading-fontfamily);
  text-shadow: none;
  text-align: center;
}
.projecttile--newproject:hover .projecttile__title {
  color: #FFFFFF;
}

.projecttile--newproject .projecttile__add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.projecttile--newproject .projecttile__add::after {
  content: "";
  content: "\f055";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 50px;
  color: var(--color-theme);
}

.projecttile__debug {
  display: none;
  color: var(--color-theme);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  line-height: 125%;
}

.site--debug .projecttile__debug {
  display: block;
}