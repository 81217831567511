/*
SHARED between the frontend (site) and backend (RTD editor).
So keep this plain CSS!
*/

/* --3col, --focusleft, --focusright */
.widget-richcontent--special + .widget-richcontent--special
{
  /* We never want these to connect because people may think columns between two
     of these widgets connect.. And then don't realize that in tablet or mobile layout
     they won't connect (messing up the order of content)
  */
  margin-top: 100px;
}


@media (min-width: 768px)
{
  .widget-richcontent--3col .widget-richcontent__content
  {
    display: flex;
    column-gap: 30px;
  }

  .widget-richcontent--3col .widget-richcontent__column
  {
    flex: 1 1 0px;
  }


  .widget-richcontent--focusleft .widget-richcontent__content
  {
    display: flex;
    column-gap: 30px;
  }
  .widget-richcontent--focusleft .widget-richcontent__column
  {
    flex: 2 1 30px;
  }
  .widget-richcontent--focusleft .widget-richcontent__column + .widget-richcontent__column
  {
    flex: 1 1 0px;
  }


  .widget-richcontent--focusright .widget-richcontent__content
  {
    display: flex;
    column-gap: 30px;
  }
  .widget-richcontent--focusright .widget-richcontent__column
  {
    flex: 1 1 0px;
  }
  .widget-richcontent--focusright .widget-richcontent__column + .widget-richcontent__column
  {
    flex: 2 1 30px;
  }
}
