.site-scrollprogressbar
{
  grid-row: 3;
  grid-column: var(--pagegrid-col-fullwidth);
  /*z-index: var(--zindex-overlap-contentarea);*/

  height: 3px;
  background-color: #D6D2D1;

  /*
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  */
/*
  position: sticky;
  top: 97px;
*/
}

.site-scrollprogressbar__indicator
{
  content: "";
  /*grid-column: var(--pagegrid-col-verywide);*/
  width: 50px;
  height: 100%;
  background-color: var(--color-theme);
}
