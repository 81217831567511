/*
FIXME: maybe we should use flex for .pageheader-sidebyside and then grid or flex on .pageheader-sidebyside__text
*/
.pageheader-sidebyside {
  display: grid;
  grid-column: 1/-1;
  grid-template-columns: var(--pagegrid-template-columns);
  /* - vertically center the textual content (but a little more to the top)
     - show the logo in the bottom whitespace area
     - allow the bottom whitespace to grow (to fit the logo) if the text doesn't leave enough whitespace in combination with the preffered header height
  */
  grid-template-rows: minmax(var(--sidebyside-top), 2fr) min-content minmax(var(--sidebyside-bottom), 3fr);
  background-color: var(--theme-background);
  --rtd-text-color: var(--theme-textcolor);
  --rtd-link-color: #FFFFFF;
  --component-tag-color: #FFFFFF;
  --component-tag-hover-background: #FFFFFF;
  --component-tag-border-color: #FFFFFF;
}

.pageheader-sidebyside--playingvideo.pageheader-sidebyside--playingvideo.pageheader-sidebyside--playingvideo {
  background-color: #000000;
}

html.use-edition-themecolor .pageheader-sidebyside {
  background-color: var(--edition-themecolor);
  --rtd-text-color: var(--edition-themecontrastcolor);
  --rtd-link-color: var(--edition-themecontrastcolor);
  --button-color: var(--edition-themecontrastcolor);
}

.pageheader-sidebyside__text {
  color: var(--rtd-text-color);
}

.pageheader-sidebyside__backlink {
  color: var(--rtd-text-color);
  text-decoration: none;
}

.pageheader-sidebyside__backlink:hover {
  text-decoration: underline;
}

.pageheader-sidebyside__backlink::before {
  display: inline-block; /* to prevent inheriting the underline on hover */
  font: var(--icon-link-font);
  content: var(--icon-backlink-content);
  margin-right: 20px;
}

.pageheader-sidebyside__pagedate + .pageheader-sidebyside__title {
  margin-top: 22px;
}

.pageheader-sidebyside__pagedate::before {
  margin-top: -5px;
  font: 200 18px "Font Awesome 5 Pro";
  content: var(--fa-calendar-day);
  margin-right: 10px;
}

/* FIXME: generalize weird editions header changes */
.pageheader-sidebyside__title ~ .pageheader-sidebyside__pagedate {
  font: bold 21px/30px var(--rtd-text-fontfamily);
}

.pageheader-sidebyside__title ~ .pageheader-sidebyside__pagedate::before {
  display: none;
}

.pageheader-sidebyside__location {
  font: 21px/30px var(--rtd-text-fontfamily);
}

.pageheader-sidebyside__title ~ .pageheader-sidebyside__pagedate + p,
.pageheader-sidebyside__title ~ .pageheader-sidebyside__location + p {
  margin-top: 30px;
}

.pageheader-sidebyside__image--placeholder {
  background-color: rgba(255, 255, 255, 0.03);
}

.pageheader-sidebyside__mediawrapper {
  display: grid;
}

.pageheader-sidebyside__item {
  grid-column: 1;
  grid-row: 1;
}

.pageheader-sidebyside__item {
  opacity: 0;
  transition: opacity 0.25s;
  pointer-events: none;
}

.pageheader-sidebyside__item.activeslide {
  opacity: 1;
  pointer-events: auto;
}

/*
.pageheader-sidebyside--playingvideo::before
{
  content: "";
  grid-column: 1 !important;
  grid-row: 1 / 4;
  // background: linear-gradient(to right, rgba(0,0,0,0) calc(100% - 200px), rgba(0,0,0,1) 100%);
  background: linear-gradient(94deg,rgba(0,0,0,0) calc(100% - 220px),black 87%);
}

.pageheader-sidebyside--playingvideo::after
{
  content: "";
  grid-column: 27 !important;
  grid-row: 1 / 4;
  //background: linear-gradient(to left, rgba(0,0,0,0) calc(100% - 200px), rgba(0,0,0,1) 100%);
  background: linear-gradient(274deg,rgba(0,0,0,0) calc(100% - 220px),black 87%);
}
*/
.pageheader-sidebyside--playingvideo.pageheader-sidebyside--playingvideo .pageheader-sidebyside__mediawrapper {
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.pageheader-sidebyside__item--video {
  display: grid;
  grid-template-rows: 100%;
  height: 100%;
}

.pageheader-sidebyside__item--video .pageheader-sidebyside__image,
.pageheader-sidebyside__item--video .pageheader-sidebyside__videocontainer {
  grid-column: 1;
  grid-row: 1;
}

.pageheader-sidebyside__playbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  grid-column: 1;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 101px;
  height: 101px;
  border-radius: 50%;
  background-color: #000000;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.pageheader-sidebyside__playbutton::before {
  content: var(--fa-play);
  font: var(--fontawesome-solid);
  font-size: 28px;
  color: #FFFFFF;
  align-self: center;
  margin-left: 3px;
}

.pageheader-sidebyside__playbutton:hover {
  /* https://www.florin-pop.com/blog/2019/03/css-pulse-effect/ */
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.pageheader-sidebyside__videocontainer {
  z-index: -1;
  visibility: hidden;
}

.pageheader-sidebyside__videohoverblock {
  position: relative;
  display: none;
}

.pageheader-sidebyside__videohoverblock::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 150px;
}

.pageheader-sidebyside__closebutton {
  z-index: 10;
  color: #FFFFFF;
  display: flex;
  padding: 25px 30px;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  cursor: pointer;
}

.pageheader-sidebyside__videohoverblock,
.pageheader-sidebyside__closebutton {
  grid-column: 20/26;
  grid-row: 1;
  align-self: start;
  justify-self: end;
  z-index: 1000;
  position: relative;
}

.pageheader-sidebyside__videohoverblock {
  grid-column: 20/27;
}

.pageheader-sidebyside__closebutton::before {
  font: 400 28px "Font Awesome 5 Pro";
  content: var(--fa-times);
}

.pageheader-sidebyside__closebutton:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.pageheader-sidebyside--playingvideo .pageheader-sidebyside__image {
  display: none;
}
.pageheader-sidebyside--playingvideo .pageheader-sidebyside__videohoverblock {
  display: block;
}
.pageheader-sidebyside--playingvideo .pageheader-sidebyside__closebutton {
  opacity: 1;
}
.pageheader-sidebyside--playingvideo .pageheader-sidebyside__jumpbuttons {
  display: none;
}

.pageheader-sidebyside__item--video.playingvideo {
  content: "";
  grid-row: 1/span 3;
  grid-column: 1/-1;
  background-color: #000000;
  z-index: 1;
}

.pageheader-sidebyside__item--video .pageheader-sidebyside__videocontainer {
  z-index: 2;
}

.pageheader-sidebyside__item.playingvideo .pageheader-sidebyside__videocontainer {
  z-index: 0;
  visibility: visible;
}

.pageheader-sidebyside__item.playingvideo .pageheader-sidebyside__playbutton {
  opacity: 0;
}

@media (min-width: 768px) {
  .pageheader-sidebyside {
    --sidebyside-top: 45px;
    --sidebyside-bottom: 30px;
  }
  .pageheader-sidebyside--withimage {
    --sidebyside-top: 60px;
    --sidebyside-bottom: 60px;
  }
  /* By applying the height to the before we control the size we want,
     but still allow the header to be stretched due to long text content.
  */
  .pageheader-sidebyside--withimage::before {
    content: "";
    grid-column: 2;
    grid-row: 1/span 3;
    min-height: var(--pageheader-sidebyside-minheight);
    height: 80vw; /* fallback for iOS < 13.4 */
    /* minus approximate siteheader height */
    height: var(--pageheader-sidebyside-height);
  }
  .pageheader-sidebyside--withlogo {
    --sidebyside-top: 90px;
    --sidebyside-bottom: 160px;
    grid-template-rows: minmax(var(--sidebyside-top), 2fr) min-content minmax(var(--sidebyside-bottom), 3fr);
  }
  .pageheader-sidebyside__backlink {
    grid-row: 1;
    margin-top: min(60px, 3vw);
    margin-bottom: min(30px, 3vw);
  }
  .pageheader-sidebyside__text {
    grid-row: 2;
  }
  .pageheader-sidebyside__backlink,
  .pageheader-sidebyside__text {
    grid-column: var(--pagegrid-col-verywide-left-extraspacing);
    padding-left: 0px;
    padding-right: 40px;
  }
  .pageheader-sidebyside__pagedate {
    font: 17px var(--rtd-heading-fontfamily);
    text-transform: lowercase;
  }
  .pageheader-sidebyside__title {
    font: bold 63px/70px var(--rtd-heading-fontfamily);
    font-size: clamp(41px, 5vw, 63px);
    line-height: 110%;
    margin-bottom: 15px;
  }
  .pageheader-sidebyside__subtitle {
    font: 24px/32px var(--rtd-heading-fontfamily);
    margin-bottom: 15px;
  }
  .pageheader-sidebyside__text > p.normal {
    font: 20px/32px var(--rtd-text-fontfamily);
  }
  .pageheader-sidebyside__button {
    margin-top: 30px;
  }
  html.page--edition .pageheader-sidebyside {
    display: grid;
    grid-template-rows: min-content min-content min-content;
  }
  html.page--edition .pageheader-sidebyside--withimage.pageheader-sidebyside--withimage::before {
    content: "";
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
    grid-row: 1/span 3;
    aspect-ratio: 16/9;
    min-height: auto;
    height: auto;
  }
  html.page--edition .pageheader-sidebyside__text {
    padding-top: 0px;
    padding-bottom: 55px;
  }
  html.page--edition .pageheader-sidebyside__backlink, html.page--edition .pageheader-sidebyside__text {
    grid-column: var(--pagegrid-col-verywide-right-extraspacing);
    padding-left: 40px;
    padding-right: 0px;
  }
  html.page--edition .pageheader-sidebyside--withimage::before,
  html.page--edition .pageheader-sidebyside__mediawrapper {
    grid-column: var(--pagegrid-col-viewportstart-to-middle);
  }
  .pageheader-sidebyside__mediawrapper {
    grid-column: var(--pagegrid-col-middle-to-viewportend);
    grid-row: 1/span 3;
  }
  .pageheader-sidebyside__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  /* For Chrome we need to get the image outside the flow.
     Otherwise Chrome seems to enforce the image's aspect ratio
     (but due to using row-span it fails to stretch it's container correctly,
     causing the image to overflow the grid container).
     (Chrome 102 has this bug)
  */
  .pageheader-sidebyside__mediawrapper {
    position: relative;
  }
  .pageheader-sidebyside__image,
  .pageheader-sidebyside__videocontainer {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  /* end of Chrome fix **************/
  .pageheader-sidebyside__jumpbuttons {
    position: absolute;
    right: 38px;
    top: 50%;
    transform: translaten(0, -50%);
  }
  .pageheader-sidebyside__jumpbutton {
    display: block;
    width: 13px;
    height: 13px;
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;
  }
  .pageheader-sidebyside__jumpbutton.active {
    background-color: #141414;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .pageheader-sidebyside__jumpbutton {
    margin-top: 22px;
  }
  .pageheader-sidebyside__logos {
    grid-column: var(--pagegrid-col-verywide-left-extraspacing);
    grid-row: 3;
    align-self: center;
    opacity: 0.5;
  }
  .pageheader-sidebyside__logos > * {
    max-height: 89px;
  }
}
/*
Mobile
- we make the image have 16:9 so a header with video will have the same size
*/
@media (max-width: 767px) {
  .pageheader-sidebyside {
    display: grid;
    grid-template-rows: min-content min-content min-content;
  }
  .pageheader-sidebyside__mediawrapper {
    grid-column: 1/-1;
    grid-row: 1;
    display: grid;
  }
  .pageheader-sidebyside__jumpbuttons {
    grid-column: 1/-1;
    grid-row: 1;
    display: none; /* fixme */
  }
  .pageheader-sidebyside__item {
    grid-column: 1;
    grid-row: 1;
  }
  .pageheader-sidebyside__image {
    object-fit: cover;
    width: 100%;
    aspect-ratio: 16/9;
  }
  .pageheader-sidebyside--hasvideo .pageheader-sidebyside__mediawrapper {
    height: auto;
  }
  .pageheader-sidebyside--hasvideo .pageheader-sidebyside__image {
    height: auto;
    aspect-ratio: 16/9;
  }
  .pageheader-sidebyside__backlink {
    grid-row: 2;
    grid-column: var(--pagegrid-col-verywide);
    margin-top: 25px;
  }
  .pageheader-sidebyside__text {
    grid-row: 3;
    grid-column: var(--pagegrid-col-verywide);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .pageheader-sidebyside__backlink + .pageheader-sidebyside__text {
    margin-top: 30px;
  }
  .pageheader-sidebyside__pagedate {
    font: 15px var(--rtd-heading-fontfamily);
    text-transform: lowercase;
  }
  .pageheader-sidebyside__title {
    font: bold 48px/62px var(--rtd-heading-fontfamily);
    margin-bottom: 15px;
  }
  .pageheader-sidebyside__text > p.normal {
    font: 18px/28px var(--rtd-text-fontfamily);
  }
  .pageheader-sidebyside__button {
    margin-top: 30px;
  }
  .pageheader-sidebyside__logos {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 4;
    margin-bottom: 20px;
    opacity: 0.5;
  }
  .pageheader-sidebyside__logos > * {
    max-height: 80px;
  }
}
.pageheader-sidebyside .ftu-tagsblock {
  margin-top: 30px;
}

.ftu-tagsblock__button + .ftu-tagsblock {
  margin-top: 60px;
}