/*
.spc-tile__top.     0
::after             1  (the gradient which is shown upon hover)
.spc-tile__tagslist 2
.spc-tile__title    2
*/
.newsoverview__items {
  --component-tag-text-color: #FFFFFF;
  --component-tag-font: 12px var(--rtd-text-fontfamily);
  --component-tag-height: 22px;
  --component-tag-radius: 16px;
  --component-tag-padding: 3px 13px;
}
.newsoverview__items .spc-tag[data-tagcolor=orange],
.newsoverview__items .spc-tag[data-tagcolor=themecolor] {
  --component-tag-background: #E0670BE0;
  --component-tag-border: 1px solid #FFFFFF;
}
.newsoverview__items .spc-tag[data-tagcolor=grey],
.newsoverview__items .spc-tag[data-tagcolor=neutral] {
  --component-tag-background: #3F3F3FE0;
  --component-tag-border: 1px solid #FFFFFF;
}

.newsoverview.newsoverview {
  --component-spctile-title-color: #FFFFFF;
}
.newsoverview.newsoverview .spc-tile__image--empty {
  background-color: #AAAAAA;
}
.newsoverview.newsoverview .spc-tile__meta {
  display: contents;
}
.newsoverview.newsoverview .spc-tile__tagslist {
  grid-column: 1;
  grid-row: 1/span 2;
  height: 100%;
  overflow: hidden;
  contain: size;
  /*
      display: -webkit-box; // ONLY works with -webkit-box
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
  */
  text-overflow: ellipsis;
  align-self: start;
  display: inline-block;
  padding: 15px var(--component-spctile-padding-h) 0 var(--component-spctile-padding-h);
  text-wrap: balance;
  text-wrap: pretty;
  z-index: 2;
}
.newsoverview.newsoverview .spc-tile__tagslist > * {
  margin-bottom: 3px;
}
.newsoverview.newsoverview .spc-tile__title {
  grid-column: 1;
  grid-row: 2;
  align-self: end;
  padding: 0 var(--component-spctile-padding-h) 15px var(--component-spctile-padding-h);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  word-break: break-word; /* text must allways fix */
  z-index: 2;
}
.newsoverview.newsoverview .spc-tile::before {
  content: "";
  /*
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  */
  grid-column: 1;
  grid-row: 2;
  align-self: end;
  height: 100%;
  z-index: 2;
  position: relative;
  top: -25px;
  margin-bottom: -25px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.newsoverview.newsoverview .spc-tile::after {
  content: "";
  grid-column: 1;
  grid-row: 1/span 2;
  background: radial-gradient(circle, rgba(0, 103, 180, 0) 25%, rgba(0, 103, 180, 0.3) 100%), radial-gradient(circle, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.6) 100%);
  outline: 3px solid var(--color-blue);
  outline-offset: 2px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;
}
.newsoverview.newsoverview .spc-tile:hover::after {
  content: "";
  opacity: 1;
}
.newsoverview.newsoverview.folderwithfilters__items--withimages .spc-tile__title {
  padding-bottom: 15px;
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
}
.newsoverview.newsoverview .spc-tile__description {
  padding: var(--component-spctile-padding-v) var(--component-spctile-padding-h);
}
.newsoverview.newsoverview .spc-tile__when {
  padding: 0 var(--component-spctile-padding-h) var(--component-spctile-padding-v) var(--component-spctile-padding-h);
}
.newsoverview.newsoverview .spc-tile__when .icon {
  margin-right: 8px;
}
.newsoverview.newsoverview .spc-tile__when .icon::before {
  content: var(--fa-calendar-alt);
  font: var(--fontawesome-regular);
  font-size: 14px;
}
.newsoverview.newsoverview .spc-tile--withdeadline .spc-tile__when .icon::before {
  content: var(--fa-stopwatch);
  font-size: 17px;
  position: relative;
  top: 1px;
}
.newsoverview.newsoverview .spc-tile--blogitem .icon::before {
  content: var(--fa-clock);
}