.widget--practicaldetails .widget__title {
  margin-bottom: 15px;
  color: var(--color-theme);
}

.practicaldetails__table tr {
  border-top: 1px solid var(--color-theme);
}

.practicaldetails__table tr:last-child {
  border-bottom: 1px solid var(--color-theme);
}

.practicaldetails__table th {
  text-transform: uppercase;
  color: #000000;
  vertical-align: baseline;
}

.practicaldetails__table td {
  color: var(--rtd-text-color);
  vertical-align: baseline;
}

.practicaldetails__table td > *:first-child {
  margin-top: 0;
}

@media (max-width: 800px) {
  .practicaldetails__table {
    display: block;
  }
  .practicaldetails__table tbody {
    display: block;
  }
  .practicaldetails__table tr {
    display: block;
    padding: 14px 0 6px 0;
  }
  .practicaldetails__table th {
    display: block;
    font: 15px/15px var(--rtd-heading-fontfamily);
    text-align: left;
  }
  .practicaldetails__table td {
    display: block;
    font: 17px/20px var(--rtd-text-fontfamily);
  }
}
@media (min-width: 801px) {
  .practicaldetails__table {
    width: 100%;
    border-collapse: collapse;
  }
  .practicaldetails__table th,
  .practicaldetails__table td {
    padding: 13px 0 12px 0;
  }
  .practicaldetails__table th {
    font: 15px/20px var(--rtd-heading-fontfamily);
    padding-right: 70px;
    text-align: left;
    white-space: nowrap;
  }
  .practicaldetails__table td {
    width: 100%;
    font: 16px/20px var(--rtd-text-fontfamily);
  }
}