/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*

Button:
- link
- outline
- solid


Defining a button
    a.wh-form-button
    button.wh-form-button
    *.wh-form-button

Layout modifyers:
    .wh-form-button--outline - Overrides the default solid style of the button
    .wh-form-button--solid

Communicating intent (icons):
    .wh-form__button--backward
    .wh-form__button--forward

*/
.wh-form__button,
input[name=unsubscribebutton],
wh-form-upload::part(selectbutton) {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
}

.wh-form__button--darkblue {
  --button-color: var(--color-darkblue);
  --button-color-hover: var(--color-darkblue-hover);
}

.wh-form__button--orange {
  --button-color: var(--color-orange);
  --button-color-hover: var(--color-orange-hover);
}

.wh-form__button--red {
  --button-color: var(--color-red);
  --button-color-hover: var(--color-red-hover);
}

.wh-form__button--black {
  --button-color: var(--color-black);
  --button-color-hover: var(--color-black-hover);
}

.wh-form__button:not(.wh-form__button--solid),
.wh-form__button--outline,
wh-form-upload::part(selectbutton) {
  background-color: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
}
.wh-form__button:not(.wh-form__button--solid):hover,
.wh-form__button--outline:hover,
wh-form-upload::part(selectbutton):hover {
  background-color: var(--button-color);
  color: #FFFFFF;
  color: var(--button-contrastcolor);
  text-decoration: none;
}
.wh-form__button:not(.wh-form__button--solid) .wh-form__button--disabled,
.wh-form__button--outline .wh-form__button--disabled,
wh-form-upload::part(selectbutton) .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.wh-form__buttongroup .wh-form__button {
  background-color: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
}
.wh-form__buttongroup .wh-form__button:hover {
  background-color: var(--button-color);
  color: #FFFFFF;
  color: var(--button-contrastcolor);
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.wh-form__buttongroup .wh-form__button--next,
.wh-form__buttongroup .wh-form__button--submit {
  background-color: var(--color-orange);
  color: #FFFFFF;
  border: 0;
}
.wh-form__buttongroup .wh-form__button--next:hover,
.wh-form__buttongroup .wh-form__button--submit:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button--next .wh-form__button--disabled,
.wh-form__buttongroup .wh-form__button--submit .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.wh-form__button.wh-form__button--solid,
input[name=unsubscribebutton] {
  background-color: var(--color-orange);
  color: #FFFFFF;
  border: 0;
}
.wh-form__button.wh-form__button--solid:hover,
input[name=unsubscribebutton]:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__button.wh-form__button--solid .wh-form__button--disabled,
input[name=unsubscribebutton] .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.wh-form .wh-form__button {
  height: var(--formcontrols-height);
}

.wh-form__navbuttons .wh-form__button {
  height: var(--formcontrols-height);
}

.wh-form__button--backward::before,
.wh-form .wh-form__button--previous::before,
.spc-textbutton--backward::before {
  font: var(--fontawesome-solid);
  content: var(--fa-arrow-left);
  font-size: 13px;
}

.wh-form__button--backward::before,
.spc-textbutton--backward::before {
  margin-right: 15px;
}

.wh-form .wh-form__button--previous::before {
  margin-right: auto;
}

.spc-textbutton .wh-form__button--backward::before {
  margin-right: 15px;
}

.wh-form__button--forward::after,
.wh-form .wh-form__button--next::after,
.wh-form .wh-form__button--submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}

.spc-textbutton--forward::before {
  font: var(--fontawesome-solid);
  content: var(--fa-arrow-right);
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

.spc-textbutton .wh-form__button--forward::after {
  margin-left: 15px;
}

.widget-cta .wh-form__button--forward::after {
  margin-left: 70px;
}

.wh-form__button--next,
.wh-form__button--submit {
  justify-content: space-between;
}

.widget--background-darkblue .wh-form__button.wh-form__button,
.widget--background-orange .wh-form__button.wh-form__button,
.widget--background-red .wh-form__button.wh-form__button,
.page-header__slidecontent .wh-form__button.wh-form__button--outline:not(:hover) {
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.widget--background-darkblue .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: var(--color-darkblue);
}

.widget--background-red .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: var(--color-red);
}

.widget--background-orange .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: var(--color-orange);
}

form.wh-form--submitting .wh-form__button--submit {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.spc-textbutton {
  color: var(--button-color);
  text-decoration: none;
}

.spc-textbutton:hover {
  color: var(--button-color-hover);
  text-decoration: underline;
}

.spc-textbutton.wh-form__button--red {
  color: var(--color-red);
}

.spc-textbutton.wh-form__button--red:hover {
  color: var(--color-red-hover);
}

.spc-textbutton.wh-form__button--darkblue {
  color: var(--color-darkblue);
}

.spc-textbutton.wh-form__button--darkblue:hover {
  color: var(--color-darkblue-hover);
}

.spc-textbutton.wh-form__button--orange {
  color: var(--color-orange);
}

.spc-textbutton.wh-form__button--orange:hover {
  color: var(--color-orange-hover);
}

.wh-form__button--outline.wh-form__button--red {
  border-color: var(--color-red);
  color: var(--color-red);
}

.wh-form__button--outline.wh-form__button--red:hover {
  background-color: var(--color-red);
}

.wh-form__button--outline.wh-form__button--darkblue {
  border-color: var(--color-darkblue);
  color: var(--color-darkblue);
}

.wh-form__button--outline.wh-form__button--darkblue:hover {
  background-color: var(--color-darkblue);
}

.wh-form__button--outline.wh-form__button--orange {
  border-color: var(--color-orange);
  color: var(--color-orange);
}

.wh-form__button--outline.wh-form__button--orange:hover {
  background-color: var(--color-orange);
}

.wh-form__button--solid.wh-form__button--red {
  background-color: var(--color-red);
}

.wh-form__button--solid.wh-form__button--darkblue {
  background-color: var(--color-darkblue);
}

.wh-form__button--solid.wh-form__button--orange {
  background-color: var(--color-orange);
}

.wh-form__button--solid.wh-form__button--red:hover {
  background-color: var(--color-red-hover);
}

.wh-form__button--solid.wh-form__button--darkblue:hover {
  background-color: var(--color-darkblue-hover);
}

.wh-form__button--solid.wh-form__button--orange:hover {
  background-color: var(--color-orange-hover);
}