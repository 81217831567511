/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.projects__results.projects__results {
  margin-top: 25px;
}

html.page--projectsoverview .page__balloon {
  height: 83px;
}

.innovationspage--overview .header-projectpage, .innovationspage--overview .page--projectsoverview .page__body,
.innovationspage--overview .page--projectsoverview .page__balloon {
  background-color: var(--color-lightgrey);
}

.filteredoverview-action--resetfilters {
  display: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: 14px var(--rtd-text-fontfamily);
  color: var(--color-theme);
  text-decoration: underline;
  margin-left: 7px;
  padding: 4px 8px;
  cursor: pointer;
}

.filteredoverview--filtersactive .filteredoverview-action--resetfilters {
  display: block;
}

@media (min-width: 1023px) {
  .header-page--projectsoverview,
  .projects__results,
  html.filteredoverview--showfilter .projects__filters {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-page--projectsoverview__title,
  .projects__results__items {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (max-width: 1023px) {
  .projects__results {
    padding-left: var(--pagegrid-gutteroutside-mobile);
    padding-right: var(--pagegrid-gutteroutside-mobile);
  }
}
.projects__results {
  margin: -4px;
  padding: 4px;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option input + label {
  flex: none;
  margin-right: 10px;
}

@media (max-width: 500px) {
  .header__filterbar__foldout {
    padding-bottom: 0;
  }
  .projects__filters__label {
    display: none !important;
  }
  .genericfilters__panel {
    padding-top: 22px;
    padding-bottom: 25px;
  }
  .filtertags__items {
    margin-top: 5px;
  }
}
@media (min-width: 501px) {
  .header__filterbar__foldout {
    padding-bottom: 30px;
  }
}