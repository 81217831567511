.widget--stories
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}


.widget--stories .projectsviewer__items
{
}



.ftu-storyblock
{
  display: flex;
  flex-direction: column;

  position: relative;
  text-decoration: none;

  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
}

/* override ".rtdcontent a:hover" */
a.ftu-storyblock.ftu-storyblock
{
  text-decoration: none;
}

.ftu-storyblock__image-portrait
{
  display: none;
}

.ftu-storyblock:nth-child(1)
{
  background-color: var(--color-darkblue);
  color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
}

.ftu-storyblock:nth-child(2)
{
  background-color: var(--color-orange);
  color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
}

.ftu-storyblock:nth-child(3)
{
  background-color: var(--color-black);
  color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
}

.ftu-storyblock__meta
{
  flex: 1 0 auto; /* fit content but stretch if an item next to us is higher */

  padding: 25px;

  /*color: var(--rtd-text-color);*/

  display: flex;
  flex-direction: column;
}

.ftu-storyblock__image-wrapper
{
  flex: none;
  width: 100%;

  /*position: relative;*/
  overflow: hidden;
}

.ftu-storyblock__image
{
  display: block;
  width: 100%;
  /*aspect-ratio: 4 / 3;*/
  object-fit: cover;

  transition: transform 0.15s ease-in-out
            , opacity   0.25s;
}

.ftu-storyblock__image--portrait
{
  display: none;
}



.ftu-storyblock:hover
{
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.4);
  /*
  border: 1px solid var(--color-theme-transparent);
  outline: 1px solid var(--color-theme-transparent);
  */
}

.ftu-storyblock:hover .ftu-storyblock__image
{
  transform: scale(1.1);
}

.ftu-storyblock:hover .ftu-storyblock__title
{
  text-decoration: underline;
}



.ftu-storyblock--noimage .ftu-storyblock__image-wrapper
{
  /*aspect-ratio: 4 / 3;*/
  background-color: rgba(255,255,255,0.1);
}

.ftu-storyblock__title
{
  font: var(--widget-item-heading-font);

  margin-bottom: 12px;
}

.ftu-storyblock__description
{
  font: var(--widget-item-description-font);

  margin-bottom: 15px;


  display: -webkit-box; /* ONLY works with -webkit-box */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
}

.ftu-storyblock__filler
{
  flex: 1 0 0px;
}

.ftu-storyblock__readtime
{
  margin-top: 30px;
  font: var(--widget-item-description-font);
}

.ftu-storyblock__readtime::before
{
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  font-size: 20px;
  content: var(--fa-clock);

  margin-right: 10px;
}

.ftu-storyblock__readtime:empty::before
{
  display: none;
}

.ftu-storyblock__meta > *:last-child
{
  margin-bottom: 0;
}

.ftu-storyblock__filler
{
  flex: 1 0 0px;
}



@media (min-width: 1200px)
{
  .widget--stories .projectsviewer__items
  {
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);
  }

  .ftu-storyblock:nth-child(1)
  {
    flex-direction: row;
  }

    .ftu-storyblock:nth-child(1) .ftu-storyblock__image-wrapper
  , .ftu-storyblock:nth-child(1) .ftu-storyblock__meta
  {
    flex: 0 0 50%;
  }

  .ftu-storyblock:nth-child(1) .ftu-storyblock__image-wrapper
  {
    order: 2;
  }

  .ftu-storyblock:nth-child(1) .ftu-storyblock__image
  {
    display: none;
  }

  .ftu-storyblock:nth-child(1) .ftu-storyblock__image--portrait
  {
    display: block;
    min-height: 150px;
    height: 100%;
  }

  .ftu-storyblock:nth-child(1) .ftu-storyblock__description
  {
    margin-top: auto;
  }
}
