/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.page__body > .page__headerfilters {
  grid-row: 5;
}

.page__headerfilters__filterbar {
  display: flex;
}

.filterform--alwaysopen .header-filterbar__togglefilters {
  display: none !important;
}

.header-innovations .header-expandedfilters {
  display: none;
}

.header-innovations.filterform--alwaysopen .header-expandedfilters,
html.filteredoverview--showfilters .header-innovations .header-expandedfilters {
  display: var(--header-expandedfilters-display);
}

.header-filterbar__togglefilters.header-filterbar__togglefilters.header-filterbar__togglefilters {
  display: grid;
}

.header-filterbar__togglefilters.header-filterbar__togglefilters {
  font: bold 16px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.header-filterbar__togglefilters > * {
  grid-column: 1;
  grid-row: 1;
}

@media (min-width: 800px) {
  .filterform--openondesktop .header-filterbar__togglefilters {
    display: none;
  }
  .filterform--openondesktop.filterform--openondesktop .header-expandedfilters {
    display: grid;
  }
}
.header-filterbar__togglefilters__open {
  visibility: visible;
}

.header-filterbar__togglefilters__close {
  visibility: hidden;
}

html.filteredoverview--showfilters .header-filterbar__togglefilters__open {
  visibility: hidden;
}
html.filteredoverview--showfilters .header-filterbar__togglefilters__close {
  visibility: visible;
}
html.filteredoverview--showfilters .page__body .footer {
  display: none;
}

.projects__filters label {
  cursor: pointer;
}

.header-filterbar__topbar__label {
  color: var(--color-theme);
  font: bold 18px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  align-self: center;
  margin-right: 38px;
}

@media (max-width: 500px) {
  .header__filterbar__foldout {
    padding-bottom: 0;
  }
  .resources__filters__label {
    display: none !important;
  }
  .tagcategory {
    padding-top: 22px;
    padding-bottom: 25px;
  }
  .tagcategory + .tagcategory {
    margin-top: 0 !important;
  }
}
@media (min-width: 501px) {
  .header__filterbar__foldout {
    padding-bottom: 30px;
  }
}
.page__headerfilters__expandedfilters {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-fullwidth);
}

.page__filterbar {
  grid-column: var(--pagegrid-col-fullwidth);
}

.header-expandedfilters__content {
  grid-column: var(--pagegrid-col-verywide);
}

.page--projectsoverview .page__headerfilters,
.page--resourcesoverview .page__headerfilters {
  background-color: var(--color-lightgrey);
}

.page--projectsoverview-du .page__headerfilters {
  background-color: var(--color-white);
}

html:not(.innovationspage) .header-expandedfilters {
  padding-top: 20px;
  padding-bottom: 20px;
}

/*
.header-innovations__filterbar header-filterbar__topbar

FIXME:ONLY when the topbar doesn't have a background color'image we need this
*/
@media (min-width: 768px) {
  html:not(.innovationspage) .header-innovations__background, html:not(.innovationspage) .header-innovations__filterbarbackground {
    grid-column: var(--pagegrid-col-verywide);
  }
  html:not(.innovationspage) .header-innovations__expandedfilters {
    padding-bottom: 24px;
  }
}
.genericfilters__foldout__heading {
  align-self: center;
  font: 25px/28px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.genericfilters__panel {
  background-color: #FFFFFF;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.22);
  padding: 20px 0;
}

.genericfilters__subpanel + .genericfilters__subpanel {
  margin-top: 35px;
}

.genericfilters__panel .checkboxlist__option {
  color: var(--rtd-text-color);
  font: 16px/22px var(--rtd-text-fontfamily);
}

.checkboxlist__option + .checkboxlist__option {
  margin-top: 3px;
}

.filterbar__option + .filterbar__option,
.multiselect__panel__item + .multiselect__panel__item {
  margin-top: 15px;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option input + label,
.multiselect__panel__item input + label {
  flex: none;
  margin-right: 10px;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option input + label {
  flex: none;
  margin-right: 10px;
}

.tagcategory__options {
  padding: 18px 16px 30px 16px;
  color: #000000;
}

.checkboxlist__option {
  display: flex;
  align-items: baseline;
}

.checkboxlist__option > input + label {
  margin-right: 10px;
}

.checkboxlist__option > input + label + label {
  padding-top: 8px;
  padding-bottom: 8px;
}

.switchesgroup__items .switchesgroup__item + .switchesgroup__item {
  margin-left: 35px;
}

/*
.spc-toggle + label
{
  font: 600 18px/24px var(--rtd-heading-fontfamily);
  color: #000000;
}
*/
.spc-toggle + label {
  margin-left: 8px;
  font: 17px/24px var(--rtd-text-fontfamily);
  color: var(--rtd-text-color);
}

input[type=checkbox].spc-toggle::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  font-size: 11px;
  line-height: 30px;
  color: #FFFFFF;
  align-self: center;
  position: absolute;
  left: 5px;
  line-height: 19px;
}

input[type=checkbox].spc-toggle:checked + label {
  color: #1E2328;
}

/*fieldset*/
.switchesgroup {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}

/*fieldset*/
.switchesgroup > legend {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.switchesgroup__items {
  display: flex;
}

.tagcategory__title {
  padding: 0 20px;
  margin-bottom: 10px;
  font: bold 20px/24px var(--rtd-heading-fontfamily);
  color: var(--color-theme);
  text-transform: uppercase;
}

.tagcategory__options {
  padding: 0 20px;
  color: #000000;
}

.checkboxlist__option {
  display: flex;
  align-items: baseline;
}

.checkboxlist__option > input + label {
  margin-right: 10px;
}

.checkboxlist__option > input + label + label {
  padding-top: 8px;
  padding-bottom: 8px;
}