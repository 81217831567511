.widget-quote
{
  grid-column: var(--pagegrid-col-default-max8col);
}


.widget-quote--hasimage
{
  display: flex;
  align-items: center;
}

.widget-quote--hasimage .widget-quote__image
{
  width:  var(--rtd-quote-photosize);
  height: var(--rtd-quote-photosize);
  border-radius: 50%;
  flex: none;

  margin-right: var(--rtd-quote-margin);
}

.widget-quote--hasimage .widget-quote__text
{
  flex: 1 0 0px;
}

.widget-quote--hasimage .widget-quote__quote.widget-quote__quote
{
  color: var(--rtd-quote-color);
  font: var(--widget-quote-withimage-font);
  font-style: italic;
}

.widget-quote--hasimage .widget-quote__quote + .widget-quote__person
{
  margin-top: 23px;
}

.widget-quote--hasimage .widget-quote__person__name
{
  color: var(--color-theme);
  font: bold 20px/32px var(--rtd-text-fontfamily);
}

.widget-quote--hasimage .widget-quote__person__function
{
  color: var(--rtd-text-color);
  font: 16px var(--rtd-text-fontfamily);
}



.widget-quote--withoutimage
{
  border-left: 3px solid var(--color-theme);
}

.widget-quote--withoutimage .widget-quote__text
{
  padding-left:   30px;
  padding-right:  30px;
}

.widget-quote--withoutimage .widget-quote__quote.widget-quote__quote
{
  color: var(--rtd-quote-color);
  font: var(--widget-quote-withoutimage-font);
  font-style: italic;
}

.widget-quote--withoutimage .widget-quote__person__name
{
  margin-top: 20px;
  color: var(--text-grey-small);
  font: normal 20px/32px var(--rtd-text-fontfamily);
}

.widget-quote--withoutimage .widget-quote__person__name::before
{
  content: "— ";
}

.widget-quote--withoutimage .widget-quote__person__function
{
  color: var(--rtd-quote-color);
  font: 16px var(--rtd-text-fontfamily);
  margin-top: 5px;
}
