.widget-image__image
{
  display: block;
  width: 100%;
}

.widget-image__caption.widget-image__caption > *
{
  margin-top: var(--widget-image-caption-margin);
}

.widget-image__caption
{
/*  color: var(--widget-image-caption-color);*/
/*  font: var(--widget-image-caption-font);*/
  --rtd-text-color: var(--widget-image-caption-color);
  --rtd-text-font: var(--widget-image-caption-font);
}
