@charset "UTF-8";
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

/* FIXME: quickhack to have the tabstrip in view when a tab hash is used */
html {
  scroll-padding-top: 200px;
}

@media (min-width: 768px) {
  html {
    --pageheader-default-minheight: 320px;
    --pageheader-default-height: 70vw; /* fallback for old Android (without min() support) */
    --pageheader-default-height:
            min( calc(70vw)
               , calc(75vh - var(--siteheader-height-approximation))
               , 450px
               );
    --pageheader-large-minheight: 320px;
    --pageheader-large-height: 70vw; /* fallback for old Android (without min() support) */
    --pageheader-large-height:
            min( calc(70vw)
               , calc(75vh - var(--siteheader-height-approximation))
               , 540px
               );
    --pageheader-flatten-minheight: 0;
    --pageheader-sidebyside-minheight: 540px;
    --pageheader-sidebyside-maxheight: 540px;
    --pageheader-carrousel-minheight: 400px;
    --pageheader-carrousel-maxheight: 745px;
    --pageheader-projectdetails-du-minheight: 400px;
    --pageheader-projectdetails-du-maxheight: 844px;
    --siteheader-height-approximation: 200px;
  }
  html.page--edition {
    --pageheader-sidebyside-minheight: 540px;
    --pageheader-sidebyside-maxheight: 750px;
  }
}
@media (max-width: 767px) {
  html {
    --pageheader-sidebyside-minheight: 350px;
    --pageheader-sidebyside-maxheight: 540px;
    --pageheader-carrousel-minheight: 400px;
    --pageheader-carrousel-maxheight: 745px;
    --pageheader-projectdetails-du-minheight: 400px;
    --pageheader-projectdetails-du-maxheight: 844px;
    --siteheader-height-approximation: 200px;
  }
}
html {
  --pageheader-sidebyside-height:
          min( calc(70vw)
             , calc(75vh - var(--siteheader-height-approximation))
             , var(--pageheader-sidebyside-maxheight)
             ) ;
}

html.unsupported-browser .header-top__content {
  position: relative;
}

html.unsupported-browser .header-top__content > * {
  display: none;
}

html.unsupported-browser .header-top__content::before {
  display: block;
  background-color: rgba(255, 255, 255, 0.35);
  padding: 11px 15px 8px 45px;
  content: "Internet Explorer isn't supported. Not all feature may work (correctly) on this website when using it.";
  font-size: 14px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

html.unsupported-browser .header-top__content::after {
  position: absolute;
  left: 15px;
  top: 19px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f05a";
  font-size: 21px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.consentbar__poscontainer {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.consentbar__content {
  grid-column: var(--pagegrid-col-default);
}

.consentbar__acceptcookiesbutton {
  padding-top: 4px;
  font: bold 18px Officina, Arial, Sans-Serif;
  text-transform: uppercase;
}

.page-backlink {
  grid-row: 1/span 2;
  align-self: start;
}

a.page-backlink {
  outline-offset: var(--rtd-link-outline-offset);
  display: flex;
  align-items: center;
  display: inline-block;
  color: var(--rtd-link-color);
  font: 17px var(--rtd-heading-fontfamily);
  text-decoration: none;
  padding-top: 3px;
  /* In Safari preview since feb 2023
  leading-trim: both;
  text-edge: cap alphabetic;
  */
}
a.page-backlink::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0a8";
  font-size: 18px;
  margin-right: 10px;
  text-decoration: none;
}

/* Backlink on these pages is stiled like the .projectheader__backlink__link
   (which is used for the Innovation Map) */
.page--ethics-person a.page-backlink {
  display: flex;
  align-items: center;
  font: 15px var(--rtd-heading-fontfamily);
  text-decoration: none;
  text-transform: uppercase;
}

.page--ethics-person a.page-backlink::before {
  content: var(--fa-chevron-left);
  font-size: 13px;
  margin-top: -3px; /* compensate for Officina font (of the label next to the icon) */
}

@media (max-width: 1023px) {
  .page-backlink {
    grid-column: var(--pagegrid-col-verywide);
  }
  .page-backlink + .page-tags {
    margin-top: 20px;
  }
}
@media (min-width: 1024px) {
  .page-backlink {
    grid-column: var(--pagegrid-gutter-left-full);
  }
}
.pageheader__date {
  grid-column: var(--pagegrid-col-default);
}

.page__body .pageheader__date {
  align-self: end;
}

#page-sharebuttons-heading {
  margin-bottom: 10px;
  font: var(--rtd-heading-weight) 16px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.deeplinks #page-sharebuttons-heading {
  font: var(--rtd-h2-font);
  text-transform: none;
}

#page-sharebuttons-heading {
  font-size: 16px;
}

.page-tags {
  grid-column: var(--pagegrid-col-default);
}

.pagetag.pagetag {
  display: inline-block;
  border: 1px solid #D2D1D1;
  border-radius: 16px;
  padding: 0 15px;
  font-weight: normal;
  color: var(--color-theme);
  font: 14px/17px var(--rtd-text-fontfamily);
  height: 28px;
  line-height: 28px;
  border-radius: 16px;
  margin-right: 11px;
  margin-bottom: 10px;
  text-decoration: none;
}

a.pagetag:hover {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
  color: #FFFFFF;
  text-decoration: none;
}

.page-tags + .page-header__title {
  margin-top: 15px;
}

.page__headerfilters {
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: var(--color-white);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__headerfilters__filterbar {
  grid-column: var(--pagegrid-col-verywide);
}

.pageheader-carrousel + .page__body > .page__headerfilters {
  padding-top: 60px; /* change to 80px when __filterbar starts to use row-gap instead of the padding hack */
}

.page__contentarea > .page-sharebuttons {
  margin-top: 80px;
}

.page__footer {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__footer__content {
  grid-column: var(--pagegrid-col-verywide);
  padding-bottom: 15px;
}

@media (max-width: 949px) {
  .page__footer {
    display: none;
  }
}
.widgetdl__item__icon.widgetdl__item__icon,
.widget-accordion__item__header::after {
  color: var(--color-theme);
}

/* FontAwesome5 only has the old Twitter icon,
   so override all uses of it with the 𝕏 in Aria-Black. */
.fa-twitter::before,
.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black !important;
  padding-left: 1px;
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  font-size: calc(var(--component-sharepage-button-symbolsize) * 1.2);
}

html {
  --pagegrid-template-columns-inside: 0 0 repeat(11, minmax(0, 1fr) 5px) minmax(0, 1fr) 0 0; /* continue grid inside container which is 12col */
}

@media (max-width: 639px) {
  html {
    --pagegrid-template-columns: [viewport_left] 0 15px repeat(11, minmax(0, 1fr) 5px) minmax(0, 1fr) 15px 0 [viewport_right];
  }
}
@media (min-width: 640px) {
  html {
    --pagegrid-template-columns: [viewport_left] calc((100% - 1424px) / 2) minmax(30px, calc((100% - 1294px) / 2)) repeat(11, minmax(0, 1fr) 26px) minmax(0, 1fr) minmax(30px, calc((100% - 1294px) / 2)) calc((100% - 1424px) / 2) [viewport_right];
  }
}
@media (min-width: 1424px) {
  html {
    --pagegrid-template-columns: [viewport_left] calc((100% - 1424px) / 2) 65px repeat(11, minmax(0, 100fr) 26px) minmax(0, 100fr) 65px calc((100% - 1424px) / 2) [viewport_right];
  }
}
html {
  --pagegrid-gutteroutside-mobile: $pagegrid-gutteroutside-mobile;
  --pagegrid-gutter-left: 3 / 6;
  --pagegrid-gutter-left-full: 3 / 8; /* no spacing to verywide content */
  --pagegrid-gutter-right: 23 / 26; /* next to verynarrow */
  --pagegrid-col-verynarrow: 9 / span 11;
  --pagegrid-col-narrow: 7 / span 15;
  --pagegrid-col-wide: 5 / span 19;
  --pagegrid-col-wide-include-gutter: 4 / span 21;
  --pagegrid-col-verywide: 3 / 26;
  --pagegrid-col-verywide-outsidegutter: 2 / 27;
  --pagegrid-col-default-left: 7 / span 7; /* 2 column / left */
  --pagegrid-col-default-right: 15 / span 7;
  --pagegrid-col-verywide-left: 3 / 14;
  --pagegrid-col-verywide-right: 15 / 26;
  --pagegrid-col-viewportstart-to-middle: 1 / 14;
  --pagegrid-col-middle-to-viewportend: 15 / 28;
  /*
  Focus with these traits:
  - FOCUS (image) up till exact middle
  - Ends at content end

  --pagegrid-col-verywide-focusleft:           3 / 15;
  --pagegrid-col-verywide-right-extraspacing: 17 / 26;

  --pagegrid-col-verywide-focusright:       15 / 26;
  --pagegrid-col-verywide-left-extraspacing: 3 / 13; / * keep an extra column spacing * /
  */
  /*
  Focus with these traits
  - gutter in the middle is clear in both left/right focus mode
  -
  */
  --pagegrid-col-verywide-focusleft: 3 / 14;
  --pagegrid-col-verywide-right-extraspacing: 15 / 26;
  --pagegrid-col-verywide-focusright: 15 / 26;
  --pagegrid-col-verywide-left-extraspacing: 3 / 14; /* keep an extra column spacing */
  --pagegrid-col-fullwidth: 1 / 28;
  --pagegrid-col-verywide-leftsmall: 3 / 10; /* 4 columns */
  --pagegrid-col-verywide-leftsmall-right: 11 / 26;
  --pagegrid-col-verywide-leftsmall-right-padding: 30px;
  --zindex-overlap-deeplinks: 10;
  --zindex-overlap-contentarea: 11;
}

@media (max-width: 1023px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-narrow);
  }
}
.deeplinks .page-backlink + .page-sharebuttons {
  margin-top: 30px;
}

/** Temporary rule can be removed once 4tu ethics import is done */
#importsourceurl {
  position: fixed;
  top: 0;
  left: 0;
  background: yellow;
  padding: 2px 6px;
  z-index: 9999;
  font-size: 80%;
  border-radius: 0 0 10px 0;
}

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.multiselect__valuedisplay svg {
  flex: none;
  width: 22px;
}

.multiselect svg > * {
  fill: #444444;
}

.multiselect:focus-within svg > * {
  fill: #000000;
}

.multiselect__panel__clear,
.multiselect__panel__ok {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font-family: var(--rtd-heading-fontfamily);
}

.multiselect__panel__cancel {
  height: var(--formcontrols-height);
  color: #888888;
  font-weight: 600;
}

.multiselect__panel__clear {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  font: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: var(--rtd-text-color);
}

.multiselect__panel__ok {
  display: flex;
  align-items: center;
  height: 36px;
  background-color: var(--color-theme);
  transition: background-color 0.25s;
  color: #FFFFFF;
  border-radius: 2px;
  border-radius: 2px;
  padding-left: 33px;
  padding-right: 33px;
  cursor: pointer;
}
.multiselect__panel__ok:hover {
  background-color: var(--color-theme-hover);
}
@media (max-width: 800px) {
  .multiselect__panel__ok {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.multiselect__panel__ok {
  margin-left: 25px;
}