.projectdetails-du-header {
  grid-row: 5;
  grid-column: var(--pagegrid-col-fullwidth);
  height: var(--pageheader-projectdetails-du-maxheight);
  min-height: var(--pageheader-projectdetails-du-minheight);
  max-height: min(80vh - var(--siteheader-height-approximation), 50vw);
  background-size: cover;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.projectdetails-du-header::before {
  grid-column: 1;
  grid-row: 1;
  content: "";
  grid-column: 1/-1;
  height: 116px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.projectdetails-du-header__navpath {
  grid-column: 1;
  grid-row: 1;
  margin-left: -7px;
  margin-top: 35px;
  grid-column: var(--pagegrid-col-verywide);
  font: 16px/19px var(--rtd-heading-fontfamily);
  color: #FFFFFF;
}

html.page--projectdetails-du.pageheader--inflow h1.page-header__title,
html.page--projectdetails-du.pageheader--inflow .page-header__subtitle {
  grid-column: var(--pagegrid-col-verywide);
}