/*
--rtd-link-color: #E1670B;
--rtd-text-color: #4F4F4F;
*/
/*
.consentbar
  .consentbar__poscontainer centered
    .consentbar__content
      .consentbar__text
      .consentbar__buttonbar
        .consentbar__settingslink
        .consentbar__acceptcookiesbutton
*/
.consentbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  opacity: 1;
  transition: opacity 0.5s;
}

@media screen {
  .consentbar--visible {
    display: block !important;
    opacity: 1;
  }
}
.consentbar--consentgiven {
  pointer-events: none;
  opacity: 0;
}

.consentbar {
  background-color: var(--consentbar-background);
  box-shadow: 0 0px 13px rgba(0, 0, 0, 0.4);
  padding: var(--consentbar-padding);
}

.consentbar__buttonbar {
  flex: none;
}

.consentbar__buttonbar > * + * {
  margin-left: 20px;
}

/* override p.normal, ul.unordered, ol.ordered */
.consentbar__text.consentbar__text.consentbar__text > * {
  color: var(--consentbar-text-color);
  font: var(--consentbar-text-font);
}

.consentbar__text.consentbar__text.consentbar__text > h2 {
  color: var(--consentbar-heading-color);
  font: var(--consentbar-heading-font);
}

.consentbar__text a {
  color: var(--rtd-link-color);
  text-decoration: underline;
}

.consentbar__buttonbar {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/*
.consentbar__acceptcookiesbutton::before
{
  content: "\f00c";

  display: inline-block;
  font: normal normal normal 14px/1 'Font Awesome 5 Pro';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // fix for Edge ////////
  line-height: 41px;
  height: 41px;
  //////////////////////////

  background: none;

  margin-right: 17px;
}
*/
.consentbar__settingslink {
  color: #666666;
  text-decoration: underline;
  font: var(--consentbar-preferenceslink-font);
}