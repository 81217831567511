/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.filteredoverview__items a {
  text-decoration: none;
}

.programmes .programmes__item__title a,
.programmes a.programmes__subitem {
  color: inherit;
  cursor: pointer;
}

.filteredoverview__noresults {
  grid-column: var(--pagegrid-col-verywide);
}

.filteredoverview--noresults .programmes {
  display: none;
}

table.programmes {
  border-collapse: collapse;
  font: 19px var(--rtd-heading-fontfamily);
}

table.programmes td {
  padding: 0;
  border: 0;
}

.programmes__item__title {
  flex: auto;
  width: 100%;
  font-weight: bold;
}

.programmes__subitem .programmes__item__title {
  font-weight: normal;
}

.programmes__header td {
  font-weight: normal;
}

.programmes {
  font-size: 16px;
}

.programmes__item {
  border-bottom: 1px solid #D0C8BF;
  color: #3F3F3F;
}

/* because we use a class instead of media query, we require an extra class to override by specificy */
a.programmes__item.programmes__item {
  color: #3F3F3F;
}

a.programmes__item.programmes__item:hover {
  background-color: #F7F6F5;
}

.programmes__item.hidden.programmes__item.hidden,
.programmes__item.hidden.programmes__item.hidden + .programmes__item__subitems {
  display: none;
}

.programmes__item--expanded {
  background-color: #F0EEEB;
}

.programmes__item__degree,
.programmes__item__university,
.programmes__item__trackcount {
  white-space: nowrap;
}

.programmes__item--hastracks .programmes__item__trackcount {
  cursor: pointer;
}

.programmes__item__degree {
  width: 100px;
}

.programmes__item__org {
  white-space: nowrap;
  width: 200px;
}

.programmes__item__trackcount {
  flex: auto;
}

.programmes__item .programmes__item__trackcount {
  text-decoration: underline;
}

.programmes__item--expand {
  background-color: #F2EFED;
}

.programmes__item__subitems {
  display: none;
  padding-left: 23px;
  background-color: #F7F6F5;
  border-bottom: 1px solid #D0C8BF;
}

.programmes__item__subitems .programmes__item__title::after {
  display: none !important;
}

.specialization__title.specialization__title.specialization__title {
  display: flex;
}

.specialization__title::before {
  display: inline-block;
}

@media (max-width: 599px) {
  .filteredoverview__items {
    grid-column: var(--pagegrid-col-fullwidth);
  }
  .programmes__item__title {
    display: flex;
    align-items: baseline;
  }
  .programmes__item__title::after {
    margin-left: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f105";
  }
  .programmes__item--expand .programmes__item__title::after {
    content: "\f107";
  }
  a .programmes__item__title::after {
    color: #F38F1D;
  }
  .programmes__item__degree,
  .programmes__item__org {
    display: none;
  }
  .programmes__item__trackcount {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: -8px;
    padding-bottom: 8px;
  }
  .programmes__item__trackcount:empty {
    display: none;
  }
  .programmes__item--expand + .programmes__item__subitems {
    display: block;
  }
}
@media (min-width: 1020px) {
  .programmes__subitem {
    display: table-row;
  }
  .programmes__subitem > * {
    display: table-cell;
    padding-top: 15px;
    padding-bottom: 12px;
    padding-left: 23px;
  }
  .programmes__item--expand + .programmes__item__subitems {
    display: table-row;
  }
}
/*body.desktop.type3*/
@media (max-width: 1019px) {
  .programmes__item__degree {
    display: none;
  }
  .programmes__item__org {
    display: none;
  }
}
@media (max-width: 599px) {
  .programmes__header__mobile {
    display: block;
    margin-top: 20px;
    font-size: 16px;
    height: 44px;
    line-height: 44px;
    padding-left: 23px;
    color: #FFFFFF;
    background-color: #E1670B;
  }
  .programmes__themeheader {
    height: 50px;
    background-color: #63605E;
    color: #FFFFFF;
    text-transform: uppercase;
  }
  .programmes__themeheader.programmes__themeheader td {
    padding-left: 23px;
    padding-top: 5px;
  }
  .programmes .spacer {
    height: 50px;
  }
  .programmes__header {
    display: none;
  }
  .programmes__item {
    display: block;
  }
  .programmes__item {
    padding: 15px 15px 12px 15px;
  }
  .programmes__subitem {
    padding: 6px 0 6px;
  }
  .programmes__subitem .programmes__item__trackcount {
    display: none;
  }
  .programmes__subitem > * {
    padding-top: 10px;
    padding-bottom: 7px;
  }
  .programmes__item__degree,
  .programmes__item__org,
  .programmes__item__university {
    display: none;
  }
}
@media (min-width: 600px) {
  .filteredoverview__items {
    grid-column: var(--pagegrid-col-verywide);
  }
  .programmes__header__mobile {
    display: none;
  }
  .programmes {
    position: relative;
  }
  .programmes__header {
    display: table-row;
    height: 44px;
    color: #FFFFFF;
    background-color: #E1670B;
  }
  tr.programmes__item,
  .programmes__subitem .programmes__item__title {
    height: 60px;
  }
  .programmes__header.programmes__header > * {
    vertical-align: middle;
    padding-top: 5px;
  }
  .programmestheme {
    display: table-row-group;
  }
  .programmestheme.hidden {
    /*
    https://stackoverflow.com/questions/12710226/visibilitycollapse-is-rendered-as-visibilityhidden
    https://stackoverflow.com/questions/25807564/hiding-a-tr-while-still-involving-it-in-width-calculations/25807729#25807729

    (another fix might be to add line-height: 0; to the visibility: collapse; ?)
    */
    display: none;
  }
  .programmestheme > .spacer {
    height: 50px;
  }
  .programmes__themeheader {
    width: 100%;
    height: 60px;
    color: #FFFFFF;
    background-color: var(--rtd-text-color);
    text-transform: uppercase;
  }
  .programmes__themeheader.programmes__themeheader td {
    padding-left: 23px;
    padding-top: 3px;
  }
  .programmes {
    display: table;
  }
  .programmes__item {
    display: table-row;
  }
  .programmes__subitem .programmes__item__title {
    display: flex;
    align-items: center;
  }
  .programmes__subitem .programmes__item__title::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f054";
    font-size: 15px;
    margin-right: 16px;
    margin-top: -2px;
    color: #E1670B;
  }
  .programmes__header.programmes__header > *,
  .programmes__item.programmes__item > * {
    display: table-cell;
    padding-right: 30px;
  }
  .programmes__header.programmes__header > *:first-child,
  .programmes__item.programmes__item > *:first-child {
    padding-left: 23px;
  }
  .programmes__item.programmes__item > * {
    padding-top: 15px;
    padding-bottom: 12px;
  }
  /*
  .programmes__subitem
  {
    padding-top: 15px;
    padding-bottom: 12px;
  }
  */
  .programmes__item.programmes__item > *:last-child {
    padding-right: 0;
  }
}