#blogsoverviewfilter .projectsoverview__filters__tags {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
@media (max-width: 899px) {
  #blogsoverviewfilter .projectsoverview__filters__tags {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 30px;
  }
  #blogsoverviewfilter .filterpanel[data-categoryid=blog-topics] .filterpanel__options {
    column-gap: 25px;
    column-count: 2;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  #blogsoverviewfilter .projectsoverview__filters__tags {
    display: flex;
    flex-direction: column;
    column-gap: 30px;
    row-gap: 30px;
  }
  #blogsoverviewfilter .filterpanel[data-categoryid=blog-topics] .filterpanel__options {
    column-count: 3;
  }
}
@media (min-width: 1200px) {
  #blogsoverviewfilter .projectsoverview__filters__tags {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    column-gap: 30px;
  }
  #blogsoverviewfilter .filterpanel[data-categoryid=blog-topics] .filterpanel__options {
    column-count: 3;
  }
}