/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
.wh-form__arrayadd {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
}
.wh-form__arrayadd:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__arrayadd .wh-form__button--disabled {
  background-color: var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}
.wh-form__arrayadd::after {
  margin-left: 35px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f055";
  font-size: 18px;
  vertical-align: middle;
}

.wh-form__arrayadd::before {
  content: "Add";
}

.wh-form__arrayinput[data-wh-form-name=images] ~ .wh-form__arrayadd::before {
  content: "Add image";
}

.wh-form__arrayinput[data-wh-form-name=documents] ~ .wh-form__arrayadd::before {
  content: "Add document";
}

.wh-form__arrayrow {
  align-items: baseline;
  display: flex;
  margin-bottom: 10px;
}

.wh-form__arrayrow > .wh-form__fieldgroup {
  flex: 1;
  margin-bottom: 0;
}

.wh-form__arrayrow + .wh-form__arrayrow {
  margin-top: 20px;
}

.wh-form__arrayrow.wh-form__arrayrow.wh-form__arrayrow.wh-form__arrayrow .wh-form__fieldgroup {
  margin-bottom: 0;
}

.wh-form__arraydelete {
  margin-left: 15px;
  border: 0;
  background-color: transparent;
  color: var(--rtd-text-color);
  cursor: pointer;
  flex: none;
  padding: 5px;
}
.wh-form__arraydelete::before {
  font: var(--formcontrol-icon-delete-font);
  content: var(--formcontrol-icon-delete-icon);
}