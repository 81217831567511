.ftu-tagsblock
{
  display: flex;
  flex-wrap: wrap;
  column-gap: 9px;
  row-gap: 7px;
}

.ftu-tagsblock__item
{
  display: inline-flex;
  align-items: center;

  color: var(--component-tag-color);

  min-height: var(--component-tag-height);

  font: 14px/17px var(--rtd-text-fontfamily);

  border: 1px solid var(--component-tag-border-color);
  border-radius: var(--component-tag-radius);

  /* the top has an extra 4px to compensate for Officina's weird font alignment */
  padding: var(--component-tag-padding);

  text-decoration: none;
}

  a.ftu-tagsblock__item
, .ftu-tagsblock__item[data-ftulink]
{
  cursor: pointer;
}

  a.ftu-tagsblock__item:hover
, .ftu-tagsblock__item[data-ftulink]:hover
{
  background: var(--component-tag-hover-background);
  color: var(--color-white);
}
