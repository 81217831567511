/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
.widget-richcontent-with-links {
  --content-squeeze-preference: needwide;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-fullwidth);
}

.widget-richcontent-with-links.widget--includespadding {
  padding-top: var(--widget-generic-bigpanel-padding-top);
  padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
}

.widget-richcontent-with-links.widget--background-grey {
  background-color: #FAF6F4;
}

.widget-richcontent-with-links.widget--background-white {
  border-top: 1px solid var(--widget-generic-panel-divider-color);
  border-bottom: 1px solid var(--widget-generic-panel-divider-color);
}

.widget-richcontent-with-links__title {
  color: var(--text-grey-large);
}

.widget-richcontent-with-links__title b {
  color: #000000;
  font-weight: bold;
}

.widget-richcontent-with-links__links ul {
  margin-top: -7px;
  margin-bottom: 0;
  padding: 0 0 0 17px;
}

.widget-richcontent-with-links__links li {
  padding-left: 30px;
}

/*
NOTE: Safari 15.4 doesn't support using the content property for ::marker
.widget-richcontent-with-links__links li::marker
{
  font: var(--icon-link-font);
  content: var(--icon-link-content);

  color: var(--text-grey-large);
}
*/
.widget-richcontent-with-links__links li {
  list-style: none;
  font: var(--icon-link-font);
  content: var(--icon-link-content);
}

.widget-richcontent-with-links__links li::before {
  margin-left: -30px;
  margin-right: 30px;
  font: var(--icon-link-font);
  content: var(--icon-link-content);
  color: var(--text-grey-large);
}

.widget-richcontent-with-links__links a {
  text-decoration: none;
  color: #000000;
  font: bold 22px/30px var(--rtd-heading-fontfamily);
  display: inline-flex; /* to grow hit-area using padding while keeping baseline alignment with ::marker */
  padding-top: 7px;
  padding-bottom: 7px;
}

.widget-richcontent-with-links__links a:hover {
  text-decoration: underline;
  color: var(--color-theme);
}

@media (max-width: 599px) {
  .widget-richcontent-with-links__contents {
    --content-layout: verywide;
    grid-column: var(--pagegrid-col-verywide);
  }
  .widget-richcontent-with-links__title {
    color: var(--text-grey-large);
    font: 30px/38px var(--rtd-heading-fontfamily);
  }
  .widget-richcontent-with-links__title.widget-richcontent-with-links__title + * {
    margin-top: 34px;
  }
  .widget-richcontent-with-links__links {
    margin-top: 15px;
  }
  /*
    .widget-richcontent__content
    {
      display: block;
      padding-left:  $pagegrid-gutteroutside-mobile;
      padding-right: $pagegrid-gutteroutside-mobile;
    }

    .widget-richcontent__column + .widget-richcontent__column
    {
      margin-top: $rtdcontent_vspace;
    }

    .widget-richcontent__column:first-child > *:first-child
    {
      margin-top: 0;
    }

    .widget-richcontent__column:last-child > *:last-child
    {
      margin-bottom: 0;
    }
  */
}
@media (min-width: 600px) {
  .widget-richcontent-with-links__contents {
    --content-layout: verywide;
    grid-column: var(--pagegrid-col-verywide);
    display: flex;
    align-items: center;
    position: relative;
  }
  /*
    .widget-richcontent-with-links__contents::before
    {
      position: absolute;
      top: 10px;
      bottom: 0;
      left: 50%;
      border-right: 1px solid var(--color-divider);
      content: "";
    }
  */
  .widget-richcontent-with-links__title {
    font: 62px/65px var(--rtd-heading-fontfamily);
    margin-bottom: 34px;
  }
  /*
    .widget-richcontent-with-links__textcontent
    {
      padding-bottom: calc(var(--widget-generic-panel-padding-bottom) * 0.5);
    }
  */
  .widget-richcontent-with-links__textcontent,
  .widget-richcontent-with-links__links {
    flex: 1 1 50%;
  }
  .widget-richcontent-with-links__divider {
    flex: none;
    height: 100%;
    border-right: 1px solid var(--color-divider);
    margin-left: 30px;
    margin-right: 30px;
    margin-left: max(30px, 3vw);
    margin-right: max(30px, 3vw);
  }
  .widget-richcontent-with-links__links {
    padding-top: 50px;
  }
  .widget-richcontent-with-links__textcontent > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent-with-links__textcontent > *:last-child {
    margin-top: 0;
  }
}