/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/*

By using the following DOM you can make checkboxes/radiobuttons which look like tabs or toggle buttons.

<div class="comp-togglebuttonbar">
  <input id="" type="checkbox" name="..." />
  <label class="comp-togglebutton" for=""></label>
</div>

*/
.comp-togglebuttonbar {
  display: flex;
  flex-wrap: wrap; /* rather not, but it's better than overflowing */
  column-gap: 20px;
  row-gap: 16px;
}

.comp-togglebutton {
  flex: none;
  cursor: pointer;
}

.comp-togglebuttonbar--stretch {
  flex: 1 1 auto;
}

.comp-togglebuttonbar input[type=checkbox],
.comp-togglebuttonbar input[type=radio] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}

.comp-togglebutton::before {
  display: none !important;
}

input:checked + .comp-togglebutton {
  color: #FFFFFF;
  border-color: transparent;
  height: 100%;
}

input:checked + .comp-togglebutton + input:checked + .comp-togglebutton.selected {
  border-left: 1px solid rgba(255, 255, 255, 0.25);
}