/** Default whitespace between header and content
    In some cases (heading image followed by content with fullwidth color background)
    this margin will be removed.
*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/*****************************************************************************
*
*   Concept overlay
*/
.projectdetails__conceptoverlay {
  grid-row: 1;
  margin-bottom: 30px;
  background-color: rgba(255, 139, 56, 0.98);
  color: #FFFFFF;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.projectdetails__conceptoverlay__content {
  grid-column: var(--pagegrid-col-verywide);
  padding: 25px 0;
}
@media (max-width: 767px) {
  .projectdetails__conceptoverlay__content .conceptoverlay__publishbutton {
    margin-top: 15px;
  }
}
@media (min-width: 768px) {
  .projectdetails__conceptoverlay__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .projectdetails__conceptoverlay__content .conceptoverlay__publishbutton {
    margin-left: 30px;
  }
}

.conceptoverlay__title {
  color: #FFFFFF;
  font: bold 25px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.conceptoverlay__text .rtdcontent > * {
  color: #FFFFFF;
  font-size: 16px;
}

.conceptoverlay__publishbutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: var(--formcontrols-height);
  padding: var(--button-text-alignment-tweak) 20px 0 20px;
  border-radius: var(--button-borderradius);
  transition: background-color 0.3s;
  cursor: pointer;
  outline-offset: 2px;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  padding-left: 30px;
  padding-right: 15px;
  margin-left: auto;
}
.conceptoverlay__publishbutton:hover {
  background-color: #FFFFFF;
  color: var(--button-color);
  text-decoration: none;
}
.conceptoverlay__publishbutton .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: #666666;
  cursor: default;
}

.conceptoverlay__publishbutton::after {
  margin-left: 20px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 13px;
  vertical-align: middle;
}

/*****************************************************************************
*
*   Main layout
*/
@media (max-width: 799px) {
  .projectdetails--twocol {
    margin-top: 20px;
  }
}
@media (min-width: 800px) {
  .projectdetails--twocol {
    margin-top: 40px;
  }
}
.projectdetails--twocol {
  margin-bottom: 70px;
}
@media (max-width: 1279px) {
  .projectdetails--twocol {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1280px) {
  .projectdetails--twocol {
    grid-column: 7/span 19;
  }
}

@media (max-width: 600px) {
  .projectdetails--twocol > div {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .projectdetails__contentright {
    border-top: 1px solid #C5C5C5;
    padding-top: 30px;
    margin-top: 30px;
  }
}
@media (min-width: 850px) {
  .projectdetails--twocol {
    display: flex;
  }
  .projectdetails__contentleft {
    flex: 1 1 auto;
    padding-right: 50px;
    word-break: break-word;
  }
  .projectdetails__contentright {
    flex: 1 1 auto;
    max-width: 40%;
    padding-left: 50px;
    padding-bottom: 25px;
    border-left: 1px solid #C5C5C5;
  }
}
.projectdetails__contentleft a {
  word-break: break-word;
}

/*****************************************************************************
*
*   Meta info / Contacts
*/
.project__contacts {
  margin: 0;
  padding: 0;
  list-style: none; /* we only use <ul> to signal to screenreaders it's a list */
}

.project-contact {
  display: flex;
  align-items: center;
}

.project-contact + .project-contact {
  margin-top: 10px;
}

.project-contact--withphoto + .project-contact {
  margin-top: 20px;
}

/* for DU projects (Innovations shows a placeholder image instead) */
.project-contact__bullet {
  align-self: baseline;
  color: #8E8E8E;
  font: bold 15px Lato;
  margin-right: 10px;
}

.project-contact__photo {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background-size: cover;
  background-color: #F2EFED;
  border-radius: 50%;
  margin-right: 20px;
}

.project-contact__photo::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f007";
  color: var(--color-theme);
  font-size: 28px;
}

.project-contact__photo.b-loaded::before {
  display: none;
}

.project-contact__contactinfo {
  font: 15px var(--rtd-text-fontfamily);
}

.project-contact__contactinfo__name {
  color: #3F3F3F;
  font-weight: bold;
}

.project-contact__contactinfo__email {
  display: table;
  text-decoration: none;
  color: inherit;
}

.project-contact__contactinfo__email:hover {
  text-decoration: underline;
}

.project-contact__contactinfo__profilelink {
  font-size: 13px;
  color: var(--color-theme);
  align-items: center;
  text-decoration: none;
}

.project-contact__contactinfo__profilelink:hover {
  text-decoration: underline;
}

.project-contact__contactinfo__profilelink::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f105";
  font-size: 18px;
  margin-right: 6px;
  position: relative;
  top: 2px;
}

/*****************************************************************************
*
*   Sidebar (meta)
*/
.projectdetails-meta {
  font: 15px/18px var(--rtd-text-fontfamily);
}
.projectdetails-meta h2 {
  font: bold 19px var(--rtd-heading-fontfamily);
  margin-bottom: 10px;
}
.projectdetails-meta .meta-item + .meta-item {
  margin-top: 35px;
}

/*****************************************************************************
*
*   Sidebar (meta) / Tags
*/
.meta-item--status .filtertags__item {
  margin: 0;
}

.meta-item--themes .tagslist,
.meta-item--tags .tagslist {
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-bottom: -8px;
}
.meta-item--themes .filtertags__item,
.meta-item--tags .filtertags__item {
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 8px;
  margin-top: 0;
}

.meta-item--themes .filtertags__item.filtertags__item {
  border-color: var(--color-theme);
  color: var(--color-theme);
}
.meta-item--themes .filtertags__item.filtertags__item:hover {
  background-color: var(--color-theme);
  color: #FFFFFF;
}

.meta-item--status .filtertags__item.filtertags__item,
.meta-item--tags .filtertags__item.filtertags__item {
  border-color: #3F3F3F;
  color: #3F3F3F;
}
.meta-item--status .filtertags__item.filtertags__item:hover,
.meta-item--tags .filtertags__item.filtertags__item:hover {
  border-color: var(--color-theme);
  color: var(--color-theme);
}
.meta-item--status .filtertags__item::after,
.meta-item--tags .filtertags__item::after {
  display: none;
}
.meta-item--status .filtertags__item--started::before,
.meta-item--tags .filtertags__item--started::before {
  margin-right: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f017";
}
.meta-item--status .filtertags__item--ongoing::before,
.meta-item--tags .filtertags__item--ongoing::before {
  margin-right: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f110";
}
.meta-item--status .filtertags__item--completed::before,
.meta-item--tags .filtertags__item--completed::before {
  margin-right: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00c";
}

/*****************************************************************************
*
*   Sidebar (meta) / Downloads
*/
.meta-item--downloads a {
  display: flex;
  align-items: baseline;
  color: var(--rtd-text-color);
  font: 15px/18px var(--rtd-text-fontfamily);
  word-break: break;
  text-decoration: none;
}

.meta-item--downloads a:hover {
  text-decoration: underline;
}

.downloaditem__icon {
  flex: none;
  width: 27px;
  color: var(--color-theme);
  font-size: 18px;
}

.downloaditem__meta {
  color: var(--rtd-text-color);
  flex: 1 1 0;
}

.downloaditem__filesize {
  color: #8E8E8E;
  white-space: nowrap;
}

/*****************************************************************************
*
*   Sidebar (meta) / Downloads
*/
.meta-item--websites a {
  display: flex;
  align-items: baseline;
  color: var(--rtd-text-color);
  font: 15px/18px var(--rtd-text-fontfamily);
  text-decoration: none;
  word-break: break-word;
}

.meta-item--websites a:hover {
  text-decoration: underline;
}

.meta-item--websites a::before {
  flex: none;
  width: 27px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f37e";
  color: var(--color-theme);
  font-size: 18px;
}