/*
@import "../../shared/config.scss";

Views
- Mobile         -> stacked, image above
- Tablet/desktop -> Image floating top right


$widget-cta__background:          "fullwidth";   // "normal", "borders", "fullwidth"
$widget-cta__layout:              "gridaligned"; // "image-float", "gridaligned"
$widget-cta__minwidth-sidebyside: 700px;         // NOTE: could also use $minwidth-centercontent
*/


.widget-cta
{
  --content-layout:             verywide;
  --content-squeeze-preference: needwide;

  --rtd-link-color:    var(--theme-textcolor);
  --rtd-heading-color: var(--theme-textcolor);
  --rtd-text-color:    var(--theme-textcolor);

  z-index: var(--zindex-overlap-deeplinks);

  background: var(--theme-background);

  /* outline: 3px solid #F00; */
}

.widget-cta .widget__subtitle
{
  color: var(--rtd-text-color);
}

.widget-cta__image
{
  background-size: cover;
}

.widget-cta__subtitle + .widget-cta__title
{
  margin-top: 4px;
}

  .widget-cta__text > * + .widget-cta__description
, .widget-cta__text > * + .widget-cta__button
{
  margin-top: 25px;
}


.widget-cta--withbackground
{
  .rtdcontent > p.normal > a
  {
    text-decoration: underline;
  }

  .widget-cta__text
  {
/*    padding-bottom: 30px; */
  }
}


/*
Mobile
// @media (max-width: $widget-cta__minwidth-sidebyside - 1px)
*/
@media (max-width: 699px)
{
  .widget-cta
  {
    grid-column: var(--pagegrid-col-fullwidth);
  }

  .widget-cta__text
  {
    padding-top:    var(--widget-generic-panel-padding-top);
    padding-bottom: var(--widget-generic-panel-padding-bottom);
    padding-left:   var(--pagegrid-gutteroutside);
    padding-right:  var(--pagegrid-gutteroutside);
  }

  .widget-cta.colortheme--grey
  {
    padding-top:    var(--widget-generic-bigpanel-padding-top);
    padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
  }
}


/*
Tablet / desktop
@media (min-width: $widget-cta__minwidth-sidebyside)
*/
@media (min-width: 700px)
{
  .widget-cta
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .widget-cta__content
  {
    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

  .widget-cta--focusleft .widget-cta__text
  {
    grid-column: var(--pagegrid-col-verywide-right-extraspacing);
    grid-row: 1;
  }
  .widget-cta--focusleft .widget-cta__imagewrapper
  {
    grid-column: var(--pagegrid-col-verywide-focusleft);
    grid-row: 1;
  }

  .widget-cta--focusright .widget-cta__text
  {
    grid-column: var(--pagegrid-col-verywide-left-extraspacing);
    grid-row: 1;
  }
  .widget-cta--focusright .widget-cta__imagewrapper
  {
    grid-column: var(--pagegrid-col-verywide-focusright);
    grid-row: 1;
  }

  .widget-cta__image
  {
    width: 100%;
    height: 100%;
  }

  .widget-cta--showfullimage .widget-cta__imagewrapper
  {
    align-self: center;
  }

  .widget-cta--showfullimage .widget-cta__image
  {
    height: auto;
  }

  .widget-cta__text
  {
    padding-top:    var(--widget-generic-panel-padding-top);
    padding-bottom: var(--widget-generic-panel-padding-bottom);
  }



  /* with a grey background we place the whitespace around both
     the image and text.
  */
  .widget-cta.colortheme--grey
  {
    padding-top:    var(--widget-generic-bigpanel-padding-top);
    padding-bottom: var(--widget-generic-bigpanel-padding-bottom);
  }
  .widget-cta.colortheme--grey .widget-cta__text
  {
    padding-top: 0;
    padding-bottom: 0;
  }
}
